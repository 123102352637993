import { MessagingProviderIntegrationBase } from '../index'

export type GmailIntegrationCredentials = {
	type: 'gmail'
	email: string
	lastHistoryId: string
	accessToken: string
	refreshToken: string
} & MessagingProviderIntegrationBase

export type GmailHookRequest = {
	deliveryAttempt?: number
	message: {
		attributes?: {
			key: string
		}
		data: string //base64
		messageId: string
		message_id: string
		orderingKey?: string
		publishTime: string
		publish_time: string
	}
	subscription: string
}

export type GmailHookMessageData = {
	emailAddress: string
	historyId: number
}

export const DEFAULT_GMAIL_LABELS = [
	'INBOX',
	'UNREAD',
	'STARRED',
	'IMPORTANT',
	'CATEGORY_PERSONAL',
	'CATEGORY_SOCIAL',
	'CATEGORY_PROMOTIONS',
	'CATEGORY_UPDATES',
	'CATEGORY_FORUMS',
] as const

export const GMAIL_SCOPE = 'https://mail.google.com/' as const
