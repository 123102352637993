import React, { useState } from 'react'
import TextArea from 'antd/es/input/TextArea'
import { App, Button, Descriptions, DescriptionsProps, Form, Input, Select } from 'antd'
import query from '../../../../../../utils/query'
import useTryCatch from '../../../../../../hooks/useTryCatch'
import { AdminAccount, GLOBAL_ACCOUNT_ROLES } from '@neuron/types/account'
import AccountRoleTag from '../AccountRoleTag/AccountRoleTag'
import { useAccountContext } from '../../../../../../hooks/context/AccountContext'
import styles from './AccountForm.module.scss'

type Props = {
	updateAccount: (account: AdminAccount) => void
	account: AdminAccount
}

type AccountData = Pick<AdminAccount, 'note' | 'brokerEmail' | 'roles'>

const AccountForm = ({ account, updateAccount }: Props) => {
	const { message } = App.useApp()
	const tryCatch = useTryCatch(message)

	const accountContext = useAccountContext()
	const isAdmin = accountContext.useSubscribe((context) => context.roles.includes('admin'))

	const [accountData, setAccountData] = useState<AccountData>({
		note: account.note,
		brokerEmail: account.brokerEmail,
		roles: account.roles,
	})

	const updateAccountData = (_value: Record<string, any>, values: AccountData) => {
		setAccountData(values)
	}

	const onUpdateAccountData = async () => {
		await tryCatch(
			async () => {
				const updatedAccount = await query<AdminAccount>('/account/admin/update', 'POST', {
					data: {
						id: account.id,
						...accountData,
					},
					withCredentials: true,
				})

				updateAccount(updatedAccount)
				message.open({
					type: 'success',
					content: 'Account data updated',
				})
			},
			undefined,
			{ message: 'Error while updating account data. Try again.' },
		)
	}

	const accountDetails: DescriptionsProps['items'] = [
		{
			key: '1',
			label: 'First name',
			children: <span>{account.firstName ?? '-'}</span>,
		},
		{
			key: '2',
			label: 'Last name',
			children: <span>{account.lastName ?? '-'}</span>,
		},
		{
			key: '3',
			label: 'Phone',
			children: <span>{account.phone ?? '-'}</span>,
		},
		{
			key: '4',
			label: 'Company name',
			children: <span>{account.company?.name ?? '-'}</span>,
		},
		{
			key: '5',
			label: 'Tax ID',
			children: <span>{account.company?.taxId ?? '-'}</span>,
		},
		{
			key: '6',
			label: 'Company Address',
			children: <span>{account.company?.address ?? '-'}</span>,
		},
		{
			key: '7',
			label: 'Company Country',
			children: <span>{account.company?.country ?? '-'}</span>,
		},
		{
			key: '8',
			label: 'Company Province',
			children: <span>{account.company?.province ?? '-'}</span>,
		},
		{
			key: '9',
			label: 'Company City',
			children: <span>{account.company?.city ?? '-'}</span>,
		},
		{
			key: '10',
			label: 'Company Postal Code',
			children: <span>{account.company?.postalCode ?? '-'}</span>,
		},
	]

	return (
		<Form
			className={styles.form}
			initialValues={accountData}
			onValuesChange={updateAccountData}
			labelCol={{ span: 4 }}
			wrapperCol={{ span: 14 }}
			layout='vertical'
		>
			<Descriptions className={styles.descriptions} bordered items={accountDetails} />

			{isAdmin && (
				<Form.Item name='roles' label='Account roles' className={styles.formItem}>
					<Select
						size='large'
						showSearch
						mode='multiple'
						options={GLOBAL_ACCOUNT_ROLES.map((role) => ({
							label: <AccountRoleTag role={role} />,
							value: role,
						}))}
					/>
				</Form.Item>
			)}

			<Form.Item
				rules={[{ message: 'Account note can have a maximum of 1000 characters', max: 1000 }]}
				className={styles.formItem}
				label='Account note'
				name='note'
			>
				<TextArea
					className={styles.formTextarea}
					size='large'
					allowClear
					maxLength={1000}
					autoSize={{ minRows: 2, maxRows: 4 }}
					placeholder='Note'
				/>
			</Form.Item>

			<Form.Item
				rules={[
					{ type: 'email', message: 'Invalid broker e-mail format' },
					{
						message: 'Broker e-mail can have a maximum of 250 characters',
						max: 250,
					},
				]}
				className={styles.formItem}
				label='Broker e-mail'
				name='brokerEmail'
			>
				<Input
					allowClear
					size='large'
					className={styles.formInput}
					maxLength={250}
					placeholder='Broker e-mail'
				/>
			</Form.Item>

			<Button size='large' onClick={onUpdateAccountData}>
				Save
			</Button>
		</Form>
	)
}

export default AccountForm
