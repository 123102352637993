import React, { useMemo } from 'react'
import ContextTextarea from './ContextTextarea/ContextTextarea'
import ContextUrl from './ContextUrl/ContextUrl'
import UploadContextFile from './UploadContextFile/UploadContextFile'
import { FileContent, LinkContent, Tab, TextContent } from '../../../types/AiContext'
import { useAiContext } from '../../../hooks/context/AiContext'
import { SourceType } from '@neuron/types/context'
import styles from './Context.module.scss'

type Props = {
	tab?: Tab
	createNewTab: (type: SourceType, tab?: Partial<Tab>) => void
}

const Context = ({ tab, createNewTab }: Props) => {
	const aiContext = useAiContext()

	const updateTab = (newTab: Partial<Tab>) => {
		if (!tab) {
			return
		}
		if (newTab.name && !tab.name.startsWith('Website')) {
			// Website is a default name for url tab
			newTab.name = tab.name
		}
		aiContext.updateTab(tab.id, newTab)
	}

	const getContext = useMemo(() => {
		if (!tab) {
			return <></>
		}

		if (tab.type === 'text') {
			return <ContextTextarea content={tab.content as TextContent} updateTab={updateTab} />
		}

		if (tab.type === 'url') {
			return <ContextUrl content={tab.content as LinkContent} updateTab={updateTab} />
		}

		if (tab.type === 'file') {
			return (
				<UploadContextFile
					content={tab.content as FileContent}
					updateTab={updateTab}
					createNewTab={createNewTab}
				/>
			)
		}

		return <></>
	}, [tab?.type, tab?.content])

	if (!tab) {
		return (
			<div className={styles.typeButtons}>
				<button className={styles.typeButton} onClick={() => createNewTab('text')}>
					Plain text
				</button>
				<button
					className={`${styles.typeButton} ${styles.centerButton}`}
					onClick={() => createNewTab('url')}
				>
					Website content
				</button>
				<button className={styles.typeButton} onClick={() => createNewTab('file')}>
					File content
				</button>
			</div>
		)
	}

	return getContext
}

export default Context
