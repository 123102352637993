import { MessagingIntegrationProvider } from '../integration/messaging'
import { UsingUserId } from '../global'
import { SellerOffersRole } from './roles/sellerOffers'
import { AppointmentSetterRole } from './roles/appointmentSetter'
import { HelpdeskRole } from './roles/helpdesk'
import { HelpdeskUpsellRole } from './roles/helpdeskUpsell'
import { InternalAssistantRole } from './roles/internalAssistant'
import { ExternalAssistantRole } from './roles/externalAssistant'
import { LeadGenerationRole } from './roles/leadGeneration'

export type AssistantRole = BaseAssistantRole & Roles

export type BaseAssistantRole = {
	id: string
	assistantId: string
	name: string
	additionalInformation?: string
	naturalizationBooster?: boolean
	redirectConditions?: RedirectCondition[]
	contextIds?: number[]
	connections?: RoleConnections
	userId: string
	createdAt: Date
	updatedAt?: Date
}

export type Roles =
	| SellerOffersRole
	| LeadGenerationRole
	| AppointmentSetterRole
	| HelpdeskRole
	| HelpdeskUpsellRole
	| InternalAssistantRole
	| ExternalAssistantRole

export type CreateAssistantRole = Omit<
	BaseAssistantRole,
	'id' | 'userId' | 'createdAt' | 'integrations' | 'leadsGroupIds' | 'contextIds'
> &
	Roles

export type RedirectCondition = {
	condition: string
	message?: string
}

export type RoleConnections = {
	leadsGroupIds?: string[]
	integrations?: AssistantRoleIntegration[]
}

export type GoalUrl = {
	url: string
	context: string
}

export type AssistantRoleType = AssistantRole['type']
export const ASSISTANT_ROLES: AssistantRoleType[] = [
	'helpdesk',
	'sellerOffers',
	'leadGeneration',
	'appointmentSetter',
	'helpdeskUpsell',
	'internalAssistant',
	'externalAssistant',
]

export const FOLLOWUP_INTERVAL = [
	1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23,
] as const
export type FollowupInterval = (typeof FOLLOWUP_INTERVAL)[number]

export const MAX_FOLLOWUPS = [1, 2, 3, 4, 5] as const
export type MaxFollowups = (typeof MAX_FOLLOWUPS)[number]

export const WARMUP_LEVEL = [1, 2, 3, 4] as const
export type WarmupLevel = (typeof WARMUP_LEVEL)[number]

export const CONVERSATION_STYLES = ['official', 'balanced', 'casual'] as const
export type ConversationStyle = (typeof CONVERSATION_STYLES)[number]

export const RESPONSE_LENGTHS = ['short', 'balanced', 'long'] as const
export type ResponseLength = (typeof RESPONSE_LENGTHS)[number]

export type AssistantRoleIntegration = {
	id: string
	provider: MessagingIntegrationProvider
}

// API
export type GetAssistantRoleRequest = Pick<AssistantRole, 'id'>
export type GetAssistantRolesRequest = {
	assistantId: string
}
export type GetAssistantRolesByIntegrationRequest = {
	integrationId: string
}
export type GetAssistantRolesByLeadsGroupRequest = {
	leadsGroupId: string
}
export type GetAssistantRolesByIntegrationProvidersRequest = {
	providers: MessagingIntegrationProvider[]
} & UsingUserId

export type CreateAssistantRoleRequest = Omit<
	BaseAssistantRole,
	'id' | 'userId' | 'createdAt' | 'integrations' | 'leadsGroupIds' | 'contextIds'
> &
	Roles &
	UsingUserId
export type UpdateAssistantRoleRequest = Partial<
	Omit<
		BaseAssistantRole,
		'userId' | 'type' | 'createdAt' | 'updatedAt' | 'integrations' | 'leadsGroupIds' | 'contextIds'
	>
> &
	Roles & { id: string }
export type DeleteAssistantRoleRequest = Pick<AssistantRole, 'id'>

export type UpdateAssistantRoleContextsRequest = Pick<AssistantRole, 'id'> & {
	contextIds: number[]
}
export type UpdateAssistantRoleLeadsGroupsRequest = Pick<AssistantRole, 'id'> & {
	leadsGroupIds: string[]
}
export type UpdateAssistantRoleIntegrationsRequest = Pick<AssistantRole, 'id'> & {
	integrations: AssistantRoleIntegration[]
}
