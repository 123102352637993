import { App, Button, Form, Input, Select, Switch } from 'antd'
import { useMemo, useState } from 'react'
import { NewLeadsGroup, LeadsGroup, UpdateLeadsGroupRequest } from '@neuron/types/leadsGroup'
import TextArea from 'antd/es/input/TextArea'
import useAsyncEffect from '../../../hooks/useAsyncEffect'
import query from '../../../utils/query'
import useTryCatch from '../../../hooks/useTryCatch'
import Loading from '../../../components/Loading/Loading'
import { Context } from '@neuron/types/context'
import { useAccountContext } from '../../../hooks/context/AccountContext'
import { formValidate } from '../../../utils/validation'
import { createLeadsGroupRequestSchema } from '@neuron/schemas/leadsGroup'
import { omit } from '@neuron/utils'
import { Template } from '@neuron/types/leadsGroup/template'
import FormRow from '../../../components/FormRow/FormRow'
import styles from './LeadsGroupForm.module.scss'

type Props = {
	onSubmit: (data: UpdateLeadsGroupRequest | NewLeadsGroup) => Promise<void>
	onCancel: () => void
	leadsGroupId?: string
}

const LeadsGroupForm = ({ onSubmit, leadsGroupId, onCancel }: Props) => {
	const { message } = App.useApp()
	const tryCatch = useTryCatch(message)

	const accountContext = useAccountContext()
	const usingUserId = accountContext.useSubscribe((account) => account.usingUserId)

	const [leadsGroup, setLeadsGroup] = useState<Partial<LeadsGroup> | undefined>()
	const [contexts, setContexts] = useState<Context[]>([])
	const [templates, setTemplates] = useState<Template[]>([])

	const [loadingLeadsGroup, setLoadingLeadsGroup] = useState<boolean>(true)
	const [loadingContexts, setLoadingContexts] = useState<boolean>(true)
	const [loadingTemplates, setLoadingTemplates] = useState<boolean>(true)

	useAsyncEffect(async () => {
		if (!leadsGroupId) {
			setLoadingLeadsGroup(false)
			return
		}

		setLoadingLeadsGroup(true)
		await tryCatch(
			async () => {
				const resLeadsGroup = await query<LeadsGroup>('/leadsGroup/get', 'GET', {
					params: { id: leadsGroupId },
					withCredentials: true,
				})

				setLeadsGroup(resLeadsGroup)
			},
			undefined,
			{ message: 'Fetch leads group data error. Try again and refresh page.' },
		)
		setLoadingLeadsGroup(false)
	}, [leadsGroupId])

	useAsyncEffect(async () => {
		setLoadingContexts(true)
		await tryCatch(
			async () => {
				const resContexts = await query<Context[]>('/context/forUser', 'GET', {
					withCredentials: true,
					params: {
						usingUserId,
					},
				})

				setContexts(resContexts)
			},
			undefined,
			{ message: 'Fetch contexts data error. Try again and refresh page.' },
		)
		setLoadingContexts(false)
	}, [])

	useAsyncEffect(async () => {
		setLoadingTemplates(true)
		await tryCatch(
			async () => {
				const resTemplates = await query<Template[]>('/leadsGroup/templates/user', 'GET', {
					withCredentials: true,
					params: {
						usingUserId,
					},
				})

				setTemplates(resTemplates)
			},
			undefined,
			{ message: 'Fetch templates data error. Try again and refresh page.' },
		)
		setLoadingTemplates(false)
	}, [])

	const onFinish = async () => {
		if (!isValid) {
			return
		}

		if (leadsGroupId) {
			const updateData: UpdateLeadsGroupRequest = {
				id: leadsGroupId,
				...toFormData(leadsGroup),
			}
			await onSubmit(updateData)
		} else {
			await onSubmit(toFormData(leadsGroup) as NewLeadsGroup)
		}
	}

	const onUpdateLeadGroup = (_value: any, values: Partial<LeadsGroup>) => {
		setLeadsGroup((group) => ({ ...(group ?? {}), ...values }))
	}

	const isValid = useMemo(() => {
		const validate = formValidate(toFormData(leadsGroup), createLeadsGroupRequestSchema)
		return validate ? !Object.keys(validate).length : true
	}, [leadsGroup])

	if (loadingContexts || loadingLeadsGroup || loadingTemplates) {
		return <Loading size={32} />
	}

	return (
		<Form
			autoComplete='true'
			initialValues={toFormData(leadsGroup)}
			onValuesChange={onUpdateLeadGroup}
			labelCol={{ span: 4 }}
			wrapperCol={{ span: 14 }}
			layout='vertical'
		>
			<Form.Item
				rules={[
					{ required: true, message: 'Please fill group name' },
					{ message: 'Group name must have a minimum of 3 characters', min: 3 },
					{ message: 'Group name can have a maximum of 120 characters', max: 120 },
				]}
				className={styles.formItem}
				label='Name'
				name='name'
			>
				<Input
					count={{
						show: true,
					}}
					maxLength={120}
					size='large'
					className={styles.formInput}
					placeholder='E-mail leads'
				/>
			</Form.Item>

			<Form.Item
				rules={[{ message: 'Group description can have a maximum of 2000 characters', max: 2000 }]}
				className={styles.formItem}
				label='Description'
				name='description'
			>
				<TextArea
					className={styles.formTextarea}
					size='large'
					allowClear
					count={{
						show: true,
					}}
					maxLength={2000}
					autoSize={{ minRows: 2, maxRows: 6 }}
					placeholder='Leads group description'
				/>
			</Form.Item>

			<Form.Item
				tooltip='Select the contexts from which leads are to be added.'
				className={styles.formItem}
				label='Contexts'
				name='contextIds'
			>
				<Select
					mode='multiple'
					allowClear
					size='large'
					showSearch
					placeholder='Select contexts'
					options={contexts.map((context) => ({
						label: context.resource.name,
						value: context.id,
						disabled: context.status !== 'indexed' && context.status !== 'needReindex',
					}))}
				/>
			</Form.Item>

			<FormRow>
				<Form.Item
					className={styles.formItem}
					label='Auto start conversation'
					name='autoStartConversation'
					valuePropName='checked'
					tooltip='Automatically start conversations with new leads.'
				>
					<Switch defaultChecked={leadsGroup?.autoStartConversation} />
				</Form.Item>

				<Form.Item
					className={styles.formItem}
					label='Sync new leads with contexts'
					name='syncNewLeads'
					valuePropName='checked'
					tooltip='Automatically add new leads to selected contexts.'
				>
					<Switch defaultChecked={leadsGroup?.syncNewLeads} />
				</Form.Item>
			</FormRow>

			{!!leadsGroup?.autoStartConversation && (
				<Form.Item
					rules={[{ required: true, message: 'Please select a template' }]}
					className={styles.formItem}
					label='Templates'
					name='templateIds'
				>
					<Select
						allowClear
						showSearch
						size='large'
						mode='multiple'
						placeholder='Select a template'
						options={templates.map((template) => ({
							label: `${template.name} (${template.provider})`,
							value: template.id,
							disabled: isDisabledTemplate(leadsGroup.templateIds, templates, template),
						}))}
					/>
				</Form.Item>
			)}

			<Form.Item noStyle>
				<div className={styles.buttons}>
					<Button disabled={!isValid} onClick={onFinish} size='large' type='primary'>
						{leadsGroup?.id ? 'Update' : 'Create'}
					</Button>
					<Button size='large' onClick={onCancel}>
						Cancel
					</Button>
				</div>
			</Form.Item>
		</Form>
	)
}

const toFormData = (leadGroup: Partial<LeadsGroup> | undefined): Partial<LeadsGroup> => {
	if (!leadGroup) {
		return {}
	}
	return omit(leadGroup, 'id', 'userId', 'createdAt')
}

const isDisabledTemplate = (
	leadsGroupTemplateIds: string[] | undefined,
	templates: Template[],
	template: Template,
): boolean => {
	if (
		leadsGroupTemplateIds?.some(
			(templateId) => templates.find(({ id }) => id === templateId)!.provider === template.provider,
		)
	) {
		return true
	}
	return template.status !== 'active'
}

export default LeadsGroupForm
