import { Form, Select } from 'antd'
import { DEFAULT_GMAIL_LABELS } from '@neuron/types/integration/messaging/api/gmail'
import React from 'react'
import TextArea from 'antd/es/input/TextArea'
import styles from './GmailConfig.module.scss'

const GmailConfig = () => {
	return (
		<>
			<Form.Item
				name={['config', 'activeLabels']}
				label='Active e-mail labels'
				className={styles.formItem}
				tooltip='Select the names of mail labels to be handled by the integration.'
			>
				<Select
					size='large'
					mode='tags'
					showSearch
					maxLength={10}
					tokenSeparators={[',']}
					options={DEFAULT_GMAIL_LABELS.map((provider) => ({
						label: provider,
						value: provider,
					}))}
				/>
			</Form.Item>

			<Form.Item
				name={['config', 'signature']}
				tooltip='Enter mail signature in HTML format'
				className={styles.formItem}
				label='Mail signature'
			>
				<TextArea
					className={styles.formTextarea}
					size='large'
					allowClear
					maxLength={10000}
					autoSize={{ minRows: 2, maxRows: 6 }}
					placeholder='Mail signature'
				/>
			</Form.Item>
		</>
	)
}

export default GmailConfig
