import React from 'react'
import { MenuProps, Popconfirm } from 'antd'
import { SecurityScanOutlined, UserOutlined, StopOutlined, DeleteOutlined } from '@ant-design/icons'
import { AccountTableData } from '../../UsersTable'
import styles from './UserTableOptions.module.scss'

type Props = {
	record: AccountTableData & { key: string }
	previewAccount: (id: string) => void
	viewUserOffer: (id: string) => void
	blockAccount: (id: string) => void
	deleteAccount: (id: string) => void
	isAdmin: boolean
	currentUserId?: string
}

const UserTableOptions = ({
	record,
	previewAccount,
	viewUserOffer,
	blockAccount,
	deleteAccount,
	isAdmin,
	currentUserId,
}: Props) => {
	const menu: MenuProps = {
		items: [
			{
				key: 'dashboard',
				icon: <SecurityScanOutlined className={styles.icon} />,
				label: <span className={styles.menuItem}>Dashboard</span>,
				onClick: () => previewAccount(record.key),
				disabled: record.key === currentUserId,
			},
			{
				key: 'offer',
				icon: <UserOutlined className={styles.icon} />,
				label: <span className={styles.menuItem}>Offer</span>,
				onClick: () => viewUserOffer(record.key),
			},
			...(isAdmin
				? [
						{
							key: 'block',
							icon: <StopOutlined className={styles.icon} />,
							label: (
								<Popconfirm
									disabled={record.key === currentUserId || !!record.blocked}
									title='Are you sure you want to block this account?'
									onConfirm={() => blockAccount(record.key)}
									okText='Yes'
									cancelText='No'
								>
									<span className={`${styles.menuItem} ${styles.bold}`}>Block</span>
								</Popconfirm>
							),
							disabled: record.key === currentUserId || !!record.blocked,
						},
						{
							key: 'delete',
							icon: (
								<DeleteOutlined
									className={`${styles.icon} ${record.key !== currentUserId ? styles.danger : ''}`}
								/>
							),
							label: (
								<Popconfirm
									disabled={record.key === currentUserId}
									title='Are you sure you want to delete this account?'
									onConfirm={() => deleteAccount(record.key)}
									okText='Yes'
									cancelText='No'
								>
									<span
										className={`${styles.menuItem} ${styles.bold} ${
											record.key !== currentUserId ? styles.danger : ''
										}`}
									>
										Delete
									</span>
								</Popconfirm>
							),
							disabled: record.key === currentUserId,
						},
					]
				: []),
		],
	}

	return menu
}

export default UserTableOptions
