import React, { useEffect, useState } from 'react'
import { Button, Dropdown, PaginationProps, Table, type TableProps, Tag } from 'antd'
import { formatDate } from '@neuron/utils/dates'
import { DownOutlined } from '@ant-design/icons'
import { Currency, Order, OrderStatus, PaymentInterval, Product } from '@neuron/types/order'
import OrderProductsTable from './OrderProductsTable/OrderProductsTable'
import OrderStatusTableOptions from './OrderStatusTableOptions/OrderStatusTableOptions'
import { useAccountContext } from '../../../hooks/context/AccountContext'
import styles from './UserOffersTable.module.scss'

type Props = {
	orders: Order[]
	changeOrderStatus: (orderId: string, status: OrderStatus) => Promise<void>
}

const UserOffersTable = ({ orders, changeOrderStatus }: Props) => {
	const accountContext = useAccountContext()
	const isAdmin = accountContext.useSubscribe((context) => context.roles.includes('admin'))
	const isAuditor = accountContext.useSubscribe((context) => context.roles.includes('auditor'))

	const [windowHeight, setWindowHeight] = useState(window.innerHeight)

	useEffect(() => {
		const handleResize = () => {
			setWindowHeight(window.innerHeight)
		}

		window.addEventListener('resize', handleResize)
		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	const columns: TableProps<OrdersTableData>['columns'] = [
		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
			className: `${styles.boldValue}`,
			width: 70,
			render: (value: OrderStatus) => <Tag color={offerStatusToColor[value]}>{value}</Tag>,
		},
		{
			title: 'Currency',
			dataIndex: 'currency',
			key: 'currency',
			width: 70,
		},
		{
			title: 'Payment interval',
			dataIndex: 'paymentInterval',
			key: 'paymentInterval',
			width: 70,
			render: (value) => <Tag>{value}</Tag>,
		},
		{
			title: 'Total price',
			dataIndex: 'totalPrice',
			key: 'totalPrice',
			className: styles.boldValue,
			width: 60,
			render: (value) => (value / 100).toFixed(2),
		},
		{
			title: 'Paid amount',
			dataIndex: 'paidAmount',
			key: 'paidAmount',
			className: styles.boldValue,
			width: 60,
			render: (value) => (value / 100).toFixed(2),
		},
		{
			title: 'Last payment date',
			dataIndex: 'lastPaymentAt',
			key: 'lastPaymentAt',
			width: 80,
			render: (value) => (value ? formatDate(value) : '-'),
		},
		{
			title: 'Next payment date',
			dataIndex: 'nextPaymentAt',
			key: 'nextPaymentAt',
			width: 80,
			render: (value) => (value ? formatDate(value) : '-'),
		},
		Table.EXPAND_COLUMN,
	]

	if (isAdmin || isAuditor) {
		columns.push({
			title: 'Action',
			key: 'action',
			fixed: 'right',
			render: (record: OrdersTableData & { key: string }) => (
				<Dropdown
					menu={OrderStatusTableOptions({
						record,
						changeOrderStatus,
						isAdmin,
						isAuditor,
					})}
					trigger={['click']}
				>
					<Button type='link' icon={<DownOutlined />}>
						Change status
					</Button>
				</Dropdown>
			),
			width: 90,
		})
	}

	return (
		<Table
			pagination={{
				total: orders.length,
				showTotal,
			}}
			expandable={{
				expandedRowRender: (order) => <OrderProductsTable products={order.products} />,
			}}
			columns={columns}
			dataSource={ordersToTableData(orders)}
			scroll={{ y: windowHeight - 366 }}
		/>
	)
}

const showTotal: PaginationProps['showTotal'] = (total) => `Total ${total} items`

const ordersToTableData = (orders: Order[]): Array<OrdersTableData & { key: string }> => {
	return orders.map((order) => ({
		key: order.id,
		status: order.status,
		currency: order.currency,
		paymentInterval: order.paymentInterval,
		totalPrice: order.totalPrice,
		paidAmount: order.paidAmount,
		products: order.products,
		lastPaymentAt: order.lastPaymentAt,
		nextPaymentAt: order.nextPaymentAt,
	}))
}

export interface OrdersTableData {
	status: OrderStatus
	currency: Currency
	paymentInterval: PaymentInterval
	totalPrice: number
	paidAmount: number
	products: Product[]
	lastPaymentAt?: Date
	nextPaymentAt?: Date
}

const offerStatusToColor: Record<OrderStatus, string> = {
	draft: '#d9d9d9', // default
	pending: '#faad14', // processing
	active: '#1890ff', // success
	inactive: '#d9d9d9', // default
	purchased: '#52c41a', // success
	paymentProblem: '#f5222d', // error
	cancelled: '#f5222d', // error
}

export default UserOffersTable
