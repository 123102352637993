import { Chat } from '@neuron/types/chat'
import { App, Button, Checkbox, Collapse, Form, Input, Select, Tooltip } from 'antd'
import { useState } from 'react'
import useAsyncEffect from '../../hooks/useAsyncEffect'
import query from '../../utils/query'
import { Context } from '@neuron/types/context'
import LLMModelMapper from '../LLMModelMapper/LLMModelMapper'
import Loading from '../Loading/Loading'
import { InfoCircleOutlined } from '@ant-design/icons'
import useTryCatch from '../../hooks/useTryCatch'
import { DEFAULT_LANGUAGE, LANGUAGES, LLM_NAMES } from '@neuron/types/global'
import { useAccountContext } from '../../hooks/context/AccountContext'
import styles from './ChatForm.module.scss'

const { Option } = Select
const { TextArea } = Input

type Props = {
	chatId?: string | undefined
	onSubmit: (chatData: Partial<Chat>) => Promise<void>
	onCancel: () => void
}

const ChatForm = ({ chatId, onSubmit, onCancel }: Props) => {
	const { message } = App.useApp()
	const tryCatch = useTryCatch(message)

	const accountContext = useAccountContext()
	const usingUserId = accountContext.useSubscribe((account) => account.usingUserId)

	const [chat, setChat] = useState<Chat | null>(null)
	const [hasSelectedAnyContext, setHasSelectedAnyContext] = useState<boolean>(false)
	const [contexts, setContexts] = useState<Context[] | null>(null)
	const [loading, setLoading] = useState<boolean>(true)

	useAsyncEffect(async () => {
		if (!chatId) {
			setLoading(false)
			return
		}

		setLoading(true)
		await tryCatch(
			async () => {
				const resChat = await query<Chat>('/chat/get', 'GET', {
					params: { id: chatId },
					withCredentials: true,
				})
				if (resChat.contextIds.length) {
					setHasSelectedAnyContext(true)
				}
				setChat(resChat)
				setLoading(false)
			},
			undefined,
			{ message: 'Chat data error. Try again and refresh page.' },
		)
	}, [chatId])

	useAsyncEffect(async () => {
		await tryCatch(async () => {
			const resContexts = await query<Context[]>('/context/forUser', 'GET', {
				withCredentials: true,
				params: {
					usingUserId,
				},
			})
			setContexts(resContexts)
		})
	}, [usingUserId])

	const onFinish = async (chatData: Partial<Chat>) => {
		await onSubmit({
			id: chatId,
			contextIds: chatData.contextIds ?? [],
			name: chatData.name,
			teamId: chatData.teamId,
			options: {
				...(chatData.options as any), // TODO: Use other type for chatData
				// When chat don't have any context, it should use prior knowledge
				useOnlyContext: chatData.contextIds?.length ? chatData.options?.useOnlyContext : false,
			},
		})
	}

	if (loading) {
		return <Loading size={32} />
	}

	const additionalForm = () => {
		return (
			<>
				<Form.Item name={['options', 'systemContext']} label='System context'>
					<TextArea
						allowClear
						count={{
							show: true,
						}}
						maxLength={2500}
						defaultValue=''
						autoSize={{ minRows: 2, maxRows: 4 }}
						placeholder='Enter extra context data. For example: "You are a chatbot created by XYZ. Your purpose is helping users visiting xyz.com webside."'
					/>
				</Form.Item>

				<div className={styles.inlineCheckbox}>
					<Form.Item
						initialValue={chat?.options.useTools !== undefined ? undefined : true}
						name={['options', 'useTools']}
						valuePropName='checked'
						noStyle
					>
						<Checkbox>Use chat tools</Checkbox>
					</Form.Item>
				</div>
			</>
		)
	}

	return (
		<Form
			layout='horizontal'
			onFinish={onFinish}
			initialValues={chat ?? {}}
			style={{ maxWidth: 600 }}
		>
			<Form.Item
				rules={[{ required: true, message: 'Please fill chat name' }]}
				name='name'
				label='Name'
			>
				<Input placeholder='Chat name' />
			</Form.Item>

			<Form.Item
				initialValue={chat?.options ? undefined : LLM_NAMES[1]}
				name={['options', 'llmModel']}
				label='LLM Model'
				rules={[{ required: true, message: 'Please select LLM model!' }]}
			>
				<Select>
					{LLM_NAMES.map((llmModel) => (
						<Option key={llmModel} value={llmModel}>
							<LLMModelMapper llmModel={llmModel} />
						</Option>
					))}
				</Select>
			</Form.Item>

			<Form.Item
				initialValue={
					chat?.options?.language !== undefined ? chat.options.language : DEFAULT_LANGUAGE
				}
				name={['options', 'language']}
				label='Language'
				rules={[{ required: true, message: 'Please select chat language' }]}
			>
				<Select
					showSearch
					placeholder='Chat language'
					options={LANGUAGES.sort((a, b) => a.localeCompare(b)).map((language) => ({
						label: language.split(':')[0],
						value: language,
					}))}
				/>
			</Form.Item>

			<Form.Item name='contextIds' label='Contexts'>
				{contexts === null ? (
					<Loading size={24} />
				) : (
					<Select
						allowClear
						onChange={(value: number[]) => {
							setHasSelectedAnyContext(!!value.length)
						}}
						mode='multiple'
						placeholder='Chat without own context'
						options={contexts.map((context) => ({
							label: context.resource.name,
							value: context.id,
							disabled: context.status !== 'indexed' && context.status !== 'needReindex',
						}))}
					/>
				)}
			</Form.Item>

			<Form.Item
				initialValue={chat?.options?.useOnlyContext !== undefined ? undefined : false}
				name={['options', 'useOnlyContext']}
				valuePropName='checked'
			>
				<Checkbox disabled={!hasSelectedAnyContext}>
					Use only context knowledge
					<Tooltip title='LLMs like GPT have own prior knowledge. When this checkbox is selected this models can use only your context.'>
						<InfoCircleOutlined className={styles.infoIcon} />
					</Tooltip>
				</Checkbox>
			</Form.Item>

			<Collapse
				className={styles.additionOptions}
				size='middle'
				items={[{ key: '1', label: 'Addition options', children: additionalForm() }]}
			/>

			<Form.Item noStyle>
				<div className={styles.buttons}>
					<Button htmlType='submit' type='primary'>
						Submit
					</Button>
					<Button onClick={onCancel}>Cancel</Button>
				</div>
			</Form.Item>
		</Form>
	)
}

export default ChatForm
