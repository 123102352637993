import { Lead, LeadsFilters } from '@neuron/types/leadsGroup/lead'
import { Button, PaginationProps, Table, type TableProps } from 'antd'
import React, { useEffect, useState } from 'react'
import { formatDate } from '@neuron/utils/dates'
import { Link } from 'react-router-dom'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import styles from './LeadsTable.module.scss'

type Props = {
	leads: Lead[]
	filters: LeadsFilters
	total: number
	selectedLeadsIds: string[]
	setPagination: (newPage?: number, newPerPage?: number) => void
	deleteLeads: (leadIds: string[]) => void
	openUpdateLeadModal: (data: Partial<Lead>) => void
	setSelectedLeads: (leadIds: string[]) => void
}

const LeadsTable = ({
	leads,
	openUpdateLeadModal,
	setSelectedLeads,
	selectedLeadsIds,
	deleteLeads,
	setPagination,
	filters,
	total,
}: Props) => {
	const [windowHeight, setWindowHeight] = useState(window.innerHeight)

	useEffect(() => {
		const handleResize = () => {
			setWindowHeight(window.innerHeight)
		}

		window.addEventListener('resize', handleResize)
		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	const rowSelection = {
		onChange: (selectedRowKeys: React.Key[]) => {
			setSelectedLeads(selectedRowKeys as string[])
		},
	}

	const columns: TableProps<LeadsTableData>['columns'] = [
		{
			title: 'E-mail',
			dataIndex: 'email',
			key: 'email',
			className: `${styles.contextValue} ${styles.contextName}`,
			ellipsis: true,
			width: 180,
			render: (value) => (value ? value : <span className={styles.center}>-</span>),
		},
		{
			title: 'Phone',
			dataIndex: 'phone',
			className: `${styles.contextValue} ${styles.contextType}`,
			key: 'phone',
			ellipsis: true,
			width: 180,
			render: (value) => (value ? value : <span className={styles.center}>-</span>),
		},
		{
			title: 'Firstname',
			dataIndex: 'firstname',
			key: 'firstname',
			className: styles.contextValue,
			width: 160,
			ellipsis: true,
			render: (value) => (value ? value : <span className={styles.center}>-</span>),
		},
		{
			title: 'Lastname',
			dataIndex: 'lastname',
			key: 'lastname',
			className: styles.contextValue,
			width: 160,
			ellipsis: true,
			render: (value) => (value ? value : <span className={styles.center}>-</span>),
		},
		{
			title: 'Gender',
			dataIndex: 'gender',
			key: 'gender',
			className: styles.contextValue,
			width: 90,
			render: (value) => (value ? value : <span className={styles.center}>-</span>),
		},
		{
			title: 'Created date',
			dataIndex: 'createdAt',
			key: 'createdAt',
			className: styles.contextValue,
			render: (date) => formatDate(date),
			width: 160,
		},
		{
			title: 'Conversation',
			dataIndex: 'conversationId',
			key: 'conversationId',
			className: styles.contextValue,
			render: (value) =>
				value ? (
					<Link to={`/personas/conversation?conversationId=${value}`}>conversation</Link>
				) : (
					<span className={styles.center}>-</span>
				),
			width: 120,
		},
		Table.EXPAND_COLUMN,
		{
			title: 'Action',
			key: 'action',
			className: styles.contextValue,
			fixed: 'right',
			render: (record: LeadsTableData & { key: string }) => (
				<div className={styles.actions}>
					<Button className={styles.actionButton} type='text'>
						<EditOutlined
							className={styles.actionIcon}
							onClick={() => openUpdateLeadModal(tableDataToLead(record))}
						/>
					</Button>
					<Button className={styles.actionButton} type='text'>
						<DeleteOutlined
							className={`${styles.actionIcon} ${styles.dangerIcon}`}
							onClick={() => deleteLeads([record.key])}
						/>
					</Button>
				</div>
			),
			width: 100,
		},
	]

	return (
		<Table
			rowSelection={{
				type: 'checkbox',
				...rowSelection,
				selectedRowKeys: selectedLeadsIds,
				selections: [Table.SELECTION_ALL, Table.SELECTION_NONE],
			}}
			pagination={{
				pageSize: filters.perPage,
				total,
				current: filters.page,
				onChange: (page: number, pageSize: number) => setPagination(page, pageSize),
				showTotal,
			}}
			expandable={{
				expandedRowRender: (item) => <p style={{ margin: 0 }}>Note: {item.note}</p>,
				rowExpandable: (item) => !!item.note?.length,
			}}
			columns={columns}
			dataSource={leadsToTableData(leads)}
			scroll={{ y: windowHeight - 366 }}
		/>
	)
}

const showTotal: PaginationProps['showTotal'] = (total) => `Total ${total} items`

const leadsToTableData = (leads: Lead[]): Array<LeadsTableData & { key: string }> => {
	return leads.map((lead) => ({
		key: lead.id,
		email: lead.email,
		phone: lead.phone,
		firstname: lead.firstname,
		lastname: lead.lastname,
		gender: lead.gender,
		note: lead.note,
		conversationId: lead.conversationId,
		createdAt: lead.createdAt,
	}))
}

const tableDataToLead = (
	tableData: LeadsTableData & { key: string },
): Pick<Lead, 'id' | 'email' | 'phone' | 'gender' | 'firstname' | 'lastname' | 'note'> => ({
	id: tableData.key,
	email: tableData.email,
	phone: tableData.phone,
	gender: tableData.gender,
	firstname: tableData.firstname,
	lastname: tableData.lastname,
	note: tableData.note,
})

interface LeadsTableData {
	email?: string
	phone?: string
	firstname?: string
	lastname?: string
	gender?: string
	note?: string
	conversationId?: string
	createdAt: Date
}

export default LeadsTable
