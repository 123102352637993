import React, { useState } from 'react'
import useAsyncEffect from '../../../hooks/useAsyncEffect'
import query from '../../../utils/query'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, Layout, App, Tooltip, Tag } from 'antd'
import { formatDate } from '@neuron/utils/dates'
import Loading from '../../../components/Loading/Loading'
import useTryCatch from '../../../hooks/useTryCatch'
import CardTitle from '../../../components/CardTitle/CardTitle'
import EmptyData from '../../../components/EmptyData/EmptyData'
import { AssistantRole } from '@neuron/types/assistant/assistantRole'
import {
	DatabaseOutlined,
	DeleteOutlined,
	EditOutlined,
	SyncOutlined,
	UserOutlined,
	CommentOutlined,
	ApiOutlined,
} from '@ant-design/icons'
import CardItem from '../../../components/CardItem/CardItem'
import { useGlobalContext } from '../../../hooks/context/GlobalContext'
import RolesList from './components/RolesList/RolesList'
import { assistantRoleTypeToName } from '../../../utils/helpers'
import styles from './Roles.module.scss'

const { Header } = Layout

const PersonaRoles = () => {
	const navigate = useNavigate()
	const { message } = App.useApp()
	const tryCatch = useTryCatch(message)

	const globalContext = useGlobalContext()

	const { assistantId } = useParams()

	const [assistantRoles, setAssistantRoles] = useState<AssistantRole[]>([])
	const [selectedAssistantRoleIds, setSelectedAssistantRoleIds] = useState<string[]>([])
	const [loading, setLoading] = useState<boolean>(true)

	const onLoadAssistantRoles = async () => {
		if (!assistantId) {
			return
		}

		setLoading(true)
		await tryCatch(async () => {
			const resAssistants = await query<AssistantRole[]>('/assistant/roles', 'GET', {
				withCredentials: true,
				params: {
					assistantId,
				},
			})
			setAssistantRoles(resAssistants)
		})
		setLoading(false)
	}

	useAsyncEffect(onLoadAssistantRoles, [assistantId])

	const deleteAssistantRoles = async (roleIds: string[]) => {
		const expectRoleIds: string[] = []

		for (const roleId of roleIds) {
			await tryCatch(
				async () => {
					await query('/assistant/role/delete', 'POST', {
						data: { id: roleId },
						withCredentials: true,
					})
					message.open({
						type: 'success',
						content: 'Persona role was deleted',
					})
				},
				() => expectRoleIds.push(roleId),
				{ message: 'Error while deleting Persona role' },
			)
		}

		setAssistantRoles((assistantRoles) => {
			return assistantRoles.filter(
				(assistantRole) =>
					!roleIds.includes(assistantRole.id) || expectRoleIds.includes(assistantRole.id),
			)
		})
		setSelectedAssistantRoleIds([])
	}

	const selectAssistantRole = (assistantRoleId: string) => {
		const hasSelectedAssistantRoleId = selectedAssistantRoleIds.some((id) => id === assistantRoleId)

		if (hasSelectedAssistantRoleId) {
			setSelectedAssistantRoleIds((ids) => ids.filter((id) => id !== assistantRoleId))
		} else {
			setSelectedAssistantRoleIds([...selectedAssistantRoleIds, assistantRoleId])
		}
	}

	if (loading) {
		return <Loading />
	}

	return (
		<div className={styles.container}>
			<RolesList />

			<Header className={styles.header}>
				<div className={styles.headerElements}>
					{!!selectedAssistantRoleIds.length && (
						<>
							<Button
								icon={<DeleteOutlined />}
								danger
								onClick={() => deleteAssistantRoles(selectedAssistantRoleIds)}
							>
								Delete selected{' '}
								{selectedAssistantRoleIds.length > 1 ? 'Persona roles' : 'Persona role'} (
								{selectedAssistantRoleIds.length})
							</Button>
						</>
					)}
				</div>

				<div className={styles.headerElements}>
					<Tooltip trigger={['hover', 'focus']} title='Refresh'>
						<Button
							className={styles.headerRefresh}
							onClick={onLoadAssistantRoles}
							shape='circle'
							icon={<SyncOutlined />}
						/>
					</Tooltip>
				</div>
			</Header>

			<div className={styles.assistants}>
				{assistantRoles.map((assistantRole) => (
					<CardItem
						key={assistantRole.id}
						itemId={assistantRole.id}
						selectedItems={selectedAssistantRoleIds}
						selectItem={() => selectAssistantRole(assistantRole.id)}
						cardTitle={
							<CardTitle
								id={assistantRole.id}
								name={
									<span>
										<UserOutlined className={styles.roleIcon} />
										{assistantRole.name}
									</span>
								}
								onDelete={(id: string) => deleteAssistantRoles([id])}
							/>
						}
						labels={[
							{
								label: 'Role',
								value: assistantRoleTypeToName(assistantRole.type),
							},
							{
								label: 'Style',
								value: <Tag>{assistantRole.conversationStyle}</Tag>,
							},
							{
								label: 'Response length',
								value: <Tag>{assistantRole.responseLength}</Tag>,
							},
							{
								label: 'separator',
							},
							{
								label: 'Knowledge base',
								value: <Tag>{assistantRole.contextIds?.length ?? 0}</Tag>,
							},
							{
								label: 'Integrations',
								value: (
									<Tag>
										{assistantRole.connections?.integrations?.filter(
											(integration) => integration.provider !== 'playground',
										)?.length ?? 0}
									</Tag>
								),
							},
							{
								label: 'Created date',
								value: formatDate(assistantRole.createdAt),
							},
						]}
						actions={[
							<Tooltip key='edit' trigger={['hover', 'focus']} title='Edit form'>
								<EditOutlined
									onClick={(e) => {
										e.stopPropagation()
										navigate(`/persona/${assistantId}/role/${assistantRole.id}`)
									}}
								/>
							</Tooltip>,
							<Tooltip key='context' trigger={['hover', 'focus']} title='Knowladge base'>
								<DatabaseOutlined
									onClick={(e) => {
										e.stopPropagation()
										navigate(`/persona/${assistantId}/role/${assistantRole.id}/context`)
									}}
								/>
							</Tooltip>,
							<Tooltip key='playground' trigger={['hover', 'focus']} title='Playground'>
								<CommentOutlined
									onClick={(e) => {
										e.stopPropagation()
										globalContext.updatePageTitle('Role - Playground')
										navigate(`/persona/${assistantId}/role/${assistantRole.id}/playground`)
									}}
								/>
							</Tooltip>,
							<Tooltip key='connections' trigger={['hover', 'focus']} title='Connections'>
								<ApiOutlined
									onClick={(e) => {
										e.stopPropagation()
										globalContext.updatePageTitle('Role - Connections')
										navigate(`/persona/${assistantId}/role/${assistantRole.id}/connections`)
									}}
								/>
							</Tooltip>,
						]}
					/>
				))}

				{!assistantRoles.length && <EmptyData description='Empty Persona roles' />}
			</div>
		</div>
	)
}

export default PersonaRoles
