import React, { useEffect, useMemo, useState } from 'react'
import useAsyncEffect from '../../hooks/useAsyncEffect'
import query from '../../utils/query'
import { Context, ContextSettings, ContextStatus } from '@neuron/types/context'
import { useNavigate } from 'react-router-dom'
import { useAiContext } from '../../hooks/context/AiContext'
import { formatDate } from '@neuron/utils/dates'
import { App, Button, Layout, Modal, Tag, Tooltip } from 'antd'
import Loading, { InlineLoading } from '../../components/Loading/Loading'
import { socket } from '../../global/SocketManager'
import useTryCatch from '../../hooks/useTryCatch'
import CardTitle from '../../components/CardTitle/CardTitle'
import EmptyData from '../../components/EmptyData/EmptyData'
import {
	DeleteOutlined,
	SyncOutlined,
	UndoOutlined,
	FormOutlined,
	DatabaseOutlined,
	SettingOutlined,
	EditOutlined,
} from '@ant-design/icons'
import { contextStatusToColor } from '../../utils/helpers'
import CardItem from '../../components/CardItem/CardItem'
import { useAccountContext } from '../../hooks/context/AccountContext'
import { TabContent } from '../../types/AiContext'
import ContextSettingsForm from './UpdateContextSettings/UpdateContextSettings'
import styles from './AiContexts.module.scss'

const { Header } = Layout

const AiContexts = () => {
	const navigate = useNavigate()
	const { message } = App.useApp()
	const tryCatch = useTryCatch(message)

	const accountContext = useAccountContext()
	const usingUserId = accountContext.useSubscribe((account) => account.usingUserId)

	const aiContext = useAiContext()

	const [openContextSettingsModal, setOpenContextSettingsModal] = useState<boolean>(false)
	const [editContextSettingsId, setEditContextSettingsId] = useState<number | undefined>()
	const [selectedContextIds, setSelectedContextIds] = useState<number[]>([])
	const [contexts, setContexts] = useState<Context[]>([])
	const [loading, setLoading] = useState<boolean>(true)

	useEffect(() => {
		socket.on('contextStatusUpdated', updateContextState)
		socket.on('contextDeleted', removeContextState)

		return () => {
			socket.off('contextStatusUpdated', updateContextState)
			socket.off('contextDeleted', removeContextState)
		}
	}, [contexts])

	const onLoadContext = async () => {
		setLoading(true)
		await tryCatch(async () => {
			const resContexts = await query<Context[]>('/context/forUser', 'GET', {
				withCredentials: true,
				params: {
					usingUserId,
				},
			})
			setContexts(resContexts)
		})
		setLoading(false)
	}

	useAsyncEffect(onLoadContext, [usingUserId])

	const updateContextState = async (updatedContext: Context) => {
		const findContextToUpdate = contexts.find((context) => context.id === updatedContext.id)
		if (findContextToUpdate) {
			if (updatedContext.status === 'error') {
				message.open({
					type: 'error',
					content: `Context "${updatedContext.resource.name}" error. Try again`,
				})
			}
			if (updatedContext.status === 'refreshing') {
				message.open({
					type: 'info',
					content: `Context "${updatedContext.resource.name}" is refreshing...`,
				})
			}
			if (updatedContext.status === 'indexed') {
				message.open({
					type: 'success',
					content: `Context "${updatedContext.resource.name}" is indexed`,
				})
			}

			setContexts((contexts) =>
				contexts.map((context) => (context.id === updatedContext.id ? updatedContext : context)),
			)
		}
	}

	const removeContextState = async (contextId: number) => {
		const findContextToRemove = contexts.find((context) => context.id === contextId)
		if (findContextToRemove) {
			message.open({
				type: 'success',
				content: `Context "${findContextToRemove.resource.name}" was deleted.`,
			})
			setContexts((contexts) => contexts.filter((context) => context.id !== contextId))
		}
	}

	const deleteContexts = async (contextIds: number[]) => {
		for (const contextId of contextIds) {
			await tryCatch(
				async () => {
					await query('/context/delete', 'POST', {
						data: { id: contextId },
						withCredentials: true,
					})
				},
				undefined,
				{
					message: 'Error while deleting context.',
				},
			)
		}
		setSelectedContextIds([])
	}

	const contextToTabContent = (context: Context): TabContent | undefined => {
		if (context.resource.type === 'text') {
			return {
				text: context.resource.text,
			}
		}
		if (context.resource.type === 'url') {
			return {
				url: context.resource.url,
				isValidUrl: true,
				databaseIntegrationId: context.resource.databaseIntegrationId,
				fileId: context.resource.fileId,
			}
		}
		if (context.resource.type === 'file') {
			return {
				fileId: context.resource.fileId,
				file: null,
			}
		}
	}

	const editContexts = (contextIds: number[]) => {
		for (const context of contexts.filter((context) => contextIds.includes(context.id))) {
			if (isPendingStatus(context.status)) {
				continue
			}

			const content = contextToTabContent(context)
			if (!content) {
				continue
			}

			aiContext.addNewTab({
				id: context.id,
				name: context.resource.name,
				type: context.resource.type,
				content,
				editing: true,
			})
		}
		navigate('/context')
	}

	const selectContext = (contextId: number, contextStatus: ContextStatus) => {
		if (isPendingStatus(contextStatus)) {
			setSelectedContextIds((ids) => ids.filter((id) => id !== contextId))
		}

		const hasSelectedContextId = selectedContextIds.some((id) => id === contextId)
		if (hasSelectedContextId) {
			setSelectedContextIds((ids) => ids.filter((id) => id !== contextId))
		} else {
			setSelectedContextIds([...selectedContextIds, contextId])
		}
	}

	const refreshContexts = async (contextIds: number[]) => {
		for (const contextId of contextIds) {
			await tryCatch(
				async () => {
					await query('/context/refresh', 'POST', {
						data: { id: contextId },
						withCredentials: true,
					})

					setContexts((contexts) =>
						contexts.map((context) =>
							contextIds.includes(context.id) ? { ...context, status: 'refreshing' } : context,
						),
					)
				},
				() => {
					setContexts((contexts) =>
						contexts.map((context) =>
							contextIds.includes(context.id) ? { ...context, status: 'error' } : context,
						),
					)
				},
				{ message: 'Error while refreshing context. Try again.' },
			)
		}
	}

	const updateContextSettings = async (settings: ContextSettings) => {
		await tryCatch(
			async () => {
				await query('/context/update/settings', 'POST', {
					data: { id: editContextSettingsId, ...settings },
					withCredentials: true,
				})

				message.open({
					type: 'success',
					content: 'Context settings was updated',
				})
				await onLoadContext()
			},
			undefined,
			{ message: 'Error while updating context settings' },
		)
		onCloseContextSettingsModal()
	}

	const atLeastOneSelectedContextIsAvailable = (): boolean => {
		const selectedContexts = contexts.filter(({ id }) => selectedContextIds.includes(id))
		return selectedContexts.some(({ status }) => status !== 'refreshing' && status !== 'deleting')
	}

	const onCloseContextSettingsModal = () => {
		setEditContextSettingsId(undefined)
		setOpenContextSettingsModal(false)
	}

	const contextSettingsModal = useMemo(() => {
		if (!editContextSettingsId) {
			return <></>
		}

		return (
			<Modal
				destroyOnClose
				footer={null}
				className={styles.contextSettingsModal}
				title='Context settings'
				open={openContextSettingsModal}
				onCancel={onCloseContextSettingsModal}
			>
				<div className={styles.modalContent}>
					<ContextSettingsForm
						onSubmit={updateContextSettings}
						onCancel={onCloseContextSettingsModal}
						contextId={editContextSettingsId}
					/>
				</div>
			</Modal>
		)
	}, [openContextSettingsModal, editContextSettingsId])

	if (loading) {
		return <Loading />
	}

	const leastOneSelectedContextIsAvailable = atLeastOneSelectedContextIsAvailable()

	return (
		<div className={styles.container}>
			{contextSettingsModal}

			<Header className={styles.header}>
				<div className={styles.headerElements}>
					<Button icon={<DatabaseOutlined />} onClick={() => navigate('/context')}>
						Create new context
					</Button>
					{!!selectedContextIds.length && leastOneSelectedContextIsAvailable && (
						<>
							<Button icon={<FormOutlined />} onClick={() => editContexts(selectedContextIds)}>
								Edit selected {selectedContextIds.length > 1 ? 'contexts' : 'context'} (
								{selectedContextIds.length})
							</Button>
							<Button icon={<UndoOutlined />} onClick={() => refreshContexts(selectedContextIds)}>
								Refresh selected {selectedContextIds.length > 1 ? 'contexts' : 'context'} (
								{selectedContextIds.length})
							</Button>
						</>
					)}
				</div>

				<div className={styles.headerElements}>
					{!!selectedContextIds.length && leastOneSelectedContextIsAvailable && (
						<Button
							icon={<DeleteOutlined />}
							danger
							onClick={() => deleteContexts(selectedContextIds)}
						>
							Delete selected {selectedContextIds.length > 1 ? 'contexts' : 'context'} (
							{selectedContextIds.length})
						</Button>
					)}

					<Tooltip trigger={['hover', 'focus']} title='Refresh'>
						<Button
							className={styles.headerRefresh}
							onClick={onLoadContext}
							shape='circle'
							icon={<SyncOutlined />}
						/>
					</Tooltip>
				</div>
			</Header>

			<div className={styles.contexts}>
				{contexts.map((context) => (
					<CardItem
						key={context.id}
						itemId={context.id}
						selectedItems={selectedContextIds}
						selectItem={() => selectContext(context.id, context.status)}
						cardTitle={
							<CardTitle
								id={context.id}
								name={context.resource.name}
								disabled={context.status === 'deleting'}
								onDelete={(id: number) => deleteContexts([id])}
							/>
						}
						labels={[
							{
								label: 'Type',
								value: context.resource.type,
							},
							{
								label: 'Status',
								value: (
									<Tag color={contextStatusToColor[context.status]}>
										{isPendingStatus(context.status) && (
											<InlineLoading iconClassName={styles.loading} color='#fff' size={10} />
										)}
										{context.status}
									</Tag>
								),
							},
							{
								label: 'Next refresh date',
								value: context.nextReindexAt && formatDate(context.nextReindexAt),
							},
							{
								label: 'Last refresh date',
								value: context.reindexedAt && formatDate(context.reindexedAt),
							},
							{
								label: 'Created date',
								value: formatDate(context.createdAt),
							},
						]}
						actions={[
							<Tooltip key='edit' trigger={['hover', 'focus']} title='Edit'>
								<EditOutlined
									className={`${isPendingStatus(context.status) ? styles.disabledActionIcon : ''}`}
									onClick={(e) => {
										e.stopPropagation()
										if (!isPendingStatus(context.status)) {
											editContexts([context.id])
										}
									}}
								/>
							</Tooltip>,
							<Tooltip key='refresh' trigger={['hover', 'focus']} title='Refresh'>
								<SyncOutlined
									className={`${isPendingStatus(context.status) ? styles.disabledActionIcon : ''}`}
									onClick={async (e) => {
										e.stopPropagation()
										if (!isPendingStatus(context.status)) {
											await refreshContexts([context.id])
										}
									}}
								/>
							</Tooltip>,
							<Tooltip key='settings' trigger={['hover', 'focus']} title='Settings'>
								<SettingOutlined
									className={`${isPendingStatus(context.status) ? styles.disabledActionIcon : ''}`}
									onClick={async (e) => {
										e.stopPropagation()
										if (!isPendingStatus(context.status)) {
											setEditContextSettingsId(context.id)
											setOpenContextSettingsModal(true)
										}
									}}
								/>
							</Tooltip>,
						]}
					/>
				))}

				{!contexts.length && (
					<EmptyData onClick={() => navigate('/context')} description='Empty context list' />
				)}
			</div>
		</div>
	)
}

const isPendingStatus = (status: ContextStatus) => status === 'deleting' || status === 'refreshing'

export default AiContexts
