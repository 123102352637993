import { UsingUserId } from '../global'

export const MIN_REINDEX_INTERVAL_IN_MINUTES = 10 as const

export interface Context {
	id: number
	userId: string
	indexId?: string
	status: ContextStatus
	errorMessage?: string
	resource: Resource
	reindexInterval?: number
	reindexIntervalUnit?: ReindexIntervalUnit

	createdAt: Date
	nextReindexAt?: Date
	reindexedAt?: Date
	updatedAt?: Date
}

export type Resource = TextResource | UrlResource | FileResource

export type TextResource = {
	name: string
	type: 'text'
	text: string
}

export type UrlResource = {
	name: string
	type: 'url'
	url: string
	databaseIntegrationId?: string
	fileId?: string
}

export type FileResource = {
	name: string
	type: 'file'
	fileId: string
}

export const MAX_TEXT_CONTENT_LENGTH = 50000 as const
export const MAX_URL_LENGTH = 1000 as const
export const MAX_CONTEXT_NAME_LENGTH = 256 as const

export const CONTEXT_STATUSES = [
	'indexed',
	'refreshing',
	'needReindex',
	'error',
	'deleting',
] as const

export type ContextStatus = (typeof CONTEXT_STATUSES)[number]

export const SOURCE_TYPES = ['text', 'url', 'file'] as const
export type SourceType = (typeof SOURCE_TYPES)[number]

export const REINDEX_INTERVAL_UNITS = ['minutes', 'hours', 'days'] as const
export type ReindexIntervalUnit = (typeof REINDEX_INTERVAL_UNITS)[number]

export interface DataSummary {
	id: string
	name: string
	title: string
	summary: string
}

export type RemoveContextData = {
	contextId: number
	indexId: string
}

export type ContextSettings = Pick<Context, 'reindexInterval' | 'reindexIntervalUnit'>

export type GetContextRequest = {
	id?: number
	ids?: number[]
}
export type RefreshContextRequest = Pick<Context, 'id'>
export type UpdateContextRequest = Pick<Context, 'id' | 'resource'>
export type UpdateContextSettingsRequest = Pick<Context, 'id'> & ContextSettings
export type UpdateContextStatusRequest = Pick<
	Context,
	'id' | 'status' | 'errorMessage' | 'indexId' | 'userId'
>
export type CreateContextRequest = Pick<Context, 'resource'> & {
	assistantRoleId?: string
} & UsingUserId
export type DeleteContextPublicRequest = Pick<Context, 'id'>

export type DeleteContextPrivateRequest = Pick<Context, 'id' | 'userId'>
