import React from 'react'
import { MenuProps } from 'antd'
import { OrderStatus } from '@neuron/types/order'
import { OrdersTableData } from '../UserOffersTable'
import styles from './OrderStatusTableOptions.module.scss'

type Props = {
	record: OrdersTableData & { key: string }
	changeOrderStatus: (orderId: string, status: OrderStatus) => Promise<void>
	isAdmin?: boolean
	isAuditor?: boolean
}

const OrderStatusTableOptions = ({ record, changeOrderStatus, isAdmin, isAuditor }: Props) => {
	const menu: MenuProps = {
		items: [
			{
				key: 'cancelled',
				disabled:
					record.status === 'cancelled' || (!isAdmin && !isAuditor)
						? record.status !== 'draft'
						: false,
				label: <span className={styles.menuItem}>Cancel</span>,
				onClick: () => changeOrderStatus(record.key, 'cancelled'),
			},
			...(isAdmin || isAuditor
				? [
						{
							key: 'active',
							label: <span className={styles.menuItem}>Active</span>,
							onClick: () => changeOrderStatus(record.key, 'active'),
							disabled: record.status === 'active',
						},
						{
							key: 'inactive',
							label: <span className={styles.menuItem}>Inactive</span>,
							onClick: () => changeOrderStatus(record.key, 'inactive'),
							disabled: record.status === 'inactive',
						},
					]
				: []),
			...(isAdmin
				? [
						{
							key: 'purchased',
							label: <span className={styles.menuItem}>Purchased</span>,
							onClick: () => changeOrderStatus(record.key, 'purchased'),
							disabled: record.status === 'purchased',
						},
					]
				: []),
		],
	}

	return menu
}

export default OrderStatusTableOptions
