import React, { useEffect, useMemo, useState } from 'react'
import useAsyncEffect from '../../hooks/useAsyncEffect'
import query from '../../utils/query'
import { Chat, ChatStatus, CreateChatRequest, UpdateChatRequest } from '@neuron/types/chat'
import { useNavigate } from 'react-router-dom'
import { Button, Modal, Layout, App, Tooltip, Tag } from 'antd'
import ChatForm from '../../components/ChatForm/ChatForm'
import { formatDate } from '@neuron/utils/dates'
import LLMModelMapper from '../../components/LLMModelMapper/LLMModelMapper'
import Loading, { InlineLoading } from '../../components/Loading/Loading'
import { socket } from '../../global/SocketManager'
import useTryCatch from '../../hooks/useTryCatch'
import CardTitle from '../../components/CardTitle/CardTitle'
import EmptyData from '../../components/EmptyData/EmptyData'
import { CommentOutlined, DeleteOutlined, SyncOutlined } from '@ant-design/icons'
import CardItem from '../../components/CardItem/CardItem'
import { useAccountContext } from '../../hooks/context/AccountContext'
import styles from './AiChats.module.scss'

const { Header } = Layout

const AiChats = () => {
	const navigate = useNavigate()
	const { message } = App.useApp()
	const tryCatch = useTryCatch(message)

	const accountContext = useAccountContext()
	const usingUserId = accountContext.useSubscribe((account) => account.usingUserId)

	const [isChatFormModalOpen, setIsChatFormModalOpen] = useState(false)
	const [selectedChatIds, setSelectedChatIds] = useState<string[]>([])
	const [editingChat, setEditingChat] = useState<Chat | null>()
	const [chats, setChats] = useState<Chat[]>([])
	const [loading, setLoading] = useState<boolean>(true)

	useEffect(() => {
		socket.on('chatStatusUpdated', updateChatState)
		socket.on('chatDeleted', removeChatState)

		return () => {
			socket.off('chatStatusUpdated', updateChatState)
			socket.off('chatDeleted', removeChatState)
		}
	}, [chats])

	const onLoadChats = async () => {
		setLoading(true)
		await tryCatch(async () => {
			const resChats = await query<Chat[]>('/chat/forUser', 'GET', {
				withCredentials: true,
				params: { usingUserId },
			})
			setChats(resChats)
		})
		setLoading(false)
	}

	useAsyncEffect(onLoadChats, [usingUserId])

	useEffect(() => {
		if (editingChat) {
			setIsChatFormModalOpen(true)
		}
	}, [!!editingChat])

	const updateChatState = async (updatedChat: Chat) => {
		const findChatToUpdate = chats.find((chat) => chat.id === updatedChat.id)
		if (findChatToUpdate) {
			if (updatedChat.status === 'error') {
				message.open({
					type: 'error',
					content: `Updating chat "${updatedChat.name}" error. Try again.`,
				})
			}
			if (updatedChat.status === 'contextError') {
				message.open({
					type: 'error',
					content: `Updating chat "${updatedChat.name}" error. Check your contexts.`,
				})
			}

			setChats((chats) => chats.map((chat) => (chat.id === updatedChat.id ? updatedChat : chat)))
		}
	}

	const removeChatState = async (chatId: string) => {
		const findChatToRemove = chats.find((chat) => chat.id === chatId)
		if (findChatToRemove) {
			message.open({
				type: 'success',
				content: `Chat "${findChatToRemove.name}" was deleted.`,
			})
			setChats((chats) => chats.filter((chat) => chat.id !== chatId))
		}
	}

	const deleteChats = async (chatIds: string[]) => {
		const expectChatIds: string[] = []

		for (const chatId of chatIds) {
			await tryCatch(
				async () => {
					await query('/chat/delete', 'POST', {
						data: { id: chatId },
						withCredentials: true,
					})
				},
				() => expectChatIds.push(chatId),
				{ message: 'Error while deleting chat.' },
			)
		}

		setChats((chats) =>
			chats.map((chat) =>
				chatIds.includes(chat.id)
					? { ...chat, status: expectChatIds.includes(chat.id) ? 'error' : 'deleting' }
					: chat,
			),
		)
		setSelectedChatIds([])
	}

	const createNewChat = async (newChatData: CreateChatRequest) => {
		await tryCatch(
			async () => {
				const newChat = await query<Chat>('/chat/create', 'POST', {
					data: {
						...newChatData,
						usingUserId,
					},
					withCredentials: true,
				})

				message.open({
					type: 'success',
					content: 'New chat is created',
				})

				setChats((chats) => [...chats, newChat])
			},
			undefined,
			{ message: 'Error while creating new chat. Try again.' },
		)

		onCloseChatFormModal()
	}

	const updateChat = async (updatedChatData: UpdateChatRequest) => {
		await tryCatch(
			async () => {
				const updatedChat = await query<Chat>('/chat/update', 'POST', {
					data: updatedChatData,
					withCredentials: true,
				})

				message.open({
					type: 'success',
					content: 'Chat updated',
				})

				setChats((chats) =>
					chats.map((chat) => (chat.id === updatedChat.id ? { ...chat, ...updatedChat } : chat)),
				)
			},
			undefined,
			{ message: 'Error while updating chat. Try again.' },
		)

		onCloseChatFormModal()
	}

	const onCloseChatFormModal = () => {
		setIsChatFormModalOpen(false)
		setEditingChat(null)
	}

	const selectChat = (chatId: string, chatStatus: ChatStatus) => {
		if (chatStatus === 'deleting') {
			setSelectedChatIds((ids) => ids.filter((id) => id !== chatId))
		}

		const hasSelectedChatId = selectedChatIds.some((id) => id === chatId)

		if (hasSelectedChatId) {
			setSelectedChatIds((ids) => ids.filter((id) => id !== chatId))
		} else {
			setSelectedChatIds([...selectedChatIds, chatId])
		}
	}

	const chatIsInactive = (status: ChatStatus): boolean => status !== 'active'
	const pendingStatus = (status: ChatStatus) => status === 'deleting' || status === 'refreshing'

	const chatFormModal = useMemo(
		() => (
			<Modal
				destroyOnClose
				footer={null}
				className={styles.chatFormModal}
				title={editingChat ? 'Edit chat' : 'Create chat'}
				open={isChatFormModalOpen}
				onCancel={onCloseChatFormModal}
			>
				<div className={styles.modalContent}>
					<ChatForm
						onCancel={onCloseChatFormModal}
						onSubmit={(chatData) =>
							chatData.id
								? updateChat(chatData as UpdateChatRequest)
								: createNewChat(chatData as CreateChatRequest)
						}
						chatId={editingChat?.id}
					/>
				</div>
			</Modal>
		),
		[!!editingChat, isChatFormModalOpen],
	)

	if (loading) {
		return <Loading />
	}

	return (
		<div className={styles.container}>
			{chatFormModal}

			<Header className={styles.header}>
				<div className={styles.headerElements}>
					<Button icon={<CommentOutlined />} onClick={() => setIsChatFormModalOpen(true)}>
						Create new chat
					</Button>
				</div>

				<div className={styles.headerElements}>
					{!!selectedChatIds.length && (
						<>
							<Button icon={<DeleteOutlined />} danger onClick={() => deleteChats(selectedChatIds)}>
								Delete selected {selectedChatIds.length > 1 ? 'chats' : 'chat'} (
								{selectedChatIds.length})
							</Button>
						</>
					)}

					<Tooltip trigger={['hover', 'focus']} title='Refresh'>
						<Button
							className={styles.headerRefresh}
							onClick={onLoadChats}
							shape='circle'
							icon={<SyncOutlined />}
						/>
					</Tooltip>
				</div>
			</Header>

			<div className={styles.chats}>
				{chats.map((chat) => (
					<CardItem
						key={chat.id}
						itemId={chat.id}
						selectedItems={selectedChatIds}
						selectItem={() => selectChat(chat.id, chat.status)}
						cardTitle={
							<CardTitle
								id={chat.id}
								name={chat.name}
								disabled={chat.status === 'deleting'}
								onDelete={(id: string) => deleteChats([id])}
							/>
						}
						labels={[
							{
								label: 'Status',
								value: (
									<Tag>
										{pendingStatus(chat.status) && (
											<InlineLoading iconClassName={styles.loading} color='#fff' size={10} />
										)}
										{chat.status}
									</Tag>
								),
							},
							{
								label: 'LLM Model',
								value: <LLMModelMapper llmModel={chat.options.llmModel} simple />,
							},
							{
								label: 'Created date',
								value: formatDate(chat.createdAt),
							},
						]}
					>
						<Button
							disabled={chatIsInactive(chat.status)}
							onClick={(e) => {
								e.stopPropagation()
								navigate(`/chat?chatId=${chat.id}`)
							}}
						>
							Open
						</Button>
						<Button
							disabled={pendingStatus(chat.status)}
							onClick={(e) => {
								e.stopPropagation()
								setEditingChat(chat)
							}}
						>
							Edit
						</Button>
					</CardItem>
				))}

				{!chats.length && (
					<EmptyData onClick={() => setIsChatFormModalOpen(true)} description='Empty chat list' />
				)}
			</div>
		</div>
	)
}

export default AiChats
