import { App, Button, Tooltip } from 'antd'
import { Assistant } from '@neuron/types/assistant'
import React, { useEffect, useState } from 'react'
import { Dayjs } from 'dayjs'
import Joi from 'joi'
import query from '../../../utils/query'
import { useNavigate, useParams } from 'react-router-dom'
import useTryCatch from '../../../hooks/useTryCatch'
import useAsyncEffect from '../../../hooks/useAsyncEffect'
import { omit } from '@neuron/utils'
import { formValidate } from '../../../utils/validation'
import { createAssistantSchema } from '@neuron/schemas/assistant'
import PersonaForm from './PersonaForm/PersonaForm'
import Loading from '../../../components/Loading/Loading'
import { DEFAULT_LANGUAGE } from '@neuron/types/global'
import { useAccountContext } from '../../../hooks/context/AccountContext'
import styles from './Persona.module.scss'

const defaultPersonaData: Partial<Assistant> = {
	gender: 'male',
	personality: {
		type: 'default',
	},
	defaultLanguage: DEFAULT_LANGUAGE,
	multilingual: false,
	timezone: 'UTC:0',
}

const Persona = () => {
	const { message } = App.useApp()
	const tryCatch = useTryCatch(message)
	const navigate = useNavigate()

	const { assistantId } = useParams()

	const accountContext = useAccountContext()
	const usingUserId = accountContext.useSubscribe((account) => account.usingUserId)

	const [assistantData, setAssistantData] = useState<Partial<Assistant>>(defaultPersonaData)
	const [isValid, setIsValid] = useState<boolean>(false)
	const [loading, setLoading] = useState<boolean>(true)
	const [processingAssistantDataLoading, setProcessingAssistantDataLoading] =
		useState<boolean>(false)

	const onLoadAssistant = async () => {
		if (!assistantId) {
			setLoading(false)
			return
		}

		setLoading(true)
		await tryCatch(async () => {
			const resAssistant = await query<Assistant>('/assistant/get', 'GET', {
				withCredentials: true,
				params: {
					id: assistantId,
				},
			})
			setAssistantData(resAssistant)
		})
		setLoading(false)
	}

	useAsyncEffect(onLoadAssistant, [assistantId])

	useEffect(() => {
		checkFormValidate()
	}, [assistantData])

	const createOrUpdateAssistant = async () => {
		setProcessingAssistantDataLoading(true)
		await tryCatch(async () => {
			if (assistantData.id) {
				await query<Assistant>('/assistant/update', 'POST', {
					data: prepareAssistantData(assistantData),
					withCredentials: true,
				})
			} else {
				await query<Assistant>('/assistant/create', 'POST', {
					data: {
						...prepareAssistantData(assistantData),
						usingUserId,
					},
					withCredentials: true,
				})
			}

			message.open({
				type: 'success',
				content: assistantData.id ? 'Persona has been updated' : 'Persona was created',
			})
			navigate('/personas')
		})
		setProcessingAssistantDataLoading(false)
	}

	const updateAssistantData = (assistantData: Partial<Assistant>) => {
		if (
			assistantData.workHours?.length &&
			typeof assistantData.workHours[0] === 'object' &&
			typeof assistantData.workHours[1] === 'object'
		) {
			assistantData.workHours = [
				`${(assistantData.workHours[0] as Dayjs).hour()}:${(
					assistantData.workHours[0] as Dayjs
				).minute()}`,
				`${(assistantData.workHours[1] as Dayjs).hour()}:${(
					assistantData.workHours[1] as Dayjs
				).minute()}`,
			]
		}

		if (assistantData.personality && assistantData.personality.type !== 'custom') {
			assistantData.personality.description = undefined
		}

		setAssistantData((data) => ({ ...data, ...assistantData }))
	}

	const checkFormValidate = () => {
		const validate = formValidate(prepareAssistantData(assistantData), assistantSchema)
		setIsValid(validate ? !Object.keys(validate).length : true)
	}

	return (
		<div className={styles.container}>
			<div className={styles.form}>
				<div className={styles.formWrapper}>
					{loading ? (
						<Loading className={styles.loading} size={42} />
					) : (
						<PersonaForm assistantData={assistantData} updateAssistantData={updateAssistantData} />
					)}
				</div>
			</div>

			<div className={styles.footer}>
				<div className={styles.footerButtons}>
					<Button onClick={() => navigate('/personas')} size='large'>
						Cancel
					</Button>

					<Tooltip
						zIndex={isValid ? -1 : 10}
						mouseEnterDelay={0.3}
						title='Make sure all form fields are completed correctly and do not contain any errors.'
					>
						<Button
							loading={processingAssistantDataLoading}
							disabled={!isValid || loading}
							onClick={() => createOrUpdateAssistant()}
							type='primary'
							size='large'
						>
							{assistantData?.id ? 'Update' : 'Create'}
						</Button>
					</Tooltip>
				</div>
			</div>
		</div>
	)
}

const prepareAssistantData = (assistantData: Partial<Assistant>) =>
	omit(assistantData, 'status', 'userId', 'createdAt', 'updatedAt')

const assistantSchema = Joi.object({
	...createAssistantSchema,
	id: Joi.any().optional(),
}).required()

export default Persona
