import { Progress } from 'antd'
import Loading from '../../../../components/Loading/Loading'
import React from 'react'
import { PublicAnalytics } from '@neuron/types/analytics'
import styles from './Header.module.scss'

type Props = {
	periodTab: string
	analytics: PublicAnalytics | undefined
	loading: boolean
}

const CONVERSATIONS_LIMIT = 30 as const

const Header = ({ periodTab, analytics, loading }: Props) => {
	const isLifetime = periodTab === 'Lifetime'

	return (
		<div>
			<div className={styles.quoteWrapper}>
				<div className={styles.quote}>
					<span>
						❝Those who can imagine anything, can create the <b>impossible</b>.❞
					</span>
					<span className={styles.quoteSignature}>― Alan Turing</span>
				</div>
			</div>

			<div className={styles.currentConversationContainer}>
				{!loading && !!analytics ? (
					<>
						<div className={styles.currentConversation}>
							<div>
								<div className={styles.currentConversationHeading}>Conversations:</div>
								<Progress
									size={200}
									type='dashboard'
									percent={
										+(
											((isLifetime
												? analytics.conversationsAmount
												: analytics.monthlyConversationsAmount) *
												100) /
											CONVERSATIONS_LIMIT
										).toFixed(2)
									}
									strokeColor={{ '0%': '#87d068', '50%': '#ffe58f', '100%': '#ffccc7' }}
								/>
							</div>

							<div className={styles.currentConversationTextWrapper}>
								<div className={styles.currentConversationText}>
									{isLifetime
										? analytics.conversationsAmount
										: analytics.monthlyConversationsAmount}
								</div>
								<div className={styles.currentConversationLimit}>/ {CONVERSATIONS_LIMIT} limit</div>
							</div>
						</div>
					</>
				) : (
					<Loading size={64} />
				)}
			</div>
		</div>
	)
}

export default Header
