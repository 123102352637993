import React, { useState, useMemo } from 'react'
import { App, Button, Form, Input, Select } from 'antd'
import {
	NewTemplate,
	Template,
	TEMPLATE_PROVIDERS,
	UpdateTemplateRequest,
} from '@neuron/types/leadsGroup/template'
import useTryCatch from '../../../../../hooks/useTryCatch'
import useAsyncEffect from '../../../../../hooks/useAsyncEffect'
import query from '../../../../../utils/query'
import Loading from '../../../../../components/Loading/Loading'
import { formValidate } from '../../../../../utils/validation'
import { templateBaseSchema } from '@neuron/schemas/leadsGroup/template'
import { AssistantRole } from '@neuron/types/assistant/assistantRole'
import CardItem from '../../../../../components/CardItem/CardItem'
import CardTitle from '../../../../../components/CardTitle/CardTitle'
import { MinusCircleOutlined, PlusOutlined, UserOutlined } from '@ant-design/icons'
import { formatDate } from '@neuron/utils/dates'
import { omit } from '@neuron/utils'
import { useAccountContext } from '../../../../../hooks/context/AccountContext'
import { assistantRoleTypeToName } from '../../../../../utils/helpers'
import styles from './TemplateForm.module.scss'

const { TextArea } = Input

type Props = {
	onSubmit: (data: NewTemplate | UpdateTemplateRequest) => Promise<void>
	onCancel: () => void
	templateId?: string
}

const TemplateForm = ({ onSubmit, onCancel, templateId }: Props) => {
	const [form] = Form.useForm()

	const { message } = App.useApp()
	const tryCatch = useTryCatch(message)

	const accountContext = useAccountContext()
	const usingUserId = accountContext.useSubscribe((account) => account.usingUserId)

	const [formData, setFormData] = useState<Partial<Template>>({})
	const [assistantRoles, setAssistantRoles] = useState<AssistantRole[]>([])

	const [loadingTemplate, setLoadingTemplate] = useState<boolean>(false)
	const [loadingAssistantRoles, setLoadingAssistantRoles] = useState<boolean>(true)

	useAsyncEffect(async () => {
		if (!templateId) {
			return
		}

		setLoadingTemplate(true)
		await tryCatch(
			async () => {
				const template = await query<Template>('/leadsGroup/template/get', 'GET', {
					params: {
						id: templateId,
					},
					withCredentials: true,
				})
				setFormData(template)
			},
			undefined,
			{ message: 'Load template error' },
		)
		setLoadingTemplate(false)
	}, [templateId])

	useAsyncEffect(async () => {
		await tryCatch(
			async () => {
				const roles = await query<AssistantRole[]>('/assistant/roles/providers', 'GET', {
					params: {
						providers: TEMPLATE_PROVIDERS,
						usingUserId,
					},
					withCredentials: true,
				})
				setAssistantRoles(roles)
			},
			undefined,
			{ message: 'Load assistant roles error' },
		)

		setLoadingAssistantRoles(false)
	}, [])

	const updateFormData = (_value: Record<string, any>, values: any) => {
		if (values.provider !== 'gmail') {
			values.subject = null
			values.signature = null
		}
		if (!values.followupMessagesContents?.length) {
			values.followupMessagesContents = null
		}

		setFormData((prevData) => ({ ...prevData, ...values }))
	}

	const handleSubmit = async () => {
		if (!isValid) {
			return
		}
		if (templateId) {
			await onSubmit({ ...prepareTemplateData(formData), id: templateId } as UpdateTemplateRequest)
		} else {
			await onSubmit(prepareTemplateData(formData) as NewTemplate)
		}
	}

	const selectAssistantRoleId = (assistantRoleId: string) => {
		setFormData((prevData) => ({ ...prevData, assistantRoleId }))
		form.resetFields()
	}

	const isValid = useMemo(() => {
		const validate = formValidate(prepareTemplateData(formData), templateBaseSchema)
		return validate ? !Object.keys(validate).length : true
	}, [formData])

	const filteredAssistantRoles = useMemo(() => {
		if (!formData.provider) {
			return []
		}

		return assistantRoles.filter(
			(role) =>
				role.connections?.integrations &&
				role.connections.integrations.some(
					(integration) => formData.provider === integration.provider,
				),
		)
	}, [formData.provider, assistantRoles])

	if (loadingTemplate || loadingAssistantRoles) {
		return <Loading />
	}

	const cannotChangeTemplateName = !!formData.id && formData.provider === 'whatsapp'

	return (
		<Form form={form} layout='vertical' initialValues={formData} onValuesChange={updateFormData}>
			<div className={styles.templateForm}>
				<Form.Item
					label='Name'
					name='name'
					rules={[
						{ required: true, message: 'Please enter the template name' },
						{ min: 3, message: 'Name must be at least 3 characters' },
						{ max: 120, message: 'Name can be up to 120 characters' },
					]}
					tooltip={
						cannotChangeTemplateName ? 'You cannot change the existing template name.' : undefined
					}
					className={styles.formItem}
				>
					<Input
						disabled={cannotChangeTemplateName}
						count={{
							show: true,
						}}
						maxLength={120}
						size='large'
						className={styles.formInput}
						placeholder='System name'
					/>
				</Form.Item>

				<Form.Item
					label='Provider'
					name='provider'
					rules={[{ required: true, message: 'Please select provider' }]}
					className={styles.formItem}
				>
					<Select
						size='large'
						placeholder='Select provider'
						options={TEMPLATE_PROVIDERS.map((provider) => ({
							label: `${provider.charAt(0).toUpperCase()}${provider.slice(1)}`,
							value: provider,
						}))}
					/>
				</Form.Item>

				{formData.provider === 'gmail' && (
					<>
						<Form.Item
							rules={[
								{ required: true, message: 'Please fill e-mail subject' },
								{ message: 'Subject must have a minimum of 5 characters', min: 5 },
								{ message: 'Subject can have a maximum of 200 characters', max: 200 },
							]}
							className={styles.formItem}
							label='Mail subject'
							name='subject'
						>
							<Input
								count={{
									show: true,
								}}
								maxLength={200}
								size='large'
								className={styles.formInput}
								placeholder='Mail subject'
							/>
						</Form.Item>

						<Form.Item
							rules={[
								{ message: 'Mail signature can have a maximum of 10 000 characters', max: 10000 },
							]}
							tooltip='Enter custom mail signature in HTML format'
							className={styles.formItem}
							label='Custom mail signature'
							name='signature'
						>
							<TextArea
								className={styles.formTextarea}
								size='large'
								allowClear
								maxLength={10000}
								autoSize={{ minRows: 2, maxRows: 6 }}
								placeholder='Mail signature'
							/>
						</Form.Item>

						<Form.Item
							rules={[
								{ type: 'email', message: 'Invalid e-mail format' },
								{
									message: 'E-mail can have a maximum of 250 characters',
									max: 250,
								},
							]}
							tooltip='The system will use this custom email address to send and converse with users. Your Gmail account must have this email address added as “Send mail as” for this functionality to work.'
							className={styles.formItem}
							label='Send mail as'
							name='customMailAddress'
						>
							<Input
								maxLength={250}
								size='large'
								className={styles.formInput}
								placeholder='Custom mail address'
							/>
						</Form.Item>
					</>
				)}

				<Form.Item
					rules={[
						{ required: true, message: 'Please fill message content' },
						{ message: 'Message content must have a minimum of 10 characters', min: 10 },
						{
							message: `Message content can have a maximum of ${
								formData.provider === 'gmail' ? 2000 : 1000
							} characters`,
							max: formData.provider === 'gmail' ? 2000 : 1000,
						},
					]}
					className={styles.formItem}
					label='First message content'
					name='firstMessageContent'
				>
					<TextArea
						className={styles.formTextarea}
						size='large'
						allowClear
						count={{
							show: true,
						}}
						maxLength={formData.provider === 'gmail' ? 2000 : 1000}
						autoSize={{ minRows: 2, maxRows: 6 }}
						placeholder='Start conversation message'
					/>
				</Form.Item>

				<Form.Item
					tooltip='Default follow-ups messages used when a user has not responded to the first message that starts a conversation. The number and time intervals of follow-ups depend on the configuration of the selected Persona role.'
					className={`${styles.formItem}`}
					label='Follow-ups messages'
				>
					<Form.List name='followupMessagesContents'>
						{(fields, { add, remove }) => (
							<>
								{fields.map(({ key, name, ...restField }, index) => (
									<div key={key} className={styles.fieldList}>
										<Form.Item
											{...restField}
											rules={[
												{ required: true, message: 'Please fill follow-up message' },
												{
													message: 'Follow-up message must have a minimum of 10 characters',
													min: 10,
												},
												{
													message: 'Follow-up message can have a maximum of 2000 characters',
													max: 2000,
												},
											]}
											className={styles.formItem}
											label={`${index + 1}.`}
											name={name}
										>
											<TextArea
												className={styles.formTextarea}
												size='large'
												allowClear
												count={{
													show: true,
												}}
												maxLength={2000}
												autoSize={{ minRows: 2, maxRows: 6 }}
												placeholder='Follow-up message'
											/>
										</Form.Item>

										<MinusCircleOutlined
											className={styles.listRemoveIcon}
											onClick={() => remove(name)}
										/>
									</div>
								))}
								<Form.Item className={styles.formItem}>
									<Button
										size='large'
										type='dashed'
										disabled={fields.length === 5}
										onClick={() => {
											fields.length < 5 && add()
										}}
										block
										icon={<PlusOutlined />}
									>
										Add{fields.length ? ' next ' : ' '}follow-up message
									</Button>
								</Form.Item>
							</>
						)}
					</Form.List>
				</Form.Item>

				{!!formData.provider && (
					<>
						<div className={styles.heading}>Available Persona Roles</div>
						<div className={styles.assistantRoles}>
							{!filteredAssistantRoles.length && (
								<div>
									No available Persona roles. Add {formData.provider} integration to your Persona
									Roles
								</div>
							)}
							{filteredAssistantRoles.map((assistantRole) => (
								<CardItem
									key={assistantRole.id}
									itemId={assistantRole.id}
									selectedItems={formData.assistantRoleId ? [formData.assistantRoleId] : []}
									selectItem={() => selectAssistantRoleId(assistantRole.id)}
									cardTitle={
										<CardTitle
											id={assistantRole.id}
											name={
												<span>
													<UserOutlined className={styles.roleIcon} />
													{assistantRole.name}
												</span>
											}
										/>
									}
									labels={[
										{
											label: 'Role',
											value: assistantRoleTypeToName(assistantRole.type),
										},
										{
											label: 'Integrations',
											value: assistantRole.connections?.integrations
												?.filter((integration) => integration.provider !== 'playground')
												.map((integration) => integration.provider)
												.join(', '),
										},
										{
											label: 'Created date',
											value: formatDate(assistantRole.createdAt),
										},
									]}
								>
									<Button
										className={
											formData.assistantRoleId === assistantRole.id ? styles.selectedButton : ''
										}
										onClick={(e) => {
											e.stopPropagation()
											selectAssistantRoleId(assistantRole.id)
										}}
									>
										{formData.assistantRoleId === assistantRole.id ? 'Selected' : 'Select'}
									</Button>
								</CardItem>
							))}
						</div>
					</>
				)}

				<Form.Item noStyle>
					<div className={styles.buttons}>
						<Button size='large' type='primary' onClick={handleSubmit} disabled={!isValid}>
							{templateId ? 'Update' : 'Create'}
						</Button>
						<Button size='large' onClick={onCancel}>
							Cancel
						</Button>
					</div>
				</Form.Item>
			</div>
		</Form>
	)
}

const prepareTemplateData = (templateData: Partial<Template>) =>
	omit(templateData, 'id', 'userId', 'createdAt')

export default TemplateForm
