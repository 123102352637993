import { Statistic } from 'antd'
import React from 'react'
import { PublicAnalytics } from '@neuron/types/analytics'
import { getCostValue } from '@neuron/utils'
import styles from './AdminStatistics.module.scss'

type Props = {
	periodTab: string
	analytics: PublicAnalytics | undefined
	loading: boolean
}

const AdminStatistics = ({ periodTab, analytics, loading }: Props) => {
	const isLifetime = periodTab === 'Lifetime'

	return (
		<div className={styles.statistics}>
			<Statistic
				title='Real lifetime cost'
				value={getCostValue(
					(isLifetime ? analytics?.realLifetimeCost : analytics?.monthlyRealCost) ?? 0,
					false,
				)}
				loading={loading}
			/>

			<Statistic
				title='Total used tokens'
				value={isLifetime ? analytics?.totalUsedTokens : analytics?.monthlyUsedTokens}
				loading={loading}
			/>
			<Statistic
				title='Prompt used tokens'
				value={isLifetime ? analytics?.promptUsedTokens : analytics?.monthlyPromptUsedTokens}
				loading={loading}
			/>
			<Statistic
				title='Completion used tokens'
				value={
					isLifetime ? analytics?.completionUsedTokens : analytics?.monthlyCompletionUsedTokens
				}
				loading={loading}
			/>
			<Statistic
				title='Embedding used tokens'
				value={isLifetime ? analytics?.embeddingUsedTokens : analytics?.monthlyEmbeddingUsedTokens}
				loading={loading}
			/>
		</div>
	)
}

export default AdminStatistics
