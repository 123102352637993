import { getConversationStatus } from '../../../../containers/Personas/Conversations/Conversations'
import Line from '../../../Line/Line'
import { Button } from 'antd'
import React, { useMemo } from 'react'
import { Conversation } from '@neuron/types/assistant/conversation'
import { ConversationUsage } from '@neuron/types/usage'
import { Assistant } from '@neuron/types/assistant'
import { isAssistantWork } from '@neuron/utils/assistant'
import { formatDate } from '@neuron/utils/dates'
import { AssistantRole } from '@neuron/types/assistant/assistantRole'
import { Link } from 'react-router-dom'
import { useAccountContext } from '../../../../hooks/context/AccountContext'
import { getCostValue } from '@neuron/utils'
import { assistantRoleTypeToName } from '../../../../utils/helpers'
import styles from './ConversationInfo.module.scss'

type Props = {
	conversation: Conversation
	conversationUsage: ConversationUsage | null
	triggerConversationResponseLoading: boolean
	redirectConversationLoading: boolean
	manualTriggerConversationResponse: () => Promise<void>
	manualRedirectConversation: () => Promise<void>
	restoreAssistantManageConversation: () => Promise<void>
	assistant?: Assistant
	assistantRoles?: AssistantRole[]
}

const ConversationInfo = ({
	conversation,
	conversationUsage,
	assistant,
	assistantRoles,
	redirectConversationLoading,
	restoreAssistantManageConversation,
	manualRedirectConversation,
	triggerConversationResponseLoading,
	manualTriggerConversationResponse,
}: Props) => {
	const accountContext = useAccountContext()
	const isAdmin = accountContext.useSubscribe((context) => context.roles.includes('admin'))

	const cannotGenerateResponse = useMemo(() => {
		return (
			!conversation ||
			conversation.processing ||
			conversation.ended ||
			conversation.redirected ||
			conversation.userActivityTimeout ||
			triggerConversationResponseLoading
		)
	}, [conversation, triggerConversationResponseLoading])

	const getAvailableConversationRoles = (): string => {
		if (!assistantRoles?.length) {
			return 'No attached roles'
		}

		if (conversation.assistantRoleId) {
			const selectedRole = assistantRoles.find((role) => role.id === conversation.assistantRoleId)
			return selectedRole ? assistantRoleTypeToName(selectedRole.type) : 'No attached roles'
		}

		return assistantRoles.map((role) => assistantRoleTypeToName(role.type)).join(', ')
	}

	return (
		<>
			<div className={styles.heading}>Current conversation:</div>

			<div className={styles.label}>
				Provider:{' '}
				<b>
					{conversation.integration?.provider}{' '}
					{!conversation.integration?.id && (
						<span className={styles.dangerText}>(No integration)</span>
					)}
				</b>
			</div>
			<div className={styles.label}>
				Status: <b>{getConversationStatus(conversation)}</b>
			</div>
			{assistant?.status === 'active' ? (
				<>
					<div className={styles.label}>
						Persona:{' '}
						<b>
							<Link to={`/persona/${assistant.id}`}>{assistant.firstname}</Link>
						</b>
					</div>

					<div className={styles.label}>
						Available roles: <b>{getAvailableConversationRoles()}</b>
					</div>

					<div className={styles.label}>
						Persona at work: <b>{isAssistantWork(assistant) ? 'Yes' : 'No'}</b>
					</div>
				</>
			) : (
				<div className={styles.label}>
					<span className={styles.dangerText}>No active AI Person</span>
				</div>
			)}

			{!!conversation.error && (
				<div className={styles.label}>
					Problem: <b>{`${conversation.error.message}`}</b>
				</div>
			)}
			<div className={styles.label}>
				Managed by Persona: <b>{conversation?.ended || conversation?.redirected ? 'No' : 'Yes'}</b>
			</div>
			<div className={styles.label}>
				Completed: <b>{conversation.ended ? 'Yes' : 'No'}</b>
			</div>
			{!!conversation.endedAt && (
				<div className={styles.label}>
					Completed date: <b>{`${formatDate(conversation.endedAt)}`}</b>
				</div>
			)}

			<Line className={styles.line} />

			<div className={styles.label}>
				Provider conversation Id: <b>{conversation.providerConversationId}</b>
			</div>
			<div className={styles.label}>
				Provider user Id: <b>{conversation.userProviderId}</b>
			</div>
			{conversation.integration?.provider === 'gmail' && (
				<div className={styles.label}>
					Conversation read: <b>{conversation.read ? 'Yes' : 'No'}</b>
				</div>
			)}
			{!!conversation.conversationSubject && (
				<div className={styles.label}>
					Conversation subject: <b>{conversation.conversationSubject}</b>
				</div>
			)}
			{!!conversation.userProviderInfo && (
				<>
					{conversation.userProviderInfo.firstName && (
						<div className={styles.label}>
							User name: <b>{conversation.userProviderInfo.firstName}</b>
						</div>
					)}
					{conversation.userProviderInfo.gender && (
						<div className={styles.label}>
							User gender: <b>{conversation.userProviderInfo.gender}</b>
						</div>
					)}
					{conversation.userProviderInfo.timezone && (
						<div className={styles.label}>
							User timezone:{' '}
							<b>
								{conversation.userProviderInfo.timezone > 0
									? `+${conversation.userProviderInfo.timezone}`
									: `${conversation.userProviderInfo.timezone}`}
							</b>
						</div>
					)}
				</>
			)}

			<Line className={styles.line} />

			{!!conversation.responseAt && (
				<div className={styles.label}>
					Next response date: <b>~{`${formatDate(conversation.responseAt, true)}`}</b>
				</div>
			)}
			{!!conversation.lastUserActivityAt && (
				<div className={styles.label}>
					Last user activity date: <b>{`${formatDate(conversation.lastUserActivityAt, true)}`}</b>
				</div>
			)}
			<div className={styles.label}>
				Last activity date: <b>{`${formatDate(conversation.lastActivityAt, true)}`}</b>
			</div>
			<div className={styles.label}>
				Created date: <b>{`${formatDate(conversation.createdAt)}`}</b>
			</div>

			<Line className={styles.line} />

			{!!conversationUsage && (
				<>
					<div className={styles.label}>
						Conversation total cost: <b>{`${getCostValue(conversationUsage.cost)}`}</b>
					</div>
					{isAdmin && (
						<>
							<div className={styles.label}>
								Conversation <b>real</b> total cost:{' '}
								<b>{`${getCostValue(conversationUsage.realCost ?? 0)}`}</b>
							</div>
							<div className={styles.label}>
								Conversation used tokens: <b>{`${conversationUsage.tokens}`}</b>
							</div>
						</>
					)}

					<Line className={styles.line} />
				</>
			)}

			<div className={styles.actions}>
				<Button
					className={styles.button}
					disabled={cannotGenerateResponse}
					loading={triggerConversationResponseLoading}
					onClick={manualTriggerConversationResponse}
				>
					Generate new response
				</Button>

				{!conversation.ended && !conversation.redirected && (
					<Button
						className={styles.button}
						disabled={conversation.ended || conversation.redirected || redirectConversationLoading}
						loading={redirectConversationLoading}
						onClick={manualRedirectConversation}
					>
						Redirect conversation to human
					</Button>
				)}

				{conversation.redirected && (
					<Button
						className={styles.button}
						disabled={conversation.ended || !conversation.redirected}
						onClick={restoreAssistantManageConversation}
					>
						Restore Persona management
					</Button>
				)}
			</div>
		</>
	)
}

export default ConversationInfo
