import Joi from 'joi'
import { STRICT_URL_REGEX } from '../../utils/consts'

export const idSchema = Joi.number().integer().positive()

export const uuidIdSchema = Joi.string().guid()

export const urlSchema = Joi.string().pattern(STRICT_URL_REGEX, 'url').trim().min(6).max(1000)

export const emailSchema = Joi.string()
	.lowercase()
	.email({ tlds: { allow: false } })
	.pattern(/^[\u0021-\u007E]+$/) // allows only ASCII characters in e-mail
	.regex(/···/, { name: 'middledots', invert: true })
	.trim()
	.max(250)

export const usingUserIdSchema = {
	usingUserId: uuidIdSchema.allow(null).optional(),
}
