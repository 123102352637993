import { AssistantRole } from '@neuron/types/assistant/assistantRole'
import { Conversation } from '@neuron/types/assistant/conversation'
import { ConversationMessage } from '@neuron/types/assistant/conversationMessage'
import { Chat } from '@neuron/types/chat'
import { ChatSession } from '@neuron/types/chat/chatSession'
import { ChatMessage } from '@neuron/types/chat/chatMessage'
import { PublicChat, PublicMessage, PublicSession } from '../types/Chat'
import { formatDate } from '@neuron/utils/dates'

export const toPublicChat = (data: AssistantRole | Chat, roleFirstname?: string): PublicChat => ({
	id: data.id,
	userId: data.userId,
	name: data.name,
	status: 'assistantId' in data ? 'active' : data.status, // chat preview always should be active for assistant conversations
	roleFirstname: roleFirstname,
	roleType: 'type' in data ? data.type : undefined,
	integrations: 'connections' in data ? data.connections?.integrations : undefined,
	isAssistant: 'assistantId' in data ? true : undefined,
	options: 'options' in data ? data.options : undefined,
	createdAt: data.createdAt,
})

export const toPublicSession = (data: Conversation | ChatSession): PublicSession => ({
	id: data.id,
	name: 'name' in data ? data.name : formatDate(data.createdAt),
	chatId: 'chatId' in data ? data.chatId : data.integration?.id ?? '',
	error: 'error' in data ? data.error : undefined,
	provider: 'integration' in data ? data.integration?.provider : undefined,
	processing: 'processing' in data ? data.processing : undefined,
	redirected: 'redirected' in data ? data.redirected : undefined,
	userActivityTimeout: 'userActivityTimeout' in data ? data.userActivityTimeout : undefined,
	ended: 'ended' in data ? data.ended : false,
})

export const toPublicMessage = (data: ConversationMessage | ChatMessage): PublicMessage => ({
	id: data.id,
	sessionId: 'sessionId' in data ? data.sessionId : data.conversationId,
	role: data.role,
	content: data.content,
	sources: 'sources' in data ? data.sources : undefined,
	sentByProvider: 'sentByProvider' in data ? data.sentByProvider : undefined,
	attachedFileIds: 'attachedFileIds' in data ? data.attachedFileIds : undefined,
	toolType: 'toolType' in data ? data.toolType : undefined,
	parentMessageId: 'parentMessageId' in data ? data.parentMessageId : undefined,
	category: 'category' in data ? data.category : undefined,
	salesStage: 'salesStage' in data ? data.salesStage : undefined,
	isComment: 'isComment' in data ? data.isComment : undefined,
	error: 'error' in data ? data.error : undefined,
	publishAt: 'publishAt' in data ? data.publishAt : undefined,
	sentAt: 'sentAt' in data ? data.sentAt : undefined,
	createdAt: data.createdAt,
})
