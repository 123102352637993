import { Currency } from '../index'
import { BillingAddress } from '../paymentMethod'
import { FailureObject } from '../../global/failure'

export interface Payment {
	id: string
	userId: string
	orderId: string
	paymentMethodId: string
	providerPaymentId?: string
	status: PaymentStatus
	error?: FailureObject
	amount: number
	currency: Currency
	paymentAttempts?: number
	lastPaymentAttempt?: Date
	updatedAt?: Date
	createdAt: Date
}

export const PAYMENT_STATUSES = [
	'initialized',
	'processing',
	'requiresAction',
	'succeeded',
	'failed',
	'canceled',
	'capturing',
	'refunded',
	'error',
] as const
export type PaymentStatus = (typeof PAYMENT_STATUSES)[number]

export const COMPLETED_PAYMENT_STATUSES: PaymentStatus[] = ['capturing', 'succeeded', 'refunded']
export const FAILED_PAYMENT_STATUSES: PaymentStatus[] = ['failed', 'canceled', 'error']

export type CreatePayment = Omit<
	Payment,
	'id' | 'createdAt' | 'userId' | 'status' | 'updatedAt' | 'paymentAttempts' | 'lastPaymentAttempt'
>

export type MakePaymentRequest = {
	orderId: string
	paymentMethod?: {
		holderName: string
		providerPaymentMethodId: string
		billingAddress: BillingAddress
	}
}

export type PrivateMakePaymentRequest = {
	orderId: string
}

export type UpdatePaymentStatusEvent = {
	payment: Payment
	clientSecret?: string | null
}
