import React, { useState } from 'react'
import UsersTable from './UsersTable/UsersTable'
import {
	AccountsFilter,
	AccountsSortParams,
	AdminAccount,
	FilterAccountsResponse,
} from '@neuron/types/account'
import query from '../../../utils/query'
import { App, Button, Tooltip } from 'antd'
import useTryCatch from '../../../hooks/useTryCatch'
import useAsyncEffect from '../../../hooks/useAsyncEffect'
import Loading from '../../../components/Loading/Loading'
import { SyncOutlined, SafetyCertificateOutlined } from '@ant-design/icons'
import { Header } from 'antd/es/layout/layout'
import { useAccountContext } from '../../../hooks/context/AccountContext'
import { useNavigate } from 'react-router-dom'
import styles from './Users.module.scss'

const Users = () => {
	const navigate = useNavigate()
	const { message } = App.useApp()
	const tryCatch = useTryCatch(message)

	const accountContext = useAccountContext()
	const accountId = accountContext.useSubscribe((account) => account.id)

	const [accounts, setAccounts] = useState<AdminAccount[]>([])
	const [accountsCount, setAccountsCount] = useState<number>(0)

	const [selectedAccountIds, setSelectedAccountIds] = useState<string[]>([])

	const [filters, setFilters] = useState<AccountsFilter>({ page: 1, perPage: 20 })
	const [sortParams, setSortParams] = useState<AccountsSortParams | undefined>()

	const [loading, setLoading] = useState<boolean>(true)

	const onLoadAccounts = async (filters: AccountsFilter, sortParams?: AccountsSortParams) => {
		setLoading(true)
		await tryCatch(async () => {
			const { data, count } = await query<FilterAccountsResponse>('/account/accounts', 'GET', {
				withCredentials: true,
				params: filters,
			})

			setAccounts(data)
			setAccountsCount(count)
			setFilters(filters)
			setSortParams(sortParams)
		})
		setLoading(false)
	}

	useAsyncEffect(() => onLoadAccounts(filters, sortParams), [])

	const updateAccount = (newAccount: AdminAccount) => {
		setAccounts((accounts) =>
			accounts.map((account) => (account.id === newAccount.id ? newAccount : account)),
		)
	}

	const setPagination = (newPage?: number, newPerPage?: number) => {
		let needRefresh = false
		const newFilters: AccountsFilter = { ...filters }

		if (newPage && newPage !== newFilters.page) {
			newFilters.page = newPage
			needRefresh = true
		}
		if (newPerPage && newPerPage !== newFilters.perPage) {
			newFilters.perPage = newPerPage
			needRefresh = true
		}

		if (needRefresh) {
			onLoadAccounts(newFilters, sortParams).then(() => {})
		}
	}

	const previewAccount = async (userId: string) => {
		if (userId === accountId) {
			return
		}
		accountContext.setUsingUserId(userId)
		navigate('/')
	}

	const viewOffer = async (userId: string) => {
		navigate(`/offers?userId=${userId}`)
	}

	const blockAccount = async (userId: string) => {
		if (userId === accountId) {
			return
		}

		await tryCatch(
			async () => {
				await query('/account/admin/block', 'POST', {
					data: { userId },
					withCredentials: true,
				})

				message.open({
					type: 'warning',
					content: 'Account blocked!',
				})
				await onLoadAccounts(filters, sortParams)
			},
			undefined,
			{ message: 'Error while blocking account' },
		)
	}

	const deleteAccount = async (userId: string) => {
		if (userId === accountId) {
			return
		}

		await tryCatch(
			async () => {
				await query('/account/admin/delete', 'POST', {
					data: { userId },
					withCredentials: true,
				})

				message.open({
					type: 'warning',
					content: 'Account deleted!',
				})
				await onLoadAccounts(filters, sortParams)
			},
			undefined,
			{ message: 'Error while deleting account' },
		)
	}

	const activeAccounts = async (accountIds: string[]) => {
		const expectAccountIds: string[] = []

		for (const accountId of accountIds) {
			await tryCatch(
				async () => {
					await query('/account/active', 'POST', {
						data: { userId: accountId },
						withCredentials: true,
					})
				},
				() => expectAccountIds.push(accountId),
			)
		}

		message.open({
			type: 'info',
			content: `${accountIds.length - expectAccountIds.length} accounts was activated`,
		})
		await onLoadAccounts(filters, sortParams)
	}

	if (loading) {
		return <Loading />
	}

	return (
		<div className={styles.container}>
			<Header className={styles.header}>
				<div className={styles.headerElements}>
					{!!selectedAccountIds.length && (
						<>
							<Button
								icon={<SafetyCertificateOutlined />}
								onClick={() => activeAccounts(selectedAccountIds)}
								className={styles.activeAccountButton}
							>
								Active selected accounts ({selectedAccountIds.length})
							</Button>
						</>
					)}
				</div>

				<div className={styles.headerElements}>
					<Tooltip trigger={['hover', 'focus']} title='Refresh'>
						<Button
							className={styles.headerRefresh}
							onClick={() => onLoadAccounts(filters, sortParams)}
							shape='circle'
							icon={<SyncOutlined />}
						/>
					</Tooltip>
				</div>
			</Header>

			<div className={styles.usersTable}>
				<UsersTable
					currentUserId={accountId}
					users={accounts}
					filters={filters}
					total={accountsCount}
					previewAccount={previewAccount}
					blockAccount={blockAccount}
					viewUserOffer={viewOffer}
					deleteAccount={deleteAccount}
					selectedAccountIds={selectedAccountIds}
					setPagination={setPagination}
					setSelectedAccounts={setSelectedAccountIds}
					updateAccount={updateAccount}
				/>
			</div>
		</div>
	)
}

export default Users
