import { Progress, Skeleton } from 'antd'
import React from 'react'
import { PublicAnalytics } from '@neuron/types/analytics'
import { roundNumber } from '@neuron/utils'
import styles from './ConversationsStatistics.module.scss'

type Props = {
	periodTab: string
	analytics: PublicAnalytics | undefined
	loading: boolean
}

const ConversationsStatistics = ({ periodTab, analytics, loading }: Props) => {
	const isLifetime = periodTab === 'Lifetime'

	return (
		<div className={styles.statistics}>
			<Skeleton loading={loading} active>
				<div className={styles.progressWrapper}>
					<div className={styles.progressHeading}>Completed conversations</div>
					<Progress
						type='circle'
						percent={roundNumber(
							isLifetime
								? analytics?.endedConversationsPercent
								: analytics?.monthlyEndedConversationsPercent,
							0,
						)}
						strokeColor={{
							'0%': '#108ee9',
							'100%': '#87d068',
						}}
					/>
					{!!(isLifetime
						? analytics?.endedConversationsAmount
						: analytics?.monthlyEndedConversationsAmount) && (
						<div className={styles.progressLabel}>
							{isLifetime
								? analytics?.endedConversationsAmount
								: analytics?.monthlyEndedConversationsAmount}{' '}
							/{' '}
							{isLifetime ? analytics?.conversationsAmount : analytics?.monthlyConversationsAmount}
						</div>
					)}
				</div>

				<div className={styles.progressWrapper}>
					<div className={styles.progressHeading}>Redirected conversations</div>
					<Progress
						type='circle'
						percent={roundNumber(
							isLifetime
								? analytics?.redirectedConversationsPercent
								: analytics?.monthlyRedirectedConversationsPercent,
							0,
						)}
						strokeColor={{
							'0%': '#87d068',
							'50%': '#ffe58f',
							'100%': '#ffccc7',
						}}
					/>
					{!!(isLifetime
						? analytics?.redirectedConversationsAmount
						: analytics?.monthlyRedirectedConversationsAmount) && (
						<div className={styles.progressLabel}>
							{isLifetime
								? analytics?.redirectedConversationsAmount
								: analytics?.monthlyRedirectedConversationsAmount}{' '}
							/{' '}
							{isLifetime ? analytics?.conversationsAmount : analytics?.monthlyConversationsAmount}
						</div>
					)}
				</div>

				<div className={styles.progressWrapper}>
					<div className={styles.progressHeading}>Read mail conversations</div>
					<Progress
						type='circle'
						percent={roundNumber(
							isLifetime
								? analytics?.readGmailConversationsPercent
								: analytics?.monthlyReadGmailConversationsPercent,
							0,
						)}
						strokeColor={{
							'0%': '#108ee9',
							'100%': '#87d068',
						}}
					/>
					{!!(isLifetime
						? analytics?.everReadGmailConversationsAmount
						: analytics?.monthlyEverReadGmailConversationsAmount) && (
						<div className={styles.progressLabel}>
							{isLifetime
								? analytics?.everReadGmailConversationsAmount
								: analytics?.monthlyEverReadGmailConversationsAmount}{' '}
							/{' '}
							{isLifetime
								? analytics?.conversationsGmailAmount
								: analytics?.monthlyConversationsGmailAmount}
						</div>
					)}
				</div>

				<div className={styles.progressWrapper}>
					<div className={styles.progressHeading}>Mail conversations with response</div>
					<Progress
						type='circle'
						percent={roundNumber(
							isLifetime
								? analytics?.responseGmailConversationsPercent
								: analytics?.monthlyResponseGmailConversationsPercent,
							0,
						)}
						strokeColor={{
							'0%': '#108ee9',
							'100%': '#87d068',
						}}
					/>
					{!!(isLifetime
						? analytics?.responseGmailConversationsAmount
						: analytics?.monthlyResponseGmailConversationsAmount) && (
						<div className={styles.progressLabel}>
							{isLifetime
								? analytics?.responseGmailConversationsAmount
								: analytics?.monthlyResponseGmailConversationsAmount}{' '}
							/{' '}
							{isLifetime
								? analytics?.conversationsGmailAmount
								: analytics?.monthlyConversationsGmailAmount}
						</div>
					)}
				</div>
			</Skeleton>
		</div>
	)
}

export default ConversationsStatistics
