import React, { useEffect, useMemo, useState } from 'react'
import { Form, Input, Button, App, Select } from 'antd'
import Joi from 'joi'
import { billingAddressSchema } from '@neuron/schemas/order/paymentMethod'
import useTryCatch from '../../hooks/useTryCatch'
import { Order } from '@neuron/types/order'
import query from '../../utils/query'
import { formValidate } from '../../utils/validation'
import Line from '../Line/Line'
import { socket } from '../../global/SocketManager'
import {
	COMPLETED_PAYMENT_STATUSES,
	FAILED_PAYMENT_STATUSES,
	MakePaymentRequest,
	UpdatePaymentStatusEvent,
} from '@neuron/types/order/payment'
import FormRow from '../FormRow/FormRow'
import env from '../../boot/env'
import { selectFilterOption } from '../../utils/helpers'
import { useAccountContext } from '../../hooks/context/AccountContext'
import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js'
import {
	loadStripe,
	PaymentMethod as StripePaymentMethod,
	StripeCardElementChangeEvent,
} from '@stripe/stripe-js'
import { AddPaymentMethodRequest, BillingAddress } from '@neuron/types/order/paymentMethod'

import styles from './Payment.module.scss'
import './StripeStyles.scss'

import countries from '@neuron/utils/resources/countries.json'

type Props = {
	onClose: () => Promise<void>
	order?: Order
}

const stripePromise = loadStripe(env.STRIPE_PUBLIC_KEY)

const PaymentComponent = ({ order, onClose }: Props) => {
	const { message } = App.useApp()
	const tryCatch = useTryCatch(message)
	const [form] = Form.useForm()

	const stripe = useStripe()
	const elements = useElements()

	const accountContext = useAccountContext()
	const accountId = accountContext.useSubscribe((account) => account.id)
	const accountEmail = accountContext.useSubscribe((account) => account.email)

	const [formData, setFormData] = useState<BillingAddress & { holderName: string }>({
		holderName: '',
		streetLine1: '',
		streetLine2: '',
		country: 'PL',
		city: '',
		postalCode: '',
		state: '',
	})

	const [isCardValid, setIsCardValid] = useState(false)

	const [paymentId, setPaymentId] = useState<string>()
	const [paymentProcessing, setPaymentProcessing] = useState(false)

	useEffect(() => {
		socket.on('paymentUpdated', checkPaymentStatus)
		return () => {
			socket.off('paymentUpdated', checkPaymentStatus)
		}
	}, [paymentId, stripe])

	const checkPaymentStatus = async (updatePaymentStatusEvent: UpdatePaymentStatusEvent) => {
		if (updatePaymentStatusEvent.payment.id !== paymentId) {
			return
		}

		if (updatePaymentStatusEvent.clientSecret && stripe) {
			await tryCatch(async () => {
				console.log('test')
				const { error: actionError } = await stripe.handleCardAction(
					updatePaymentStatusEvent.clientSecret!,
				)
				console.log(actionError)
				if (actionError) {
					await onPaymentError()
				}
			}, onPaymentError)
			return
		}

		if (COMPLETED_PAYMENT_STATUSES.includes(updatePaymentStatusEvent.payment.status)) {
			message.open({
				type: 'success',
				content: order ? 'Płatność zakończona pomyślnie' : 'Karta płatnicza dodana pomyślnie',
			})
			await onClose()
		}

		if (FAILED_PAYMENT_STATUSES.includes(updatePaymentStatusEvent.payment.status)) {
			await onPaymentError()
		}
	}

	const onPaymentError = async () => {
		message.open({
			type: 'error',
			content: order ? 'Płatność nie powiodła się' : 'Dodanie karty płatniczej nie powiodło się',
		})
		setPaymentProcessing(false)
	}

	const makePayment = async (stripePaymentMethod: StripePaymentMethod) => {
		await tryCatch(async () => {
			const makePaymentRequest = {
				orderId: order ? order.id : undefined,
				userId: order ? undefined : accountId,
				paymentMethod: {
					holderName: formData.holderName,
					billingAddress: {
						streetLine1: formData.streetLine1,
						streetLine2: formData.streetLine2,
						postalCode: formData.postalCode,
						city: formData.city,
						country: formData.country,
						state: formData.state,
					},
					providerPaymentMethodId: stripePaymentMethod.id,
				},
			} as MakePaymentRequest | AddPaymentMethodRequest

			const paymentId = await query<string>(
				order ? '/order/payment/make' : '/order/payment-method/add',
				'POST',
				{
					withCredentials: true,
					data: makePaymentRequest,
				},
			)

			setPaymentId(paymentId)
		}, onPaymentError)
	}

	const onSubmitPayment = async () => {
		if (!stripe || !elements) {
			return
		}

		const cardElement = elements.getElement(CardElement)
		if (!cardElement) {
			return
		}

		setPaymentProcessing(true)

		const { error: methodError, paymentMethod } = await stripe!.createPaymentMethod({
			type: 'card',
			card: cardElement,
			billing_details: {
				name: formData.holderName,
				email: accountEmail,
				address: {
					city: formData.city,
					country: formData.country,
					line1: formData.streetLine1,
					line2: formData.streetLine2,
					postal_code: formData.postalCode,
					state: formData.state,
				},
			},
		})

		if (methodError || !paymentMethod) {
			await onPaymentError()
			return
		}

		await makePayment(paymentMethod)
	}

	const updateFormData = (_value: Record<string, any>, values: any) => {
		setFormData((prevData) => ({ ...prevData, ...values }))
	}

	const handleCardChange = (event: StripeCardElementChangeEvent) => {
		setIsCardValid(event.complete)
	}

	const countryOptions = useMemo(() => {
		return Object.entries(countries).map(([code, name]) => ({
			label: name,
			value: code,
		}))
	}, [])

	const isFormValid = useMemo(() => {
		const validate = formValidate(formData, paymentMethodSchema)
		return validate ? !Object.keys(validate).length : true
	}, [formData])

	return (
		<Form
			form={form}
			layout='vertical'
			autoComplete='true'
			initialValues={formData}
			onValuesChange={updateFormData}
		>
			<Line />

			<Form.Item
				name='holderName'
				label='Imię i nazwisko posiadacza karty'
				className={styles.formItem}
				rules={[
					{ required: true, message: 'Wprowadź imię i nazwisko posiadacza karty' },
					{ min: 5, message: 'Imię i nazwisko musi zawierać przynajmniej 5 znaków' },
					{ max: 100, message: 'Imię i nazwisko nie może być dłuższe niż 100 znaków' },
				]}
			>
				<Input
					disabled={paymentProcessing}
					count={{ show: true }}
					maxLength={100}
					size='large'
					placeholder='Jan Kowalski'
				/>
			</Form.Item>

			<Form.Item
				name='streetLine1'
				label='Pierwsza linia adresu'
				className={styles.formItem}
				rules={[
					{ required: true, message: 'Wprowadź nazwę ulicy' },
					{ min: 5, message: 'Nazwa ulicy musi zawierać przynajmniej 5 znaków' },
					{ max: 250, message: 'Nazwa ulicy nie może być dłuższa niż 250 znaków' },
				]}
			>
				<Input
					disabled={paymentProcessing}
					count={{ show: true }}
					maxLength={250}
					size='large'
					placeholder='Pierwsza linia adresu'
				/>
			</Form.Item>

			<Form.Item
				name='streetLine2'
				label='Druga linia adresu'
				className={styles.formItem}
				rules={[{ max: 250, message: 'Nazwa ulicy nie może być dłuższa niż 250 znaków' }]}
			>
				<Input
					disabled={paymentProcessing}
					count={{ show: true }}
					maxLength={250}
					size='large'
					placeholder='Druga linia adresu'
				/>
			</Form.Item>

			<FormRow>
				<Form.Item
					name='city'
					label='Miasto'
					className={styles.formItem}
					rules={[
						{ required: true, message: 'Wprowadź nazwę miasta' },
						{ min: 5, message: 'Nazwa miasta musi zawierać przynajmniej 5 znaków' },
						{ max: 100, message: 'Nazwa miasta nie może być dłuższa niż 100 znaków' },
					]}
				>
					<Input
						disabled={paymentProcessing}
						count={{ show: true }}
						maxLength={100}
						size='large'
						placeholder='Warszawa'
					/>
				</Form.Item>

				<Form.Item
					name='postalCode'
					label='Kod pocztowy'
					className={styles.formItem}
					rules={[
						{ required: true, message: 'Wprowadź kod pocztowy' },
						{ min: 5, message: 'Kod pocztowy musi zawierać przynajmniej 5 znaków' },
						{ max: 10, message: 'Kod pocztowy nie może być dłuższy niż 10 znaków' },
					]}
				>
					<Input
						disabled={paymentProcessing}
						count={{ show: true }}
						maxLength={10}
						size='large'
						placeholder='00-123'
					/>
				</Form.Item>
			</FormRow>

			<FormRow>
				<Form.Item
					name='state'
					label='Województwo'
					className={styles.formItem}
					rules={[
						{ required: true, message: 'Wprowadź nazwę województwa' },
						{ min: 5, message: 'Nazwa województwa musi zawierać przynajmniej 5 znaków' },
						{ max: 100, message: 'Nazwa województwa nie może być dłuższa niż 100 znaków' },
					]}
				>
					<Input
						disabled={paymentProcessing}
						count={{ show: true }}
						maxLength={100}
						size='large'
						placeholder='Wielkopolskie'
					/>
				</Form.Item>

				<Form.Item
					className={styles.formItem}
					label='Kraj'
					name='country'
					rules={[{ required: true, message: 'Proszę wybrać kraj' }]}
				>
					<Select
						showSearch
						disabled={paymentProcessing}
						size='large'
						filterOption={selectFilterOption}
						placeholder='Wybierz kraj'
						options={countryOptions}
					/>
				</Form.Item>
			</FormRow>

			<Line className={styles.cardLine} />

			<CardElement
				options={{
					hidePostalCode: true,
					style: { base: { fontSize: '16px', fontFamily: 'Lato, sans-serif' } },
				}}
				onChange={handleCardChange}
			/>

			<Button
				size='large'
				className={styles.addPaymentMethodButton}
				type='primary'
				onClick={onSubmitPayment}
				loading={paymentProcessing}
				disabled={!isFormValid || !isCardValid || paymentProcessing}
			>
				{order ? 'Zapłać teraz' : 'Dodaj kartę płatniczą'}
			</Button>
		</Form>
	)
}

const Payment = ({ order, onClose }: Props) => {
	return (
		<Elements stripe={stripePromise}>
			<PaymentComponent order={order} onClose={onClose} />
		</Elements>
	)
}

const paymentMethodSchema = Joi.object({
	holderName: Joi.string().min(3).max(100).required(),
	...billingAddressSchema,
}).required()

export default Payment
