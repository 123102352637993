import { Button, Form, Input, Radio, Select, TimePicker } from 'antd'
import Line from '../../../../components/Line/Line'
import FormRow from '../../../../components/FormRow/FormRow'
import TextArea from 'antd/es/input/TextArea'
import { LANGUAGES } from '@neuron/types/global'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { Assistant, PERSONALITY_TYPES } from '@neuron/types/assistant'
import dayjs, { Dayjs } from 'dayjs'
import { useEffect } from 'react'
import { selectFilterOption } from '../../../../utils/helpers'
import styles from './PersonaForm.module.scss'

import timezones from '@neuron/utils/resources/timezones.json'

type Props = {
	assistantData: Partial<Assistant>
	updateAssistantData: (assistantData: Partial<Assistant>) => void
}

const PersonaForm = ({ assistantData, updateAssistantData }: Props) => {
	const [form] = Form.useForm()

	useEffect(() => {
		form.resetFields()
	}, [assistantData?.id, assistantData.personality?.type === 'custom'])

	return (
		<Form
			form={form}
			autoComplete='true'
			initialValues={toFormValues(assistantData)}
			onValuesChange={(_value, values) => updateAssistantData(values)}
			labelCol={{ span: 4 }}
			wrapperCol={{ span: 14 }}
			layout='vertical'
		>
			<h3 className={styles.headingLabel}>Persona information</h3>
			<Line />

			<FormRow columns={[3, 1]}>
				<Form.Item
					rules={[
						{ required: true, message: 'Please fill Persona firstname' },
						{ message: 'Persona firstname must have a minimum of 3 characters', min: 3 },
						{ message: 'Persona firstname can have a maximum of 50 characters', max: 50 },
					]}
					className={styles.formItem}
					label='Persona firstname'
					name='firstname'
				>
					<Input
						count={{
							show: true,
						}}
						maxLength={50}
						size='large'
						className={styles.formInput}
						placeholder='Clara'
					/>
				</Form.Item>

				<Form.Item
					rules={[{ required: true, message: 'Please select Persona gender' }]}
					className={styles.formItem}
					label='Persona gender'
					name='gender'
				>
					<Radio.Group size='large'>
						<Radio.Button value='male'>Male</Radio.Button>
						<Radio.Button value='female'>Female</Radio.Button>
					</Radio.Group>
				</Form.Item>
			</FormRow>

			<Form.Item
				rules={[
					{ required: true, message: 'Please fill company name' },
					{ message: 'Company name must have a minimum of 3 characters', min: 3 },
					{ message: 'Company name can have a maximum of 50 characters', max: 50 },
				]}
				className={styles.formItem}
				label='Company name'
				name='companyName'
			>
				<Input
					count={{
						show: true,
					}}
					maxLength={50}
					size='large'
					className={styles.formInput}
					placeholder='Quantum Neuron'
				/>
			</Form.Item>

			<Form.Item
				rules={[
					{ required: true, message: 'Please fill company description' },
					{ message: 'Company description must have a minimum of 3 characters', min: 3 },
					{ message: 'Company description can have a maximum of 2500 characters', max: 2500 },
				]}
				className={styles.formItem}
				label='Company description'
				name='companyDescription'
			>
				<TextArea
					className={styles.formTextarea}
					size='large'
					allowClear
					count={{
						show: true,
					}}
					maxLength={2500}
					defaultValue=''
					autoSize={{ minRows: 2, maxRows: 25 }}
					placeholder='Company description'
				/>
			</Form.Item>

			<Form.Item
				rules={[
					{ message: 'Additional information can have a maximum of 5000 characters', max: 5000 },
				]}
				className={styles.formItem}
				label='Additional information'
				name='additionalContext'
			>
				<TextArea
					className={styles.formTextarea}
					size='large'
					allowClear
					count={{
						show: true,
					}}
					maxLength={5000}
					defaultValue=''
					autoSize={{ minRows: 2, maxRows: 50 }}
					placeholder='Additional information about role of Persona'
				/>
			</Form.Item>

			<Form.Item
				rules={[{ required: true, message: 'Please select personality' }]}
				name={['personality', 'type']}
				label='Personality'
				className={styles.formItem}
			>
				<Select
					size='large'
					className={styles.capitalize}
					options={PERSONALITY_TYPES.map((type) => ({
						label: `${type.charAt(0).toUpperCase()}${type.slice(1)}`,
						value: type,
					}))}
				/>
			</Form.Item>

			{assistantData.personality?.type === 'custom' && (
				<Form.Item
					rules={[
						{ required: true, message: 'Please fill personality description' },
						{ message: 'Personality description must have a minimum of 3 characters', min: 3 },
						{ message: 'Personality description can have a maximum of 2000 characters', max: 2000 },
					]}
					className={styles.formItem}
					label='Personality description'
					name={['personality', 'description']}
				>
					<TextArea
						className={styles.formTextarea}
						size='large'
						allowClear
						count={{
							show: true,
						}}
						maxLength={2000}
						defaultValue=''
						autoSize={{ minRows: 2, maxRows: 20 }}
						placeholder='Personality description'
					/>
				</Form.Item>
			)}

			<FormRow>
				<Form.Item
					rules={[{ required: true, message: 'Please select Persona timezone' }]}
					name='timezone'
					label='Timezone'
					className={styles.formItem}
				>
					<Select
						size='large'
						filterOption={selectFilterOption}
						showSearch
						options={timezones.map((timezone) => ({
							label: timezone.text,
							value: `${timezone.value}:${timezone.offset}`,
						}))}
					/>
				</Form.Item>

				<Form.Item
					tooltip='Leave it blank so that the Persona is available 24 hours a day.'
					className={styles.formItem}
					label='Persona work hours'
					name='workHours'
				>
					<TimePicker.RangePicker
						className={styles.timeRange}
						showSecond={false}
						allowClear
						placeholder={['08:00', '16:00']}
						format='HH:mm'
						size='large'
					/>
				</Form.Item>
			</FormRow>

			<FormRow>
				<Form.Item
					rules={[{ required: true, message: 'Please select language' }]}
					name='defaultLanguage'
					label='Default response language'
					className={styles.formItem}
				>
					<Select
						size='large'
						showSearch
						options={LANGUAGES.sort((a, b) => a.localeCompare(b)).map((language) => ({
							label: language.split(':')[0],
							value: language,
						}))}
					/>
				</Form.Item>

				<Form.Item
					rules={[{ required: true, message: 'Please select any option' }]}
					className={styles.formItem}
					label='Multilingual responding'
					name='multilingual'
					tooltip='Persona will adapt the language of the response to the language used by the user'
				>
					<Radio.Group size='large' defaultValue={true}>
						<Radio.Button value={true}>Enabled</Radio.Button>
						<Radio.Button value={false}>Disabled</Radio.Button>
					</Radio.Group>
				</Form.Item>
			</FormRow>

			<Form.Item
				className={styles.formItem}
				required
				label='Notifications e-mails'
				tooltip='Enter the email addresses to which Presona will send notifications regarding ongoing conversations.'
				rules={[{ required: true, message: 'Please fill notifications e-mail', min: 1 }]}
			>
				<Form.List initialValue={assistantData?.humanEmails ? undefined : ['']} name='humanEmails'>
					{(fields, { add, remove }) => (
						<>
							{fields.map(({ key, name, ...restField }) => (
								<div key={key} className={styles.fieldList}>
									<Form.Item
										{...restField}
										rules={[
											{ required: true, message: 'Please fill e-mail' },
											{ type: 'email', message: 'Invalid e-mail format' },
											{ message: 'E-mail must have a minimum of 3 characters', min: 3 },
											{
												message: 'E-mail can have a maximum of 120 characters',
												max: 250,
											},
										]}
										className={styles.formItem}
										name={name}
									>
										<Input
											count={{
												show: true,
											}}
											maxLength={250}
											size='large'
											className={styles.formInput}
											placeholder='sales@example.com'
										/>
									</Form.Item>
									{key !== 0 && (
										<MinusCircleOutlined
											className={styles.listRemoveIcon}
											onClick={() => remove(name)}
										/>
									)}
								</div>
							))}
							<Form.Item className={styles.formItem}>
								<Button
									size='large'
									type='dashed'
									disabled={fields.length === 5}
									onClick={() => {
										fields.length < 5 && add()
									}}
									block
									icon={<PlusOutlined />}
								>
									Add redirect e-mail
								</Button>
							</Form.Item>
						</>
					)}
				</Form.List>
			</Form.Item>
		</Form>
	)
}

const toFormValues = (values: Partial<Assistant>) => ({
	...values,
	workHours: values.workHours?.length
		? [getDayjsFromStringTime(values.workHours[0]), getDayjsFromStringTime(values.workHours[1])]
		: undefined,
})

const getDayjsFromStringTime = (time: string): Dayjs => {
	return dayjs().set('hours', +time.split(':')[0]).set('minutes', +time.split(':')[1])
}

export default PersonaForm
