import Joi from 'joi'
import { idSchema, usingUserIdSchema, uuidIdSchema } from '../global'

const leadsGroupBaseSchema = {
	name: Joi.string().max(120).min(3),
	description: Joi.string().max(2000).allow(''),
	contextIds: Joi.array().items(idSchema),
	autoStartConversation: Joi.boolean(),
	syncNewLeads: Joi.boolean(),
	templateIds: Joi.array().when('autoStartConversation', {
		is: true,
		then: Joi.array().items(uuidIdSchema.required()).min(1).max(2).required(),
		otherwise: Joi.array().items(uuidIdSchema).max(2),
	}),
}

export const getLeadsGroupRequestSchema = Joi.object({
	id: uuidIdSchema.required(),
})

export const createLeadsGroupRequestSchema = Joi.object({
	name: leadsGroupBaseSchema.name.required(),
	description: leadsGroupBaseSchema.description.optional(),
	contextIds: leadsGroupBaseSchema.contextIds.optional(),
	autoStartConversation: leadsGroupBaseSchema.autoStartConversation.optional(),
	syncNewLeads: leadsGroupBaseSchema.syncNewLeads.optional(),
	templateIds: leadsGroupBaseSchema.templateIds,
	...usingUserIdSchema,
})

export const updateLeadsGroupRequestSchema = Joi.object({
	id: uuidIdSchema.required(),
	name: leadsGroupBaseSchema.name.optional(),
	description: leadsGroupBaseSchema.description.optional(),
	contextIds: leadsGroupBaseSchema.contextIds.optional(),
	autoStartConversation: leadsGroupBaseSchema.autoStartConversation.optional(),
	syncNewLeads: leadsGroupBaseSchema.syncNewLeads.optional(),
	templateIds: leadsGroupBaseSchema.templateIds,
})

export const syncLeadsGroupContextRequestSchema = Joi.object({
	id: uuidIdSchema.required(),
	contextId: idSchema.required(),
})

export const deleteLeadsGroupRequestSchema = Joi.object({
	id: uuidIdSchema.required(),
})
