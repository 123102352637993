import { App, Button, Drawer, Input } from 'antd'
import React, { useState } from 'react'
import query from '../../../../../utils/query'
import useTryCatch from '../../../../../hooks/useTryCatch'
import { FileInfo } from '@neuron/types/fileInfo'
import styles from './PreviewTranscription.module.scss'

const { TextArea } = Input

type Props = {
	fileInfo?: FileInfo
	transcription: string
	setTranscription: (transcription: string) => void
}

const PreviewTranscription = ({ fileInfo, transcription, setTranscription }: Props) => {
	const { message } = App.useApp()
	const tryCatch = useTryCatch(message)

	const [openPreview, setOpenPreview] = useState<boolean>(false)

	const onCloseTranscription = async () => {
		if (transcription === fileInfo?.transcription) {
			setOpenPreview(false)
			return
		}

		await tryCatch(
			async () => {
				await query('/file/updateTranscription', 'POST', {
					withCredentials: true,
					data: {
						id: fileInfo?.id,
						transcription,
					},
				})
				setOpenPreview(false)
			},
			undefined,
			{ message: 'Update transcription error. Try again.' },
		)
	}

	if (!fileInfo) {
		return <></>
	}

	return (
		<div>
			{!!fileInfo.transcription && (
				<Button
					onClick={(e) => {
						e.stopPropagation()
						setOpenPreview(true)
					}}
					type='text'
					className={styles.previewTranscription}
				>
					Preview transcription
				</Button>
			)}

			{!fileInfo.transcription && (
				<div className={styles.previewTranscription}>
					Transcript preview will be available after the context refresh.
				</div>
			)}

			<Drawer
				destroyOnClose
				title='Transcription preview'
				placement='right'
				size='large'
				onClose={onCloseTranscription}
				open={openPreview}
			>
				<TextArea
					bordered={false}
					className={styles.transcription}
					value={transcription}
					onChange={(e) => setTranscription(e.target.value)}
				/>
			</Drawer>
		</div>
	)
}

export default PreviewTranscription
