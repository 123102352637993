import React from 'react'
import { formatDate } from '@neuron/utils/dates'
import CardTitle from '../../CardTitle/CardTitle'
import {
	PublicMessagingIntegration,
	MessagingIntegrationProvider,
	MessagingIntegrationStatus,
} from '@neuron/types/integration/messaging'
import { Button, Tag } from 'antd'
import { useNavigate } from 'react-router-dom'
import { InlineLoading } from '../../Loading/Loading'
import CardItem from '../../CardItem/CardItem'
import styles from './IntegrationCard.module.scss'

type Props = {
	integration: PublicMessagingIntegration
	selectedIntegrationIds: string[]
	selectIntegration: (integrationId: string, provider: MessagingIntegrationProvider) => void
	showSelectingButton?: boolean
	showEditButton?: boolean
	deleteIntegrations?: (selectedIntegrationIds: string[]) => void
}

const IntegrationCard = ({
	integration,
	deleteIntegrations,
	selectedIntegrationIds,
	selectIntegration,
	showEditButton,
	showSelectingButton,
}: Props) => {
	const navigate = useNavigate()
	const isSelecting = selectedIntegrationIds.some((id) => id === integration.id)

	return (
		<CardItem
			itemId={integration.id}
			selectedItems={selectedIntegrationIds}
			selectItem={() => selectIntegration(integration.id, integration.provider)}
			cardTitle={
				<CardTitle
					id={integration.id}
					name={integration.name}
					disabled={integration.status === 'deleting'}
					onDelete={deleteIntegrations ? (id: string) => deleteIntegrations([id]) : undefined}
				/>
			}
			labels={[
				{
					label: 'Provider',
					value: integration.provider,
				},
				{
					label: 'Status',
					value: getIntegrationStatus(integration.status),
				},
				{
					label:
						integration.credentials && integration.credentials.providerNames.length > 1
							? 'Accounts'
							: 'Account',
					value: integration.credentials && integration.credentials.providerNames.join(', '),
				},
				{
					label: integration.credentials?.phoneNumbers && 'Phone numbers',
					value:
						integration.credentials?.phoneNumbers &&
						Object.values(integration.credentials.phoneNumbers)
							.map(({ phoneNumber }) => phoneNumber)
							.join(', '),
				},
				{
					label: 'Created date',
					value: formatDate(integration.createdAt),
				},
				{
					label: 'Updated date',
					value: integration.updatedAt && formatDate(integration.updatedAt),
				},
			]}
		>
			{!!showSelectingButton && (
				<Button
					className={isSelecting ? styles.selectedButton : ''}
					onClick={(e) => {
						e.stopPropagation()
						selectIntegration(integration.id, integration.provider)
					}}
				>
					{isSelecting ? 'Selected' : 'Select'}
				</Button>
			)}
			{!!showEditButton && (
				<Button
					onClick={(e) => {
						e.stopPropagation()
						navigate(`/personas/integration?integrationId=${integration.id}`)
					}}
				>
					Edit
				</Button>
			)}
		</CardItem>
	)
}

const getIntegrationStatus = (status: MessagingIntegrationStatus) => {
	if (status === 'deleting') {
		return (
			<Tag>
				<InlineLoading iconClassName={styles.loading} size={10} />
				<span>Deleting</span>
			</Tag>
		)
	}
	if (status === 'inactive') {
		return <Tag color='#d9534f'>Inactive</Tag>
	}
	return <Tag color='#5cb85c'>Active</Tag>
}

export default IntegrationCard
