import { Statistic } from 'antd'
import React from 'react'
import { PublicAnalytics } from '@neuron/types/analytics'
import styles from './MessagesStatistics.module.scss'

type Props = {
	periodTab: string
	analytics: PublicAnalytics | undefined
	loading: boolean
}

const MessagesStatistics = ({ analytics, periodTab, loading }: Props) => {
	const isLifetime = periodTab === 'Lifetime'

	return (
		<div className={styles.statistics}>
			<Statistic
				title='Total generated messages'
				value={
					isLifetime
						? analytics?.generatedAssistantMessages
						: analytics?.monthlyGeneratedAssistantMessages
				}
				loading={loading}
			/>
			<Statistic
				title='Generated messages in last 24h'
				value={analytics?.generatedAssistantMessagesInLastDay}
				loading={loading}
			/>
			<Statistic
				title='Total generated characters'
				value={isLifetime ? analytics?.generatedCharacters : analytics?.monthlyGeneratedCharacters}
				loading={loading}
			/>
		</div>
	)
}

export default MessagesStatistics
