import React, { useEffect, useState } from 'react'
import { ChatMessage } from '@neuron/types/chat/chatMessage'
import { ERROR_CONTENT, LOADING_CONTENT, messageRole } from '@neuron/utils/consts'
import { messageContentIsPending } from '@neuron/utils/assistant'
import { App } from 'antd'
import { PublicProgressStep } from '@neuron/types/progressSteps'
import { v4 as uuidv4 } from 'uuid'
import { ConversationMessage } from '@neuron/types/assistant/conversationMessage'
import { Conversation as ConversationType } from '@neuron/types/assistant/conversation'
import useTryCatch from '../../../hooks/useTryCatch'
import useQuery from '../../../hooks/useQuery'
import { useEditorContext } from '../../../hooks/context/EditorContext'
import { useChatContext } from '../../../hooks/context/ChatContext'
import { PublicMessage } from '../../../types/Chat'
import { socket } from '../../../global/SocketManager'
import useAsyncEffect from '../../../hooks/useAsyncEffect'
import query from '../../../utils/query'
import { toPublicMessage, toPublicSession } from '../../../utils/chatMappers'
import { getUpdatedMessages } from '../../../utils/helpers'
import { MIN_CHAT_FOOTER_HEIGHT } from '../../../utils/consts'
import Chat from '../../../components/Chat/Chat'
import Loading from '../../../components/Loading/Loading'
import { useNavigate } from 'react-router-dom'
import { useAccountContext } from '../../../hooks/context/AccountContext'
import { utcDate } from '@neuron/utils/dates'
import styles from './Conversation.module.scss'

const Conversation = () => {
	const navigate = useNavigate()
	const { message } = App.useApp()
	const tryCatch = useTryCatch(message)

	const conversationId = useQuery().get('conversationId')

	const editorContext = useEditorContext()

	const accountContext = useAccountContext()
	const accountId = accountContext.useSubscribe((account) => account.id)

	const chatContext = useChatContext()
	const currentChatSession = chatContext.useSubscribe((context) => context.currentChatSession)
	const disableNewMessage = chatContext.useSubscribe((context) => context.disableNewMessage)

	const [messages, setMessages] = useState<PublicMessage[]>([])
	const [scrollDownVisible, setScrollDownVisible] = useState<boolean>(false)

	useEffect(() => {
		socket.on('updateConversationMessage', updateConversationMessage)
		socket.on('updateConversation', updateConversation)
		return () => {
			socket.off('updateConversationMessage', updateConversationMessage)
			socket.off('updateConversation', updateConversation)
		}
	}, [conversationId])

	useEffect(() => {
		socket.on('updatedProgressStep', updateProgressSteps)
		return () => {
			socket.off('updatedProgressStep', updateProgressSteps)
		}
	}, [messages])

	useAsyncEffect(async () => {
		if (conversationId) {
			setScrollDownVisible(false)
			await fetchConversation()
			await fetchConversationMessages()
		} else {
			navigate('/personas/conversations')
		}
	}, [conversationId])

	useEffect(() => {
		chatContext.setDisableNewMessage(!!currentChatSession?.processing)
	}, [currentChatSession])

	const fetchConversation = async () => {
		await tryCatch(async () => {
			const resConversation = await query<ConversationType>('/assistant/conversation/get', 'GET', {
				params: {
					id: conversationId,
				},
				withCredentials: true,
			})

			chatContext.setChat({
				id: resConversation.id,
				userId: accountId,
				name: `Persona ${resConversation.integration?.provider} conversation`,
				status: 'active',
				isAssistant: true,
				createdAt: resConversation.createdAt,
			})
			chatContext.setCurrentChatSession(toPublicSession(resConversation))
		})
	}

	const fetchConversationMessages = async () => {
		await tryCatch(async () => {
			const resMessages = await query<ChatMessage[]>('/assistant/messages/forConversation', 'GET', {
				params: {
					conversationId,
				},
				withCredentials: true,
			})
			const publicMessages = resMessages.map(toPublicMessage)
			const loadingMessages = publicMessages.filter((message) =>
				messageContentIsPending(message.content),
			)

			if (loadingMessages && loadingMessages[loadingMessages.length - 1]) {
				await loadMessageProgressSteps(loadingMessages[loadingMessages.length - 1])
			}

			chatContext.setUserScrolling(false)
			setMessages(publicMessages)
		})
	}

	const loadMessageProgressSteps = async (conversationMessage: PublicMessage) => {
		await tryCatch(
			async () => {
				const publicProgressSteps = await query<PublicProgressStep[]>('/progressStep/get', 'GET', {
					withCredentials: true,
					params: {
						conversationMessageId: conversationMessage.id,
					},
				})
				chatContext.setProgressSteps(publicProgressSteps)
			},
			() => {},
			{ hideMessage: true },
		)
	}

	const updateConversationMessage = (updatedMessage: ConversationMessage) => {
		if (!conversationId || conversationId !== updatedMessage?.conversationId) {
			return
		}

		setMessages((messages) => getUpdatedMessages(messages, updatedMessage))
	}

	const updateConversation = (updatedConversation: ConversationType) => {
		const publicUpdatedSession = toPublicSession(updatedConversation)

		if (updatedConversation.id === conversationId) {
			chatContext.setCurrentChatSession(publicUpdatedSession)
		}
	}

	const updateProgressSteps = (progressStep: PublicProgressStep) => {
		if (messages.some((message) => message.id === progressStep.conversationMessageId)) {
			chatContext.setProgressStep(progressStep)
		}
	}

	const sendNewMessage = async (messageContent: string) => {
		if (disableNewMessage) {
			return
		}

		chatContext.setChatContext({
			userScrolling: false,
			footerHeight: MIN_CHAT_FOOTER_HEIGHT,
		})
		chatContext.clearProgressSteps()
		editorContext.clearEditor()

		await tryCatch(
			async () => {
				await query('/assistant/message/assistantCreate', 'POST', {
					data: {
						conversationId,
						content: messageContent,
					},
					withCredentials: true,
				})
			},
			() => {
				const assistantTemplateMessage: PublicMessage = {
					id: uuidv4(),
					sessionId: '',
					createdAt: utcDate(),
					content: messageContent,
					role: messageRole.ASSISTANT,
				}

				setMessages((messages) => [...messages, assistantTemplateMessage])
			},
			{ hideMessage: true },
		)
	}

	const cancelAssistantMessage = async (messageId?: string) => {
		await tryCatch(
			async () => {
				await query('/assistant/message/cancel', 'POST', {
					data: { id: messageId ?? messages[messages.length - 1].id },
					withCredentials: true,
				})
			},
			undefined,
			{ message: 'An error occurred while trying to cancel Persona message' },
		)
	}

	const deleteAssistantMessage = async (messageToDelete: PublicMessage) => {
		if (!!messageToDelete.sentByProvider || messageToDelete.role === messageRole.USER) {
			return
		}

		await tryCatch(async () => {
			await query('/assistant/message/delete', 'POST', {
				withCredentials: true,
				data: {
					id: messageToDelete.id,
				},
			})
			setMessages((messages) => messages.filter((message) => message.id !== messageToDelete.id))
		})
	}

	const retryProviderMessageSend = async (messageToRetry: PublicMessage) => {
		if (disableNewMessage || messageToRetry.sentByProvider) {
			return
		}

		const messagesClone = [...messages]
		setMessages((messages) =>
			messages.map((message) =>
				message.id === messageToRetry.id ? { ...message, content: LOADING_CONTENT } : message,
			),
		)
		await tryCatch(
			async () => {
				const updatedMessage = await query<ConversationMessage | undefined>(
					'/integration/messaging/retryProvider',
					'POST',
					{
						data: { conversationMessageId: messageToRetry.id },
						withCredentials: true,
					},
				)

				if (updatedMessage) {
					setMessages((messages) =>
						messages.map((message) =>
							message.id === updatedMessage.id ? toPublicMessage(updatedMessage) : message,
						),
					)
				} else {
					setMessages(messagesClone)
				}
			},
			() => {
				setMessages((messages) =>
					messages.map((message) =>
						message.id === messageToRetry.id ? { ...message, content: ERROR_CONTENT } : message,
					),
				)
			},
			{ message: 'An error occurred while trying to retry Persona message send' },
		)
	}

	if (!currentChatSession) {
		return <Loading size={48} />
	}

	return (
		<div className={styles.chatWrapper}>
			<Chat
				messages={messages}
				sessionId={conversationId}
				sendNewMessage={sendNewMessage}
				scrollDownVisible={scrollDownVisible}
				setScrollDownVisible={setScrollDownVisible}
				resendMessage={retryProviderMessageSend}
				cancelMessage={cancelAssistantMessage}
				deleteAssistantMessage={deleteAssistantMessage}
				contentCustomHeight={232}
			/>
		</div>
	)
}

export default Conversation
