import env from '../boot/env'
import { MessageInstance, NoticeType } from 'antd/es/message/interface'

type AnyFn = ((a?: any) => Promise<void | (() => void)>) | ((a?: any) => void)

type ErrorHandlingOptions = {
	message?: string
	hideMessage?: boolean
	messageType?: NoticeType
}

export const DEFAULT_ERROR_MESSAGE = 'An unknown error occurred, please try again.'

const useTryCatch = (message: MessageInstance) => {
	return async <T>(
		tryFn: AnyFn,
		catchFn?: AnyFn,
		errorHandlingOptions?: ErrorHandlingOptions,
	): Promise<T> => {
		try {
			return (await tryFn()) as T
		} catch (err: any) {
			if (env.ENVIRONMENT === 'dev') {
				console.error(err)
			} else {
				// report error to Sentry
			}

			if (!errorHandlingOptions || !errorHandlingOptions.hideMessage) {
				const messageContent = errorHandlingOptions?.message ?? err.message ?? DEFAULT_ERROR_MESSAGE
				message.open({
					type: errorHandlingOptions?.messageType ?? 'error',
					content: messageContent,
					duration: messageContent.length >= 50 ? 3 + messageContent.length / 50 : 3, // duration in seconds
				})
			}

			return (await catchFn?.(err)) as T
		}
	}
}

export default useTryCatch
