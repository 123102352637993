import { useGoogleLogin } from '@react-oauth/google'
import env from '../../../boot/env'
import { GOOGLE_SPREADSHEETS_SCOPES } from '@neuron/types/integration/database/googleSpreadsheet'
import React from 'react'
import { DatabaseIntegrationProvider } from '@neuron/types/integration/database'
import { Button } from 'antd'
import { GoogleOutlined } from '@ant-design/icons'
import styles from './GoogleSheetsUrl.module.scss'

type Props = {
	createDatabaseIntegration: (
		provider: DatabaseIntegrationProvider,
		accessCode: string,
	) => Promise<void>
	showAuthorizationErrorMessage: () => Promise<void>
	processingIntegrationLoading: boolean
	setProcessingIntegrationLoading: (loading: boolean) => void
}

const GoogleSheetsUrl = ({
	createDatabaseIntegration,
	showAuthorizationErrorMessage,
	processingIntegrationLoading,
	setProcessingIntegrationLoading,
}: Props) => {
	const useGoogleSheetsAuthorization = useGoogleLogin({
		redirect_uri: `https://${env.DOMAIN_NAME}`,
		scope: GOOGLE_SPREADSHEETS_SCOPES.join(' '),
		onSuccess: async (codeResponse) => {
			await createDatabaseIntegration('google-spreadsheet', codeResponse.code)
		},
		onError: async () => {
			await showAuthorizationErrorMessage()
			setProcessingIntegrationLoading(false)
		},
		onNonOAuthError: async () => {
			await showAuthorizationErrorMessage()
			setProcessingIntegrationLoading(false)
		},
		flow: 'auth-code',
	})

	return (
		<Button
			type='primary'
			loading={processingIntegrationLoading}
			disabled={processingIntegrationLoading}
			size='large'
			icon={<GoogleOutlined />}
			onClick={() => {
				setProcessingIntegrationLoading(true)
				useGoogleSheetsAuthorization()
			}}
			className={styles.googleButton}
		/>
	)
}

export default GoogleSheetsUrl
