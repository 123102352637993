import React, { useState } from 'react'
import { LinkContent, Tab } from '../../../../types/AiContext'
import { extractDomain } from '../../../../utils/helpers'
import ContextUrlForm from '../../../../components/ContextUrlForm/ContextUrlForm'
import { GOOGLE_DOCS_URL_REGEX, GOOGLE_SHEETS_URL_REGEX } from '@neuron/utils/consts'
import { FileInfo } from '@neuron/types/fileInfo'
import { App } from 'antd'
import useTryCatch from '../../../../hooks/useTryCatch'
import useAsyncEffect from '../../../../hooks/useAsyncEffect'
import query from '../../../../utils/query'
import PreviewTranscription from '../components/PreviewTranscription/PreviewTranscription'
import Loading from '../../../../components/Loading/Loading'
import styles from './ContextUrl.module.scss'

type Props = {
	content: LinkContent
	updateTab: (newTab: Partial<Tab>) => void
}

const ContextUrl = ({ content, updateTab }: Props) => {
	const { message } = App.useApp()
	const tryCatch = useTryCatch(message)

	const [transcription, setTranscription] = useState<string>('')
	const [fileInfo, setFileInfo] = useState<FileInfo>()
	const [loading, setLoading] = useState<boolean>(false)

	useAsyncEffect(async () => {
		if (!content.fileId) {
			setFileInfo(undefined)
			setTranscription('')
			return
		}

		setLoading(true)
		await tryCatch(async () => {
			const fileInfo = await query<FileInfo>('/file/get', 'GET', {
				params: {
					id: content.fileId,
				},
				withCredentials: true,
			})

			setFileInfo(fileInfo)
			setTranscription(fileInfo.transcription ?? '')
		})
		setLoading(false)
	}, [content.fileId])

	const updateLinkContent = async (linkContent: LinkContent) => {
		const newTab: Partial<Tab> = {
			content: linkContent,
		}

		if (linkContent.isValidUrl) {
			newTab.name = getDatabaseUrlName(linkContent.url)
		}

		updateTab(newTab)
	}

	return (
		<div className={styles.wrapper}>
			<ContextUrlForm linkContent={content} updateLinkContent={updateLinkContent} />

			{loading ? (
				<Loading />
			) : (
				<PreviewTranscription
					fileInfo={fileInfo}
					setTranscription={setTranscription}
					transcription={transcription}
				/>
			)}
		</div>
	)
}

const getDatabaseUrlName = (url: string): string => {
	if (url.match(GOOGLE_SHEETS_URL_REGEX)) {
		return 'Google Spreadsheet'
	}
	if (url.match(GOOGLE_DOCS_URL_REGEX)) {
		return 'Google Docs'
	}
	return extractDomain(url)
}

export default ContextUrl
