import React, { useMemo, useState } from 'react'
import TextArea from 'antd/es/input/TextArea'
import { Button, DatePicker, Form, InputNumber, Slider, Switch } from 'antd'
import FormRow from '../../../../components/FormRow/FormRow'
import Joi from 'joi'
import { formValidate } from '../../../../utils/validation'
import { ReflinkFormData } from '../Reflinks'
import { createReflinkSchema } from '@neuron/schemas/reflink'
import { BASE_TRAINING_COSTS } from '@neuron/types/order'
import { utcDate } from '@neuron/utils/dates'
import dayjs from 'dayjs'
import styles from './ReflinkForm.module.scss'

type Props = {
	saveReflinkFormData: (formData: ReflinkFormData) => Promise<void>
	onClose?: () => void
	reflinkData?: ReflinkFormData
}

const DATE_FORMAT = 'DD-MM-YYYY'

const ReflinkForm = ({ saveReflinkFormData, onClose, reflinkData }: Props) => {
	const [reflinkFormData, setReflinkFormData] = useState<ReflinkFormData>({
		id: reflinkData?.id,
		additionalCost: reflinkData?.additionalCost ? reflinkData.additionalCost / 100 : 0,
		businessTurnoverLevel: reflinkData?.businessTurnoverLevel ?? 1,
		trainingDays: reflinkData?.trainingDays ?? 1,
		enterprise: reflinkData?.enterprise ?? false,
		agreementStartDate: reflinkData?.agreementStartDate ?? utcDate(),
		note: reflinkData?.note,
	})

	const updateReflinkData = (_value: Record<string, any>, values: ReflinkFormData) => {
		setReflinkFormData((reflink) => formDataToData({ ...reflink, ...values }))
	}

	const baseTrainingCost = useMemo(() => {
		if (
			reflinkFormData.enterprise ||
			(reflinkFormData.trainingDays && reflinkFormData.trainingDays >= 5)
		) {
			return BASE_TRAINING_COSTS[3]
		}
		if (reflinkFormData.businessTurnoverLevel) {
			return BASE_TRAINING_COSTS[reflinkFormData.businessTurnoverLevel - 1]
		}
		return 0
	}, [
		reflinkFormData.enterprise,
		reflinkFormData.trainingDays,
		reflinkFormData.businessTurnoverLevel,
	])

	const isFormValid = useMemo(() => {
		const validate = formValidate(reflinkFormData, reflinkFormSchema)
		return validate ? !Object.keys(validate).length : true
	}, [reflinkData])

	return (
		<Form
			className={styles.form}
			initialValues={dataToFormData(reflinkFormData)}
			onValuesChange={updateReflinkData}
			labelCol={{ span: 4 }}
			wrapperCol={{ span: 14 }}
			layout='vertical'
		>
			<Form.Item
				name='businessTurnoverLevel'
				label='Business turnover'
				className={styles.formItem}
				rules={[{ required: true, message: 'Please select a business turnover level' }]}
			>
				<Slider
					className={styles.turnoverSlider}
					min={1}
					max={4}
					marks={{
						1: '0-1 mln',
						2: '1-4 mln',
						3: '4-10 mln',
						4: '10+ mln',
					}}
				/>
			</Form.Item>

			<FormRow columns={[2, 2, 1]}>
				<Form.Item
					label='Agreement start date'
					name='agreementStartDate'
					className={styles.formItem}
					rules={[{ required: true, message: 'Please enter an start date' }]}
				>
					<DatePicker size='large' format={DATE_FORMAT} maxDate={dayjs(utcDate())} />
				</Form.Item>

				<Form.Item
					name='trainingDays'
					label='Number of Training Days'
					className={styles.formItem}
					rules={[
						{ required: true, message: 'Please enter the number of training days' },
						{ type: 'number', min: 1, message: 'Training days must be at least 1' },
						{ type: 'number', max: 10, message: 'Training days cannot exceed 10' },
					]}
				>
					<InputNumber min={1} max={10} size='large' />
				</Form.Item>

				<Form.Item
					name='enterprise'
					label='Enterprise'
					valuePropName='checked'
					className={styles.formItem}
					rules={[{ required: true, message: 'Please indicate if this is an enterprise' }]}
				>
					<Switch />
				</Form.Item>
			</FormRow>

			<FormRow>
				<Form.Item
					className={styles.formItem}
					label='Additional training cost'
					name='additionalCost'
					rules={[
						{ required: true, message: 'Please enter an additional cost' },
						{ type: 'number', min: 0, message: 'Additional cost cannot be negative' },
						{ type: 'number', max: 200000, message: 'Additional cost cannot exceed 200000' },
					]}
				>
					<InputNumber min='0' max='200000' size='large' addonAfter='PLN' precision={2} />
				</Form.Item>

				<div className={styles.dealCostWrapper}>
					<div>Base training cost: {baseTrainingCost} PLN</div>
					<div>Additional training cost: {reflinkFormData.additionalCost} PLN</div>
					<hr />
					<div>
						Total training cost: <b>{baseTrainingCost + reflinkFormData.additionalCost} PLN</b>
					</div>
				</div>
			</FormRow>

			<Form.Item
				rules={[{ message: 'Note can have a maximum of 1000 characters', max: 1000 }]}
				className={styles.formItem}
				label='Private note'
				name='note'
			>
				<TextArea
					className={styles.formTextarea}
					size='large'
					allowClear
					maxLength={1000}
					autoSize={{ minRows: 2, maxRows: 4 }}
					placeholder='Note'
				/>
			</Form.Item>

			<div className={styles.buttons}>
				{!!onClose && (
					<Button size='large' onClick={onClose}>
						Cancel
					</Button>
				)}

				<Button
					type='primary'
					disabled={!isFormValid}
					size='large'
					onClick={() => isFormValid && saveReflinkFormData(reflinkFormData)}
				>
					{reflinkFormData.id ? 'Update' : 'Create'}
				</Button>
			</div>
		</Form>
	)
}

const dataToFormData = (agreementData: ReflinkFormData) => {
	return {
		...agreementData,
		agreementStartDate: agreementData.agreementStartDate
			? dayjs(agreementData.agreementStartDate)
			: dayjs(utcDate()),
	}
}

const formDataToData = (agreementData: ReflinkFormData) => {
	return {
		...agreementData,
		agreementStartDate: dayjs(agreementData.agreementStartDate).toDate(),
	}
}

const reflinkFormSchema = Joi.object({
	...createReflinkSchema,
	id: Joi.any().optional(),
}).required()

export default ReflinkForm
