import { App, Button, Layout, Menu, MenuProps } from 'antd'
import { MessageOutlined } from '@ant-design/icons'
import React, { MutableRefObject, useMemo, useRef, useState } from 'react'
import SessionItem from './SessionItem/SessionItem'
import ScrollTo from '../../../components/ScrollTo/ScrollTo'
import { useChatContext } from '../../../hooks/context/ChatContext'
import { PublicSession } from '../../../types/Chat'
import query from '../../../utils/query'
import useTryCatch from '../../../hooks/useTryCatch'
import styles from './SessionList.module.scss'

type Props = {
	sessionId: string | null
	newSession: () => void
	deleteSession: (sessionId: string) => Promise<void>
	moveToSession: (sessionId: string) => void
	onSetAttachedSessionIds: (sessionId: string) => void
	chatSessionsRef: MutableRefObject<any>
}

const { Sider } = Layout

const SessionList = ({
	sessionId,
	newSession,
	deleteSession,
	moveToSession,
	onSetAttachedSessionIds,
	chatSessionsRef,
}: Props) => {
	const { message } = App.useApp()
	const tryCatch = useTryCatch(message)

	const chatContext = useChatContext()
	const isAssistant = chatContext.useSubscribe((context) => context.chat?.isAssistant)
	const disableNewMessage = chatContext.useSubscribe((context) => context.disableNewMessage)
	const sessionsCollapsed = chatContext.useSubscribe((context) => context.sessionsCollapsed)
	const chatSessions = chatContext.useSubscribe((context) => context.chatSessions)

	const [scrollTopVisible, setScrollTopVisible] = useState<boolean>(false)
	const [triggerConversationResponseLoading, setTriggerConversationResponseLoading] =
		useState<boolean>(false)

	const scrollableContainerRef = useRef<any>(null)

	const scrollToBottom = () => {
		if (scrollableContainerRef.current) {
			scrollableContainerRef.current.menu.list.scrollTop = 0
		}
	}

	const handleScroll = (e: any) => {
		if (e.target.scrollTop > 320) {
			setScrollTopVisible(true)
		} else {
			setScrollTopVisible(false)
		}
	}

	const onNewSession = () => {
		chatContext.setCurrentChatSession(undefined)
		newSession()
	}

	const onMoveToSession = (session: PublicSession) => {
		chatContext.setCurrentChatSession(session)
		moveToSession(session.id)
	}

	const manualTriggerConversationResponse = async () => {
		if (!sessionId || triggerConversationResponseLoading) {
			return
		}

		setTriggerConversationResponseLoading(true)
		await tryCatch(async () => {
			await query('/integration/messaging/triggerResponse', 'POST', {
				data: {
					conversationId: sessionId,
				},
				withCredentials: true,
			})
		})
		setTriggerConversationResponseLoading(false)
	}

	const menuItems = useMemo(() => {
		const items: MenuProps['items'] = [
			{
				key: '0',
				className: styles.sessionMenuButton,
				label: <MenuButton content={isAssistant ? 'New conversation' : 'New session'} />,
				onClick: onNewSession,
			},
			...chatSessions.map((session) => ({
				key: session.id,
				className: styles.sessionContainer,
				icon: <MessageOutlined />,
				label: (
					<SessionItem
						onSetAttachedSessionIds={onSetAttachedSessionIds}
						currentSessionId={sessionId}
						session={session}
						deleteSession={deleteSession}
					/>
				),
				onClick: () => onMoveToSession(session),
			})),
		]

		if (isAssistant && sessionId) {
			items.splice(1, 0, {
				key: '1',
				className: styles.sessionMenuButton,
				label: (
					<MenuButton
						disabled={disableNewMessage || triggerConversationResponseLoading}
						loading={triggerConversationResponseLoading}
						content='Generate remind message'
					/>
				),
				onClick: manualTriggerConversationResponse,
			})
		}

		return items
	}, [isAssistant, chatSessions, sessionId, disableNewMessage, triggerConversationResponseLoading])

	return useMemo(
		() => (
			<Sider
				width='20%'
				collapsedWidth={0}
				theme='light'
				trigger={null}
				collapsible
				collapsed={sessionsCollapsed}
			>
				<div ref={chatSessionsRef} className={styles.menuContainer}>
					<Menu
						ref={scrollableContainerRef}
						theme='light'
						mode='inline'
						onScroll={handleScroll}
						style={{ maxHeight: isAssistant ? 'calc(100vh - 277px)' : 'calc(100vh - 194px)' }}
						className={styles.sessionMenu}
						selectedKeys={sessionId ? [sessionId] : undefined}
						items={menuItems}
					/>

					<ScrollTo
						className={styles.scrollToTop}
						scrollTo={scrollToBottom}
						scrollToVisible={scrollTopVisible}
						direction='top'
					/>
				</div>
			</Sider>
		),
		[sessionsCollapsed, isAssistant, sessionId, menuItems, scrollTopVisible],
	)
}

const MenuButton = ({
	content,
	loading,
	disabled,
}: {
	content: string
	loading?: boolean
	disabled?: boolean
}) => {
	return (
		<Button loading={loading} disabled={disabled} className={styles.menuButton}>
			{content}
		</Button>
	)
}

export default SessionList
