import React, { useMemo, useState } from 'react'
import BrokerReflinksTable from './BrokerReflinksTable/BrokerReflinksTable'
import query from '../../../utils/query'
import { App, Button, Layout, Modal, Segmented, Tooltip } from 'antd'
import useTryCatch from '../../../hooks/useTryCatch'
import useAsyncEffect from '../../../hooks/useAsyncEffect'
import Loading from '../../../components/Loading/Loading'
import { DeleteOutlined, PlusCircleOutlined, SyncOutlined } from '@ant-design/icons'
import {
	FilterReflinksResponse,
	Reflink,
	ReflinksFilter,
	ReflinksSortParams,
} from '@neuron/types/reflink'
import ReflinkForm from './ReflinkForm/ReflinkForm'
import styles from './Reflinks.module.scss'

const { Header } = Layout

const TABS = ['Not used', 'Used'] as const
type Tab = (typeof TABS)[number]

const BrokerReflinks = () => {
	const { message } = App.useApp()
	const tryCatch = useTryCatch(message)

	const [brokerReflinks, setBrokerReflinks] = useState<Reflink[]>([])
	const [reflinksCount, setReflinksCount] = useState<number>(0)

	const [openReflinkModal, setOpenReflinkModal] = useState<boolean>(false)

	const [selectedReflinkIds, setSelectedReflinkIds] = useState<string[]>([])

	const [filters, setFilters] = useState<ReflinksFilter>({ page: 1, perPage: 20, used: false })
	const [sortParams, setSortParams] = useState<ReflinksSortParams | undefined>()

	const [tab, setTab] = useState<Tab>('Not used')

	const [loading, setLoading] = useState<boolean>(true)

	const onLoadBrokerReflinks = async (filters: ReflinksFilter, sortParams?: ReflinksSortParams) => {
		setLoading(true)
		await tryCatch(async () => {
			const { data, count } = await query<FilterReflinksResponse>('/reflink/filter', 'GET', {
				withCredentials: true,
				params: filters,
			})
			setBrokerReflinks(data)
			setReflinksCount(count)
			setFilters(filters)
			setSortParams(sortParams)
		})
		setLoading(false)
	}

	useAsyncEffect(
		() => onLoadBrokerReflinks({ ...filters, used: tab === 'Used' }, sortParams),
		[sortParams, tab],
	)

	const updateReflink = (newReflink: Reflink) => {
		setBrokerReflinks((reflinks) =>
			reflinks.map((reflink) => (reflink.id === newReflink.id ? newReflink : reflink)),
		)
	}

	const setPagination = (newPage?: number, newPerPage?: number) => {
		let needRefresh = false
		const newFilters: ReflinksFilter = { ...filters }

		if (newPage && newPage !== newFilters.page) {
			newFilters.page = newPage
			needRefresh = true
		}
		if (newPerPage && newPerPage !== newFilters.perPage) {
			newFilters.perPage = newPerPage
			needRefresh = true
		}

		if (needRefresh) {
			onLoadBrokerReflinks(newFilters, sortParams).then(() => {})
		}
	}

	const onSaveReflinkFormData = async (reflinkData: ReflinkFormData) => {
		await tryCatch(
			async () => {
				const savedReflink = await query<Reflink>(
					reflinkData.id ? '/reflink/update' : '/reflink/create',
					'POST',
					{
						data: reflinkData,
						withCredentials: true,
					},
				)

				message.open({
					type: 'success',
					content: `Reflink ${reflinkData.id ? 'updated' : 'created'}`,
				})
				setOpenReflinkModal(false)

				if (reflinkData.id) {
					updateReflink(savedReflink)
				} else {
					await onLoadBrokerReflinks(filters, sortParams)
				}
			},
			undefined,
			{ message: `Error while ${reflinkData.id ? 'updating' : 'creating'} reflink. Try again.` },
		)
	}

	const deleteReflinks = async (ids: string[]) => {
		await tryCatch(
			async () => {
				await query('/reflink/delete', 'POST', {
					data: { ids },
					withCredentials: true,
				})
				setSelectedReflinkIds([])
			},
			undefined,
			{
				message: 'Error when deleting selected reflinks. Some reflinks may not have been deleted.',
			},
		)

		message.open({
			type: 'success',
			content: `${ids.length} reflinks was deleted`,
		})

		await onLoadBrokerReflinks(filters, sortParams)
	}

	const reflinkFormModal = useMemo(
		() => (
			<Modal
				destroyOnClose
				footer={null}
				className={styles.reflinkFormModal}
				title='Create reflink'
				open={openReflinkModal}
				onCancel={() => setOpenReflinkModal(false)}
			>
				<div className={styles.modalContent}>
					<ReflinkForm
						onClose={() => setOpenReflinkModal(false)}
						saveReflinkFormData={onSaveReflinkFormData}
					/>
				</div>
			</Modal>
		),
		[openReflinkModal],
	)

	if (loading) {
		return <Loading />
	}

	return (
		<div className={styles.container}>
			{reflinkFormModal}

			<div className={styles.tabsWrapper}>
				<Segmented
					value={tab}
					onChange={(tabValue) => setTab(tabValue as Tab)}
					className={styles.contentTabs}
					size='large'
					options={[...TABS]}
				/>
			</div>

			<Header className={styles.header}>
				<div className={styles.headerElements}>
					<Button icon={<PlusCircleOutlined />} onClick={() => setOpenReflinkModal(true)}>
						Create reflink
					</Button>
				</div>

				<div className={styles.headerElements}>
					{!!selectedReflinkIds.length && (
						<div className={styles.headerElements}>
							<Button
								icon={<DeleteOutlined />}
								danger
								onClick={() => deleteReflinks(selectedReflinkIds)}
							>
								Delete selected reflinks ({selectedReflinkIds.length})
							</Button>
						</div>
					)}

					<Tooltip trigger={['hover', 'focus']} title='Refresh'>
						<Button
							className={styles.headerRefresh}
							onClick={() => onLoadBrokerReflinks(filters, sortParams)}
							shape='circle'
							icon={<SyncOutlined />}
						/>
					</Tooltip>
				</div>
			</Header>

			<div className={styles.reflinksTable}>
				<BrokerReflinksTable
					reflinks={brokerReflinks}
					filters={filters}
					total={reflinksCount}
					showingUsed={!!filters.used}
					selectedReflinkIds={selectedReflinkIds}
					setPagination={setPagination}
					setSelectedReflinks={setSelectedReflinkIds}
					saveReflinkFormData={onSaveReflinkFormData}
				/>
			</div>
		</div>
	)
}

export type ReflinkFormData = { id?: string } & Pick<
	Reflink,
	| 'additionalCost'
	| 'businessTurnoverLevel'
	| 'trainingDays'
	| 'enterprise'
	| 'agreementStartDate'
	| 'note'
>

export default BrokerReflinks
