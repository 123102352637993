import { useAssistantRoleContext } from '../../../../../../hooks/context/AssistantRoleContext'
import ContextUrlForm from '../../../../../../components/ContextUrlForm/ContextUrlForm'
import { LinkContent } from '../../../../../../types/AiContext'
import ShadowSection from '../../components/ShadowSection/ShadowSection'

const KnowledgeLink = () => {
	const assistantRoleContext = useAssistantRoleContext()
	const contextUrlData = assistantRoleContext.useSubscribe((context) => context.contextUrlData)

	const updateLinkContent = (linkContent: LinkContent) => {
		assistantRoleContext.setContextUrlData(linkContent)
	}

	return (
		<ShadowSection>
			<ContextUrlForm
				linkContent={contextUrlData ?? { url: '', isValidUrl: false }}
				updateLinkContent={updateLinkContent}
			/>
		</ShadowSection>
	)
}

export default KnowledgeLink
