import React, { useState, useMemo } from 'react'
import { Card, Button, Modal, Tooltip, App } from 'antd'
import { SyncOutlined, PlusCircleOutlined } from '@ant-design/icons'
import useTryCatch from '../../../hooks/useTryCatch'
import { useAccountContext } from '../../../hooks/context/AccountContext'
import query from '../../../utils/query'
import { Header } from 'antd/es/layout/layout'
import useAsyncEffect from '../../../hooks/useAsyncEffect'
import Cards from 'react-credit-cards-2'
import { PaymentCardStatuses, PaymentMethod } from '@neuron/types/order/paymentMethod'
import Loading from '../../../components/Loading/Loading'
import EmptyData from '../../../components/EmptyData/EmptyData'
import Payment from '../../../components/Payment/Payment'

import 'react-credit-cards-2/es/styles-compiled.css'
import styles from './PaymentMethods.module.scss'

const PaymentMethods = () => {
	const { message } = App.useApp()
	const tryCatch = useTryCatch(message)

	const accountContext = useAccountContext()
	const usingUserId = accountContext.useSubscribe((account) => account.usingUserId)

	const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([])
	const [openPaymentMethodModal, setOpenPaymentMethodModal] = useState<boolean>(false)
	const [loading, setLoading] = useState<boolean>(true)

	const loadPaymentMethods = async () => {
		setLoading(true)
		await tryCatch(async () => {
			const methods = await query<PaymentMethod[]>('/order/payment-methods', 'GET', {
				params: { usingUserId },
			})
			setPaymentMethods(methods)
		})
		setLoading(false)
	}

	useAsyncEffect(loadPaymentMethods, [])

	const removePaymentMethod = async (id: string) => {
		Modal.confirm({
			title: 'Are you sure you want to remove this payment method?',
			onOk: async () => {
				setLoading(true)
				await tryCatch(async () => {
					await query('/order/payment-method/delete', 'POST', {
						data: { id, usingUserId },
					})
					message.open({
						type: 'success',
						content: 'Payment method removed successfully',
					})
					await loadPaymentMethods()
				})
				setLoading(false)
			},
		})
	}

	const openNewPaymentMethodModal = () => {
		setOpenPaymentMethodModal(true)
	}

	const closePaymentMethodModal = async (shouldRefresh?: boolean) => {
		setOpenPaymentMethodModal(false)
		if (shouldRefresh) {
			await loadPaymentMethods()
		}
	}

	const CardProblemStatus = ({ status }: { status: PaymentCardStatuses }) => {
		if (status === 'expired') {
			return <div className={styles.cardProblemStatus}>Payment card is expired</div>
		}
		if (status === 'invalid') {
			return <div className={styles.cardProblemStatus}>Payment card is invalid</div>
		}
		return <></>
	}

	const newPaymentMethodModal = useMemo(
		() => (
			<Modal
				destroyOnClose
				footer={null}
				className={styles.paymentMethodModal}
				title={<h3 className={styles.headingLabel}>Add Payment Method</h3>}
				open={openPaymentMethodModal}
				onCancel={() => closePaymentMethodModal()}
			>
				<div className={styles.modalContent}>
					<Payment onClose={() => closePaymentMethodModal(true)} />
				</div>
			</Modal>
		),
		[openPaymentMethodModal],
	)

	return (
		<div className={styles.container}>
			{newPaymentMethodModal}

			<Header className={styles.header}>
				<div className={styles.headerElements}>
					<Button icon={<PlusCircleOutlined />} onClick={openNewPaymentMethodModal}>
						Add payment method
					</Button>
				</div>

				<div className={styles.headerElements}>
					<Tooltip trigger={['hover', 'focus']} title='Refresh'>
						<Button
							className={styles.headerRefresh}
							onClick={loadPaymentMethods}
							shape='circle'
							icon={<SyncOutlined />}
							loading={loading}
						/>
					</Tooltip>
				</div>
			</Header>

			<div className={styles.content}>
				<h3 className={styles.headingLabel}>Payment methods</h3>
				<Card className={styles.savedCards}>
					{loading ? (
						<Loading className={styles.loading} size={40} />
					) : (
						<div className={styles.savedCardList}>
							{paymentMethods.length === 0 && (
								<EmptyData
									onClick={openNewPaymentMethodModal}
									description='Empty payment methods'
									buttonText='Add card'
								/>
							)}

							{paymentMethods.map((method) => (
								<div key={method.id} className={styles.savedCardWrapper}>
									<Cards
										preview
										cvc='***'
										expiry={getParsedExpiryCardDate(
											method.card.expiryMonth,
											method.card.expiryYear,
										)}
										name={method.card.holderName}
										number={`**** **** **** ${method.card.lastCardNumbers}`}
										issuer={method.card.brand}
									/>

									<CardProblemStatus status={method.status} />

									<Button
										danger
										onClick={() => removePaymentMethod(method.id)}
										disabled={loading || method.isDefault}
										className={styles.removeCardButton}
									>
										Remove
									</Button>
								</div>
							))}
						</div>
					)}
				</Card>
			</div>
		</div>
	)
}

const getParsedExpiryCardDate = (expiryMonth: string, expiryYear: string): string => {
	if (expiryMonth.length === 1) {
		return `0${expiryMonth}/${expiryYear}`
	}
	return `${expiryMonth}/${expiryYear}`
}

export default PaymentMethods
