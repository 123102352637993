import React, { useMemo, useState } from 'react'
import useAsyncEffect from '../../hooks/useAsyncEffect'
import query from '../../utils/query'
import { App, Modal } from 'antd'
import Loading from '../../components/Loading/Loading'
import useTryCatch from '../../hooks/useTryCatch'
import {
	GetLeadsGroupsResponse,
	NewLeadsGroup,
	LeadsGroup,
	UpdateLeadsGroupRequest,
} from '@neuron/types/leadsGroup'
import LeadsGroupForm from './LeadsGroupForm/LeadsGroupForm'
import LeadsGroupList from '../../components/LeadsGroupList/LeadsGroupList'
import { useAccountContext } from '../../hooks/context/AccountContext'
import styles from './LeadsGroups.module.scss'

const LeadsGroups = () => {
	const { message } = App.useApp()
	const tryCatch = useTryCatch(message)

	const accountContext = useAccountContext()
	const usingUserId = accountContext.useSubscribe((account) => account.usingUserId)

	const [leadsGroups, setLeadsGroups] = useState<GetLeadsGroupsResponse>([])
	const [selectedLeadsGroupId, setSelectedLeadsGroupId] = useState<string | undefined>()
	const [openLeadsGroupModal, setOpenLeadsGroupModal] = useState<boolean>(false)
	const [loading, setLoading] = useState<boolean>(true)

	const onLoadLeadsGroups = async () => {
		setLoading(true)
		await tryCatch(async () => {
			const resLeadsGroups = await query<GetLeadsGroupsResponse>('/leadsGroup/all', 'GET', {
				withCredentials: true,
				params: {
					usingUserId,
				},
			})
			setLeadsGroups(resLeadsGroups)
		})
		setLoading(false)
	}

	useAsyncEffect(onLoadLeadsGroups, [])

	const deleteLeadsGroups = async (leadsGroupIds: string[]) => {
		const expectGroupIds: string[] = []

		for (const groupId of leadsGroupIds) {
			await tryCatch(
				async () => {
					await query('/leadsGroup/delete', 'POST', {
						data: { id: groupId },
						withCredentials: true,
					})
					message.open({
						type: 'success',
						content: 'Leads group was deleted',
					})
				},
				() => expectGroupIds.push(groupId),
				{ message: 'Error while deleting leads group' },
			)
		}

		setLeadsGroups((groupsData) => {
			return groupsData.filter(
				(groupData) =>
					!leadsGroupIds.includes(groupData.group.id) ||
					expectGroupIds.includes(groupData.group.id),
			)
		})
	}

	const createOrUpdateLeadsGroup = async (data: UpdateLeadsGroupRequest | NewLeadsGroup) => {
		if ('id' in data) {
			await tryCatch(
				async () => {
					const updatedLeadsGroup = await query<LeadsGroup>('/leadsGroup/update', 'POST', {
						data,
						withCredentials: true,
					})

					message.open({
						type: 'success',
						content: 'Leads group was updated',
					})

					setLeadsGroups((groupsData) =>
						groupsData.map((groupData) =>
							groupData.group.id === updatedLeadsGroup.id
								? { group: updatedLeadsGroup, count: groupData.count }
								: groupData,
						),
					)
					onCloseLeadsGroupModal()
				},
				undefined,
				{ message: 'Error while creating new lead groups. Try again.' },
			)
		} else {
			await tryCatch(
				async () => {
					await query<LeadsGroup>('/leadsGroup/create', 'POST', {
						data: {
							...data,
							usingUserId,
						},
						withCredentials: true,
					})

					message.open({
						type: 'success',
						content: 'New leads group was created',
					})

					onCloseLeadsGroupModal()
					await onLoadLeadsGroups()
				},
				undefined,
				{ message: 'Error while creating new leads group. Try again.' },
			)
		}
	}

	const onCreateNewLeadsGroup = () => {
		setOpenLeadsGroupModal(true)
	}

	const onEditNewLeadsGroup = (groupId: string) => {
		setSelectedLeadsGroupId(groupId)
		setOpenLeadsGroupModal(true)
	}

	const onCloseLeadsGroupModal = () => {
		setSelectedLeadsGroupId(undefined)
		setOpenLeadsGroupModal(false)
	}

	const leadsGroupModal = useMemo(
		() => (
			<Modal
				destroyOnClose
				footer={null}
				className={styles.groupFormModal}
				title={selectedLeadsGroupId ? 'Edit leads group' : 'Create new leads group'}
				open={openLeadsGroupModal}
				onCancel={onCloseLeadsGroupModal}
			>
				<div className={styles.modalContent}>
					<LeadsGroupForm
						onSubmit={createOrUpdateLeadsGroup}
						onCancel={onCloseLeadsGroupModal}
						leadsGroupId={selectedLeadsGroupId}
					/>
				</div>
			</Modal>
		),
		[!!selectedLeadsGroupId, openLeadsGroupModal],
	)

	if (loading) {
		return <Loading />
	}

	return (
		<>
			{leadsGroupModal}

			<LeadsGroupList
				onLoadLeadsGroups={onLoadLeadsGroups}
				leadsGroups={leadsGroups}
				deleteLeadsGroups={deleteLeadsGroups}
				onCreateNewLeadsGroup={onCreateNewLeadsGroup}
				onEditNewLeadsGroup={onEditNewLeadsGroup}
				showOpenButton
			/>
		</>
	)
}

export default LeadsGroups
