import React, { useMemo, useState } from 'react'
import { Button, Modal, List } from 'antd'
import Payment from '../../../components/Payment/Payment'
import { Order } from '@neuron/types/order'
import styles from './TrainingPayment.module.scss'

type Props = {
	order: Order
	reloadOrders: () => Promise<void>
}

const TrainingPayment = ({ order, reloadOrders }: Props) => {
	const [openPaymentModal, setOpenPaymentModal] = useState<boolean>(false)

	const trainingProduct = order.products.find((product) => product.type === 'training')
	const formattedPrice = (order.totalPrice / 100).toFixed(2)

	const closePaymentModalAfterPayment = async () => {
		setOpenPaymentModal(false)
		await reloadOrders()
	}

	const paymentModal = useMemo(
		() => (
			<Modal
				destroyOnClose
				footer={null}
				className={styles.paymentModal}
				title={<h3 className={styles.modalHeadingLabel}>Płatność za szkolenie</h3>}
				open={openPaymentModal}
				onCancel={() => setOpenPaymentModal(false)}
			>
				<div className={styles.modalContent}>
					<Payment order={order} onClose={closePaymentModalAfterPayment} />
				</div>
			</Modal>
		),
		[openPaymentModal, order],
	)

	return (
		<div className={styles.container}>
			{paymentModal}

			<h3 className={styles.headingLabel}>Płatność za szkolenie</h3>
			{trainingProduct && (
				<div className={styles.trainingDetails}>
					<div className={styles.trainingInfo}>
						<span className={styles.boldText}>Cena:</span>{' '}
						<span>
							{formattedPrice} {order.currency}
						</span>
					</div>
					<div className={styles.trainingInfo}>
						<span className={styles.boldText}>Czas trwania szkolenia:</span>{' '}
						<span>{'trainingDays' in trainingProduct ? trainingProduct.trainingDays : 0} dni</span>
					</div>
					<List
						className={styles.trainingList}
						bordered
						dataSource={[
							'Mapowanie organizacji',
							'Budowanie jakościowej bazy wiedzy na bazie organizacji',
							'Tworzenie dedykowanej technologii dla organizacji',
							'Szkolenie kadry',
						]}
						renderItem={(item) => <List.Item>{item}</List.Item>}
					/>
					<Button
						type='primary'
						size='large'
						className={styles.paymentButton}
						onClick={() => setOpenPaymentModal(true)}
					>
						Przejdź do płatności
					</Button>
				</div>
			)}
		</div>
	)
}

export default TrainingPayment
