import React, { useState } from 'react'
import useAsyncEffect from '../../hooks/useAsyncEffect'
import query from '../../utils/query'
import { useNavigate } from 'react-router-dom'
import { Button, Layout, App, Tooltip, Tag } from 'antd'
import { formatDate } from '@neuron/utils/dates'
import Loading from '../../components/Loading/Loading'
import useTryCatch from '../../hooks/useTryCatch'
import CardTitle from '../../components/CardTitle/CardTitle'
import EmptyData from '../../components/EmptyData/EmptyData'
import { Assistant, AssistantStatus } from '@neuron/types/assistant'
import { UserOutlined, EditOutlined, TeamOutlined, SyncOutlined } from '@ant-design/icons'
import CardItem from '../../components/CardItem/CardItem'
import { useAccountContext } from '../../hooks/context/AccountContext'
import styles from './Personas.module.scss'

import logoIcon from '../../assets/logo.svg'

const { Header } = Layout

const Personas = () => {
	const navigate = useNavigate()
	const { message } = App.useApp()
	const tryCatch = useTryCatch(message)

	const accountContext = useAccountContext()
	const usingUserId = accountContext.useSubscribe((account) => account.usingUserId)
	const accountOffer = accountContext.useSubscribe((account) => account.offer)
	const isOnboarding = accountContext.useSubscribe((context) =>
		context.roles.includes('onboarding'),
	)
	const isAdmin = accountContext.useSubscribe((context) => context.roles.includes('admin'))

	const [assistants, setAssistants] = useState<Assistant[]>([])
	const [loading, setLoading] = useState<boolean>(true)

	const onLoadAssistants = async () => {
		setLoading(true)
		await tryCatch(async () => {
			const resAssistants = await query<Assistant[]>('/assistant/forUser', 'GET', {
				withCredentials: true,
				params: {
					usingUserId,
				},
			})
			setAssistants(resAssistants)
		})
		setLoading(false)
	}

	useAsyncEffect(onLoadAssistants, [usingUserId])

	const deleteAssistant = async (assistantId: string) => {
		await tryCatch(
			async () => {
				await query('/assistant/delete', 'POST', {
					data: { id: assistantId },
					withCredentials: true,
				})
				message.open({
					type: 'success',
					content: 'Persona was deleted',
				})

				setAssistants((assistants) =>
					assistants.filter((assistant) => assistant.id !== assistantId),
				)
			},
			undefined,
			{ message: 'Error while deleting Persona' },
		)
	}

	const assistantStatusAction = async (assistant: Assistant) => {
		if (assistant.status === 'error' || assistant.status === 'deleting') {
			return
		}

		if (isOnboarding) {
			let newStatus: AssistantStatus = 'approved'
			if (assistant.status === 'approved' || assistant.status === 'active') {
				newStatus = 'inactive'
			}

			await changeAssistantStatus(assistant.id, newStatus)
			return
		}

		if (isAdmin) {
			await changeAssistantStatus(
				assistant.id,
				assistant.status === 'active' ? 'inactive' : 'active',
			)
			return
		}

		if (assistant.status === 'inactive') {
			await requestToActiveAssistant(assistant.id)
		}
	}

	const changeAssistantStatus = async (assistantId: string, status: AssistantStatus) => {
		await tryCatch(
			async () => {
				const updatedAssistant = await query<Assistant>('/assistant/update/status', 'POST', {
					data: {
						id: assistantId,
						status,
					},
					withCredentials: true,
				})
				message.open({
					type: updatedAssistant.status === 'active' ? 'success' : 'warning',
					content: `Persona status was changed to: ${updatedAssistant.status}`,
				})
				setAssistants((assistants) =>
					assistants.map((assistant) =>
						assistant.id === assistantId ? updatedAssistant : assistant,
					),
				)
			},
			undefined,
			{ message: 'Error while changing Persona status' },
		)
	}

	const requestToActiveAssistant = async (assistantId: string) => {
		await tryCatch(
			async () => {
				const updatedAssistant = await query<Assistant>('/assistant/update/status', 'POST', {
					data: {
						id: assistantId,
						status: 'requested',
					},
					withCredentials: true,
				})
				message.open({
					type: 'info',
					content: 'Waiting to activate Persona',
				})
				setAssistants((assistants) =>
					assistants.map((assistant) =>
						assistant.id === assistantId ? updatedAssistant : assistant,
					),
				)
			},
			undefined,
			{ message: 'Error while requesting for change Persona status' },
		)
	}

	const createNewAssistant = () => {
		navigate('/persona')
	}

	if (loading) {
		return <Loading />
	}

	const getAssistantStatusButtonContent = (status: AssistantStatus): string => {
		if (isAdmin) {
			if (status !== 'active') {
				return 'Active'
			}
			return 'Inactive'
		}

		if (isOnboarding) {
			if (status === 'active') {
				return 'Inactive'
			}
			return 'Approve'
		}

		if (status === 'active') {
			return 'Inactive'
		}
		if (status === 'requested') {
			return 'Requested'
		}
		return 'Active'
	}

	const getAssistantStatusButtonDisabled = (assistant: Assistant): boolean => {
		if (isOnboarding || isAdmin) {
			return assistant.status === 'deleting' || assistant.status === 'error'
		}
		return assistant.status !== 'inactive'
	}

	const getAssistantStatusColor = (status: AssistantStatus): string | undefined => {
		if (status === 'active') {
			return '#5cb85c'
		}
		if (status === 'inactive') {
			return '#d9534f'
		}
		if (status === 'requested') {
			return '#5bc0de'
		}
	}

	return (
		<div className={styles.container}>
			<Header className={styles.header}>
				<div className={styles.headerElements}>
					<Tooltip
						zIndex={assistants.length >= accountOffer.availablePersonas ? 10 : -1}
						mouseEnterDelay={0.3}
						title='You have reached the maximum number of Personas for the current offer. Contact your broker to increase the number of Personas in your offer.'
					>
						<Button
							disabled={assistants.length >= accountOffer.availablePersonas}
							icon={<TeamOutlined />}
							onClick={createNewAssistant}
						>
							Create new Persona
						</Button>
					</Tooltip>
				</div>

				<div className={styles.headerElements}>
					<Tooltip trigger={['hover', 'focus']} title='Refresh'>
						<Button
							className={styles.headerRefresh}
							onClick={onLoadAssistants}
							shape='circle'
							icon={<SyncOutlined />}
						/>
					</Tooltip>
				</div>
			</Header>

			<div className={styles.assistants}>
				{assistants.map((assistant) => (
					<CardItem
						key={assistant.id}
						itemId={assistant.id}
						size='large'
						cardTitle={
							<CardTitle
								id={assistant.id}
								name={`Persona: ${assistant.firstname}`}
								headerIcon={logoIcon}
								size='large'
								headerButton={{
									text: getAssistantStatusButtonContent(assistant.status),
									disabled: getAssistantStatusButtonDisabled(assistant),
									onClick: () => assistantStatusAction(assistant),
									size: 'middle',
									className: `${
										assistant.status === 'active' ? styles.inactiveButton : styles.activeButton
									}`,
								}}
								disabled={assistant.status === 'deleting'}
								onDelete={(id: string) => deleteAssistant(id)}
							/>
						}
						labels={[
							{
								label: 'Company name',
								value: assistant.companyName,
							},
							{
								label: 'Status',
								value: (
									<Tag color={getAssistantStatusColor(assistant.status)}>{assistant.status}</Tag>
								),
							},
							{
								label: 'Created date',
								value: formatDate(assistant.createdAt),
							},
							{
								label: 'Last update date',
								value: assistant.updatedAt && formatDate(assistant.updatedAt),
							},
						]}
					>
						<Button
							disabled={assistant.status === 'deleting'}
							size='large'
							icon={<UserOutlined />}
							onClick={(e) => {
								e.stopPropagation()
								navigate(`/persona/${assistant.id}/roles`)
							}}
						>
							Roles
						</Button>
						<Button
							disabled={assistant.status === 'deleting'}
							size='large'
							icon={<EditOutlined />}
							onClick={(e) => {
								e.stopPropagation()
								navigate(`/persona/${assistant.id}`)
							}}
						>
							Edit
						</Button>
					</CardItem>
				))}

				{!assistants.length && <EmptyData description='Empty Personas list' />}
			</div>
		</div>
	)
}

export default Personas
