import query from '../../utils/query'
import { App } from 'antd'
import useTryCatch from '../../hooks/useTryCatch'
import { useChatContext } from '../../hooks/context/ChatContext'
import { FileInfo } from '@neuron/types/fileInfo'
import { useEditorContext } from '../../hooks/context/EditorContext'
import { promptsTemplates } from '@neuron/utils/toolsPrompts'

export const useAttachFiles = () => {
	const { message } = App.useApp()
	const tryCatch = useTryCatch(message)

	const chatContext = useChatContext()
	const editorContext = useEditorContext()

	const getFileIdFromUrl = (url: string) => {
		return url.split('/').at(-1)
	}

	const loadChatFile = async (
		file: string | File,
		currentAttachedFileIds?: string[],
	): Promise<string | undefined> => {
		if (typeof file === 'string') {
			if (currentAttachedFileIds?.some((fileId) => fileId === file)) {
				return
			}

			// download file from DB
			await tryCatch(async () => {
				const [fileInfo, fileBlob] = await Promise.all([
					query<FileInfo>('/file/get', 'GET', {
						params: {
							id: file,
						},
						withCredentials: true,
						useCache: true,
					}),
					query<Blob>('/file/download', 'GET', {
						params: {
							id: file,
						},
						timeout: 1000 * 30,
						responseType: 'blob',
						withCredentials: true,
						useCache: true,
					}),
				])

				const url = window.URL.createObjectURL(fileBlob)
				chatContext.addChatFile({
					id: file,
					name: fileInfo.filename,
					url,
					type: fileBlob.type,
				})
			})
			return file
		} else {
			const fileId = file.name.split('.').slice(0, -1).join('')
			if (
				currentAttachedFileIds?.some((attachedFileId) => attachedFileId === fileId) ||
				chatContext.storeRef.current?.chatFiles.some(
					(chatFile) => getFileIdFromUrl(chatFile.url) === fileId,
				)
			) {
				return
			}

			// file before upload
			const url = window.URL.createObjectURL(file)
			const fileIdx = url.split('/').at(-1) ?? file.name
			chatContext.addChatFile({
				id: fileIdx,
				name: file.name,
				url,
				type: file.type,
				file: new File([file], fileIdx, { type: file.type }),
			})
			return fileIdx
		}
	}

	const attachFilesToMessage = async (files: Array<string | File>): Promise<string[]> => {
		const newAttachedFileIds: string[] = []
		const chatContextRef = chatContext.storeRef.current

		if (!chatContextRef) {
			return newAttachedFileIds
		}

		for (const file of files) {
			if (typeof file === 'string') {
				if (!chatContextRef.chatFiles.some((chatFile) => chatFile.id === file)) {
					continue
				}

				if (!chatContextRef.attachedFileIds.some((fileId) => fileId === file)) {
					chatContext.addAttachedFileId(file)
					newAttachedFileIds.push(file)
				}
			} else {
				if (!chatContextRef.attachedFileIds.some((fileId) => fileId === file.name)) {
					const fileId = await loadChatFile(file, chatContextRef.attachedFileIds)
					if (fileId) {
						chatContext.addAttachedFileId(fileId)
						newAttachedFileIds.push(fileId)
					}
				}
			}
		}

		return newAttachedFileIds
	}

	const attachFilesToEditor = async (inputId: string, files: File[]): Promise<void> => {
		let currentFilesAmount = editorContext.storeRef.current!.editorFiles[inputId]?.length ?? 0
		const inputTemplate = Object.values(promptsTemplates)
			.map((template) => template.inputs)
			.flat()
			.find((input) => input.id === inputId)!

		for (const file of files) {
			currentFilesAmount += 1

			if (currentFilesAmount > inputTemplate.limit) {
				throw new Error('The limit of attached files in this field has been reached.')
			}

			const fileId = file.name.split('.').slice(0, -1).join('')

			const findExistFileId = chatContext.storeRef.current?.chatFiles.find(
				(chatFile) => getFileIdFromUrl(chatFile.url) === fileId,
			)?.id

			if (findExistFileId) {
				for (const name of Object.keys(editorContext.storeRef.current!.editorFiles)) {
					editorContext.removeEditorFiles(name, [findExistFileId])
				}
				editorContext.addEditorFiles(inputId, [findExistFileId])
			} else {
				const fileId = await loadChatFile(file, chatContext.storeRef.current?.attachedFileIds)
				if (fileId) {
					editorContext.addEditorFiles(inputId, [fileId])
					chatContext.addAttachedFileId(fileId)
				}
			}
		}
	}

	return {
		loadChatFile,
		attachFilesToMessage,
		attachFilesToEditor,
	}
}
