import React, { ReactNode } from 'react'
import { Card, Tooltip } from 'antd'
import styles from './CardItem.module.scss'
import Line from '../Line/Line'

type Props = {
	itemId: string | number
	cardTitle: ReactNode
	labels: Array<{
		label: ReactNode | 'separator'
		value?: ReactNode
	}>
	cover?: ReactNode
	children?: ReactNode
	selectedItems?: string[] | number[]
	selectItem?: () => void
	size?: 'default' | 'large'
	actions?: ReactNode[]
}

const CardItem = ({
	cardTitle,
	labels,
	children,
	itemId,
	selectedItems,
	selectItem,
	size = 'default',
	cover,
	actions,
}: Props) => {
	return (
		<div className={`${styles.cardWrapper} ${size === 'large' ? styles.largeCard : ''}`}>
			<Card
				hoverable
				title={cardTitle}
				styles={{
					header: { width: size === 'large' ? 380 : 330 },
					body: { padding: '12px 24px 24px 24px', width: size === 'large' ? 380 : 330 },
				}}
				className={`${styles.card} ${
					selectedItems?.some((id) => id === itemId) ? styles.selected : ''
				} ${selectItem ? styles.pointerCard : ''}
				${size === 'large' ? styles.largeCard : ''}`}
				onClick={selectItem}
				cover={cover}
				actions={actions}
			>
				{labels
					.filter((label) => label.label === 'separator' || !!label.value)
					.map(({ label, value }, index) => (
						<div key={`${itemId}-${index}`}>
							{label === 'separator' ? (
								<Line className={styles.separator} />
							) : (
								<div key={`${itemId}-${index}`} className={styles.label}>
									{label}:
									{typeof value === 'string' ? (
										<Tooltip placement='topLeft' mouseEnterDelay={0.3} title={value}>
											<b>{value}</b>
										</Tooltip>
									) : (
										<b>{value}</b>
									)}
								</div>
							)}
						</div>
					))}

				{!!children && (
					<>
						<div className={`${styles.line} ${size === 'large' ? styles.largeCard : ''}`} />
						<div className={styles.buttons}>{children}</div>
					</>
				)}
			</Card>
		</div>
	)
}

export default CardItem
