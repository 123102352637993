import React, { MutableRefObject, useEffect, useMemo, useRef, useState } from 'react'
import { Button, Tooltip } from 'antd'
import {
	SendOutlined,
	PaperClipOutlined,
	CloseOutlined,
	PauseCircleOutlined,
} from '@ant-design/icons'
import { InlineLoading } from '../../Loading/Loading'
import { useDebounced } from '../../../utils/helpers'
import ChatTools from './ChatTools/ChatTools'
import { useChatContext } from '../../../hooks/context/ChatContext'
import MessageEditor from './MessageEditor/MessageEditor'
import { useEditorContext } from '../../../hooks/context/EditorContext'
import { DEFAULT_INPUT_ID, EDITOR_VALIDATION_CODE_MAPPER } from '../../../types/EditorContext'
import { promptsTemplates } from '@neuron/utils/toolsPrompts'
import { MAX_CHAT_FOOTER_HEIGHT, MIN_CHAT_FOOTER_HEIGHT } from '../../../utils/consts'
import ChatAttachedFile from './ChatAttachedFile/ChatAttachedFile'
import { useGlobalContext } from '../../../hooks/context/GlobalContext'
import styles from './ChatFooter.module.scss'

type Props = {
	sendNewMessage: (messageContent: string) => void
	removeAttachedFile: (fileId: string) => void
	messagesIsBlocked: boolean
	chatToolsRef: MutableRefObject<any>
	chatChatAttachmentRef: MutableRefObject<any>
	cancelMessage?: () => void
}

const FIRST_MESSAGE_FILE_SPACE = -50
const MESSAGE_FILE_SPACE = -42

const ChatFooter = ({
	sendNewMessage,
	cancelMessage,
	removeAttachedFile,
	messagesIsBlocked,
	chatToolsRef,
	chatChatAttachmentRef,
}: Props) => {
	const debounced = useDebounced()

	const editorContext = useEditorContext()
	const chatContext = useChatContext()
	const globalContext = useGlobalContext()

	const toolType = editorContext.useSubscribe((context) => context.toolType)
	const editorHeight = editorContext.useSubscribe((context) => context.editorHeight)
	const validationCode = editorContext.useSubscribe((context) => context.validationCode)
	const editorContent = editorContext.useSubscribe((context) => context.editorContent)
	const canSubmit = editorContext.useSubscribe((context) => context.canSubmit)

	const isAssistant = chatContext.useSubscribe((context) => context.chat?.isAssistant)
	const useTools = chatContext.useSubscribe((context) => context.chat?.options?.useTools)
	const chatFiles = chatContext.useSubscribe((context) => context.chatFiles)
	const attachedChatFileIds = chatContext.useSubscribe((context) => context.attachedFileIds)
	const disableNewMessage = chatContext.useSubscribe((context) => context.disableNewMessage)
	const footerHeight = chatContext.useSubscribe((context) => context.footerHeight)
	const uploadChatFileRef = chatContext.useSubscribe((context) => context.uploadChatFileRef)
	const isHardcodedFooterHeight = chatContext.useSubscribe(
		(context) => context.isHardcodedFooterHeight,
	)

	const showChatTour = globalContext.useSubscribe((context) => context.showChatTour)

	const [resizingTextarea, setResizingTextarea] = useState<boolean>(false)
	const [cancelMessageLoading, setCancelMessageLoading] = useState<boolean>(false)

	const lastScreenY = useRef<number>(0)
	const userTextareaHeight = useRef<number>(MIN_CHAT_FOOTER_HEIGHT)

	useEffect(() => {
		if (resizingTextarea) {
			userTextareaHeight.current = footerHeight
		}
	}, [footerHeight, resizingTextarea])

	useEffect(() => {
		if (editorHeight && (editorHeight > footerHeight || !isHardcodedFooterHeight)) {
			setEditorHeight(editorHeight)
		}
	}, [editorHeight, footerHeight, isHardcodedFooterHeight])

	useEffect(() => {
		if (!disableNewMessage) {
			setCancelMessageLoading(false)
		}
	}, [disableNewMessage])

	const handleResizeStart = () => {
		setResizingTextarea(true)
		document.addEventListener('mousemove', handleResize)
		document.addEventListener('mouseup', handleResizeStop)
	}

	const handleResizeStop = () => {
		document.removeEventListener('mousemove', handleResize)
		document.removeEventListener('mouseup', handleResizeStop)
		lastScreenY.current = 0
		setResizingTextarea(false)
	}

	const handleResize = (e: any) =>
		debounced(() => {
			const getNewTextareaHeight = (currentTextareaHeight: number) => {
				const screenMove = e.screenY / 96
				if (e.screenY > lastScreenY.current) {
					if (currentTextareaHeight - screenMove <= MIN_CHAT_FOOTER_HEIGHT) {
						return MIN_CHAT_FOOTER_HEIGHT
					}
					return currentTextareaHeight - screenMove
				} else if (e.screenY < lastScreenY.current) {
					if (currentTextareaHeight + screenMove >= MAX_CHAT_FOOTER_HEIGHT) {
						return MAX_CHAT_FOOTER_HEIGHT
					}
					return currentTextareaHeight + screenMove
				}
				return currentTextareaHeight
			}

			chatContext.setChatContext({
				footerHeight: getNewTextareaHeight(chatContext.storeRef.current!.footerHeight),
				isHardcodedFooterHeight: true,
			})

			lastScreenY.current = e.screenY
		}, 5)

	const setEditorHeight = (height: number) => {
		let newHeight = height

		if (newHeight > MAX_CHAT_FOOTER_HEIGHT) {
			newHeight = MAX_CHAT_FOOTER_HEIGHT
		} else if (newHeight < MIN_CHAT_FOOTER_HEIGHT) {
			newHeight = MIN_CHAT_FOOTER_HEIGHT
		}

		chatContext.setChatContext({
			footerHeight: newHeight,
			isHardcodedFooterHeight: false,
		})
	}

	const clearAndRefreshMessage = () => {
		chatContext.setChatContext({
			footerHeight: MIN_CHAT_FOOTER_HEIGHT,
		})
		editorContext.setToolType(undefined)
	}

	const getRawMessageText = () => {
		if (toolType === undefined) {
			return editorContent[DEFAULT_INPUT_ID] ?? ''
		}

		const promptTemplate = promptsTemplates[toolType]
		let rawText = `**Tool: ${promptTemplate.name}** \n`

		for (const [inputId, value] of Object.entries(editorContent)) {
			const findTemplateText = promptTemplate.inputs.find((input) => input.id === inputId)
			if (findTemplateText) {
				rawText += `**${findTemplateText.text}:** ${value} \n`
			}
		}

		return rawText
	}

	const onSendMessage = () => {
		if (canSubmit && !messagesIsBlocked) {
			sendNewMessage(getRawMessageText())
		}
	}

	const onCancelMessage = () => {
		if (cancelMessage) {
			setCancelMessageLoading(true)
			cancelMessage()
		}
	}

	return useMemo(() => {
		const showSubmitButtonTooltip = !resizingTextarea && !canSubmit && !messagesIsBlocked

		const SubmitButton = () => {
			return (
				<Button
					disabled={!resizingTextarea && (messagesIsBlocked || !canSubmit)}
					className={`${styles.sendMessageButton} ${
						messagesIsBlocked || !canSubmit ? styles.disabled : ''
					}`}
					onClick={onSendMessage}
				>
					{messagesIsBlocked ? (
						<InlineLoading size={18} color='#fff' iconClassName={styles.loadingIcon} />
					) : (
						<SendOutlined />
					)}
				</Button>
			)
		}

		return (
			<div className={`${styles.footer} ${resizingTextarea ? styles.blockSelectingText : ''}`}>
				{!!cancelMessage && disableNewMessage && (
					<div className={styles.cancelMessageButton}>
						<Button
							disabled={cancelMessageLoading}
							loading={cancelMessageLoading}
							danger
							onClick={onCancelMessage}
						>
							<PauseCircleOutlined className={styles.cancelMessageIcon} />
							<span>{isAssistant ? 'Cancel Persona message' : 'Cancel message'}</span>
						</Button>
					</div>
				)}

				{!isAssistant && (
					<div
						className={styles.messageFiles}
						style={{
							top: FIRST_MESSAGE_FILE_SPACE + (attachedChatFileIds.length - 1) * MESSAGE_FILE_SPACE,
						}}
					>
						{attachedChatFileIds.map((fileId) => (
							<ChatAttachedFile
								key={fileId}
								chatFiles={chatFiles}
								fileId={fileId}
								removeAttachedFile={removeAttachedFile}
							/>
						))}
					</div>
				)}

				<div className={styles.newMessageContainer}>
					{useTools ? (
						<ChatTools chatToolsRef={chatToolsRef} />
					) : (
						<div className={styles.editorMargin} />
					)}

					<MessageEditor />
					<div className={styles.resizeIcon} onMouseDown={handleResizeStart} />

					<div className={styles.inputButtons}>
						<Button
							className={styles.clearMessageButton}
							type='text'
							onClick={clearAndRefreshMessage}
						>
							<CloseOutlined />
						</Button>

						{!isAssistant && (
							<Button
								ref={chatChatAttachmentRef}
								className={`${styles.attachFileButton} ${messagesIsBlocked ? styles.disabled : ''}`}
								type='text'
								onClick={() => !showChatTour && uploadChatFileRef?.openFileModal()}
							>
								<PaperClipOutlined />
							</Button>
						)}

						{showSubmitButtonTooltip ? (
							<Tooltip
								trigger={['hover', 'focus']}
								mouseEnterDelay={0.3}
								title={EDITOR_VALIDATION_CODE_MAPPER[validationCode!]}
							>
								<div>
									<SubmitButton />
								</div>
							</Tooltip>
						) : (
							<SubmitButton />
						)}
					</div>
				</div>
			</div>
		)
	}, [
		validationCode,
		disableNewMessage,
		messagesIsBlocked,
		attachedChatFileIds.length,
		footerHeight,
		canSubmit,
		resizingTextarea,
		cancelMessageLoading,
		useTools,
		editorContent,
		isAssistant,
		cancelMessage,
	])
}

export default ChatFooter
