import { App, Button, Modal, Tooltip } from 'antd'
import useTryCatch from '../../hooks/useTryCatch'
import useQuery from '../../hooks/useQuery'
import React, { useMemo, useState } from 'react'
import { Order, OrderStatus } from '@neuron/types/order'
import query from '../../utils/query'
import useAsyncEffect from '../../hooks/useAsyncEffect'
import UserOffer from './UserOffer/UserOffer'
import { PlusCircleOutlined, SyncOutlined } from '@ant-design/icons'
import { Header } from 'antd/es/layout/layout'
import UserOffersTable from './UserOffersTable/UserOffersTable'
import Loading from '../../components/Loading/Loading'
import AgreementForm from './AgreementForm/AgreementForm'
import { CreateAgreementRequest } from '@neuron/types/agreement'
import { Offer } from '@neuron/types/account'
import styles from './UserOffers.module.scss'

const UserOffers = () => {
	const { message } = App.useApp()
	const tryCatch = useTryCatch(message)

	const userId = useQuery().get('userId')

	const [orders, setOrders] = useState<Order[]>([])
	const [currentOffer, setCurrentOffer] = useState<Offer>()

	const [openOfferModal, setOpenOfferModal] = useState<boolean>(false)
	const [openAgreementFormModal, setOpenAgreementFormModal] = useState<boolean>(false)
	const [loading, setLoading] = useState<boolean>(true)

	const onLoadOrders = async () => {
		if (!userId) {
			return
		}

		setLoading(true)
		await tryCatch(async () => {
			const ordersResponse = await query<Order[]>('/order/user/get', 'GET', {
				withCredentials: true,
				params: {
					usingUserId: userId,
				},
			})

			if (!ordersResponse.length) {
				setOpenAgreementFormModal(true)
			}

			const currentOfferResponse = await query<Offer>('/account/offer', 'GET', {
				withCredentials: true,
				params: {
					userId,
				},
			})

			setOrders(ordersResponse)
			setCurrentOffer(currentOfferResponse)
		})
		setLoading(false)
	}

	useAsyncEffect(() => onLoadOrders(), [userId])

	const changeOrderStatus = async (orderId: string, status: OrderStatus) => {
		await tryCatch(
			async () => {
				await query('/order/update/status', 'POST', {
					data: { id: orderId, status },
					withCredentials: true,
				})

				message.open({
					type: 'success',
					content: `Offer status was change to: ${status}`,
				})

				await onLoadOrders()
			},
			undefined,
			{
				message: 'Error when updating offer status. Check current status and try again',
			},
		)
	}

	const createAgreement = async (agreementData: CreateAgreementRequest) => {
		await tryCatch(
			async () => {
				await query('/agreement/create', 'POST', {
					data: agreementData,
					withCredentials: true,
				})

				setOpenAgreementFormModal(false)

				message.open({
					type: 'success',
					content: 'Agreement was created',
				})

				await onLoadOrders()
			},
			undefined,
			{ message: 'Error when creating agreement. Check data and try again' },
		)
	}

	const openNewOfferModal = () => {
		setOpenOfferModal(true)
	}

	const closeOfferModal = async (shouldRefresh?: boolean) => {
		setOpenOfferModal(false)
		if (shouldRefresh) {
			await onLoadOrders()
		}
	}

	const hasPurchasedTraining = useMemo(() => {
		const trainingOrder = orders.find((order) =>
			order.products.some((product) => product.type === 'training'),
		)
		if (!trainingOrder) {
			return false
		}
		return trainingOrder.status === 'purchased'
	}, [orders])

	const newOfferModal = useMemo(() => {
		if (!currentOffer) {
			return <></>
		}

		return (
			<Modal
				destroyOnClose
				footer={null}
				className={styles.offerModal}
				title='New offer'
				open={openOfferModal}
				onCancel={() => closeOfferModal()}
			>
				<div className={styles.modalContent}>
					<UserOffer currentOffer={currentOffer} closeModal={closeOfferModal} />
				</div>
			</Modal>
		)
	}, [orders, openOfferModal])

	const agreementFormModal = useMemo(() => {
		if (!userId) {
			return <></>
		}

		return (
			<Modal
				destroyOnClose
				footer={null}
				className={styles.offerModal}
				closable={false}
				title='Agreement details'
				open={openAgreementFormModal}
			>
				<div className={styles.modalContent}>
					<AgreementForm userId={userId} onSubmitData={createAgreement} />
				</div>
			</Modal>
		)
	}, [userId, openAgreementFormModal])

	if (loading) {
		return <Loading />
	}

	return (
		<div className={styles.container}>
			{agreementFormModal}
			{newOfferModal}

			<Header className={styles.header}>
				<div className={styles.headerElements}>
					<Button
						disabled={!hasPurchasedTraining}
						icon={<PlusCircleOutlined />}
						onClick={openNewOfferModal}
					>
						Create new offer
					</Button>
				</div>

				<div className={styles.headerElements}>
					<Tooltip trigger={['hover', 'focus']} title='Refresh'>
						<Button
							className={styles.headerRefresh}
							onClick={onLoadOrders}
							shape='circle'
							icon={<SyncOutlined />}
						/>
					</Tooltip>
				</div>
			</Header>

			<div className={styles.offersTable}>
				<UserOffersTable orders={orders} changeOrderStatus={changeOrderStatus} />
			</div>
		</div>
	)
}

export default UserOffers
