import React, { useState } from 'react'
import BrokerUsersTable from './BrokerUsersTable/BrokerUsersTable'
import query from '../../../utils/query'
import { App, Button, Tooltip } from 'antd'
import useTryCatch from '../../../hooks/useTryCatch'
import useAsyncEffect from '../../../hooks/useAsyncEffect'
import Loading from '../../../components/Loading/Loading'
import { SyncOutlined } from '@ant-design/icons'
import { Header } from 'antd/es/layout/layout'
import { BrokerAccount } from '@neuron/types/account'
import { useAccountContext } from '../../../hooks/context/AccountContext'
import { useNavigate } from 'react-router-dom'
import styles from './Users.module.scss'

const BrokerUsers = () => {
	const navigate = useNavigate()
	const { message } = App.useApp()
	const tryCatch = useTryCatch(message)

	const [brokerAccounts, setBrokerAccounts] = useState<BrokerAccount[]>([])
	const [loading, setLoading] = useState<boolean>(true)

	const accountContext = useAccountContext()

	const onLoadBrokerAccounts = async () => {
		setLoading(true)
		await tryCatch(async () => {
			const brokerUsers = await query<BrokerAccount[]>('/account/broker/accounts', 'GET', {
				withCredentials: true,
			})
			setBrokerAccounts(brokerUsers)
		})
		setLoading(false)
	}

	useAsyncEffect(onLoadBrokerAccounts, [])

	const previewAccount = async (userId: string) => {
		accountContext.setUsingUserId(userId)
		navigate('/personas')
	}

	if (loading) {
		return <Loading />
	}

	return (
		<div className={styles.container}>
			<Header className={styles.header}>
				<div className={styles.headerElements} />

				<div className={styles.headerElements}>
					<Tooltip trigger={['hover', 'focus']} title='Refresh'>
						<Button
							className={styles.headerRefresh}
							onClick={onLoadBrokerAccounts}
							shape='circle'
							icon={<SyncOutlined />}
						/>
					</Tooltip>
				</div>
			</Header>

			<div className={styles.usersTable}>
				<BrokerUsersTable previewAccount={previewAccount} brokerAccounts={brokerAccounts} />
			</div>
		</div>
	)
}

export default BrokerUsers
