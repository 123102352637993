import { Button, Empty } from 'antd'
import styles from './EmptyData.module.scss'

import emptyData from '../../assets/resources/emptyData.webp'

type Props = {
	description: string
	buttonText?: string
	onClick?: () => void
	height?: number
}

const EmptyData = ({ onClick, buttonText = 'Create Now', description, height = 240 }: Props) => {
	return (
		<Empty
			className={styles.emptyData}
			image={emptyData}
			imageStyle={{ height: height }}
			description={<span className={styles.emptyDataDescription}>{description}</span>}
		>
			{!!onClick && (
				<Button size='large' onClick={onClick} type='primary'>
					{buttonText}
				</Button>
			)}
		</Empty>
	)
}

export default EmptyData
