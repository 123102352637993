import { ReactNode } from 'react'
import styles from './ShadowSection.module.scss'

type Props = {
	children: ReactNode
}

const ShadowSection = ({ children }: Props) => {
	return <div className={styles.shadowForm}>{children}</div>
}

export default ShadowSection
