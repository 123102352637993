import { uuidIdSchema } from '../../global'
import Joi from 'joi'

export const billingAddressSchema = {
	streetLine1: Joi.string().min(5).max(250).required(),
	streetLine2: Joi.string().max(250).optional(),
	city: Joi.string().min(5).max(100).required(),
	postalCode: Joi.string().min(5).max(10).required(),
	state: Joi.string().min(5).max(100).required(),
	country: Joi.string().min(2).max(2).required(),
}

export const addPaymentMethodRequestSchema = {
	userId: uuidIdSchema.required(),
	paymentMethod: Joi.object({
		holderName: Joi.string().min(3).max(100).required(),
		billingAddress: Joi.object(billingAddressSchema).required(),
		providerPaymentMethodId: Joi.string().optional(),
	}).required(),
}

export const deletePaymentMethodRequestSchema = Joi.object({
	id: uuidIdSchema.required(),
})
