import { Alert, Button, Modal, Spin } from 'antd'
import { InlineLoading } from '../../Loading/Loading'
import Message from './ChatMessage/Message'
import React, { MutableRefObject, useCallback, useMemo, useState } from 'react'
import { ChatStatus } from '@neuron/types/chat'
import { useChatContext } from '../../../hooks/context/ChatContext'
import ChatDropzone from '../ChatDropzone/ChatDropzone'
import { useDebounced } from '../../../utils/helpers'
import StartCard from './StartCard/StartCard'
import { useAttachFiles } from '../hooks'
import { PublicMessage, PublicSession } from '../../../types/Chat'
import MessageInfo from './ChatMessage/MessageInfo/MessageInfo'
import styles from './AiMessages.module.scss'

type Props = {
	messages: PublicMessage[]
	handleScroll: (e?: any) => void
	scrollableContainerRef: MutableRefObject<any>
	chatStatus: ChatStatus | undefined
	scrollToBottom: () => void
	resendMessage?: (retryMessage: PublicMessage) => Promise<void>
	cancelMessage?: (messageId: string) => Promise<void>
	deleteAssistantMessage?: (deleteMessage: PublicMessage) => Promise<void>
}

type MessagesAlert = {
	message: string
	content: string
	type: 'success' | 'info' | 'warning' | 'error'
}

const AiMessages = ({
	handleScroll,
	scrollableContainerRef,
	chatStatus,
	messages,
	resendMessage,
	cancelMessage,
	deleteAssistantMessage,
	scrollToBottom,
}: Props) => {
	const debounced = useDebounced()
	const attachFiles = useAttachFiles()

	const chatContext = useChatContext()
	const isAssistant = chatContext.useSubscribe((context) => context.chat?.isAssistant)
	const currentChatSession = chatContext.useSubscribe((context) => context.currentChatSession)

	const [messageInfo, setMessageInfo] = useState<PublicMessage | undefined>()
	const [hideMessagesAlert, setHideMessagesAlert] = useState<boolean>(false)

	const getMessagesAlert = (session: PublicSession | undefined): MessagesAlert | undefined => {
		if (!isAssistant || !session || hideMessagesAlert) {
			return
		}
		if (session.provider === 'playground') {
			if (session.error) {
				return {
					message: 'Conversation error',
					content:
						'An error occurred while generating the response. If the bug is fixable, ' +
						'system will resolve this conversation soon. You can still write new messages but ' +
						'the Persona may have a problem with generating new messages. You can try to start new conversation.',
					type: 'warning',
				}
			}
			if (session.ended) {
				return {
					message: 'Conversation is completed',
					content:
						'This conversation has already been completed by the AI Persona. Persona goal was reached. ' +
						'You can still write new messages, but the Persona will no longer respond to them.',
					type: 'info',
				}
			}
			if (session.redirected) {
				return {
					message: 'Conversation is redirected',
					content:
						'This conversation has already been redirected. ' +
						'You can still write new messages, but the Persona will no longer respond to them.',
					type: 'info',
				}
			}
		}

		if (session.ended) {
			return {
				message: 'Conversation is completed',
				content:
					'This conversation has already been completed by the AI Persona. Persona goal was reached. ' +
					'Further conversation should be conducted manually.',
				type: 'success',
			}
		}
		if (session.error) {
			return {
				message: 'Conversation error',
				content:
					'An error occurred while generating the response. If the bug is fixable, ' +
					'system will resolve this conversation soon. You can still reply to messages in this conversation manually.',
				type: 'warning',
			}
		}
		if (session.userActivityTimeout) {
			return {
				message: 'Conversation timeout',
				content:
					"This conversation has expired, the user's last activity was at least 24 hours ago. " +
					'Until the user writes a message in this conversation, Persona AI messages will be skipped.',
				type: 'warning',
			}
		}
	}

	const onSetMessageInfo = (message: PublicMessage) => {
		setMessageInfo(message)
	}

	const onClearMessageInfo = () => {
		setMessageInfo(undefined)
	}

	const messageInfoModal = useMemo(() => {
		if (messageInfo) {
			return (
				<Modal
					destroyOnClose={false}
					footer={null}
					title='Message information'
					open={!!messageInfo}
					onCancel={onClearMessageInfo}
				>
					<div>
						<MessageInfo publicMessage={messageInfo} />
					</div>
					<div className={styles.closeModalButton}>
						<Button type='primary' onClick={onClearMessageInfo}>
							Close
						</Button>
					</div>
				</Modal>
			)
		} else {
			return <></>
		}
	}, [messageInfo])

	const findLastMessageId = useMemo(
		() => messages[messages.length - 1]?.parentMessageId ?? messages[messages.length - 1]?.id,
		[messages],
	)
	const reversedMessagesWithParents = useMemo(
		() => messages.filter((message) => message.parentMessageId).reverse(),
		[messages],
	)
	const messagesAlert = useMemo(() => getMessagesAlert(currentChatSession), [currentChatSession])

	const getLestMessageChildren = useCallback(
		(message: PublicMessage) => {
			return reversedMessagesWithParents.find(
				(messageWithParent) => messageWithParent.parentMessageId === message.id,
			)
		},
		[reversedMessagesWithParents],
	)

	return useMemo(() => {
		return (
			<div
				className={styles.container}
				onDragOver={() => debounced(() => chatContext.setShowChatDropzone(true), 5)}
				onDragLeave={() => debounced(() => chatContext.setShowChatDropzone(false), 50)}
				onMouseLeave={() => debounced(() => chatContext.setShowChatDropzone(false), 50)}
			>
				{!isAssistant && <ChatDropzone />}
				{messageInfoModal}

				{messagesAlert && (
					<Alert
						className={styles.messagesAlert}
						message={messagesAlert.message}
						description={messagesAlert.content}
						type={messagesAlert.type}
						closable
						onClose={() => setHideMessagesAlert(true)}
					/>
				)}

				<div
					ref={scrollableContainerRef}
					onScroll={handleScroll}
					className={styles.messagesContainer}
				>
					<Spin
						wrapperClassName={styles.messagesWrapper}
						className={styles.messagesLoading}
						indicator={<InlineLoading size={42} />}
						spinning={chatStatus !== 'active' || !messages.length}
					>
						<div className={styles.messages}>
							{messages.map((message) => (
								<Message
									key={message.id}
									message={message}
									attachFilesToMessage={attachFiles.attachFilesToMessage}
									resendMessage={resendMessage}
									cancelMessage={cancelMessage}
									deleteAssistantMessage={deleteAssistantMessage}
									scrollToBottom={scrollToBottom}
									onSetMessageInfo={onSetMessageInfo}
									lastMessageChildren={getLestMessageChildren(message)}
									isLastMessage={message.id === findLastMessageId}
								/>
							))}

							{!isAssistant && messages.length === 1 && <StartCard />}
						</div>
					</Spin>
				</div>
			</div>
		)
	}, [messages, chatStatus, currentChatSession, isAssistant, messageInfo])
}

export default AiMessages
