import { App, PaginationProps, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { AccountsFilter, AdminAccount, Company } from '@neuron/types/account'
import { ChangeDate } from '@neuron/types/global'
import AccountForm from './components/AccountForm/AccountForm'
import { useAccountContext } from '../../../../hooks/context/AccountContext'
import UserTableColumns from './components/UserTableColumns/UserTableColumns'

type Props = {
	currentUserId?: string
	users: AdminAccount[]
	filters: AccountsFilter
	total: number
	previewAccount: (id: string) => void
	blockAccount: (id: string) => void
	deleteAccount: (id: string) => void
	viewUserOffer: (id: string) => void
	selectedAccountIds: string[]
	setPagination: (newPage?: number, newPerPage?: number) => void
	setSelectedAccounts: (accountIds: string[]) => void
	updateAccount: (account: AdminAccount) => void
}

const UsersTable = ({
	currentUserId,
	users,
	filters,
	total,
	previewAccount,
	blockAccount,
	deleteAccount,
	viewUserOffer,
	selectedAccountIds,
	setPagination,
	setSelectedAccounts,
	updateAccount,
}: Props) => {
	const { message } = App.useApp()

	const accountContext = useAccountContext()
	const isAdmin = accountContext.useSubscribe((context) => context.roles.includes('admin'))

	const [windowHeight, setWindowHeight] = useState(window.innerHeight)

	useEffect(() => {
		const handleResize = () => {
			setWindowHeight(window.innerHeight)
		}

		window.addEventListener('resize', handleResize)
		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	const rowSelection = {
		onChange: (selectedRowKeys: React.Key[]) => {
			setSelectedAccounts(selectedRowKeys as string[])
		},
	}

	const copyText = async (text: string, messageContent: string) => {
		await navigator.clipboard.writeText(text)

		message.open({
			type: 'success',
			content: messageContent,
		})
	}

	const columns = UserTableColumns({
		previewAccount,
		viewUserOffer,
		blockAccount,
		deleteAccount,
		isAdmin,
		currentUserId,
		copyText,
	})

	return (
		<Table
			rowSelection={{
				type: 'checkbox',
				...rowSelection,
				selectedRowKeys: selectedAccountIds,
				selections: [Table.SELECTION_ALL, Table.SELECTION_NONE],
			}}
			expandable={{
				expandedRowRender: (item: any) => (
					<AccountForm
						account={{
							...item,
							id: item.key,
						}}
						updateAccount={updateAccount}
					/>
				),
			}}
			pagination={{
				pageSize: filters.perPage,
				total,
				current: filters.page,
				onChange: (page: number, pageSize: number) => setPagination(page, pageSize),
				showTotal,
			}}
			columns={columns}
			dataSource={accountsToTableData(users)}
			scroll={{ y: windowHeight - 366 }}
		/>
	)
}

const showTotal: PaginationProps['showTotal'] = (total) => `Total ${total} accounts`

const accountsToTableData = (
	accounts: AdminAccount[],
): Array<AccountTableData & { key: string }> => {
	return accounts.map((account) => ({
		key: account.id,
		email: account.email,
		firstName: account.firstName,
		lastName: account.lastName,
		phone: account.phone,
		company: account.company,
		active: account.blocked ? false : account.active,
		roles: account.roles,
		createdAt: account.createdAt,
		hasNote: !!account.note?.length,
		note: account.note,
		brokerEmail: account.brokerEmail,
		blocked: account.blocked,
	}))
}

export interface AccountTableData {
	email: string
	firstName: string
	lastName: string
	phone: string
	company?: Company
	roles: string[]
	active: boolean
	hasNote: boolean
	note?: string
	brokerEmail?: string
	createdAt: Date
	blocked?: ChangeDate
}

export default UsersTable
