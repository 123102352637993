import Joi from 'joi'
import { usingUserIdSchema, uuidIdSchema } from '../../global'
import {
	MESSAGING_INTEGRATION_PROVIDERS,
	MESSAGE_REACTIONS,
} from '@neuron/types/integration/messaging'
import {
	CONVERSATION_MESSAGE_CATEGORIES,
	SALES_STAGES,
} from '@neuron/types/assistant/conversationMessage'

const messagingIntegrationConfigSchema = Joi.object({
	intelligentResponseTime: Joi.boolean().required(),
	intelligentWritingTime: Joi.boolean().required(),
	activeLabels: Joi.array().items(Joi.string().required()).allow(null).max(10).optional(),
	signature: Joi.string().max(10000).allow(null).optional(),
	excludeMessageDescriptions: Joi.array()
		.items(Joi.string().min(6).max(500).required())
		.allow(null)
		.max(5)
		.optional(),
})

const messagingIntegrationReactionsSchema = Joi.object({
	instagramCommentConversation: Joi.object({
		onlyMentions: Joi.boolean().optional(),
		startConversationMessages: Joi.array()
			.items(Joi.string().max(500).min(6).required())
			.max(5)
			.optional(),
	}),
})

export const getMessagingIntegrationRequestSchema = {
	id: uuidIdSchema.required(),
}

export const filterUserMessagingIntegrationsRequestSchema = {
	providers: Joi.array()
		.items(
			Joi.string()
				.valid(...MESSAGING_INTEGRATION_PROVIDERS)
				.required(),
		)
		.optional(),
	...usingUserIdSchema,
}

export const getMessagingIntegrationsForAssistantRoleRequestSchema = {
	assistantRoleId: uuidIdSchema.required(),
	...usingUserIdSchema,
}

export const createMessagingIntegrationRequestSchema = {
	name: Joi.string().min(3).max(120).required(),
	provider: Joi.string()
		.valid(...MESSAGING_INTEGRATION_PROVIDERS)
		.required(),

	reactions: messagingIntegrationReactionsSchema.optional(),
	config: messagingIntegrationConfigSchema.required(),

	accessCode: Joi.string().optional(),
	...usingUserIdSchema,
}

export const createPlaygroundIntegrationRequestSchema = {
	name: Joi.string().min(3).max(120).required(),
	userId: uuidIdSchema.required(),
}

export const updateMessagingIntegrationRequestSchema = {
	id: uuidIdSchema.required(),
	name: Joi.string().min(3).max(120).optional(),
	provider: Joi.string()
		.valid(...MESSAGING_INTEGRATION_PROVIDERS)
		.optional(),

	reactions: messagingIntegrationReactionsSchema.optional(),
	config: messagingIntegrationConfigSchema.optional(),

	accessCode: Joi.string().optional(),
	removeCredentials: Joi.boolean().optional(),
}

export const deleteMessagingIntegrationRequestSchema = {
	id: uuidIdSchema.required(),
}

export const retryProviderMessageSendRequestSchema = {
	conversationMessageId: uuidIdSchema.required(),
}

export const publishProviderMessageRequestSchema = {
	conversationMessageId: uuidIdSchema.required(),
}

export const triggerConversationResponseRequestSchema = {
	conversationId: uuidIdSchema.required(),
}

export const onMessagingIntegrationActionSchema = {
	integrationId: uuidIdSchema.required(),
	action: Joi.alternatives()
		.try(
			Joi.object({
				type: Joi.string().valid('newUserMessage', 'newAssistantMessage').required(),
				userId: uuidIdSchema.required(),
				content: Joi.string().required(),
				subject: Joi.string().allow(null, '').optional(),
				conversationId: uuidIdSchema.allow(null).optional(),
				providerConversationId: Joi.string().allow(null).optional(),
				providerMessageId: Joi.string().optional(),
				userProviderId: Joi.string().optional(),
				assistantProviderId: Joi.string().optional(),
				assistantRoleId: uuidIdSchema.optional(),
			}),
			Joi.object({
				type: Joi.string().valid('startCommentConversation').required(),
				comment: Joi.string().max(2000).required(),
				userProviderId: Joi.string().required(),
				assistantProviderId: Joi.string().required(),
				startConversationMessage: Joi.string().max(500).min(6).optional(),
			}),
			Joi.object({
				type: Joi.string().valid('startLeadConversationAction').required(),
				templateId: uuidIdSchema.required(),
				lead: Joi.object({
					id: uuidIdSchema.required(),
					email: Joi.string().allow('').optional(),
					phone: Joi.string().allow('').optional(),
					firstname: Joi.string().max(50).allow('').optional(),
					gender: Joi.string().valid('male', 'female').allow('').optional(),
					note: Joi.string().allow('').optional(),
				}).required(),
				publishAt: Joi.date().optional(),
			}),
			Joi.object({
				type: Joi.string().valid('typingResponse').required(),
				conversationId: uuidIdSchema.required(),
				markSeen: Joi.boolean().optional(),
			}),
			Joi.object({
				type: Joi.string().valid('response').required(),
				userId: uuidIdSchema.required(),
				conversationId: uuidIdSchema.required(),
				remind: Joi.boolean().optional(),
				manual: Joi.boolean().optional(),
			}),
			Joi.object({
				type: Joi.string().valid('setResponseTime').required(),
				conversationId: uuidIdSchema.required(),
				responseTime: Joi.string().required(),
			}),
			Joi.object({
				type: Joi.string().valid('userMessageReaction').required(),
				conversationId: uuidIdSchema.required(),
				messageId: uuidIdSchema.required(),
				reaction: Joi.string()
					.valid(...MESSAGE_REACTIONS)
					.required(),
			}),
			Joi.object({
				type: Joi.string().valid('updateAssistantMessage').required(),
				userId: uuidIdSchema.required(),
				conversationId: uuidIdSchema.required(),
				messageId: uuidIdSchema.required(),
				content: Joi.string().required(),
				redirectReason: Joi.string().allow(null).optional(),
				summary: Joi.string().allow(null).optional(),
				category: Joi.string()
					.valid(...CONVERSATION_MESSAGE_CATEGORIES)
					.optional(),
				salesStage: Joi.string()
					.valid(...SALES_STAGES)
					.optional(),
				error: Joi.object().optional(), // TODO: Type me
				processing: Joi.boolean().optional(),
			}),
		)
		.required(),
}
