import React from 'react'
import { Button, Layout, Tag, Tooltip } from 'antd'
import { formatDate } from '@neuron/utils/dates'
import { SyncOutlined, EditOutlined, FormOutlined } from '@ant-design/icons'
import { Template } from '@neuron/types/leadsGroup/template'
import CardItem from '../../../../../components/CardItem/CardItem'
import CardTitle from '../../../../../components/CardTitle/CardTitle'
import EmptyData from '../../../../../components/EmptyData/EmptyData'
import { templateStatusToColor } from '../../../../../utils/helpers'
import styles from './TemplateList.module.scss'

const { Header } = Layout

type Props = {
	templates: Template[]
	onLoadTemplates: () => Promise<void>
	onCreateNewTemplate: () => void
	deleteTemplates: (ids: string[]) => void
	onEditTemplate: (id: string) => void
}

const TemplateList = ({
	templates,
	onLoadTemplates,
	onCreateNewTemplate,
	deleteTemplates,
	onEditTemplate,
}: Props) => {
	return (
		<div className={styles.container}>
			<Header className={styles.header}>
				<div className={styles.headerElements}>
					<Button icon={<FormOutlined />} onClick={onCreateNewTemplate}>
						Create new template
					</Button>
				</div>

				<Tooltip trigger={['hover', 'focus']} title='Refresh'>
					<Button
						className={styles.headerRefresh}
						onClick={onLoadTemplates}
						shape='circle'
						icon={<SyncOutlined />}
					/>
				</Tooltip>
			</Header>

			<div className={styles.templates}>
				{templates.map((template) => (
					<CardItem
						key={template.id}
						itemId={template.id}
						cardTitle={
							<CardTitle
								id={template.id}
								name={template.name}
								onDelete={() => deleteTemplates([template.id])}
							/>
						}
						labels={[
							{
								label: 'Status',
								value: <Tag color={templateStatusToColor[template.status]}>{template.status}</Tag>,
							},
							{
								label: 'Provider',
								value: <Tag>{template.provider}</Tag>,
							},
							{
								label: 'Created date',
								value: formatDate(template.createdAt),
							},
						]}
						actions={[
							<Tooltip key='edit' trigger={['hover', 'focus']} title='Edit template'>
								<EditOutlined
									onClick={(e) => {
										e.stopPropagation()
										onEditTemplate(template.id)
									}}
								/>
							</Tooltip>,
						]}
					/>
				))}

				{!templates.length && (
					<EmptyData onClick={onCreateNewTemplate} description='Empty template list' />
				)}
			</div>
		</div>
	)
}

export default TemplateList
