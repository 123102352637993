import React, { useEffect, useRef, useState } from 'react'
import { Accept } from 'react-dropzone'
import { FileContent, Tab } from '../../../../types/AiContext'
import FileFormats from '../../../../components/FileFormats/FileFormats'
import fileFormatMapper from '../../../../utils/fileFormatMapper'
import { FileInfo } from '@neuron/types/fileInfo'
import { CloseOutlined } from '@ant-design/icons'
import useAsyncEffect from '../../../../hooks/useAsyncEffect'
import query from '../../../../utils/query'
import { App } from 'antd'
import Loading from '../../../../components/Loading/Loading'
import useTryCatch from '../../../../hooks/useTryCatch'
import UploadFileWrapper, {
	UploadFileRef,
} from '../../../../components/UploadFileWrapper/UploadFileWrapper'
import { contentTypeToExtension } from '@neuron/utils'
import { SourceType } from '@neuron/types/context'
import PreviewTranscription from '../components/PreviewTranscription/PreviewTranscription'
import styles from './UploadContextFile.module.scss'

type Props = {
	content: FileContent
	updateTab: (newTab: Partial<Tab>) => void
	createNewTab: (type: SourceType, tab: Partial<Tab>) => void
}

const UploadContextFile = ({ content, updateTab, createNewTab }: Props) => {
	const { message } = App.useApp()
	const tryCatch = useTryCatch(message)

	const uploadFileRef = useRef<UploadFileRef>(null)

	const [transcription, setTranscription] = useState<string>('')
	const [loading, setLoading] = useState<boolean>(false)
	const [fileInfo, setFileInfo] = useState<FileInfo>()
	const [acceptTypes, setAcceptTypes] = useState<Accept>(contentTypeToExtension)

	useEffect(() => {
		if (Object.keys(acceptTypes).length !== Object.keys(contentTypeToExtension).length) {
			// Open file modal when acceptTypes was updated
			uploadFileRef.current?.openFileModal()
			setAcceptTypes(contentTypeToExtension)
		}
	}, [acceptTypes])

	useAsyncEffect(async () => {
		if (!content.fileId) {
			setFileInfo(undefined)
			setTranscription('')
			return
		}

		setLoading(true)

		await tryCatch(async () => {
			const fileInfo = await query<FileInfo>('/file/get', 'GET', {
				params: {
					id: content.fileId,
				},
				withCredentials: true,
			})

			setFileInfo(fileInfo)
			setTranscription(fileInfo.transcription ?? '')
		})

		setLoading(false)
	}, [content.fileId])

	const onDrop = (acceptedFiles: File[]) => {
		acceptedFiles.forEach((file, index) => {
			if (index === 0) {
				updateTab({ name: file.name, content: { file } })
			} else {
				createNewTab('file', { name: file.name, content: { file } })
			}
		})
	}

	const setAcceptFileTypes = (types: string | string[]) => {
		if (typeof types === 'string') {
			setAcceptTypes({
				[types]: [],
			})
		} else {
			const newAcceptTypes: Accept = types.reduce((acc: Accept, type) => {
				acc[type] = []
				return acc
			}, {})

			setAcceptTypes(newAcceptTypes)
		}
	}

	const deleteFile = (e: any) => {
		e.stopPropagation()
		updateTab({ content: { file: null } })
	}

	const fileFormatData = fileFormatMapper(content.file?.type || fileInfo?.contentType)

	if (loading) {
		return (
			<div className={styles.uploadContainer}>
				<Loading />
			</div>
		)
	}

	return (
		<div className={styles.uploadContainer}>
			<UploadFileWrapper
				className={styles.uploadFileWrapper}
				ref={uploadFileRef}
				acceptTypes={acceptTypes}
				maxFiles={10}
				onUpload={onDrop}
			>
				{fileFormatData ? (
					<div className={styles.uploadedFile}>
						<div className={styles.previewFile}>
							<CloseOutlined onClick={deleteFile} className={styles.deleteFileIcon} />
							<img className={styles.fileIcon} src={fileFormatData.icon} alt='File icon' />
						</div>

						<div className={styles.filename}>
							{content.file?.name || fileInfo?.filename || 'Missing file name'}
						</div>
					</div>
				) : (
					<div className={styles.emptyFileContent}>
						<div className={styles.updateFileInfoHeading}>Upload the selected source files</div>
						<div className={styles.supportFiles}>
							We support most of the most popular formats of text files, documents, images, photos,
							audio and video files.
						</div>
						<div className={styles.fileLimits}>
							Up to 10 files each with a maximum weight of 10mb can be uploaded at one time.
						</div>

						<FileFormats onClick={setAcceptFileTypes} />
					</div>
				)}
			</UploadFileWrapper>

			<div className={styles.previewTranscriptionWrapper}>
				<PreviewTranscription
					fileInfo={fileInfo}
					setTranscription={setTranscription}
					transcription={transcription}
				/>
			</div>
		</div>
	)
}

export default UploadContextFile
