import { ChangeDate } from '../global'
import { AssistantRoleType } from '../assistant/assistantRole'

export interface Account {
	id: string
	idProvider?: IdentityProvider
	firstName: string
	lastName: string
	email: string
	phone: string
	company?: Company
	password: string // password token
	roles: Array<string | GlobalAccountRole>
	offer: Offer
	active: boolean
	modals: AccountModals
	paymentProviderId?: string
	brokerEmail?: string
	reflinkCode?: string
	note?: string
	blocked?: ChangeDate
	createdAt: Date
}

export type Offer = {
	orderIds: string[]
	availablePersonas: number
	availableRoles: AssistantRoleType[]
}

export type Company = {
	name: string
	taxId: string
	country: string
	address: string
	province: string
	city: string
	postalCode: string
}

export type AdminAccount = Omit<Account, 'idProvider' | 'password'>

export const MODALS = ['welcomeModal', 'chatTour'] as const
export type Modal = (typeof MODALS)[number]

export type LoginRequest = {
	email: string
	password: string
}

export type PublicAccount = Pick<
	Account,
	| 'id'
	| 'firstName'
	| 'lastName'
	| 'email'
	| 'active'
	| 'brokerEmail'
	| 'roles'
	| 'offer'
	| 'modals'
	| 'company'
>

export type TokenInfo = PublicAccount & {
	tokenId: string
}

type AccountModal = {
	show: boolean
	lastShowedAt?: Date
}

export type AccountModals = Partial<Record<Modal, AccountModal>>

export const BROKER_ROLES = ['broker', 'senior.broker', 'master.broker'] as const
export const GLOBAL_ACCOUNT_ROLES = [
	'admin',
	'god',
	'auditor',
	'onboarding',
	...BROKER_ROLES,
] as const
export type GlobalAccountRole = (typeof GLOBAL_ACCOUNT_ROLES)[number]

export const IDENTITY_PROVIDERS = ['facebook', 'google', 'apple'] as const
export type IdentityProvider = (typeof IDENTITY_PROVIDERS)[number]

export type BrokerAccount = {
	id: string
	level: number
	email: string
	firstName: string
	lastName: string
	phone: string
	company?: Company
	active: boolean
	brokerRole: string | undefined
	commission: number
	purchaseAmount: number
	profit: number
	children?: BrokerAccount[]
}

// export type IdProviderUser = {
// 	email: string
// 	verified: boolean
// }

export type AccountPayload = Omit<Account, 'id' | 'createdAt' | 'modals' | 'offer'>

export type UpdateModalRequest = {
	modalName: Modal
	show: boolean
}

export type AdminUpdateAccountRequest = {
	id: string
	note?: string
	brokerEmail?: string
	roles?: string[]
}

export type UpdateAccountDetailsRequest = Company

export type ActivateAccountRequest = {
	userId: string
}

export type BlockAccountRequest = {
	userId: string
}

export type DeleteAccountRequest = {
	userId: string
}

export type GetAccountOfferRequest = {
	userId: string
}

export interface CreateAccountRequest {
	email: string
	password: string
	firstName: string
	lastName: string
	phone: string
	brokerEmail?: string
	reflinkCode?: string
}

export interface AccountsFilter {
	accountIds?: string
	email?: string
	role?: string
	skipBlocked?: boolean

	page?: number
	perPage?: number
}

export type AccountsSortParams = {
	order?: 'DESC' | 'ASC'
	sortField?: keyof Account
}

export type FilterAccountsResponse = {
	data: AdminAccount[]
	count: number
	page: number
	perPage: number
}
