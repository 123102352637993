import React, { useEffect, useState } from 'react'
import { CloseOutlined, InboxOutlined } from '@ant-design/icons'
import UploadFileWrapper from '../../../../../components/UploadFileWrapper/UploadFileWrapper'
import fileFormatMapper from '../../../../../utils/fileFormatMapper'
import { PublicProgressStep } from '@neuron/types/progressSteps'
import { Button, Progress } from 'antd'
import Loading from '../../../../../components/Loading/Loading'
import styles from './CsvFileUploader.module.scss'

type Props = {
	uploadCsv: (file: File) => Promise<void>
	csvProgressStep?: PublicProgressStep
	onClose: (refresh?: boolean) => Promise<void>
}

const CsvFileUploader = ({ uploadCsv, csvProgressStep, onClose }: Props) => {
	const [loading, setLoading] = useState<boolean>(false)
	const [file, setFile] = useState<File>()

	useEffect(() => {
		if (csvProgressStep) {
			setLoading(false)
		}
	}, [!!csvProgressStep])

	const onSubmitCsv = async () => {
		if (file) {
			setLoading(true)
			await uploadCsv(file)
		}
	}

	if (loading) {
		return (
			<div className={styles.loadingCsvFile}>
				<Loading size={48} />
				<div className={styles.loadingText}>Loading CSV file...</div>
			</div>
		)
	}

	if (csvProgressStep && file) {
		return (
			<div className={styles.fileWrapper}>
				<div className={styles.fileIconWrapper}>
					<img
						className={styles.fileIcon}
						src={fileFormatMapper('text/csv')!.icon}
						alt='CSV file icon'
					/>
				</div>

				<div className={styles.filename}>{file.name}</div>

				<div className={styles.heading}>{csvProgressStep.currentStepName}</div>

				<Progress
					percent={
						csvProgressStep.currentStepPercent
							? +csvProgressStep.currentStepPercent.slice(0, -1)
							: 0
					}
					status='active'
					strokeColor={{ from: '#108ee9', to: '#87d068' }}
				/>
			</div>
		)
	}

	return (
		<div className={styles.container}>
			<UploadFileWrapper
				className={styles.uploadFileWrapper}
				maxFiles={1}
				onUpload={(files) => setFile(files[0])}
				acceptTypes={{ 'text/csv': ['.csv'] }}
			>
				<div className={styles.dropzoneContent}>
					{file ? (
						<div className={styles.fileWrapper}>
							<div className={styles.fileIconWrapper}>
								<CloseOutlined
									onClick={(e) => {
										e.stopPropagation()
										setFile(undefined)
									}}
									className={styles.deleteFileIcon}
								/>
								<img
									className={styles.fileIcon}
									src={fileFormatMapper('text/csv')!.icon}
									alt='CSV file icon'
								/>
							</div>
							<div className={styles.filename}>{file.name}</div>
						</div>
					) : (
						<>
							<InboxOutlined className={styles.dropzoneIcon} />
							<div className={styles.heading}>
								Click to upload CSV file or drag and drop it here
							</div>
							<div className={styles.contentInfo}>
								You can upload one file up to 100mb. The CSV file should have at least one field
								containing a phone number or email address.
							</div>
						</>
					)}
				</div>
			</UploadFileWrapper>

			<div className={styles.buttons}>
				<Button disabled={!file} size='large' onClick={onSubmitCsv} type='primary'>
					Submit
				</Button>
				<Button size='large' onClick={() => onClose()}>
					Cancel
				</Button>
			</div>
		</div>
	)
}

export default CsvFileUploader
