import React, { useEffect, useState } from 'react'
import { useAccountContext } from '../../hooks/context/AccountContext'
import CompanyDetails from './CompanyDetails/CompanyDetails'
import WaitingForOffer from './WaitingForOffer/WaitingForOffer'
import TrainingPayment from './TrainingPayment/TrainingPayment'
import Appointment from './Appointment/Appointment'
import query from '../../utils/query'
import { App } from 'antd'
import useTryCatch from '../../hooks/useTryCatch'
import { Order } from '@neuron/types/order'
import useAsyncEffect from '../../hooks/useAsyncEffect'
import Loading from '../../components/Loading/Loading'
import { useNavigate } from 'react-router-dom'

const Onboarding = () => {
	const navigate = useNavigate()

	const { message } = App.useApp()
	const tryCatch = useTryCatch(message)

	const accountContext = useAccountContext()
	const company = accountContext.useSubscribe((account) => account.company)
	const accountId = accountContext.useSubscribe((account) => account.id)
	const brokerEmail = accountContext.useSubscribe((account) => account.brokerEmail)
	const activeAccount = accountContext.useSubscribe((account) => account.active)

	const [orders, setOrders] = useState<Order[]>([])
	const [loading, setLoading] = useState(true)

	const onLoadOrders = async () => {
		setLoading(true)
		await tryCatch(async () => {
			const data = await query<Order[]>('/order/user/get', 'GET', {
				withCredentials: true,
			})
			setOrders(data)
		})
		setLoading(false)
	}

	useAsyncEffect(onLoadOrders, [])

	useEffect(() => {
		if (accountId && activeAccount) {
			navigate('/')
		}
	}, [activeAccount, accountId])

	if (loading) {
		return <Loading />
	}

	if (!company) {
		return <CompanyDetails />
	}

	if (!brokerEmail) {
		return <Appointment />
	}

	const hasActiveTrainingOrder = orders.find(
		(order) =>
			order.status === 'active' && order.products.some((product) => product.type === 'training'),
	)

	if (hasActiveTrainingOrder) {
		return <TrainingPayment order={hasActiveTrainingOrder} reloadOrders={onLoadOrders} />
	}

	return <WaitingForOffer />
}

export default Onboarding
