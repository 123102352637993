import { CloseOutlined, ExportOutlined } from '@ant-design/icons'
import { App, Button, Form, Input, Tag } from 'antd'
import {
	GOOGLE_DOCS_URL_REGEX,
	GOOGLE_SHEETS_URL_REGEX,
	STRICT_URL_REGEX,
} from '@neuron/utils/consts'
import { MAX_CONTEXT_NAME_LENGTH, MAX_URL_LENGTH } from '@neuron/types/context'
import React, { useEffect, useMemo, useState } from 'react'
import Loading from '../Loading/Loading'
import { LinkContent } from '../../types/AiContext'
import useTryCatch from '../../hooks/useTryCatch'
import {
	DatabaseIntegration,
	DatabaseIntegrationProvider,
} from '@neuron/types/integration/database'
import { useAccountContext } from '../../hooks/context/AccountContext'
import { GoogleOAuthProvider } from '@react-oauth/google'
import query from '../../utils/query'
import env from '../../boot/env'
import useAsyncEffect from '../../hooks/useAsyncEffect'
import GoogleSheetsUrl from './GoogleSheetsUrl/GoogleSheetsUrl'
import GoogleDocsUrl from './GoogleDocsUrl/GoogleDocsUrl'
import styles from './ContextUrlForm.module.scss'

type Props = {
	linkContent: LinkContent
	updateLinkContent: (linkContent: LinkContent) => void
}

const ContextUrlForm = ({ linkContent, updateLinkContent }: Props) => {
	const { message } = App.useApp()
	const tryCatch = useTryCatch(message)
	const [form] = Form.useForm()

	const [processingIntegrationLoading, setProcessingIntegrationLoading] = useState<boolean>(false)
	const [defaultIntegration, setDefaultIntegration] = useState<DatabaseIntegration | undefined>(
		undefined,
	)
	const [isFetchingIntegrations, setIsFetchingIntegrations] = useState<boolean>(false)
	const [hasFetchedIntegrations, setHasFetchedIntegrations] = useState<boolean>(false)

	const accountContext = useAccountContext()
	const usingUserId = accountContext.useSubscribe((account) => account.usingUserId)

	useAsyncEffect(async () => {
		if (linkContent.databaseIntegrationId || !!getDatabaseProviderByUrl(linkContent.url)) {
			await fetchUserIntegrations()
		}
	}, [
		!!getDatabaseProviderByUrl(linkContent.url),
		linkContent.databaseIntegrationId,
		hasFetchedIntegrations,
		isFetchingIntegrations,
	])

	useEffect(() => {
		form.resetFields()
	}, [linkContent.isValidUrl, linkContent.databaseIntegrationId, defaultIntegration])

	const showAuthorizationErrorMessage = async () => {
		message.open({
			type: 'warning',
			content:
				'It seems that there is a problem with integration setup. Please try to authorize the integration again',
		})
	}

	const createDatabaseIntegration = async (
		provider: DatabaseIntegrationProvider,
		accessCode: string,
	) => {
		await tryCatch(async () => {
			setProcessingIntegrationLoading(true)

			const databaseIntegration = await query<DatabaseIntegration>(
				'/integration/database/create',
				'POST',
				{
					data: {
						provider,
						accessCode,
						usingUserId,
					},
					timeout: 1000 * 60 * 2,
					withCredentials: true,
				},
			)

			const isValidUrl = validLink(linkContent.url)

			updateLinkContent({
				...linkContent,
				isValidUrl,
				databaseIntegrationId: databaseIntegration.id,
			})

			setDefaultIntegration(databaseIntegration)
		})

		setProcessingIntegrationLoading(false)
	}

	const fetchUserIntegrations = async () => {
		if (hasFetchedIntegrations || isFetchingIntegrations) {
			return
		}

		setIsFetchingIntegrations(true)

		await tryCatch(async () => {
			const databaseIntegrationProvider = getDatabaseProviderByUrl(linkContent.url)

			const integrations = await query<DatabaseIntegration[]>('/integration/database/user', 'GET', {
				params: {
					providers: [databaseIntegrationProvider],
					usingUserId,
				},
				withCredentials: true,
			})

			setHasFetchedIntegrations(true)
			setIsFetchingIntegrations(false)

			if (integrations && integrations?.length > 0) {
				const isValidUrl = validLink(linkContent.url)

				updateLinkContent({
					...linkContent,
					isValidUrl,
					databaseIntegrationId: integrations[0].id,
				})

				setDefaultIntegration(integrations[0])
			}
		})
	}

	const removeDefaultIntegration = () => {
		updateLinkContent({
			url: linkContent.url,
			isValidUrl: false,
			databaseIntegrationId: undefined,
		})
		setDefaultIntegration(undefined)
	}

	const updateLink = (link: string) => {
		const newContent: LinkContent = {
			url: link,
			isValidUrl: validLink(link),
		}

		const databaseUrlProvider = getDatabaseProviderByUrl(link)
		if (databaseUrlProvider) {
			if (defaultIntegration) {
				newContent.databaseIntegrationId = defaultIntegration.id
			} else {
				newContent.isValidUrl = false
			}
		}

		updateLinkContent(newContent)
	}

	const validLink = (link: string): boolean => {
		if (!link.match(STRICT_URL_REGEX)) {
			return false
		}

		const sheetsMatch = link.match(GOOGLE_SHEETS_URL_REGEX)
		if (sheetsMatch) {
			const sheetId = sheetsMatch[1]
			return !!sheetId
		}
		const docsMatch = link.match(GOOGLE_DOCS_URL_REGEX)
		if (docsMatch) {
			const docId = docsMatch[1]
			return !!docId
		}

		return true
	}

	const authorizationButton = useMemo(() => {
		if (isFetchingIntegrations) {
			return (
				<div>
					<Loading size={24} className={styles.loading} />
				</div>
			)
		}

		if (linkContent.databaseIntegrationId) {
			return <></>
		}

		const isGoogleDocs = !!linkContent.url.match(GOOGLE_DOCS_URL_REGEX)
		const isGoogleSheets = !!linkContent.url.match(GOOGLE_SHEETS_URL_REGEX)

		if (isGoogleSheets) {
			return (
				<GoogleOAuthProvider clientId={env.GOOGLE_SHEETS_CLIENT_ID}>
					<GoogleSheetsUrl
						createDatabaseIntegration={createDatabaseIntegration}
						showAuthorizationErrorMessage={showAuthorizationErrorMessage}
						processingIntegrationLoading={processingIntegrationLoading}
						setProcessingIntegrationLoading={setProcessingIntegrationLoading}
					/>
				</GoogleOAuthProvider>
			)
		}
		if (isGoogleDocs) {
			return (
				<GoogleOAuthProvider clientId={env.GOOGLE_DOCS_CLIENT_ID}>
					<GoogleDocsUrl
						createDatabaseIntegration={createDatabaseIntegration}
						showAuthorizationErrorMessage={showAuthorizationErrorMessage}
						processingIntegrationLoading={processingIntegrationLoading}
						setProcessingIntegrationLoading={setProcessingIntegrationLoading}
					/>
				</GoogleOAuthProvider>
			)
		}
		return <></>
	}, [
		linkContent.url,
		linkContent.databaseIntegrationId,
		processingIntegrationLoading,
		isFetchingIntegrations,
	])

	const integrationInfo = useMemo(() => {
		if (isFetchingIntegrations) {
			return <></>
		}

		const databaseUrlProvider = getDatabaseProviderByUrl(linkContent.url)

		if (databaseUrlProvider && defaultIntegration) {
			return (
				<div className={styles.integrationInfo}>
					<div>
						Authorization account:{' '}
						<Tag className={styles.integrationName}>{defaultIntegration.name}</Tag>
						<Button
							type='text'
							icon={<CloseOutlined className={styles.removeIntegrationIcon} />}
							onClick={removeDefaultIntegration}
							className={styles.removeIntegrationButton}
						/>
					</div>
				</div>
			)
		}

		return <></>
	}, [linkContent.url, defaultIntegration, isFetchingIntegrations])

	const isValidLink = useMemo(() => validLink(linkContent.url), [linkContent.url])
	const isWarningStatus = useMemo(
		() =>
			!isFetchingIntegrations &&
			!linkContent.databaseIntegrationId &&
			!!getDatabaseProviderByUrl(linkContent.url),
		[isFetchingIntegrations, linkContent.url, linkContent.databaseIntegrationId],
	)

	return useMemo(() => {
		return (
			<div className={styles.container}>
				{integrationInfo}

				<a
					className={`${isValidLink ? styles.validUrl : styles.invalidUrl}`}
					target={isValidLink ? '_blank' : undefined}
					href={isValidLink ? linkContent.url : '#'}
					rel='noreferrer'
				>
					<ExportOutlined
						className={`${styles.openLinkIcon} ${isValidLink ? styles.validLinkIcon : ''}`}
					/>
				</a>

				<Form
					form={form}
					layout='vertical'
					className={styles.form}
					initialValues={{ url: linkContent.url }}
				>
					<Form.Item
						name='url'
						label='Website URL'
						className={styles.formItem}
						hasFeedback
						validateStatus={isWarningStatus ? 'warning' : undefined}
						rules={[
							{
								message: 'Website URL must have a minimum of 6 characters',
								type: 'string',
								min: 6,
							},
							{
								message: `Website URL can have a maximum of ${MAX_URL_LENGTH} characters`,
								type: 'string',
								max: MAX_URL_LENGTH,
							},
							() => ({
								validator(_, value) {
									if (isFetchingIntegrations) {
										return Promise.resolve()
									}
									if (value.match(GOOGLE_SHEETS_URL_REGEX) && !linkContent.databaseIntegrationId) {
										return Promise.reject(new Error('Google Spreadsheet requires authorization'))
									}
									if (value.match(GOOGLE_DOCS_URL_REGEX) && !linkContent.databaseIntegrationId) {
										return Promise.reject(new Error('Google Docs requires authorization'))
									}
									if (!linkContent.isValidUrl) {
										return Promise.reject(new Error('Website URL is invalid'))
									}
									return Promise.resolve()
								},
							}),
						]}
					>
						<Input
							minLength={6}
							maxLength={MAX_CONTEXT_NAME_LENGTH}
							className={styles.formInput}
							placeholder='https://quantumneuron.eu'
							onChange={(e) => updateLink(e.target.value)}
						/>
					</Form.Item>
				</Form>

				{authorizationButton}
			</div>
		)
	}, [linkContent, isValidLink, authorizationButton, integrationInfo, isWarningStatus])
}

const getDatabaseProviderByUrl = (url: string): DatabaseIntegrationProvider | undefined => {
	if (url.match(GOOGLE_SHEETS_URL_REGEX)) {
		return 'google-spreadsheet'
	}
	if (url.match(GOOGLE_DOCS_URL_REGEX)) {
		return 'google-docs'
	}
}

export default ContextUrlForm
