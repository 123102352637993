import React, { ReactNode } from 'react'
import {
	MessagingIntegrationCredentials,
	MessagingIntegrationStatus,
} from '@neuron/types/integration/messaging'
import CardItem from '../../../../../components/CardItem/CardItem'
import CardTitle from '../../../../../components/CardTitle/CardTitle'
import { Tag } from 'antd'

type Props = {
	credentials: MessagingIntegrationCredentials
	status: MessagingIntegrationStatus | undefined
	providerIcon: ReactNode
	integrationProviderName: string
	integrationIndex: number
}

const IntegrationCard = ({
	credentials,
	status,
	providerIcon,
	integrationProviderName,
	integrationIndex,
}: Props) => {
	return (
		<CardItem
			key={`${integrationProviderName}-${integrationIndex}`}
			itemId={integrationProviderName}
			cardTitle={
				<CardTitle
					name={
						<span>
							{providerIcon} {integrationProviderName}
						</span>
					}
				/>
			}
			labels={[
				{
					label: 'Id',
					value: credentials.providerIds[integrationIndex],
				},
				{
					label: 'Integration status',
					value:
						status === 'active' ? (
							<Tag color='#5cb85c'>Active</Tag>
						) : (
							<Tag color='#d9534f'>Inactive</Tag>
						),
				},
				{
					label: 'phoneNumbers' in credentials && 'Phone number',
					value: 'phoneNumbers' in credentials && (
						<span>
							{credentials.phoneNumbers[credentials.providerIds[integrationIndex]].phoneNumber}
						</span>
					),
				},
			]}
		/>
	)
}

export default IntegrationCard
