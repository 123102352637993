import React, { useState } from 'react'
import query from '../../../../utils/query'
import { App, Button, Tooltip } from 'antd'
import InlineTextInput from '../../../../components/InlineTextInput/InlineTextInput'
import { DeleteOutlined, EditOutlined, LinkOutlined } from '@ant-design/icons'
import useTryCatch from '../../../../hooks/useTryCatch'
import { InlineLoading } from '../../../Loading/Loading'
import { useChatContext } from '../../../../hooks/context/ChatContext'
import { PublicSession } from '../../../../types/Chat'
import styles from './SessionItem.module.scss'

type Props = {
	currentSessionId: string | null
	session: PublicSession
	deleteSession: (sessionId: string) => Promise<void>
	onSetAttachedSessionIds: (sessionId: string) => void
}

const SessionItem = ({
	currentSessionId,
	session,
	deleteSession,
	onSetAttachedSessionIds,
}: Props) => {
	const { message } = App.useApp()
	const tryCatch = useTryCatch(message)

	const chatContext = useChatContext()
	const isAssistant = chatContext.useSubscribe((context) => context.chat?.isAssistant)

	const [editing, setEditing] = useState<boolean>(false)
	const [editedName, setEditedName] = useState<string | undefined>()
	const [deleteSessionLoading, setDeleteSessionLoading] = useState<boolean>(false)
	const [isAttachedSession, setIsAttachedSession] = useState<boolean>(false)

	const handleSessionNameSubmit = async (event?: React.FormEvent<HTMLFormElement>) => {
		event?.preventDefault()
		if (!editedName || editedName === session.name) {
			setEditedName(undefined)
			setEditing(false)
			return
		}

		await tryCatch(
			async () => {
				await query('/chat/session/update', 'POST', {
					data: {
						id: session.id,
						name: editedName,
					},
					withCredentials: true,
				})

				chatContext.setChatContext({
					chatSessions: chatContext.storeRef.current?.chatSessions.map((s) =>
						s.id === session.id ? { ...session, name: editedName } : s,
					),
				})
				setEditedName(undefined)
				setEditing(false)
			},
			undefined,
			{ message: 'Error while editing session name. Check name and try again.' },
		)
	}

	const onDeleteSession = async (e: any) => {
		e.stopPropagation()
		if (deleteSessionLoading) {
			return
		}

		setDeleteSessionLoading(true)
		await deleteSession(session.id)
		setDeleteSessionLoading(false)
	}

	return (
		<div className={styles.sessionItemContainer}>
			<Button type='text' className={styles.sessionItem}>
				{editing && !isAssistant ? (
					<form onClick={(e) => e.stopPropagation()} onSubmit={handleSessionNameSubmit}>
						<InlineTextInput
							className={styles.sessionNameInput}
							onBlur={() => handleSessionNameSubmit()}
							value={editedName ?? session.name}
							onChange={(e) => setEditedName(e.target.value)}
							autoFocus
						/>
					</form>
				) : (
					<div className={styles.sessionName}>{session.name}</div>
				)}
			</Button>

			<div className={styles.sessionButtons}>
				{!isAssistant && currentSessionId !== session.id && (
					<Tooltip
						trigger={['hover', 'focus']}
						mouseEnterDelay={0.3}
						title='Attach this session messages to your current session'
					>
						<Button
							type='text'
							className={`${styles.iconButton} ${isAttachedSession ? styles.active : ''}`}
							onClick={(e) => {
								e.stopPropagation()
								setIsAttachedSession((isAttached) => !isAttached)
								onSetAttachedSessionIds(session.id)
							}}
						>
							<LinkOutlined />
						</Button>
					</Tooltip>
				)}
				{!isAssistant && (
					<Button
						onClick={(e) => {
							e.stopPropagation()
							setEditing(true)
						}}
						className={styles.iconButton}
						type='text'
					>
						<EditOutlined />
					</Button>
				)}
				<Button onClick={onDeleteSession} className={styles.iconButton} type='text'>
					{deleteSessionLoading ? <InlineLoading size={14} /> : <DeleteOutlined />}
				</Button>
			</div>
		</div>
	)
}

export default SessionItem
