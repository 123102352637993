import React, { useState, useMemo } from 'react'
import { App, Button, Form, Input, Select } from 'antd'
import useTryCatch from '../../../hooks/useTryCatch'
import query from '../../../utils/query'
import { PublicAccount, UpdateAccountDetailsRequest } from '@neuron/types/account'
import { useAccountContext } from '../../../hooks/context/AccountContext'
import Line from '../../../components/Line/Line'
import FormRow from '../../../components/FormRow/FormRow'
import { formValidate } from '../../../utils/validation'
import { updateAccountDetailsRequestSchema } from '@neuron/schemas/account'
import { selectFilterOption } from '../../../utils/helpers'
import styles from './CompanyDetails.module.scss'

import countries from '@neuron/utils/resources/countries.json'

const CompanyDetails = () => {
	const { message } = App.useApp()
	const tryCatch = useTryCatch(message)

	const accountContext = useAccountContext()
	const company = accountContext.useSubscribe((account) => account.company)

	const [formData, setFormData] = useState<Partial<UpdateAccountDetailsRequest>>(company ?? {})

	const updateFormData = (
		_value: Record<string, any>,
		values: Partial<UpdateAccountDetailsRequest>,
	) => {
		setFormData(values)
	}

	const onSaveAccountData = async () => {
		await tryCatch(async () => {
			const account = await query<PublicAccount>('/account/update/company', 'POST', {
				withCredentials: true,
				data: formData,
			})

			accountContext.updateAccount(account)

			message.open({
				type: 'success',
				content: 'Company details saved',
			})
		})
	}

	const countryOptions = useMemo(() => {
		return Object.entries(countries).map(([code, name]) => ({
			label: name,
			value: code,
		}))
	}, [])

	const isValid = useMemo(() => {
		const validate = formValidate(formData, updateAccountDetailsRequestSchema)
		return validate ? !Object.keys(validate).length : true
	}, [formData])

	return (
		<div className={styles.container}>
			<div className={styles.wrapper}>
				<Form
					className={styles.form}
					autoComplete='true'
					initialValues={formData}
					onValuesChange={updateFormData}
					labelCol={{ span: 4 }}
					wrapperCol={{ span: 14 }}
					layout='vertical'
				>
					<h3 className={styles.headingLabel}>Company details</h3>
					<Line />

					<FormRow>
						<Form.Item
							className={styles.formItem}
							label='Company name'
							name='name'
							rules={[
								{ required: true, message: 'Please enter your company name' },
								{ min: 3, message: 'Company name must be at least 3 characters' },
								{ max: 250, message: 'Company name cannot exceed 250 characters' },
							]}
						>
							<Input maxLength={250} size='large' placeholder='Quantum Neuron' />
						</Form.Item>

						<Form.Item
							className={styles.formItem}
							label='Tax ID'
							name='taxId'
							rules={[
								{ required: true, message: 'Please enter your company tax ID' },
								{ min: 1, message: 'Tax ID must be at least 1 character' },
								{ max: 50, message: 'Tax ID cannot exceed 50 characters' },
							]}
						>
							<Input maxLength={50} size='large' placeholder='PL5609...' />
						</Form.Item>
					</FormRow>

					<Form.Item
						className={styles.formItem}
						label='Address'
						name='address'
						rules={[
							{ required: true, message: 'Please enter your company address' },
							{ min: 10, message: 'Address must be at least 10 characters' },
							{ max: 1000, message: 'Address cannot exceed 1000 characters' },
						]}
					>
						<Input maxLength={1000} size='large' placeholder='Company address' />
					</Form.Item>

					<FormRow>
						<Form.Item
							className={styles.formItem}
							label='City'
							name='city'
							rules={[
								{ required: true, message: 'Please enter your company city' },
								{ min: 1, message: 'City must be at least 1 character' },
								{ max: 100, message: 'City cannot exceed 100 characters' },
							]}
						>
							<Input maxLength={100} size='large' placeholder='Company city' />
						</Form.Item>

						<Form.Item
							className={styles.formItem}
							label='Postal code'
							name='postalCode'
							rules={[
								{ required: true, message: 'Please enter your company postal code' },
								{ min: 1, message: 'Postal code must be at least 1 character' },
								{ max: 20, message: 'Postal code cannot exceed 20 characters' },
							]}
						>
							<Input maxLength={20} size='large' placeholder='Company postal code' />
						</Form.Item>
					</FormRow>

					<FormRow columns={[2, 1]}>
						<Form.Item
							className={styles.formItem}
							label='Province'
							name='province'
							rules={[
								{ required: true, message: 'Please enter your company province' },
								{ min: 1, message: 'Province must be at least 1 character' },
								{ max: 100, message: 'Province cannot exceed 100 characters' },
							]}
						>
							<Input maxLength={100} size='large' placeholder='Company province' />
						</Form.Item>

						<Form.Item
							className={styles.formItem}
							label='Country'
							name='country'
							initialValue='PL'
							rules={[{ required: true, message: 'Please select your company country' }]}
						>
							<Select
								showSearch
								size='large'
								filterOption={selectFilterOption}
								placeholder='Select a country'
								options={countryOptions}
							/>
						</Form.Item>
					</FormRow>

					<Button type='primary' size='large' onClick={onSaveAccountData} disabled={!isValid}>
						Save company details
					</Button>
				</Form>
			</div>
		</div>
	)
}

export default CompanyDetails
