export const parseDateToTime = (date: Date, withSeconds?: boolean) => {
	let hours: number | string = date.getHours()
	let minutes: number | string = date.getMinutes()

	hours = hours < 10 ? '0' + hours : hours
	minutes = minutes < 10 ? '0' + minutes : minutes

	if (withSeconds) {
		let seconds: number | string = date.getSeconds()
		seconds = seconds < 10 ? '0' + seconds : seconds
		return `${hours}:${minutes}:${seconds}`
	}

	return `${hours}:${minutes}`
}

export const utcDate = (rawDate?: string | number | Date): Date => {
	let date = new Date()
	if (rawDate) {
		date = typeof rawDate !== 'object' ? new Date(rawDate) : rawDate
	}
	return new Date(date.toUTCString())
}

export const toTimezoneDate = (rawDate: string | number | Date, timezone: string): Date => {
	if (!rawDate) {
		throw new Error('Invalid Date')
	}

	const date = typeof rawDate !== 'object' ? new Date(rawDate) : rawDate
	return new Date(date.toLocaleString('en-US', { timeZone: timezone }))
}

export const formatDate = (
	rawDate: string | Date,
	withSeconds?: boolean,
	targetTimezone = 'Europe/Warsaw',
): string => {
	try {
		const timeInPoland = toTimezoneDate(rawDate, targetTimezone)

		const time = parseDateToTime(timeInPoland, withSeconds)

		const year = timeInPoland.getFullYear()
		let month: number | string = timeInPoland.getMonth() + 1
		let day: number | string = timeInPoland.getDate()

		month = month < 10 ? '0' + month : month
		day = day < 10 ? '0' + day : day

		return `${day}.${month}.${year} ${time}`
	} catch (err) {
		return 'None'
	}
}

export const addTimeToData = (rawDate: string | Date, timeMs: number): Date => {
	const date = typeof rawDate !== 'object' ? new Date(rawDate) : rawDate
	return new Date(date.getTime() + timeMs)
}

export const isInHoursRange = (date: Date, start: string, end: string): boolean => {
	const [startHour, startMinutes] = start.split(':').map(Number)
	const [endHour, endMinutes] = end.split(':').map(Number)

	const currentHour = date.getHours()
	const currentMinutes = date.getMinutes()

	const startTotalMinutes = startHour * 60 + startMinutes
	const endTotalMinutes = endHour * 60 + endMinutes
	const currentTotalMinutes = currentHour * 60 + currentMinutes

	return currentTotalMinutes >= startTotalMinutes && currentTotalMinutes <= endTotalMinutes
}

export const isCurrentMonth = (date: Date): boolean => {
	const now = utcDate()
	return date.getUTCFullYear() === now.getUTCFullYear() && date.getUTCMonth() === now.getUTCMonth()
}
