import { Button, Form, Input } from 'antd'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import TextArea from 'antd/es/input/TextArea'
import ShadowForm from '../../components/ShadowForm/ShadowForm'
import FormRow from '../../../../../../components/FormRow/FormRow'
import { useAssistantRoleContext } from '../../../../../../hooks/context/AssistantRoleContext'
import { FAQ } from '../../../../../../types/AssistantContext'
import { MAX_CONTEXT_NAME_LENGTH } from '@neuron/types/context'
import styles from './KnowledgeFAQ.module.scss'

const KnowledgeFAQ = () => {
	const assistantRoleContext = useAssistantRoleContext()
	const contextFAQs = assistantRoleContext.useSubscribe((context) => context.contextFAQs)

	const updateContextFAQs = (values: Record<string, FAQ[]>) => {
		assistantRoleContext.setContextFAQs(Object.values(values)[0])
	}

	return (
		<ShadowForm
			initialValues={{
				contextFAQs,
			}}
			onValuesChange={(_, values) => updateContextFAQs(values)}
		>
			<Form.List initialValue={[{ question: '', answer: '' }]} name='contextFAQs'>
				{(fields, { add, remove }) => (
					<>
						{fields.map(({ key, name, ...restField }) => (
							<div key={key} className={styles.faqContent}>
								<FormRow className={styles.faqInputs}>
									<Form.Item
										{...restField}
										rules={[
											{
												message: 'Question must have a minimum of 3 characters',
												type: 'string',
												min: 3,
											},
											{
												message: `Question can have a maximum of ${MAX_CONTEXT_NAME_LENGTH} characters`,
												type: 'string',
												max: MAX_CONTEXT_NAME_LENGTH,
											},
										]}
										className={styles.formItem}
										name={[name, 'question']}
										label='Question'
									>
										<Input
											count={{
												show: true,
											}}
											maxLength={MAX_CONTEXT_NAME_LENGTH}
											size='large'
											className={styles.formInput}
											placeholder='Redirect condition'
										/>
									</Form.Item>
									<Form.Item
										{...restField}
										rules={[
											{
												message: 'Answer must have a minimum of 3 characters',
												type: 'string',
												min: 3,
											},
											{
												message: 'Answer can have a maximum of 500 characters',
												type: 'string',
												max: 500,
											},
										]}
										className={styles.formItem}
										name={[name, 'answer']}
										label='Answer'
									>
										<TextArea
											className={styles.formTextarea}
											size='large'
											allowClear
											count={{
												show: true,
											}}
											maxLength={500}
											defaultValue=''
											autoSize={{ minRows: 1, maxRows: 4 }}
											placeholder='Answer'
										/>
									</Form.Item>
								</FormRow>
								{key !== 0 && (
									<MinusCircleOutlined
										className={styles.faqRemoveIcon}
										onClick={() => remove(name)}
									/>
								)}
							</div>
						))}
						<Form.Item className={styles.formItem}>
							<Button
								size='large'
								type='dashed'
								onClick={() => add()}
								block
								icon={<PlusOutlined />}
							>
								Add new
							</Button>
						</Form.Item>
					</>
				)}
			</Form.List>
		</ShadowForm>
	)
}

export default KnowledgeFAQ
