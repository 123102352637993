import { ConversationMessageCategory, SalesStage } from '../../assistant/conversationMessage'
import { Lead } from '../../leadsGroup/lead'
import { FailureObject } from '../../global/failure'
import { UsingUserId } from '../../global'
import { MessengerIntegrationCredentials } from './api/messenger'
import { InstagramIntegrationCredentials } from './api/instagram'
import { WhatsappIntegrationCredentials, WhatsappPhoneNumbers } from './api/whatsapp'
import { GmailIntegrationCredentials } from './api/gmail'

export interface MessagingIntegration {
	id: string
	name: string
	provider: MessagingIntegrationProvider
	status: MessagingIntegrationStatus
	config: MessagingIntegrationConfig
	reactions?: MessagingIntegrationReactions
	userId: string
	createdAt: Date
	updatedAt?: Date
	lastStatusCheckAt?: Date

	credentials?: MessagingIntegrationCredentials
}

export type PublicMessagingIntegration = Omit<
	MessagingIntegration,
	'credentials' | 'lastStatusCheckAt'
> & {
	credentials?: PublicMessagingCredentials
}

export type PublicMessagingCredentials = MessagingProviderIntegrationBase & {
	phoneNumbers?: WhatsappPhoneNumbers
}

export type MessagingIntegrationConfig = {
	intelligentResponseTime: boolean
	intelligentWritingTime: boolean
	activeLabels?: string[]
	signature?: string
	excludeMessageDescriptions?: string[]
}

export type MessagingIntegrationReactions = {
	instagramCommentConversation?: MessagingInstagramCommentConversationReaction
}

export type MessagingInstagramCommentConversationReaction = {
	onlyMentions?: boolean
	startConversationMessages?: string[]
}

export type MessagingIntegrationCredentials =
	| MessengerIntegrationCredentials
	| InstagramIntegrationCredentials
	| WhatsappIntegrationCredentials
	| GmailIntegrationCredentials

export type MessagingProviderIntegrationBase = {
	providerIds: string[] // e.g page ids
	providerNames: string[] // e.g page names
}

export const MESSAGING_INTEGRATION_PROVIDERS = [
	'playground',
	'messenger',
	'instagram',
	'whatsapp',
	'gmail',
] as const
export type MessagingIntegrationProvider = (typeof MESSAGING_INTEGRATION_PROVIDERS)[number]

export const MESSAGING_INTEGRATION_STATUSES = ['active', 'inactive', 'deleting'] as const
export type MessagingIntegrationStatus = (typeof MESSAGING_INTEGRATION_STATUSES)[number]

export const MESSAGE_REACTIONS = ['like', 'love'] as const
export type MessageReaction = (typeof MESSAGE_REACTIONS)[number]

export type GetMessagingIntegrationRequest = Pick<MessagingIntegration, 'id'>

export type FilterUserMessagingIntegrationsRequest = {
	providers?: MessagingIntegrationProvider[]
} & UsingUserId

export type GetMessagingIntegrationsForAssistantRoleRequest = {
	assistantRoleId: string
} & UsingUserId

export type CreateMessagingIntegrationRequest = {
	name: string
	provider: MessagingIntegrationProvider
	config: MessagingIntegrationConfig
	reactions: MessagingIntegrationReactions
	accessCode?: string
} & UsingUserId

export type CreateMessagingPlaygroundIntegrationRequest = Pick<
	MessagingIntegration,
	'name' | 'userId'
>

export type UpdateMessagingIntegrationRequest = {
	id: string
	name?: string
	provider?: MessagingIntegrationProvider
	config?: MessagingIntegrationConfig
	reactions?: MessagingIntegrationReactions

	accessCode?: string
	removeCredentials?: true
}

export type DeleteMessagingIntegrationRequest = Pick<MessagingIntegration, 'id'>

export type RetryProviderMessageSendRequest = {
	conversationMessageId: string
}

export type PublishProviderMessageRequest = {
	conversationMessageId: string
}

export type TriggerConversationResponseRequest = {
	conversationId: string
}

export type NewMessageAction = {
	type: 'newUserMessage' | 'newAssistantMessage'
	userId: string
	content: string
	subject?: string
	conversationId?: string
	providerConversationId?: string
	providerMessageId?: string
	assistantProviderId?: string
	userProviderId?: string
	assistantRoleId?: string
}
export type StartCommentConversationAction = {
	type: 'startCommentConversation'
	comment: string
	userProviderId: string
	assistantProviderId: string
	startConversationMessage?: string
}
export type StartLeadConversationAction = {
	type: 'startLeadConversationAction'
	templateId: string
	lead: Pick<Lead, 'firstname' | 'email' | 'phone' | 'gender' | 'id' | 'note'>
	publishAt?: Date
}
export type TypingResponseAction = {
	type: 'typingResponse'
	conversationId: string
	markSeen?: boolean
}
export type ResponseAction = {
	type: 'response'
	conversationId: string
	userId: string
	remind?: boolean
	manual?: boolean
}
export type SetResponseTimeAction = {
	type: 'setResponseTime'
	conversationId: string
	responseTime: string
}
export type UserMessageReactionAction = {
	type: 'userMessageReaction'
	conversationId: string
	messageId: string
	reaction: MessageReaction
}
export type UpdateAssistantMessageAction = {
	type: 'updateAssistantMessage'
	userId: string
	conversationId: string
	messageId: string
	content: string
	category?: ConversationMessageCategory
	salesStage?: SalesStage
	redirectReason?: string
	summary?: string
	error?: FailureObject
	processing?: boolean
}

export type OnIntegrationAction = {
	integrationId: string
	action:
		| NewMessageAction
		| StartCommentConversationAction
		| StartLeadConversationAction
		| TypingResponseAction
		| ResponseAction
		| SetResponseTimeAction
		| UserMessageReactionAction
		| UpdateAssistantMessageAction
}

export type MetaAccessTokenInfoResponse = {
	data: {
		user_id: string
		app_id: string
		type: string
		application: string
		data_access_expires_at: number
		expires_at: number
		is_valid: boolean
		issued_at: number
		scopes: string[]
		granular_scopes: {
			scope: string
			target_ids: string[]
		}[]
	}
}

// REDIRECT REASONS
export const NO_INTEGRATION_REDIRECT_REASON =
	'The integration assigned to this conversation has been removed.' as const
export const MANUAL_REDIRECT_REASON = 'Manual redirection.' as const
export const ERROR_REDIRECT_REASON = 'Getting error when trying to respond.' as const
