import { Button, PaginationProps, Table, type TableProps, Tag } from 'antd'
import React, { useEffect, useState } from 'react'
import { BrokerAccount, Company } from '@neuron/types/account'
import AccountRoleTag from '../../../Admin/Users/UsersTable/components/AccountRoleTag/AccountRoleTag'
import { SecurityScanOutlined, TagsOutlined } from '@ant-design/icons'
import UsersDetails from './UsersDetails/UsersDetails'
import { useNavigate } from 'react-router-dom'
import styles from './BrokerUsersTable.module.scss'

type Props = {
	brokerAccounts: BrokerAccount[]
	previewAccount: (accountId: string) => void
}

const BrokerUsersTable = ({ brokerAccounts, previewAccount }: Props) => {
	const navigate = useNavigate()

	const [windowHeight, setWindowHeight] = useState(window.innerHeight)

	useEffect(() => {
		const handleResize = () => {
			setWindowHeight(window.innerHeight)
		}

		window.addEventListener('resize', handleResize)
		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	const columns: TableProps<BrokerAccountTableData>['columns'] = [
		{
			title: 'E-mail',
			dataIndex: 'email',
			key: 'email',
			className: `${styles.tableValue} ${styles.boldValue}`,
			ellipsis: true,
			width: 120,
		},
		{
			title: 'Status',
			dataIndex: 'active',
			className: styles.tableValue,
			key: 'active',
			width: 65,
			render: (value) =>
				value ? <Tag color='#5cb85c'>Active</Tag> : <Tag color='#d9534f'>Inactive</Tag>,
		},
		{
			title: 'Broker level',
			dataIndex: 'brokerRole',
			className: styles.tableValue,
			key: 'brokerRole',
			width: 75,
			render: (role: string | undefined) => (role ? <AccountRoleTag role={role} /> : ''),
		},
		{
			title: 'Purchase amount',
			dataIndex: 'purchaseAmount',
			key: 'purchaseAmount',
			className: styles.tableValue,
			width: 75,
			render: (value) => `${(value / 100).toFixed(2)} PLN`,
		},
		{
			title: 'Commission',
			dataIndex: 'commission',
			key: 'commission',
			className: styles.tableValue,
			width: 75,
			render: (value) => `${value}%`,
		},
		{
			title: 'Profit',
			dataIndex: 'profit',
			key: 'profit',
			className: `${styles.tableValue} ${styles.boldValue}`,
			width: 75,
			render: (value) => `${(value / 100).toFixed(2)} PLN`,
		},
		Table.EXPAND_COLUMN,
		{
			title: 'Options',
			key: 'options',
			className: styles.tableValue,
			fixed: 'right',
			render: (record: BrokerAccountTableData & { key: string }) => (
				<div className={styles.actions}>
					<Button
						icon={<SecurityScanOutlined />}
						className={styles.actionButton}
						disabled={!record.active}
						type='link'
						onClick={() => previewAccount(record.key)}
					>
						Dashboard
					</Button>
					<Button
						icon={<TagsOutlined />}
						className={styles.actionButton}
						type='link'
						onClick={() => navigate(`/offers?userId=${record.key}`)}
					>
						Offers
					</Button>
				</div>
			),
			width: 100,
		},
	]

	const totalProfit = brokerAccounts.reduce((sum, account) => sum + account.profit / 100, 0)

	return (
		<Table
			pagination={{
				pageSize: 100,
				total: brokerAccounts.length,
				current: 1,
				showTotal,
			}}
			expandable={{
				expandedRowRender: (item: any) => (
					<UsersDetails
						account={{
							...item,
							id: item.key,
						}}
					/>
				),
			}}
			columns={columns}
			dataSource={brokerAccountsToTableData(brokerAccounts)}
			scroll={{ y: windowHeight - 366 }}
			summary={() => {
				return (
					<Table.Summary.Row>
						<Table.Summary.Cell index={0}></Table.Summary.Cell>
						<Table.Summary.Cell index={1}></Table.Summary.Cell>
						<Table.Summary.Cell index={2}></Table.Summary.Cell>
						<Table.Summary.Cell index={3}></Table.Summary.Cell>
						<Table.Summary.Cell index={4}>Total profit</Table.Summary.Cell>
						<Table.Summary.Cell className={styles.boldValue} index={5}>
							{totalProfit} PLN
						</Table.Summary.Cell>
					</Table.Summary.Row>
				)
			}}
		/>
	)
}

const showTotal: PaginationProps['showTotal'] = (total) => `Total ${total} items`

const brokerAccountsToTableData = (
	brokerAccounts: BrokerAccount[],
): Array<BrokerAccountTableData & { key: string }> => {
	return brokerAccounts.map((brokerAccount) => ({
		key: brokerAccount.id,
		email: brokerAccount.email,
		active: brokerAccount.active,
		brokerRole: brokerAccount.brokerRole,
		commission: brokerAccount.commission,
		purchaseAmount: brokerAccount.purchaseAmount,
		profit: brokerAccount.profit,
		firstName: brokerAccount.firstName,
		lastName: brokerAccount.lastName,
		phone: brokerAccount.phone,
		company: brokerAccount.company,
		children: brokerAccount.children,
	}))
}

interface BrokerAccountTableData {
	email: string
	active: boolean
	brokerRole: string | undefined
	commission: number
	purchaseAmount: number
	profit: number
	firstName: string
	lastName: string
	phone: string
	company?: Company
	children?: BrokerAccount[]
}

export default BrokerUsersTable
