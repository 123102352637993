import { Form } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import { AssistantRole } from '@neuron/types/assistant/assistantRole'
import { useEffect } from 'react'
import Joi from 'joi'
import { prepareAssistantData } from '../../Role'
import { useAssistantRoleContext } from '../../../../../../hooks/context/AssistantRoleContext'
import { formValidate } from '../../../../../../utils/validation'
import { createExternalAssistantRoleSchema } from '@neuron/schemas/assistant/roles/externalAssistant'
import RoleHeader from '../../components/RoleHeader/RoleHeader'
import { useParams } from 'react-router-dom'
import { prepareRoleFormValues } from '../../utils'
import RedirectConditions from '../../components/Forms/RedirectConditions'
import ConversationConfig from '../../components/Forms/ConversationConfig'
import EmojiConfig from '../../components/Forms/EmojiConfig'
import NaturalizationBooster from '../../components/Forms/NaturalizationBooster'
import FormHeading from '../../components/FormHeading/FormHeading'
import AdditionalInformation from '../../components/Forms/AdditionalInformation'
import SystemName from '../../components/Forms/SystemName'
import AppointmentLinks from '../../components/Forms/AppointmentLinks'
import { roles } from '../../../../../../utils/roles'
import styles from './ExternalAssistant.module.scss'

const DEFAULT_EXTERNAL_ASSISTANT_DATA: Partial<AssistantRole> = {
	type: 'externalAssistant',
	disabledEmoji: false,
	conversationStyle: 'balanced',
	responseLength: 'balanced',
	naturalizationBooster: false,
}

const ExternalAssistantRoleForm = () => {
	const [form] = Form.useForm()

	const { assistantRoleId } = useParams()

	const assistantRoleContext = useAssistantRoleContext()
	const assistantRoleData = assistantRoleContext.useSubscribe((context) => context.data)

	useEffect(() => {
		if (!assistantRoleId) {
			assistantRoleContext.updateData(DEFAULT_EXTERNAL_ASSISTANT_DATA)
		}
	}, [assistantRoleId])

	useEffect(() => {
		checkFormValidate()
	}, [assistantRoleData])

	useEffect(() => {
		form.resetFields()
	}, [assistantRoleData.id, assistantRoleData.type])

	const updateAssistantRoleData = (value: Record<string, any>, values: any) => {
		prepareRoleFormValues(value, values)
		assistantRoleContext.updateData(values)
	}

	const checkFormValidate = () => {
		const validate = formValidate(prepareAssistantData(assistantRoleData), assistantRoleSchema)
		assistantRoleContext.setValidStep(validate ? !Object.keys(validate).length : true)
	}

	const roleInfo = roles.flat().find((role) => role.key === 'externalAssistant')!

	return (
		<Form
			form={form}
			autoComplete='true'
			initialValues={assistantRoleData}
			onValuesChange={updateAssistantRoleData}
			labelCol={{ span: 4 }}
			wrapperCol={{ span: 14 }}
			className={styles.form}
			layout='vertical'
		>
			<RoleHeader
				avatar={roleInfo.avatar}
				name={roleInfo.name}
				description={roleInfo.description}
			/>

			<FormHeading title='Base information' />

			<SystemName />

			<Form.Item
				rules={[
					{
						message: 'Company details can have a maximum of 3000 characters',
						max: 3000,
					},
				]}
				className={styles.formItem}
				label='Company details'
				name='companyDetails'
			>
				<TextArea
					className={styles.formTextarea}
					size='large'
					allowClear
					count={{
						show: true,
					}}
					maxLength={3000}
					autoSize={{ minRows: 2, maxRows: 30 }}
					placeholder='Company details'
				/>
			</Form.Item>

			<AdditionalInformation />

			<AppointmentLinks
				meetingUrls={'meetingUrls' in assistantRoleData ? assistantRoleData.meetingUrls : undefined}
			/>

			<EmojiConfig disabledEmoji={assistantRoleData.disabledEmoji} />

			<FormHeading title='Role settings' />

			<ConversationConfig />

			<FormHeading title='Advanced settings' />

			<NaturalizationBooster />
			<RedirectConditions />
		</Form>
	)
}

const assistantRoleSchema = Joi.object({
	...createExternalAssistantRoleSchema,
	id: Joi.any().optional(),
}).required()

export default ExternalAssistantRoleForm
