import { useRef } from 'react'
import { PublicMessage } from '../types/Chat'
import { toPublicMessage } from './chatMappers'
import { ChatMessage } from '@neuron/types/chat/chatMessage'
import { ERROR_CONTENT, FIND_URL_REGEX, LOADING_CONTENT, messageRole } from '@neuron/utils/consts'
import { ConversationMessage } from '@neuron/types/assistant/conversationMessage'
import { v4 as uuidv4 } from 'uuid'
import { messageContentIsPending } from '@neuron/utils/assistant'
import { addTimeToData, utcDate } from '@neuron/utils/dates'
import { ContextStatus } from '@neuron/types/context'
import { AssistantRole } from '@neuron/types/assistant/assistantRole'
import { roles } from './roles'
import { TemplateStatus } from '@neuron/types/leadsGroup/template'
import { PaymentStatus } from '@neuron/types/order/payment'

export const extractDomain = (url: string): string => {
	let domain = ''
	const domainRegex = /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\n]+)/im
	const matches = url.match(domainRegex)
	if (matches && matches.length > 1) {
		domain = matches[1]
	}

	return domain
}

export const sliceString = (value: string, characters: number) => {
	if (value.length > characters) {
		return value.slice(0, characters)
	} else {
		return value
	}
}

type ValueToRemove = undefined | null | string | number
export const filterObjectByValues = (
	obj: Record<string, any>,
	valuesToRemove: ValueToRemove[],
): Record<string, any> => {
	const filteredObject: Record<string, any> = {}

	for (const key in obj) {
		if (obj.hasOwnProperty(key)) {
			if (!valuesToRemove.includes(obj[key])) {
				filteredObject[key] = obj[key]
			}
		}
	}

	return filteredObject
}

export const downloadFileFromUrl = (url: string, filename: string) => {
	const link = document.createElement('a')
	link.href = url

	link.setAttribute('download', filename)
	document.body.appendChild(link)
	link.click()

	document.body.removeChild(link)
	window.URL.revokeObjectURL(url)
}

export const useDebounced = () => {
	const debounceTimer = useRef<ReturnType<typeof setTimeout> | number>(-1)

	return (callback: (...args: any) => any, time = 200) => {
		clearTimeout(debounceTimer.current)
		debounceTimer.current = setTimeout(callback, time)
	}
}

export const rawTextToStyledMessage = (text?: string): string => {
	if (!text?.length) {
		return ''
	}

	const removeHTMLTagsRegex = /(<([^>]+)>)/gi
	const boldRegex = /\*\*(.*?)\*\*/g
	const italicRegex = /\*(.*?)\*/g

	const heading1Regex = /^# (.+)$/gm
	const heading2Regex = /^## (.+)$/gm
	const heading3Regex = /^### (.+)$/gm
	const heading4Regex = /^#### (.+)$/gm

	let styledMessage = text.replace(removeHTMLTagsRegex, '')
	styledMessage = styledMessage.replace(boldRegex, '<b>$1</b>')
	styledMessage = styledMessage.replace(italicRegex, '<i>$1</i>')

	styledMessage = styledMessage.replace(heading1Regex, '<h2>$1</h2>')
	styledMessage = styledMessage.replace(heading2Regex, '<h3>$1</h3>')
	styledMessage = styledMessage.replace(heading3Regex, '<h4>$1</h4>')
	styledMessage = styledMessage.replace(heading4Regex, '<h5>$1</h5>')

	styledMessage = styledMessage.replace(FIND_URL_REGEX, (url) => {
		if (!url.startsWith('http://') && !url.startsWith('https://')) {
			return `<a href="https://${url}" target="_blank">${url}</a>`
		}
		return `<a href="${url}" target="_blank">${url}</a>`
	})

	return styledMessage
}

export const styledMessageToRawText = (styledMessage?: string): string => {
	if (!styledMessage?.length) {
		return ''
	}

	const boldRegex = /\*\*(.*?)\*\*/g
	const italicRegex = /\*(.*?)\*/g
	const heading1Regex = /^# (.+)$/gm
	const heading2Regex = /^## (.+)$/gm
	const heading3Regex = /^### (.+)$/gm

	let preparedText = styledMessage.replace(boldRegex, '$1')
	preparedText = preparedText.replace(italicRegex, '$1')
	preparedText = preparedText.replace(heading1Regex, '$1')
	preparedText = preparedText.replace(heading2Regex, '$1')
	preparedText = preparedText.replace(heading3Regex, '$1')

	return preparedText
}

export const getUpdatedMessages = (
	messages: PublicMessage[],
	updatedMessage: ChatMessage | ConversationMessage,
): PublicMessage[] => {
	const findOldMessageIndexById = messages.findIndex(({ id }) => id === updatedMessage.id)
	if (findOldMessageIndexById !== -1) {
		return messages.map((message, index) =>
			index === findOldMessageIndexById ? toPublicMessage(updatedMessage) : message,
		)
	}
	if (
		messages.length > 2 &&
		updatedMessage.role === messageRole.USER &&
		messages[messages.length - 2].role === updatedMessage.role &&
		messages[messages.length - 2].content === updatedMessage.content
	) {
		return messages.map((message, index) =>
			index === messages.length - 2 ? toPublicMessage(updatedMessage) : message,
		)
	}
	if (
		messages.length > 1 &&
		updatedMessage.role === messageRole.ASSISTANT &&
		messages[messages.length - 1].role === updatedMessage.role &&
		messages[messages.length - 1].content === updatedMessage.content
	) {
		return messages.map((message, index) =>
			index === messages.length - 1 ? toPublicMessage(updatedMessage) : message,
		)
	} else {
		return [...messages, toPublicMessage(updatedMessage)]
	}
}

export const checkLastFetchedMessage = (
	fetchedMessages: PublicMessage[],
	loadMessageProgressSteps: (message: PublicMessage) => Promise<void>,
	responseMessageTimeout: number,
	loadingMessageTimeout: number,
): boolean => {
	let shouldDisableNewMessage = false

	const lastMessage = fetchedMessages[fetchedMessages.length - 1]
	const findMessageInLoadingStatus = fetchedMessages.find((message) =>
		messageContentIsPending(message.content),
	)

	if (lastMessage.role === messageRole.USER) {
		const responseTimeout =
			utcDate() >= addTimeToData(lastMessage.createdAt, responseMessageTimeout)

		fetchedMessages.push({
			id: uuidv4(),
			sessionId: '',
			createdAt: utcDate(),
			content: responseTimeout ? ERROR_CONTENT : LOADING_CONTENT,
			role: messageRole.ASSISTANT,
		})
		shouldDisableNewMessage = !responseTimeout
	}

	if (findMessageInLoadingStatus) {
		const responseTimeout =
			utcDate() >= addTimeToData(findMessageInLoadingStatus.createdAt, loadingMessageTimeout)
		if (responseTimeout) {
			findMessageInLoadingStatus.content = ERROR_CONTENT
		} else {
			loadMessageProgressSteps(findMessageInLoadingStatus).catch(() => {
				// ignore
			})
			shouldDisableNewMessage = true
		}
	}

	return shouldDisableNewMessage
}

export const contextStatusToColor: Record<ContextStatus, string> = {
	indexed: '#5cb85c',
	refreshing: '#0275d8',
	needReindex: '#f0ad4e',
	error: '#d9534f',
	deleting: '#292b2c',
}

export const templateStatusToColor: Record<TemplateStatus, string> = {
	active: '#5cb85c',
	pending: '#0275d8',
	rejected: '#d9534f',
	error: '#d9534f',
}

export const paymentStatusToColor: Record<PaymentStatus, string> = {
	initialized: '#0275d8',
	processing: '#f0ad4e',
	requiresAction: '#f0ad4e',
	succeeded: '#5cb85c',
	failed: '#d9534f',
	canceled: '#6c757d',
	capturing: '#f0ad4e',
	refunded: '#5bc0de',
	error: '#d9534f',
}

export const assistantRoleTypeToName = (roleType: AssistantRole['type']): string => {
	const roleInfo = roles.flat().find((role) => role.key === roleType)!
	return roleInfo.name
}

export const selectFilterOption = (input: string, option: any) => {
	const searchText = input.toLowerCase()
	return (
		option.label.toLowerCase().includes(searchText) ||
		option.value.toLowerCase().includes(searchText)
	)
}
