import React, { PropsWithChildren } from 'react'
import { createStateContext } from '../createStateContext'
import AiContext, { Tab } from '../../types/AiContext'
import { sliceString } from '../../utils/helpers'
import { MAX_CONTEXT_NAME_LENGTH } from '@neuron/types/context'

const aiContextInitialState: AiContext = {
	tabs: [],
	currentTabId: null,
	loading: false,
}

const AiPanelContext = createStateContext(aiContextInitialState, (setStore) => ({
	addNewTab(tab: Tab) {
		setStore((store) => ({ ...store, tabs: [...store.tabs, tab] }))
	},
	updateTab(id: number, updatedTab: Partial<Tab>) {
		setStore((store) => {
			const newTabs = store.tabs.map((tab) => {
				if (tab.id === id) {
					if (updatedTab.name) {
						updatedTab.name = sliceString(updatedTab.name.trim(), MAX_CONTEXT_NAME_LENGTH)
					}

					return { ...tab, ...updatedTab }
				}
				return tab
			})

			return {
				...store,
				tabs: newTabs,
			}
		})
	},
	deleteTab(tabId: number) {
		setStore((store) => {
			const newTabs = store.tabs.filter((tab) => tab.id !== tabId)
			return {
				...store,
				tabs: newTabs,
			}
		})
	},
	setCurrentTabId(tabId: number | null) {
		setStore((store) => ({ ...store, currentTabId: tabId }))
	},
	resetTabs() {
		setStore(() => aiContextInitialState)
	},
	setLoading(loading: boolean) {
		setStore((store) => ({ ...store, loading }))
	},
}))

export const AiContextProvider = ({ children }: PropsWithChildren<{}>) => (
	<AiPanelContext.Provider>{children}</AiPanelContext.Provider>
)

export const useAiContext = AiPanelContext.useContext
