import Joi from 'joi'
import { WARMUP_LEVEL } from '@neuron/types/assistant/assistantRole'
import { APPOINTMENT_SETTER_GOALS } from '@neuron/types/assistant/roles/appointmentSetter'
import { assistantRoleBaseSchema, GoalUrlSchema } from '../assistantRole'
import { usingUserIdSchema, uuidIdSchema } from '../../global'

export const appointmentSetterRoleSchema = {
	...assistantRoleBaseSchema,
	type: Joi.string().valid('appointmentSetter').required(),
	companyOffer: Joi.string().max(3000).required(),
	promotionsDescription: Joi.string().max(1500),
	warmupLevel: Joi.string()
		.valid(...WARMUP_LEVEL)
		.required(),
	goal: Joi.string()
		.valid(...APPOINTMENT_SETTER_GOALS)
		.required(),
	meetingUrls: Joi.any().when('goal', {
		is: 'meetingUrls',
		then: Joi.array().items(GoalUrlSchema.required()).min(1).max(5).required(),
		otherwise: Joi.any().forbidden(),
	}),
}

export const createAppointmentSetterRoleSchema = {
	assistantId: appointmentSetterRoleSchema.assistantId.required(),
	name: appointmentSetterRoleSchema.name.required(),
	additionalInformation: appointmentSetterRoleSchema.additionalInformation.optional(),
	naturalizationBooster: appointmentSetterRoleSchema.naturalizationBooster.optional(),
	redirectConditions: appointmentSetterRoleSchema.redirectConditions.optional(),

	type: appointmentSetterRoleSchema.type.required(),
	companyOffer: appointmentSetterRoleSchema.companyOffer.required(),
	promotionsDescription: appointmentSetterRoleSchema.promotionsDescription.optional(),
	disabledEmoji: appointmentSetterRoleSchema.disabledEmoji.required(),
	preferredEmoji: appointmentSetterRoleSchema.preferredEmoji.optional(),
	followupInterval: appointmentSetterRoleSchema.followupInterval.optional(),
	maxFollowups: appointmentSetterRoleSchema.maxFollowups.optional(),
	conversationStyle: appointmentSetterRoleSchema.conversationStyle.required(),
	responseLength: appointmentSetterRoleSchema.responseLength.required(),
	warmupLevel: appointmentSetterRoleSchema.warmupLevel.required(),
	goal: appointmentSetterRoleSchema.goal.required(),
	meetingUrls: appointmentSetterRoleSchema.meetingUrls.required(),
	...usingUserIdSchema,
}

export const updateAppointmentSetterRoleSchema = {
	id: uuidIdSchema.required(),
	assistantId: appointmentSetterRoleSchema.assistantId.required(),
	name: appointmentSetterRoleSchema.name.optional(),
	additionalInformation: appointmentSetterRoleSchema.additionalInformation.optional(),
	naturalizationBooster: appointmentSetterRoleSchema.naturalizationBooster.optional(),
	redirectConditions: appointmentSetterRoleSchema.redirectConditions.optional(),

	type: appointmentSetterRoleSchema.type.optional(),
	companyOffer: appointmentSetterRoleSchema.companyOffer.optional(),
	promotionsDescription: appointmentSetterRoleSchema.promotionsDescription.optional(),
	disabledEmoji: appointmentSetterRoleSchema.disabledEmoji.optional(),
	preferredEmoji: appointmentSetterRoleSchema.preferredEmoji.optional(),
	followupInterval: appointmentSetterRoleSchema.followupInterval.optional(),
	maxFollowups: appointmentSetterRoleSchema.maxFollowups.optional(),
	conversationStyle: appointmentSetterRoleSchema.conversationStyle.optional(),
	responseLength: appointmentSetterRoleSchema.responseLength.optional(),
	warmupLevel: appointmentSetterRoleSchema.warmupLevel.optional(),
	goal: appointmentSetterRoleSchema.goal.optional(),
	meetingUrls: appointmentSetterRoleSchema.meetingUrls.optional(),
}
