import { Button, Form, InputNumber, Select, Tag } from 'antd'
import { assistantRoleTypeToName } from '../../../../utils/helpers'
import React, { useEffect, useMemo, useState } from 'react'
import { ASSISTANT_ROLES, AssistantRoleType } from '@neuron/types/assistant/assistantRole'
import {
	BASE_PERSONA_PRICE,
	BASE_PERSONA_ROLE_PRICE,
	CURRENCIES,
	PAYMENT_INTERVALS,
} from '@neuron/types/order'
import FormRow from '../../../../components/FormRow/FormRow'
import Joi from 'joi'
import { formValidate } from '../../../../utils/validation'
import { OfferFormData } from '../UserOffer'
import styles from './OfferForm.module.scss'

type Props = {
	type: 'personas' | 'roles'
	ownedRoles: AssistantRoleType[]
	onSavePersonaOffer: (offerFormData: OfferFormData) => void
}

const OfferForm = ({ type, ownedRoles, onSavePersonaOffer }: Props) => {
	const [offerFormData, setOfferFormData] = useState<OfferFormData>({
		type,
		roles: [],
		currency: CURRENCIES[0],
		paymentInterval: 'once',
		additionalCost: 0,
		personasNumber: type === 'personas' ? 1 : undefined,
	})
	const [baseCost, setBaseCost] = useState<number>(0)

	useEffect(() => {
		let baseCost = 0
		if (offerFormData.type === 'personas') {
			baseCost = BASE_PERSONA_PRICE * offerFormData.personasNumber!

			if (offerFormData.roles.length > 3) {
				baseCost += (offerFormData.roles.length - 3) * BASE_PERSONA_ROLE_PRICE
			}
		}

		if (offerFormData.type === 'roles') {
			baseCost = offerFormData.roles.length * BASE_PERSONA_ROLE_PRICE
		}

		setBaseCost(baseCost / 100)
	}, [offerFormData])

	useEffect(() => {
		setOfferFormData((formData) => ({
			...formData,
			type,
			personasNumber: type === 'personas' ? 1 : undefined,
		}))
	}, [type])

	const updateOfferFormData = (_value: Record<string, any>, values: OfferFormData) => {
		setOfferFormData((formData) => ({ ...formData, ...values }))
	}

	const isFormValid = useMemo(() => {
		const validate = formValidate(offerFormData, personaOfferFormDataSchema)
		return validate ? !Object.keys(validate).length : true
	}, [offerFormData])

	return (
		<Form
			className={styles.form}
			initialValues={offerFormData}
			onValuesChange={updateOfferFormData}
			labelCol={{ span: 4 }}
			wrapperCol={{ span: 14 }}
			layout='vertical'
		>
			<FormRow columns={type === 'personas' ? [1, 4] : [1]}>
				{type === 'personas' && (
					<Form.Item
						className={styles.formItem}
						label='Numer of Personas'
						name='personasNumber'
						initialValue={1}
					>
						<InputNumber min={1} max={10} size='large' />
					</Form.Item>
				)}

				<Form.Item name='roles' label='Persona roles' className={styles.formItem}>
					<Select
						size='large'
						showSearch
						mode='multiple'
						options={ASSISTANT_ROLES.filter((role) => !ownedRoles.includes(role)).map((role) => ({
							label: <Tag>{assistantRoleTypeToName(role)}</Tag>,
							value: role,
						}))}
					/>
				</Form.Item>
			</FormRow>

			<FormRow>
				<Form.Item
					className={styles.formItem}
					label='Additional cost'
					name='additionalCost'
					initialValue={0}
				>
					<InputNumber min={0} max={30000} size='large' addonAfter='PLN' precision={2} />
				</Form.Item>

				<div className={styles.dealCostWrapper}>
					<div>
						Base cost: {baseCost} {offerFormData.currency}
					</div>
					<div>
						Additional cost: {offerFormData.additionalCost} {offerFormData.currency}
					</div>
					<hr />
					<div>
						Total deal cost:{' '}
						<b>
							{baseCost + offerFormData.additionalCost} {offerFormData.currency}
						</b>
					</div>
				</div>
			</FormRow>

			<Form.Item
				initialValue={PAYMENT_INTERVALS[1]}
				name='paymentInterval'
				label='Payment type'
				className={styles.formItem}
			>
				<Select
					size='large'
					showSearch
					options={PAYMENT_INTERVALS.map((interval) => ({
						label: interval,
						value: interval,
					}))}
				/>
			</Form.Item>

			<div className={styles.buttonWrapper}>
				<Button
					type='primary'
					disabled={!isFormValid}
					size='large'
					onClick={() => isFormValid && onSavePersonaOffer(offerFormData)}
				>
					Add new offer
				</Button>
			</div>
		</Form>
	)
}

const personaOfferFormDataSchema = Joi.object({
	type: Joi.string().valid('personas', 'roles').required(),
	personasNumber: Joi.number().when('type', {
		is: 'personas',
		then: Joi.number().required().max(10).min(1).required(),
		otherwise: Joi.number().forbidden(),
	}),
	roles: Joi.array().when('type', {
		is: 'personas',
		then: Joi.array()
			.items(Joi.string().valid(...ASSISTANT_ROLES))
			.required(),
		otherwise: Joi.array()
			.items(
				Joi.string()
					.valid(...ASSISTANT_ROLES)
					.min(1)
					.required(),
			)
			.required(),
	}),
	additionalCost: Joi.number().integer().positive().allow(0).required(),
	paymentInterval: Joi.string()
		.valid(...PAYMENT_INTERVALS)
		.required(),
	currency: Joi.string().required(),
}).required()

export default OfferForm
