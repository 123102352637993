import { ToolId } from '../global/tools'
import { LLMModelName } from '../global'

export const HUMAN_MINIMAL_COST_PER_HOUR = 28.1 as const
export const HUMAN_AVERAGE_COST_PER_HOUR = 48.5 as const

export const EMBEDDING_COST_PER_1K_TOKENS = {
	ada: 0.0001, // from openai
} as const

export const PROMPT_COST_PER_1K_TOKENS: Record<LLMModelName, number> = {
	'gpt-4o-2024-08-06': 0.0025,
	'gpt-4o-mini-2024-07-18': 0.00015,
	'gpt-3.5-turbo-0125': 0.0005,
} as const

export const COMPLETION_COST_PER_1K_TOKENS: Record<LLMModelName, number> = {
	'gpt-4o-2024-08-06': 0.01,
	'gpt-4o-mini-2024-07-18': 0.0006,
	'gpt-3.5-turbo-0125': 0.001,
} as const

export const TRANSLATION_COST_PER_1K_CHARACTERS = 0.02 as const

export const USAGE_TYPES = ['context', 'message'] as const
export type UsageType = (typeof USAGE_TYPES)[number]

export type ToolCostOptions = {
	quality?: 'hd' | 'standard'
	size?: '1024x1024' | '1792x1024' | '1024x1792'
	samples?: number // for dall-e
	textLength?: number
}

export type UsedTool = {
	toolId: ToolId
	executeTimeMs: number
	cost: number
}

export interface Usage {
	id: number
	stepId: string
	userId: string
	messageId?: string
	conversationMessageId?: string
	contextId?: number

	type: UsageType

	embeddingTokens?: number
	promptTokens?: number
	completionTokens?: number

	usedLLM?: LLMModelName
	usedTool?: UsedTool
	embeddingCost: number
	promptCost: number
	completionCost: number
	translatedCharactersCost?: number
	totalCost: number

	generatedCharactersCount: number
	translatedCharactersCount: number

	createdAt: Date
}

export type PublicMessageUsage = Pick<
	Usage,
	| 'usedLLM'
	| 'usedTool'
	| 'translatedCharactersCost'
	| 'generatedCharactersCount'
	| 'translatedCharactersCount'
	| 'embeddingTokens'
	| 'promptTokens'
	| 'completionTokens'
	| 'totalCost'
> & {
	embeddingCost?: number
	promptCost?: number
	completionCost?: number
	realTotalCost?: number
}

export type ConversationUsage = {
	cost: number
	tokens?: number
	realCost?: number
}

export type GetMessageUsageRequest =
	| {
			chatMessageId: string
	  }
	| {
			conversationMessageId: string
	  }

export type GetConversationUsageRequest = {
	conversationId: string
}

export type IncreaseUsageRequest = {
	stepId: string
	userId: string

	generatedCharactersCount: number
	translatedCharactersCount: number

	usedLLM?: LLMModelName
	embeddingTokens?: number
	promptTokens?: number
	completionTokens?: number

	options?: ToolCostOptions
}

export interface UserUsage {
	translatedCharacters: number
	generatedCharacters: number
	generatedAssistantMessages: number
	generatedAssistantMessagesInLastDay: number
	createdContexts: number

	totalExecutionHours: number

	totalUsedTokens: number
	embeddingUsedTokens: number
	promptUsedTokens: number
	completionUsedTokens: number

	totalCost: number

	monthlyTranslatedCharacters: number
	monthlyGeneratedCharacters: number
	monthlyGeneratedAssistantMessages: number
	monthlyCreatedContexts: number
	monthlyTotalCost: number
	monthlyTotalExecutionHours: number

	monthlyUsedTokens: number
	monthlyEmbeddingUsedTokens: number
	monthlyPromptUsedTokens: number
	monthlyCompletionUsedTokens: number
}
