import { App, Button, Slider, Tooltip } from 'antd'
import RoleForm from './Forms/RoleForm'
import { useEffect, useState } from 'react'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import KnowledgeBase from './KnowledgeBase/KnowledgeBase'
import Playground from './Playground/Playground'
import { omit } from '@neuron/utils'
import { marks, stepNameToProgress } from './utils'
import Connections from './Connections/Connections'
import useTryCatch from '../../../../hooks/useTryCatch'
import { useAssistantRoleContext } from '../../../../hooks/context/AssistantRoleContext'
import useAsyncEffect from '../../../../hooks/useAsyncEffect'
import query from '../../../../utils/query'
import { AssistantRole } from '@neuron/types/assistant/assistantRole'
import Loading from '../../../../components/Loading/Loading'
import { useGlobalContext } from '../../../../hooks/context/GlobalContext'
import { useAccountContext } from '../../../../hooks/context/AccountContext'
import styles from './Role.module.scss'

const Role = () => {
	const { message } = App.useApp()
	const tryCatch = useTryCatch(message)
	const navigate = useNavigate()
	const location = useLocation()

	const globalContext = useGlobalContext()

	const accountContext = useAccountContext()
	const usingUserId = accountContext.useSubscribe((account) => account.usingUserId)

	const { assistantId, assistantRoleId } = useParams()

	const assistantRoleContext = useAssistantRoleContext()
	const assistantRoleData = assistantRoleContext.useSubscribe((context) => context.data)
	const step = assistantRoleContext.useSubscribe((context) => context.step)
	const validStep = assistantRoleContext.useSubscribe((context) => context.validStep)

	const [loading, setLoading] = useState<boolean>(false)
	const [stepLoading, setStepLoading] = useState<boolean>(false)

	useAsyncEffect(async () => {
		if (!assistantRoleId) {
			assistantRoleContext.updateData({ assistantId })
			return
		}

		setLoading(true)
		await tryCatch(async () => {
			const assistantRole = await query<AssistantRole>('/assistant/role/get', 'GET', {
				params: {
					id: assistantRoleId,
				},
				withCredentials: true,
			})
			assistantRoleContext.setData(assistantRole)
		})
		setLoading(false)
	}, [assistantRoleId])

	useEffect(() => {
		const lastPathname = location.pathname.split('/').at(-1)
		if (lastPathname === 'context') {
			globalContext.updatePageTitle('Role - Knowledge base')
			assistantRoleContext.setStep('Knowledge base')
		} else if (lastPathname === 'playground') {
			globalContext.updatePageTitle('Role - Playground')
			assistantRoleContext.setStep('Playground')
		} else if (lastPathname === 'connections') {
			globalContext.updatePageTitle('Role - Connections')
			assistantRoleContext.setStep('Connections')
		} else {
			assistantRoleContext.setStep('Form')
		}
		assistantRoleContext.setValidStep(false)
		setStepLoading(false)
	}, [location.pathname])

	const createOrUpdateAssistantRole = async (): Promise<string> => {
		return await tryCatch(async () => {
			const assistantRole = await query<AssistantRole>(
				assistantRoleData.id ? '/assistant/role/update' : '/assistant/role/create',
				'POST',
				{
					data: assistantRoleData.id
						? prepareAssistantData(assistantRoleData)
						: {
								...prepareAssistantData(assistantRoleData),
								usingUserId,
							},
					withCredentials: true,
				},
			)

			assistantRoleContext.setData(assistantRole)
			return assistantRole.id
		})
	}

	const updateAssistantRoleConnections = async () => {
		await tryCatch(async () => {
			await query<AssistantRole>('/assistant/role/updateIntegrations', 'POST', {
				data: {
					id: assistantRoleData.id,
					integrations: assistantRoleData.connections?.integrations ?? [],
				},
				withCredentials: true,
			})

			const updatedAssistantRole = await query<AssistantRole>(
				'/assistant/role/updateLeadsGroups',
				'POST',
				{
					data: {
						id: assistantRoleData.id,
						leadsGroupIds: assistantRoleData.connections?.leadsGroupIds ?? [],
					},
					withCredentials: true,
				},
			)

			assistantRoleContext.setData(updatedAssistantRole)
		})
	}

	const setNextStep = async () => {
		if (step === 'Form') {
			setStepLoading(true)
			const assistantRoleId = await createOrUpdateAssistantRole()
			if (assistantRoleId) {
				return navigate({ pathname: `/persona/${assistantId}/role/${assistantRoleId}/context` })
			} else {
				setStepLoading(false)
			}
		}
		if (step === 'Knowledge base' && assistantId && assistantRoleId) {
			return navigate({ pathname: `/persona/${assistantId}/role/${assistantRoleId}/playground` })
		}
		if (step === 'Playground' && assistantId && assistantRoleId) {
			return navigate({ pathname: `/persona/${assistantId}/role/${assistantRoleId}/connections` })
		}
		if (step === 'Connections' && assistantId) {
			setStepLoading(true)
			await updateAssistantRoleConnections()
			return navigate({ pathname: `/persona/${assistantId}/roles` })
		}
	}

	const setPreviouslyStep = () => {
		if (step === 'Form') {
			return navigate({ pathname: `/persona/${assistantId}/roles` })
		}
		if (step === 'Knowledge base' && assistantId && assistantRoleId) {
			return navigate({ pathname: `/persona/${assistantId}/role/${assistantRoleId}` })
		}
		if (step === 'Playground' && assistantId && assistantRoleId) {
			return navigate({ pathname: `/persona/${assistantId}/role/${assistantRoleId}/context` })
		}
		if (step === 'Connections' && assistantId && assistantRoleId) {
			return navigate({ pathname: `/persona/${assistantId}/role/${assistantRoleId}/playground` })
		}
	}

	const setStepBySliderValue = async (value: number) => {
		if (!validStep) {
			return
		}

		let currentAssistantRoleId: string | undefined = assistantRoleId
		if (step === 'Form' && value !== 0) {
			setStepLoading(true)
			const newAssistantRoleId = await createOrUpdateAssistantRole()
			if (newAssistantRoleId) {
				currentAssistantRoleId = newAssistantRoleId
			} else {
				setStepLoading(false)
			}
		}
		if (step === 'Connections' && value !== 100) {
			setStepLoading(true)
			await updateAssistantRoleConnections()
		}

		if (value === 0) {
			if (currentAssistantRoleId) {
				return navigate({ pathname: `/persona/${assistantId}/role/${currentAssistantRoleId}` })
			}
			return navigate({ pathname: `/persona/${assistantId}/role` })
		}
		if (value === 33 && currentAssistantRoleId) {
			return navigate({
				pathname: `/persona/${assistantId}/role/${currentAssistantRoleId}/context`,
			})
		}
		if (value === 66 && currentAssistantRoleId) {
			return navigate({
				pathname: `/persona/${assistantId}/role/${currentAssistantRoleId}/playground`,
			})
		}
		if (value === 100 && currentAssistantRoleId) {
			return navigate({
				pathname: `/persona/${assistantId}/role/${currentAssistantRoleId}/connections`,
			})
		}
	}

	if (!step) {
		return <Loading size={42} />
	}

	return (
		<div className={styles.assistantContainer}>
			<div className={styles.header}>
				<Button onClick={setPreviouslyStep} size='large'>
					Back
				</Button>
				<Slider
					className={styles.progressSlider}
					marks={marks}
					disabled={!validStep}
					value={stepNameToProgress[step]}
					min={0}
					max={100}
					onChange={setStepBySliderValue}
					tooltip={{ formatter: (value) => (value ? marks[value] : marks[0]) }}
					styles={{
						track: {
							background: 'transparent',
						},
						tracks: {
							background: `linear-gradient(to right, #0033CC, #0057D9, #007BFF, #3399FF, #99CCFF)`,
						},
					}}
				/>
				<Tooltip
					zIndex={validStep ? -1 : 10}
					mouseEnterDelay={0.3}
					title='Make sure all fields are completed correctly or any data does not contain errors.'
				>
					<Button
						loading={stepLoading}
						disabled={!validStep}
						onClick={setNextStep}
						type='primary'
						size='large'
					>
						Next
					</Button>
				</Tooltip>
			</div>

			{loading && <Loading size={42} />}

			{!loading && (
				<div className={styles.assistantContent}>
					<div
						className={`${styles.contentWrapper} ${
							step === 'Playground' ? styles.fullWidth : styles.limitedWidth
						}`}
					>
						{step === 'Form' && <RoleForm />}
						{step === 'Knowledge base' && <KnowledgeBase />}
						{step === 'Playground' && <Playground />}
						{step === 'Connections' && <Connections />}
					</div>
				</div>
			)}
		</div>
	)
}

export const prepareAssistantData = (assistantRoleData: Partial<AssistantRole>) =>
	omit(assistantRoleData, 'contextIds', 'connections', 'userId', 'createdAt', 'updatedAt')

export default Role
