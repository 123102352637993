import { App, Button, Form, Input, Select } from 'antd'
import {
	MessagingIntegration,
	MESSAGING_INTEGRATION_PROVIDERS,
} from '@neuron/types/integration/messaging'
import { DEFAULT_GMAIL_LABELS } from '@neuron/types/integration/messaging/api/gmail'
import { createMessagingIntegrationRequestSchema } from '@neuron/schemas/integration/messaging'
import React, { useEffect, useMemo, useState } from 'react'
import { GoogleOAuthProvider } from '@react-oauth/google'
import {
	FacebookOutlined,
	GoogleOutlined,
	InstagramOutlined,
	WhatsAppOutlined,
} from '@ant-design/icons'
import Joi from 'joi'
import GmailAuthorizationButton from './components/GmailAuthorizationButton/GmailAuthorizationButton'
import { useNavigate } from 'react-router-dom'
import { omit } from '@neuron/utils'
import useTryCatch from '../../../hooks/useTryCatch'
import useQuery from '../../../hooks/useQuery'
import useAsyncEffect from '../../../hooks/useAsyncEffect'
import query from '../../../utils/query'
import { formValidate } from '../../../utils/validation'
import Loading from '../../../components/Loading/Loading'
import Line from '../../../components/Line/Line'
import env from '../../../boot/env'
import { useAccountContext } from '../../../hooks/context/AccountContext'
import InstagramReactions from './components/InstagramReactions/InstagramReactions'
import GmailConfig from './components/GmailConfig/GmailConfig'
import IntegrationCard from './components/IntegrationCard/IntegrationCard'
import GlobalConfig from './components/GlobalConfig/GlobalConfig'
import styles from './Integration.module.scss'

const DEFAULT_INTEGRATION_DATA: Partial<MessagingIntegration> = {
	config: {
		intelligentResponseTime: true,
		intelligentWritingTime: true,
	},
}

const Integration = () => {
	const navigate = useNavigate()
	const [form] = Form.useForm()
	const { message } = App.useApp()
	const tryCatch = useTryCatch(message)

	const integrationId = useQuery().get('integrationId')
	const metaState = useQuery().get('state')
	const metaCode = useQuery().get('code')
	const metaError = useQuery().get('error')

	const accountContext = useAccountContext()
	const usingUserId = accountContext.useSubscribe((account) => account.usingUserId)

	const [integrationData, setIntegrationData] =
		useState<Partial<MessagingIntegration>>(DEFAULT_INTEGRATION_DATA)
	const [loading, setLoading] = useState<boolean>(false)
	const [processingIntegrationLoading, setProcessingIntegrationLoading] = useState<boolean>(false)

	useAsyncEffect(async () => {
		if (!integrationId || integrationData.id === integrationId) {
			return
		}

		await loadIntegration(integrationId)
	}, [integrationId])

	useAsyncEffect(async () => {
		if (!metaState && !metaCode) {
			return
		}

		if (metaState) {
			const parsedState = JSON.parse(metaState)
			const integrationData = await loadIntegration(parsedState.integrationId)

			if (integrationData && metaCode) {
				await createOrUpdateIntegration(integrationData, metaCode)
			}
		} else {
			showAuthorizationErrorMessage()
		}
	}, [metaState, metaCode])

	useEffect(() => {
		if (metaError) {
			showAuthorizationErrorMessage()
		}
	}, [metaError])

	useEffect(() => {
		form.resetFields()
	}, [integrationData?.id])

	const loadIntegration = async (integrationId: string): Promise<MessagingIntegration | void> => {
		setLoading(true)
		return await tryCatch<MessagingIntegration>(
			async () => {
				const integration = await query<MessagingIntegration>('/integration/messaging/get', 'GET', {
					params: {
						id: integrationId,
					},
					withCredentials: true,
				})
				setIntegrationData(integration)
				setLoading(false)
				return integration
			},
			() => setLoading(false),
		)
	}

	const onMetaAuthorization = async () => {
		let currentIntegrationId: string | undefined = undefined
		if (integrationId) {
			currentIntegrationId = integrationId
		} else if (integrationData.id) {
			currentIntegrationId = integrationData.id
		} else {
			const newIntegration = await createNewIntegration()
			if (!newIntegration) {
				return
			}
			currentIntegrationId = newIntegration.id
		}

		const params: Record<string, string> = {
			client_id: env.META_APP_ID,
			redirect_uri: `https://${env.DOMAIN_NAME}/personas/integration`,
			response_type: 'code',
			state: JSON.stringify({
				integrationId: currentIntegrationId,
			}),
			...getMetaConfig(integrationData.provider),
		}

		const oauth2Endpoint = 'https://www.facebook.com/v20.0/dialog/oauth'
		const oauthURL = `${oauth2Endpoint}?${new URLSearchParams(params).toString()}`
		window.location.replace(oauthURL)
	}

	const removeIntegration = () => {
		setIntegrationData({ ...integrationData, credentials: undefined })
	}

	const showAuthorizationErrorMessage = () => {
		message
			.open({
				type: 'warning',
				content:
					'It seems that there is a problem with integration setup. ' +
					'Please try to authorize the integration again',
			})
			.then(() => {})
	}

	const updateIntegrationData = (
		value: Record<string, any>,
		values: Partial<MessagingIntegration>,
	) => {
		// TODO: Refactor it
		if (value.provider && value.provider !== integrationData.provider) {
			setIntegrationData((integration) => ({
				...(integration ?? {}),
				provider: value.provider,
				config: {
					...integration.config!,
					activeLabels: value.provider === 'gmail' ? [DEFAULT_GMAIL_LABELS[0]] : undefined,
				},
				credentials: undefined,
			}))
		} else if (value.config) {
			if (!value.config.excludeMessageDescriptions?.length) {
				values.config!.excludeMessageDescriptions = undefined
			}

			setIntegrationData((integration) => ({
				...(integration ?? {}),
				config: {
					...integration.config!,
					...values.config,
				},
			}))
		} else if (value.reactions) {
			if (
				value.reactions.instagramCommentConversation === true ||
				value.reactions.instagramCommentConversation === false
			) {
				if (value.reactions.instagramCommentConversation === true) {
					setIntegrationData((integration) => ({
						...(integration ?? {}),
						reactions: { ...integration.reactions, instagramCommentConversation: {} },
					}))
				}
				if (value.reactions.instagramCommentConversation === false) {
					setIntegrationData((integration) => ({
						...(integration ?? {}),
						reactions: { ...integration.reactions, instagramCommentConversation: undefined },
					}))
				}
			} else {
				if (
					values.reactions?.instagramCommentConversation?.startConversationMessages &&
					!values.reactions.instagramCommentConversation.startConversationMessages.length
				) {
					values.reactions.instagramCommentConversation.startConversationMessages = undefined
				}
				setIntegrationData((integration) => ({
					...(integration ?? {}),
					reactions: values.reactions,
				}))
			}
		} else {
			setIntegrationData((integration) => ({ ...(integration ?? {}), ...value }))
		}
	}

	const createNewIntegration = async () => {
		return await tryCatch<MessagingIntegration>(
			async () => {
				setProcessingIntegrationLoading(true)

				const parsedIntegrationData = getParsedIntegrationData(integrationData)
				const isValid = checkFormValidate(parsedIntegrationData)
				if (!isValid) {
					message.open({
						type: 'error',
						content: 'Incorrect data in the form. Check and correct form fields.',
					})
					setProcessingIntegrationLoading(false)
					return
				}

				return await query<MessagingIntegration>('/integration/messaging/create', 'POST', {
					data: {
						...parsedIntegrationData,
						usingUserId,
					},
					timeout: 1000 * 60 * 2,
					withCredentials: true,
				})
			},
			() => {
				setProcessingIntegrationLoading(false)
			},
		)
	}

	const createOrUpdateIntegration = async (
		integrationData: Partial<MessagingIntegration>,
		accessCode?: string,
	) => {
		await tryCatch(
			async () => {
				setProcessingIntegrationLoading(true)

				const parsedIntegrationData = getParsedIntegrationData({ ...integrationData, accessCode })
				const isValid = checkFormValidate(parsedIntegrationData)
				if (!isValid) {
					message.open({
						type: 'error',
						content: 'Incorrect data in the form. Check and correct form fields.',
					})
					setProcessingIntegrationLoading(false)
					return
				}

				let integration: MessagingIntegration | undefined

				if (integrationData?.id) {
					integration = await query<MessagingIntegration>('/integration/messaging/update', 'POST', {
						data: {
							...parsedIntegrationData,
							removeCredentials: integrationData.credentials === undefined ? true : undefined,
						},
						timeout: 1000 * 60 * 2,
						withCredentials: true,
					})
				} else {
					integration = await query<MessagingIntegration>('/integration/messaging/create', 'POST', {
						data: {
							...parsedIntegrationData,
							usingUserId,
						},
						timeout: 1000 * 60 * 2,
						withCredentials: true,
					})
				}

				if (accessCode) {
					setIntegrationData(integration)
					navigate(`/personas/integration?integrationId=${integration.id}`)

					if (!integration.credentials) {
						showAuthorizationErrorMessage()
					}
					setProcessingIntegrationLoading(false)
				} else {
					message.open({
						type: 'success',
						content: 'Integration was updated',
					})
					navigate('/personas/integrations')
				}
			},
			() => {
				setProcessingIntegrationLoading(false)
			},
		)
	}

	const checkFormValidate = (data?: Partial<MessagingIntegration>): boolean => {
		if (!data) {
			return false
		}
		const validate = formValidate(data, publicIntegrationSchema)
		return validate ? !Object.keys(validate).length : true
	}

	const providerIcon = useMemo(() => {
		if (integrationData.provider === 'messenger') {
			return <FacebookOutlined />
		}
		if (integrationData.provider === 'instagram') {
			return <InstagramOutlined />
		}
		if (integrationData.provider === 'whatsapp') {
			return <WhatsAppOutlined />
		}
		if (integrationData.provider === 'gmail') {
			return <GoogleOutlined />
		}
	}, [integrationData.provider])

	if (loading) {
		return <Loading />
	}

	const isValidForm = checkFormValidate(
		omit(integrationData, 'createdAt', 'updatedAt', 'userId', 'status', 'credentials'),
	)

	return (
		<div className={styles.content}>
			<div className={styles.container}>
				<div className={styles.formWrapper}>
					<Form
						className={styles.form}
						form={form}
						autoComplete='true'
						initialValues={integrationData}
						onValuesChange={updateIntegrationData}
						labelCol={{ span: 4 }}
						wrapperCol={{ span: 14 }}
						layout='vertical'
					>
						<h3 className={styles.headingLabel}>
							{integrationData?.provider ? integrationData.provider : 'new'} integration
						</h3>
						<Line />

						<Form.Item
							rules={[
								{ required: true, message: 'Please fill integration name' },
								{ message: 'Integration name must have a minimum of 3 characters', min: 3 },
								{ message: 'Integration name can have a maximum of 120 characters', max: 120 },
							]}
							className={styles.formItem}
							label='Integration name'
							name='name'
						>
							<Input
								count={{
									show: true,
								}}
								maxLength={120}
								size='large'
								className={styles.formInput}
								placeholder='e.g Company messenger'
							/>
						</Form.Item>

						<Form.Item
							rules={[{ required: true, message: 'Please select language' }]}
							name='provider'
							label='Provider'
							className={styles.formItem}
						>
							<Select
								size='large'
								showSearch
								className={styles.capitalize}
								options={MESSAGING_INTEGRATION_PROVIDERS.filter(
									(provider) => provider !== 'playground',
								).map((provider) => ({
									label: `${provider.charAt(0).toUpperCase()}${provider.slice(1)}`,
									value: provider,
								}))}
							/>
						</Form.Item>

						{integrationData?.provider && !integrationData.credentials && (
							<div className={styles.providerAuthWrapper}>
								{['messenger', 'instagram', 'whatsapp'].includes(integrationData.provider) && (
									<Button
										type='primary'
										loading={processingIntegrationLoading}
										disabled={processingIntegrationLoading}
										size='large'
										icon={providerIcon}
										onClick={onMetaAuthorization}
										className={styles.facebookButton}
									>
										Login via Facebook Business
									</Button>
								)}

								{integrationData.provider === 'gmail' && (
									<GoogleOAuthProvider clientId={env.GMAIL_CLIENT_ID}>
										<GmailAuthorizationButton
											showAuthorizationErrorMessage={showAuthorizationErrorMessage}
											createOrUpdateIntegration={(accessCode) =>
												createOrUpdateIntegration(integrationData, accessCode)
											}
											processingIntegrationLoading={processingIntegrationLoading}
											setProcessingIntegrationLoading={setProcessingIntegrationLoading}
											providerIcon={providerIcon}
										/>
									</GoogleOAuthProvider>
								)}
							</div>
						)}

						{!!integrationData.credentials && (
							<div className={styles.providerAuthWrapper}>
								{processingIntegrationLoading ? (
									<Loading className={styles.processingIntegrationLoading} size={48} />
								) : (
									<>
										<div className={styles.authorizedAccounts}>
											{integrationData.credentials.providerNames.map(
												(integrationProviderName, index) => (
													<IntegrationCard
														key={`${integrationProviderName}-${index}`}
														integrationProviderName={integrationProviderName}
														credentials={integrationData.credentials!}
														integrationIndex={index}
														status={integrationData.status}
														providerIcon={providerIcon}
													/>
												),
											)}
										</div>

										<Button
											danger
											className={styles.removeIntegrationButton}
											onClick={removeIntegration}
										>
											Remove
										</Button>
									</>
								)}

								<GlobalConfig />
								{integrationData.provider === 'gmail' && <GmailConfig />}
								{integrationData.provider === 'instagram' && (
									<InstagramReactions integrationData={integrationData} />
								)}
							</div>
						)}
					</Form>
				</div>
			</div>

			<div className={styles.footer}>
				<Line className={styles.footerLine} />

				<div className={styles.footerButtons}>
					<Button onClick={() => navigate('/personas/integrations')} size='large'>
						Cancel
					</Button>

					<Button
						loading={processingIntegrationLoading}
						disabled={!isValidForm}
						onClick={() => createOrUpdateIntegration(integrationData)}
						type='primary'
						size='large'
					>
						{integrationData?.id ? 'Update' : 'Create'}
					</Button>
				</div>
			</div>
		</div>
	)
}

const publicIntegrationSchema = Joi.object({
	...createMessagingIntegrationRequestSchema,
	id: Joi.string().optional(),
}).required()

const getParsedIntegrationData = (
	integrationData: Partial<MessagingIntegration> & { accessCode?: string },
) => {
	if (!integrationData.provider) {
		return
	}

	const getIntegrationName = () => {
		if (!integrationData.name || integrationData.name.length < 3) {
			return `${
				integrationData.provider!.charAt(0).toUpperCase() + integrationData.provider!.slice(1)
			} integration`
		}
		return integrationData.name
	}

	return omit(
		{
			...integrationData,
			name: getIntegrationName(),
		},
		'createdAt',
		'updatedAt',
		'userId',
		'status',
		'credentials',
	)
}

const getMetaConfig = (provider?: string): Record<string, string> => {
	if (provider === 'messenger') {
		return {
			scope: 'pages_messaging,pages_read_engagement,pages_manage_metadata,pages_show_list',
		}
	}
	if (provider === 'instagram') {
		return {
			scope:
				'instagram_basic,instagram_manage_messages,business_management,pages_read_engagement,pages_manage_metadata,pages_messaging,pages_show_list',
		}
	}
	if (provider === 'whatsapp') {
		return {
			config_id: env.WHATSAPP_CONFIG_ID,
		}
	}
	return {}
}

export default Integration
