// import { parseEnv } from '@neuron/utils'
//
// const getEnv = (envName: string): string => {
// 	const processEnv = process.env
// 	return (
// 		// eslint-disable-next-line no-underscore-dangle
// 		((typeof window !== 'undefined' ? window : {}) as any).__ENVS__?.[`__${envName}__`] ||
// 		processEnv[envName] ||
// 		processEnv[`REACT_APP_${envName}`] ||
// 		undefined
// 	)
// }

const env = {
	VERSION: '0.9.0',
	ENVIRONMENT: 'staging',
	DOMAIN_NAME: 'staging.quantumneuron.eu',

	META_APP_ID: '1861091531072362',
	WHATSAPP_CONFIG_ID: '1897260674067934',

	GMAIL_CLIENT_ID: '700161868029-3jjvs6br9tifetc90lpcjs49t5tmcokv.apps.googleusercontent.com',
	GOOGLE_SHEETS_CLIENT_ID:
		'350457465838-8ph4d057s7rrolfespe0ipjv5qanodhe.apps.googleusercontent.com',
	GOOGLE_DOCS_CLIENT_ID: '291121316570-5g6j81qgbminu1tp79hqgdiq6hdom41i.apps.googleusercontent.com',

	STRIPE_PUBLIC_KEY:
		'pk_test_51Oc6AvJCoJjHFYt81jeX6zNZD2Ms1JDxx8lch8GvP5GTTKYLWm2zqYA0UXAmqpmtRt6IrN4gO6ArgxWo6DJcgB6i00Uw0BVsXl',
}

export default env
