import { App, Form, Select } from 'antd'
import ShadowForm from '../../components/ShadowForm/ShadowForm'
import { Context } from '@neuron/types/context'
import useAsyncEffect from '../../../../../../hooks/useAsyncEffect'
import query from '../../../../../../utils/query'
import useTryCatch from '../../../../../../hooks/useTryCatch'
import { useAccountContext } from '../../../../../../hooks/context/AccountContext'
import { useEffect, useState } from 'react'
import Loading from '../../../../../../components/Loading/Loading'
import { useAssistantRoleContext } from '../../../../../../hooks/context/AssistantRoleContext'
import styles from './ExistingContexts.module.scss'

type Props = {
	alreadyAddedContexts?: Context[]
}

const ExistingContexts = ({ alreadyAddedContexts }: Props) => {
	const { message } = App.useApp()
	const tryCatch = useTryCatch(message)

	const assistantRoleContext = useAssistantRoleContext()
	const selectedExistingContextIds = assistantRoleContext.useSubscribe(
		(context) => context.selectedExistingContextIds,
	)

	const accountContext = useAccountContext()
	const usingUserId = accountContext.useSubscribe((account) => account.usingUserId)

	const [contexts, setContexts] = useState<Context[]>()
	const [existingContextsToAdd, setExistingContextsToAdd] = useState<Context[]>()
	const [loading, setLoading] = useState<boolean>(true)

	useAsyncEffect(async () => {
		await tryCatch(async () => {
			const resContexts = await query<Context[]>('/context/forUser', 'GET', {
				withCredentials: true,
				params: {
					usingUserId,
				},
			})
			setContexts(resContexts)
		})
		setLoading(false)
	}, [usingUserId])

	useEffect(() => {
		if (!contexts?.length) {
			return
		}
		setExistingContextsToAdd(
			contexts.filter(
				(context) =>
					!alreadyAddedContexts?.some(
						(alreadyAddedContext) => alreadyAddedContext.id === context.id,
					),
			),
		)
	}, [contexts, alreadyAddedContexts])

	const updateAddedContextIds = (_value: Record<string, any>, values: any) => {
		assistantRoleContext.setSelectedExistingContextIds(values.selectedContextIds)
	}

	return (
		<ShadowForm
			initialValues={{
				selectedContextIds: selectedExistingContextIds,
			}}
			onValuesChange={updateAddedContextIds}
		>
			<Form.Item
				className={styles.formItem}
				name='selectedContextIds'
				label='Existing knowledge bases to be added'
			>
				{loading ? (
					<Loading size={40} />
				) : (
					<Select
						allowClear
						mode='multiple'
						placeholder='Existing knowledge bases'
						size='large'
						options={existingContextsToAdd?.map((context) => ({
							label: context.resource.name,
							value: context.id,
							disabled: context.status !== 'indexed' && context.status !== 'needReindex',
						}))}
					/>
				)}
			</Form.Item>
		</ShadowForm>
	)
}

export default ExistingContexts
