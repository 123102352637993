import React, { useEffect, useMemo, useState } from 'react'
import { ChatMessage, MessageAttachedFileIds } from '@neuron/types/chat/chatMessage'
import { LOADING_CONTENT, ERROR_CONTENT, CANCEL_CONTENT, messageRole } from '@neuron/utils/consts'
import { attachedFilesToIds, messageContentIsPending } from '@neuron/utils/assistant'
import { ChatSession } from '@neuron/types/chat/chatSession'
import useAsyncEffect from '../../hooks/useAsyncEffect'
import { App, Modal } from 'antd'
import query from '../../utils/query'
import { useNavigate } from 'react-router-dom'
import useQuery from '../../hooks/useQuery'
import Loading from '../../components/Loading/Loading'
import { socket } from '../../global/SocketManager'
import { Chat as ChatType, UpdateChatRequest } from '@neuron/types/chat'
import ChatForm from '../../components/ChatForm/ChatForm'
import useTryCatch from '../../hooks/useTryCatch'
import { useChatContext } from '../../hooks/context/ChatContext'
import { useEditorContext } from '../../hooks/context/EditorContext'
import { MIN_CHAT_FOOTER_HEIGHT } from '../../utils/consts'
import { PublicProgressStep } from '@neuron/types/progressSteps'
import { v4 as uuidv4 } from 'uuid'
import Chat from '../../components/Chat/Chat'
import { toPublicChat, toPublicMessage, toPublicSession } from '../../utils/chatMappers'
import { useAttachFiles } from '../../components/Chat/hooks'
import { PublicChat, PublicMessage, PublicSession } from '../../types/Chat'
import { checkLastFetchedMessage, getUpdatedMessages } from '../../utils/helpers'
import { utcDate } from '@neuron/utils/dates'
import styles from './AiChat.module.scss'

const RESPONSE_MESSAGE_TIMEOUT = 1000 * 60
const LOADING_MESSAGE_TIMEOUT = 1000 * 60 * 5

const DEFAULT_MESSAGE: PublicMessage = {
	id: uuidv4(),
	sessionId: '',
	role: 'system',
	content:
		'' +
		'Hi, welcome to **Quantum Neuron**, your intelligent chatbot! 🚀🔥\n' +
		'Customize the conversation by adding **own data from files or links**. ' +
		'Choose from a variety of **AI-based tools** to generate exactly what you need! 🔗🤖✨',
	createdAt: utcDate(),
}

const AiChat = () => {
	const navigate = useNavigate()
	const { message } = App.useApp()
	const tryCatch = useTryCatch(message)

	const attachFiles = useAttachFiles()

	const sessionId = useQuery().get('sessionId')
	const chatId = useQuery().get('chatId')

	const chatContext = useChatContext()
	const chat = chatContext.useSubscribe((context) => context.chat)
	const attachedSessionIds = chatContext.useSubscribe((context) => context.attachedSessionIds)
	const chatFiles = chatContext.useSubscribe((context) => context.chatFiles)
	const disableNewMessage = chatContext.useSubscribe((context) => context.disableNewMessage)

	const editorContext = useEditorContext()
	const toolType = editorContext.useSubscribe((context) => context.toolType)
	const editorFiles = editorContext.useSubscribe((context) => context.editorFiles)

	const [messages, setMessages] = useState<PublicMessage[]>([DEFAULT_MESSAGE])
	const [chatFormModalOpen, setChatFormModalOpen] = useState<boolean>(false)
	const [scrollDownVisible, setScrollDownVisible] = useState<boolean>(false)

	useEffect(() => {
		socket.on('chatStatusUpdated', updateChatStatus)
		return () => {
			socket.off('chatStatusUpdated', updateChatStatus)
		}
	}, [chat?.id])

	useEffect(() => {
		socket.on('updatedMessage', updateMessage)
		return () => {
			socket.off('updatedMessage', updateMessage)
		}
	}, [sessionId])

	useEffect(() => {
		socket.on('updatedProgressStep', updateProgressSteps)
		return () => {
			socket.off('updatedProgressStep', updateProgressSteps)
		}
	}, [messages])

	useEffect(() => {
		return () => {
			chatContext.clearChatFiles()
		}
	}, [])

	useEffect(() => {
		if (!messages.length) {
			setMessages([DEFAULT_MESSAGE])
			setScrollDownVisible(false)
			chatContext.setDisableNewMessage(false)
		}
	}, [!!messages.length])

	useAsyncEffect(async () => {
		await tryCatch(
			async () => {
				const chatInfo = await query<ChatType>('/chat/get', 'GET', {
					params: { id: chatId },
					withCredentials: true,
				})
				chatContext.setChat(toPublicChat(chatInfo))
			},
			undefined,
			{ message: 'Chat data error. Try again and refresh page.' },
		)
	}, [])

	useAsyncEffect(async () => {
		if (!chatId) {
			navigate(-1)
			return
		}

		await fetchChatSessions()
	}, [chatId])

	useAsyncEffect(async () => {
		if (sessionId) {
			setScrollDownVisible(false)
			await fetchChatMessages()
		}
	}, [sessionId])

	const cancelMessage = async () => {
		if (!disableNewMessage) {
			return
		}

		let messageContent: string = CANCEL_CONTENT

		await tryCatch(
			async () => {
				await query('/chat/message/cancel', 'POST', {
					data: { aiMessageId: messages[messages.length - 1].id },
					withCredentials: true,
				})
			},
			() => {
				messageContent = ERROR_CONTENT
			},
		)

		setMessages((messages) =>
			messages.map((message, index) =>
				index === messages.length - 1 ? { ...message, content: messageContent } : message,
			),
		)
		chatContext.setDisableNewMessage(false)
	}

	const fetchChatMessages = async () => {
		await tryCatch(async () => {
			const resMessages = await query<ChatMessage[]>('/chat/message/forSession', 'GET', {
				params: {
					sessionId,
				},
				withCredentials: true,
			})
			const publicMessages = resMessages.map(toPublicMessage)
			const fetchedMessages = [messages[0], ...publicMessages]

			const shouldDisableNewMessage = checkLastFetchedMessage(
				fetchedMessages,
				loadMessageProgressSteps,
				RESPONSE_MESSAGE_TIMEOUT,
				LOADING_MESSAGE_TIMEOUT,
			)

			chatContext.setDisableNewMessage(shouldDisableNewMessage)
			chatContext.setUserScrolling(false)
			setMessages(fetchedMessages)

			// Load files data
			for (const message of fetchedMessages.filter(
				(fetchedMessage) => attachedFilesToIds(fetchedMessage.attachedFileIds).length,
			)) {
				await addMessageFilesToChat(message)
			}
		}, newSession)
	}

	const loadMessageProgressSteps = async (chatMessage: PublicMessage) => {
		await tryCatch(
			async () => {
				const publicProgressSteps = await query<PublicProgressStep[]>('/progressStep/get', 'GET', {
					withCredentials: true,
					params: {
						messageId: chatMessage.id,
					},
				})
				chatContext.setProgressSteps(publicProgressSteps)
			},
			() => {},
			{ hideMessage: true },
		)
	}

	const fetchChatSessions = async (): Promise<PublicSession[]> => {
		return await tryCatch<PublicSession[]>(
			async () => {
				const resChatSessions = await query<ChatSession[]>('/chat/session/forChat', 'GET', {
					params: {
						chatId,
					},
					withCredentials: true,
				})

				if (resChatSessions.length) {
					const publicSessions = resChatSessions.map(toPublicSession)
					chatContext.setChatContext({
						chatSessions: publicSessions,
					})
					return publicSessions
				}

				return []
			},
			() => {},
		)
	}

	const updateMessage = async (updatedMessage: ChatMessage) => {
		if (updatedMessage.sessionId !== sessionId) {
			return
		}

		setMessages((messages) => getUpdatedMessages(messages, updatedMessage))

		if (!messageContentIsPending(updatedMessage.content)) {
			chatContext.setDisableNewMessage(false)
			await addMessageFilesToChat(updatedMessage)
		}
	}

	const updateProgressSteps = (progressStep: PublicProgressStep) => {
		if (messages.some((message) => message.id === progressStep.messageId)) {
			chatContext.setProgressStep(progressStep)
		}
	}

	const updateChatStatus = (updatedChat: PublicChat) => {
		if (updatedChat.id !== chat?.id) {
			return
		}
		chatContext.setChat({
			status: updatedChat.status,
		})

		if (updatedChat.status === 'active') {
			message.open({
				type: 'success',
				content: 'Chat has been refreshed!',
			})
		} else {
			message.open({
				type: 'error',
				content: 'Error while chat refreshing',
			})
			navigate('/chats')
		}
	}

	const updateSingleMessage = (message: PublicMessage) => {
		setMessages((messages) =>
			messages.map((currentMessage) =>
				currentMessage.id === message.id ? message : currentMessage,
			),
		)
	}

	const newSession = () => {
		navigate({ search: `?chatId=${chatId}` })
		setMessages([])
	}

	const moveToSession = (sessionId: string) => {
		navigate({ search: `?chatId=${chatId}&sessionId=${sessionId}` })
	}

	const deleteSession = async (selectedSessionId: string) => {
		await tryCatch(async () => {
			await query('/chat/session/delete', 'POST', {
				data: {
					id: selectedSessionId,
				},
				withCredentials: true,
			})
			chatContext.setChatContext({
				chatSessions: chatContext.storeRef.current?.chatSessions.filter(
					(session) => session.id !== selectedSessionId,
				),
			})

			if (sessionId === selectedSessionId) {
				newSession()
			}
		})
	}

	const sendNewMessage = async (messageContent: string) => {
		if (disableNewMessage || chat?.status !== 'active' || !chatId || !messages.length) {
			return
		}

		const filesToUpload = chatFiles
			.filter(
				(chatFile) =>
					chatFile.file &&
					Object.values(editorFiles).some((editorFileIds) => editorFileIds.includes(chatFile.id)),
			)
			.map((chatFile) => chatFile.file)
			.filter((file) => !!file) as File[]

		chatContext.setChatContext({
			attachedFileIds: [],
			disableNewMessage: true,
			userScrolling: false,
			footerHeight: MIN_CHAT_FOOTER_HEIGHT,
		})
		chatContext.clearProgressSteps()
		editorContext.clearEditor()

		await startChatWithAi(messageContent, editorFiles, filesToUpload)
	}

	const startChatWithAi = async (
		messageContent: string,
		attachedFileIds?: MessageAttachedFileIds,
		filesToUpload?: File[],
	) => {
		await tryCatch(
			async () => {
				const messageData = new FormData()
				const filteredAttachedSessionIds = attachedSessionIds.filter(
					(attachedSessionId) => attachedSessionId !== sessionId,
				)

				if (sessionId) {
					messageData.append('sessionId', sessionId)
				}
				if (attachedFileIds) {
					messageData.append('attachedFileIds', JSON.stringify(attachedFileIds))
				}
				if (toolType) {
					messageData.append('toolType', toolType)
				}
				if (filteredAttachedSessionIds.length) {
					for (const attachedSessionId of filteredAttachedSessionIds) {
						messageData.append('attachedSessionIds', attachedSessionId)
					}
				}
				if (filesToUpload?.length) {
					for (const file of filesToUpload) {
						messageData.append('files', file)
					}
				}
				messageData.append('content', messageContent)
				messageData.append('chatId', chatId!)

				const { chatSession, userChatMessage, aiChatMessage } = await query<{
					chatSession: ChatSession
					userChatMessage: ChatMessage
					aiChatMessage: ChatMessage
				}>('/chat/message/send', 'POST', {
					data: messageData,
					timeout: RESPONSE_MESSAGE_TIMEOUT,
					withCredentials: true,
				})

				await addMessageFilesToChat(userChatMessage)
				setMessages((messages) => [
					...messages,
					toPublicMessage(userChatMessage),
					toPublicMessage(aiChatMessage),
				])

				if (!sessionId) {
					chatContext.setChatContext({
						chatSessions: [
							toPublicSession(chatSession),
							...chatContext.storeRef.current!.chatSessions,
						],
					})
					moveToSession(chatSession.id)
				}
			},
			() => {
				const userTemplateMessage: PublicMessage = {
					id: uuidv4(),
					sessionId: '',
					createdAt: utcDate(),
					content: messageContent,
					role: messageRole.USER,
					attachedFileIds: toolType && attachedFilesToIds(editorFiles).length ? {} : undefined,
					toolType,
				}
				const assistantTemplateMessage: PublicMessage = {
					id: uuidv4(),
					sessionId: '',
					createdAt: utcDate(),
					content: ERROR_CONTENT,
					role: messageRole.ASSISTANT,
				}

				setMessages((messages) => [...messages, userTemplateMessage, assistantTemplateMessage])
				chatContext.setDisableNewMessage(false)
			},
			{ hideMessage: true },
		)
	}

	const addMessageFilesToChat = async (message: PublicMessage) => {
		const currentAttachedFileIds = chatContext.storeRef.current!.chatFiles.map(({ id }) => id)
		for (const messageFile of attachedFilesToIds(message.attachedFileIds)) {
			await attachFiles.loadChatFile(messageFile, currentAttachedFileIds)
		}
	}

	const onOpenEditChatModal = () => {
		if (chat?.status === 'active') {
			setChatFormModalOpen(true)
		}
	}

	const onCloseEditChatModal = () => {
		setChatFormModalOpen(false)
	}

	const updateChat = async (updatedChatData: UpdateChatRequest) => {
		await tryCatch(
			async () => {
				const updatedChat = await query<ChatType>('/chat/update', 'POST', {
					data: updatedChatData,
					withCredentials: true,
				})

				if (updatedChat.status === 'refreshing') {
					message.open({
						type: 'info',
						content: 'Chat updated. Start refreshing...',
					})
				} else {
					message.open({
						type: 'success',
						content: 'Chat updated',
					})
				}

				chatContext.setChat(toPublicChat(updatedChat))
			},
			undefined,
			{ message: 'Error while updating chat. Try again.' },
		)

		onCloseEditChatModal()
	}

	const resendMessage = async (retryMessage: PublicMessage) => {
		if (!chatId || !messages.length || disableNewMessage) {
			return
		}

		chatContext.setDisableNewMessage(true)

		const retryTemplateMessage: PublicMessage = {
			...retryMessage,
			id: uuidv4(),
			content: LOADING_CONTENT,
			attachedFileIds: undefined,
			parentMessageId: retryMessage.id,
		}

		setMessages([...messages, retryTemplateMessage])

		await tryCatch(
			async () => {
				chatContext.clearProgressSteps()
				for (const fileId of attachedFilesToIds(retryMessage.attachedFileIds)) {
					chatContext.removeChatFile(fileId)
				}

				const aiChatMessage = await query<ChatMessage>('/chat/message/retry', 'POST', {
					data: {
						aiMessageId: retryMessage.id,
						attachedSessionIds,
					},
					timeout: RESPONSE_MESSAGE_TIMEOUT,
					withCredentials: true,
				})

				setMessages((messages) =>
					messages.map((message) =>
						message.id === retryTemplateMessage.id ? toPublicMessage(aiChatMessage) : message,
					),
				)
			},
			() => {
				updateSingleMessage({
					...retryMessage,
					content: ERROR_CONTENT,
				})
			},
			{ hideMessage: true },
		)
	}

	const chatFormModal = useMemo(
		() => (
			<Modal
				destroyOnClose
				footer={null}
				className={styles.chatFormModal}
				title='Edit chat'
				open={chatFormModalOpen}
				onCancel={onCloseEditChatModal}
			>
				<div className={styles.modalContent}>
					<ChatForm
						chatId={chat?.id}
						onSubmit={(chatData) => updateChat(chatData as UpdateChatRequest)}
						onCancel={onCloseEditChatModal}
					/>
				</div>
			</Modal>
		),
		[chatFormModalOpen, chat],
	)

	if (!chat) {
		return <Loading size={48} />
	}

	return (
		<>
			{chatFormModal}

			<Chat
				messages={messages}
				sessionId={sessionId}
				sendNewMessage={sendNewMessage}
				newSession={newSession}
				moveToSession={moveToSession}
				cancelMessage={cancelMessage}
				deleteSession={deleteSession}
				scrollDownVisible={scrollDownVisible}
				setScrollDownVisible={setScrollDownVisible}
				resendMessage={resendMessage}
				onOpenEditChatModal={onOpenEditChatModal}
			/>
		</>
	)
}

export default AiChat
