import { useEffect, useState } from 'react'
import { App } from 'antd'
import { PublicMessagingIntegration } from '@neuron/types/integration/messaging'
import useTryCatch from '../../../hooks/useTryCatch'
import useAsyncEffect from '../../../hooks/useAsyncEffect'
import query from '../../../utils/query'
import Loading from '../../../components/Loading/Loading'
import IntegrationList from '../../../components/IntegrationList/IntegrationList'
import { socket } from '../../../global/SocketManager'
import { useAccountContext } from '../../../hooks/context/AccountContext'

const Integrations = () => {
	const { message } = App.useApp()
	const tryCatch = useTryCatch(message)

	const accountContext = useAccountContext()
	const usingUserId = accountContext.useSubscribe((account) => account.usingUserId)

	const [integrations, setIntegrations] = useState<PublicMessagingIntegration[]>([])
	const [selectedIntegrationIds, setSelectedIntegrationIds] = useState<string[]>([])
	const [loading, setLoading] = useState<boolean>(true)

	const removeDeletedIntegration = async (integrationId: string) => {
		const findIntegrationToRemove = integrations.find(
			(integration) => integration.id === integrationId,
		)
		if (findIntegrationToRemove) {
			message.open({
				type: 'success',
				content: `Integration "${findIntegrationToRemove.name}" was deleted.`,
			})
			setIntegrations((integrations) =>
				integrations.filter((integration) => integration.id !== integrationId),
			)
		}
	}

	const onLoadIntegrations = async () => {
		setLoading(true)
		await tryCatch(async () => {
			const resIntegrations = await query<PublicMessagingIntegration[]>(
				'/integration/messaging/filter',
				'GET',
				{
					withCredentials: true,
					params: {
						usingUserId,
					},
				},
			)
			setIntegrations(resIntegrations)
		})
		setLoading(false)
	}

	useAsyncEffect(onLoadIntegrations, [])

	useEffect(() => {
		socket.on('integrationDeleted', removeDeletedIntegration)
		return () => {
			socket.off('integrationDeleted', removeDeletedIntegration)
		}
	}, [integrations])

	const deleteIntegrations = async (integrationIds: string[]) => {
		for (const integrationId of integrationIds) {
			await tryCatch(
				async () => {
					const deletingIntegration = await query<PublicMessagingIntegration>(
						'/integration/messaging/delete',
						'POST',
						{
							data: { id: integrationId },
							withCredentials: true,
						},
					)
					setIntegrations((integrations) =>
						integrations.map((integration) =>
							integration.id === deletingIntegration.id ? deletingIntegration : integration,
						),
					)
				},
				undefined,
				{ message: 'Error while deleting integration' },
			)
		}

		setSelectedIntegrationIds([])
	}

	const selectIntegration = (integrationId: string) => {
		const hasSelectedAssistantId = selectedIntegrationIds.some((id) => id === integrationId)

		if (hasSelectedAssistantId) {
			setSelectedIntegrationIds((ids) => ids.filter((id) => id !== integrationId))
		} else {
			setSelectedIntegrationIds([...selectedIntegrationIds, integrationId])
		}
	}

	if (loading) {
		return <Loading />
	}

	return (
		<IntegrationList
			showCreateButton
			showEditButton
			integrations={integrations}
			selectedIntegrationIds={selectedIntegrationIds}
			selectIntegration={selectIntegration}
			deleteIntegrations={deleteIntegrations}
			onRefreshIntegrations={onLoadIntegrations}
		/>
	)
}

export default Integrations
