import { Language, UsingUserId } from '../global'
import { FailureObject } from '../global/failure'

export interface Template {
	id: string
	name: string
	status: TemplateStatus
	assistantRoleId?: string
	firstMessageContent: string
	provider: TemplateProvider
	templateLanguage: Language
	followupMessagesContents?: string[]
	error?: FailureObject

	// for emails
	subject?: string
	signature?: string
	customMailAddress?: string

	// for whatsapp
	whatsappTemplateId?: string

	userId: string
	createdAt: Date
}

export const TEMPLATE_STATUSES = ['active', 'pending', 'rejected', 'error'] as const
export type TemplateStatus = (typeof TEMPLATE_STATUSES)[number]

export const TEMPLATE_PROVIDERS = ['gmail', 'whatsapp'] as const
export type TemplateProvider = (typeof TEMPLATE_PROVIDERS)[number]

export type NewTemplate = Omit<
	Template,
	'id' | 'status' | 'whatsappTemplateId' | 'error' | 'createdAt' | 'userId'
>

export type GetTemplateRequest = Pick<Template, 'id'>

export type GetUserTemplatesRequest = UsingUserId

export type CreateTemplateRequest = Omit<
	Template,
	'id' | 'status' | 'whatsappTemplateId' | 'templateLanguage' | 'error' | 'userId' | 'createdAt'
> &
	UsingUserId

export type UpdateTemplateRequest = Omit<
	Template,
	'status' | 'whatsappTemplateId' | 'templateLanguageCode' | 'userId' | 'createdAt'
>

export type DeleteTemplateRequest = Pick<Template, 'id'>
