import React from 'react'
import { Table, type TableProps, Tag } from 'antd'
import { Product } from '@neuron/types/order'
import { assistantRoleTypeToName } from '../../../../utils/helpers'

type Props = {
	products: Product[]
}

const OrderProductsTable = ({ products }: Props) => {
	const productColumns: TableProps<Product>['columns'] = [
		{
			title: 'Type',
			dataIndex: 'type',
			key: 'type',
			render: (value) => <Tag>{value}</Tag>,
			width: 70,
		},
		{
			title: 'Additional Cost',
			dataIndex: 'additionalCost',
			key: 'additionalCost',
			render: (value) => (value / 100).toFixed(2),
			width: 70,
		},
		{
			title: 'Personas Number',
			dataIndex: 'personasNumber',
			key: 'personasNumber',
			render: (value) => (value ? value : '-'),
			width: 60,
		},
		{
			title: 'Roles',
			dataIndex: 'roles',
			key: 'roles',
			ellipsis: true,
			render: (roles) => (roles ? roles.map(assistantRoleTypeToName).join(', ') : '-'),
			width: 120,
		},
	]

	return <Table columns={productColumns} dataSource={products} pagination={false} rowKey='roles' />
}

export default OrderProductsTable
