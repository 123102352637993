import React from 'react'
import { App, Button, Table, Tag, Tooltip } from 'antd'
import type { TableProps } from 'antd'
import { formatDate } from '@neuron/utils/dates'
import { Context, ContextStatus, SourceType } from '@neuron/types/context'
import { InlineLoading } from '../../../../../../components/Loading/Loading'
import useTryCatch from '../../../../../../hooks/useTryCatch'
import query from '../../../../../../utils/query'
import { contextStatusToColor } from '../../../../../../utils/helpers'
import { CloseOutlined, ExportOutlined, SyncOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import { useAssistantRoleContext } from '../../../../../../hooks/context/AssistantRoleContext'
import { AssistantRole } from '@neuron/types/assistant/assistantRole'
import styles from './ContextTable.module.scss'

type Props = {
	assistantRoleId: string
	contexts: Context[]
}

const ContextTable = ({ assistantRoleId, contexts }: Props) => {
	const navigate = useNavigate()
	const { message } = App.useApp()
	const tryCatch = useTryCatch(message)

	const assistantRoleContext = useAssistantRoleContext()

	const pendingStatus = (status: ContextStatus) => status === 'deleting' || status === 'refreshing'

	const onRefreshContext = async (contextId: number) => {
		await tryCatch(async () => {
			await query('/context/refresh', 'POST', {
				data: { id: contextId },
				withCredentials: true,
			})
		})
	}

	const onRemoveContextFromRole = async (contextId: number) => {
		await tryCatch(
			async () => {
				const updatedAssistantRole = await query<AssistantRole>(
					'/assistant/role/updateContexts',
					'POST',
					{
						data: {
							id: assistantRoleId,
							contextIds: [
								...contexts.map((context) => context.id).filter((id) => id !== contextId),
							],
						},
					},
				)
				assistantRoleContext.setData(updatedAssistantRole)
			},
			undefined,
			{
				message: 'Error while removing context from role',
			},
		)
	}

	const columns: TableProps<ContextTableData>['columns'] = [
		{
			title: 'Name',
			dataIndex: 'name',
			key: 'name',
			className: `${styles.contextValue} ${styles.contextName}`,
			ellipsis: {
				showTitle: false,
			},
			render: (value) => (
				<Tooltip placement='topLeft' title={value}>
					{value}
				</Tooltip>
			),
		},
		{
			title: 'Type',
			dataIndex: 'type',
			className: `${styles.contextValue} ${styles.contextType}`,
			key: 'type',
			width: 100,
		},
		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
			className: styles.contextValue,
			render: (status: ContextStatus) => (
				<Tag color={contextStatusToColor[status]}>
					{status === 'refreshing' && (
						<InlineLoading iconClassName={styles.loading} color='#fff' size={12} />
					)}
					<span>{status}</span>
				</Tag>
			),
			width: 140,
		},
		{
			title: 'Refreshed date',
			dataIndex: 'reindexedAt',
			key: 'reindexedAt',
			className: styles.contextValue,
			render: (date) => formatDate(date),
			width: 180,
		},
		{
			title: 'Created date',
			dataIndex: 'createdAt',
			key: 'createdAt',
			className: styles.contextValue,
			render: (date) => formatDate(date),
			width: 180,
		},
		{
			title: 'Action',
			key: 'action',
			className: styles.contextValue,
			render: (record: ContextTableData & { key: number }) => (
				<div className={styles.actions}>
					<Tooltip key='refresh' trigger={['hover', 'focus']} title='Refresh'>
						<Button
							disabled={pendingStatus(record.status)}
							icon={<SyncOutlined />}
							onClick={async () => {
								if (!pendingStatus(record.status)) {
									await onRefreshContext(record.key)
								}
							}}
						/>
					</Tooltip>
					<Tooltip key='details' trigger={['hover', 'focus']} title='Details'>
						<Button
							icon={<ExportOutlined />}
							onClick={() => {
								navigate('/contexts')
							}}
						/>
					</Tooltip>
					<Tooltip key='remove' trigger={['hover', 'focus']} title='Remove from role'>
						<Button
							danger
							disabled={record.status === 'deleting'}
							icon={<CloseOutlined />}
							onClick={async () => {
								if (record.status !== 'deleting') {
									await onRemoveContextFromRole(record.key)
								}
							}}
						/>
					</Tooltip>
				</div>
			),
			width: 180,
		},
	]

	const contextsToContextTable = (): Array<ContextTableData & { key: number }> => {
		return contexts.map((context) => ({
			key: context.id,
			name: context.resource.name,
			type: context.resource.type,
			status: context.status,
			reindexedAt: context.reindexedAt,
			createdAt: context.createdAt,
		}))
	}

	return <Table columns={columns} dataSource={contextsToContextTable()} />
}

interface ContextTableData {
	name: string
	type: SourceType
	status: ContextStatus
	reindexedAt?: Date
	createdAt: Date
}

export default ContextTable
