import React, { useMemo, useState } from 'react'
import { App, Card, Statistic, Typography, List, Button } from 'antd'
import { useAccountContext } from '../../../hooks/context/AccountContext'
import useTryCatch from '../../../hooks/useTryCatch'
import useAsyncEffect from '../../../hooks/useAsyncEffect'
import query from '../../../utils/query'
import { Order, PersonaProduct, RolesProduct } from '@neuron/types/order'
import { Agreement } from '@neuron/types/agreement'
import { formatDate, utcDate } from '@neuron/utils/dates'
import { assistantRoleTypeToName } from '../../../utils/helpers'
import { useNavigate } from 'react-router-dom'
import styles from './Overview.module.scss'

const Overview = () => {
	const navigate = useNavigate()
	const { message } = App.useApp()
	const tryCatch = useTryCatch(message)

	const accountContext = useAccountContext()
	const usingUserId = accountContext.useSubscribe((account) => account.usingUserId)

	const [orders, setOrders] = useState<Order[]>([])
	const [agreement, setAgreement] = useState<Agreement | null>(null)

	// Load Orders and Agreement
	const onLoadUserBillingInfo = async () => {
		await tryCatch(async () => {
			const orders = await query<Order[]>('/order/user/get', 'GET', { params: { usingUserId } })
			const agreement = await query<Agreement>('/agreement/user/current', 'GET', {
				params: { usingUserId },
			})

			setOrders(orders)
			setAgreement(agreement)
		})
	}

	useAsyncEffect(onLoadUserBillingInfo, [])

	// Memoized Calculations
	const upcomingPayments = useMemo(() => {
		const now = utcDate()

		const monthlyPayments = orders
			.filter(
				(order) =>
					(order.status === 'active' || order.status === 'paymentProblem') &&
					order.paymentInterval === 'month' &&
					order.nextPaymentAt &&
					utcDate(order.nextPaymentAt) > now,
			)
			.reduce((total, order) => total + order.totalPrice, 0)

		const oneTimePayments = orders
			.filter(
				(order) =>
					(order.status === 'active' || order.status === 'paymentProblem') &&
					order.paymentInterval === 'once',
			)
			.reduce((total, order) => total + order.totalPrice, 0)

		return {
			monthly: monthlyPayments / 100,
			oneTime: oneTimePayments / 100,
		}
	}, [orders])

	const personasNumber = useMemo(() => {
		return orders.reduce((total, order) => {
			return (
				total +
				order.products
					.filter((product) => product.type === 'personas')
					.reduce((sum, product) => sum + (product as PersonaProduct).personasNumber, 0)
			)
		}, 0)
	}, [orders])

	const availableRoles = useMemo(() => {
		const roles = orders.flatMap((order) =>
			order.products.flatMap((product) => {
				if (product.type === 'personas') {
					return (product as PersonaProduct).roles || []
				} else if (product.type === 'roles') {
					return (product as RolesProduct).roles
				}
				return []
			}),
		)
		return [...new Set(roles)]
	}, [orders])

	// Determine payment status
	const paymentStatus = useMemo(() => {
		const hasActiveOrdersWithIssues = orders.some((order) => order.status === 'paymentProblem')
		return hasActiveOrdersWithIssues
			? 'There are issues with some payments.'
			: 'All payments are up to date.'
	}, [orders])

	return (
		<div className={styles.container}>
			<h3 className={styles.headingLabel}>Overview</h3>

			<div className={styles.rowCards}>
				<Card title='Agreement Details'>
					<Statistic
						title='Start Date'
						loading={!agreement}
						value={agreement?.startAt ? formatDate(agreement.startAt).split(' ')[0] : 'N/A'}
					/>
					<Statistic
						title='End Date'
						loading={!agreement}
						value={agreement?.endAt ? formatDate(agreement.endAt).split(' ')[0] : 'N/A'}
					/>
				</Card>

				<Card title='Products Summary'>
					<div className={styles.rowContent}>
						<div>
							<Statistic title='Total Personas' value={personasNumber} />
							<Statistic title='Total Roles' value={availableRoles.length} />
						</div>

						<div className={styles.rolesListWrapper}>
							<div className={styles.rolesListHeading}>Available Roles</div>
							<List
								size='small'
								bordered
								className={styles.rolesList}
								dataSource={availableRoles}
								renderItem={(role) => (
									<List.Item>
										<Typography.Text>{assistantRoleTypeToName(role)}</Typography.Text>
									</List.Item>
								)}
							/>
						</div>
					</div>
				</Card>
			</div>

			<Card title='Payments Summary'>
				<div className={styles.rowContent}>
					<div>
						<Statistic
							title='Upcoming Monthly Payments'
							value={upcomingPayments.monthly}
							precision={2}
						/>
						<Statistic
							title='Immediate One-Time Payments'
							value={upcomingPayments.oneTime}
							precision={2}
							style={{ marginTop: 16 }}
						/>
						<Typography.Paragraph
							style={{ marginTop: 16, color: paymentStatus.includes('issues') ? 'red' : 'green' }}
						>
							{paymentStatus}
						</Typography.Paragraph>
					</div>

					<div className={styles.paymentButtons}>
						<Button onClick={() => navigate('/billing/history')}>Go to payment history</Button>
						<Button onClick={() => navigate('/billing/payment-methods')}>
							Go to payment methods
						</Button>
					</div>
				</div>
			</Card>
		</div>
	)
}

export default Overview
