import React, { useMemo, useState } from 'react'
import { App, Modal } from 'antd'
import { NewTemplate, Template, UpdateTemplateRequest } from '@neuron/types/leadsGroup/template'
import { useAccountContext } from '../../../hooks/context/AccountContext'
import useTryCatch from '../../../hooks/useTryCatch'
import query from '../../../utils/query'
import useAsyncEffect from '../../../hooks/useAsyncEffect'
import TemplateForm from './components/TemplateForm/TemplateForm'
import Loading from '../../../components/Loading/Loading'
import TemplateList from './components/TemplateList/TemplateList'
import styles from './Templates.module.scss'

const Templates = () => {
	const { message } = App.useApp()
	const tryCatch = useTryCatch(message)

	const accountContext = useAccountContext()
	const usingUserId = accountContext.useSubscribe((account) => account.usingUserId)

	const [templates, setTemplates] = useState<Template[]>([])
	const [selectedTemplateId, setSelectedTemplateId] = useState<string | undefined>()
	const [openTemplateModal, setOpenTemplateModal] = useState<boolean>(false)
	const [loading, setLoading] = useState<boolean>(true)

	const onLoadTemplates = async () => {
		setLoading(true)
		await tryCatch(async () => {
			const resTemplates = await query<Template[]>('/leadsGroup/templates/user', 'GET', {
				withCredentials: true,
				params: {
					usingUserId,
				},
			})
			setTemplates(resTemplates)
		})
		setLoading(false)
	}

	useAsyncEffect(onLoadTemplates, [])

	const deleteTemplates = async (templateIds: string[]) => {
		const expectTemplateIds: string[] = []

		for (const templateId of templateIds) {
			await tryCatch(
				async () => {
					await query('/leadsGroup/template/delete', 'POST', {
						data: { id: templateId },
						withCredentials: true,
					})
					message.open({
						type: 'success',
						content: 'Template was deleted',
					})
				},
				() => expectTemplateIds.push(templateId),
				{ message: 'Error while deleting template' },
			)
		}

		setTemplates((templatesData) => {
			return templatesData.filter(
				(templateData) =>
					!templateIds.includes(templateData.id) || expectTemplateIds.includes(templateData.id),
			)
		})
	}

	const createOrUpdateTemplate = async (data: UpdateTemplateRequest | NewTemplate) => {
		if ('id' in data) {
			await tryCatch(
				async () => {
					const updatedTemplate = await query<Template>('/leadsGroup/template/update', 'POST', {
						data,
						withCredentials: true,
					})

					message.open({
						type: 'success',
						content: 'Template was updated',
					})

					setTemplates((templatesData) =>
						templatesData.map((templateData) =>
							templateData.id === updatedTemplate.id ? updatedTemplate : templateData,
						),
					)
					onCloseTemplateModal()
				},
				undefined,
				{ message: 'Error while updating template. Try again.' },
			)
		} else {
			await tryCatch(
				async () => {
					await query<Template>('/leadsGroup/template/create', 'POST', {
						data: {
							...data,
							usingUserId,
						},
						withCredentials: true,
					})

					message.open({
						type: 'success',
						content: 'New template was created',
					})

					onCloseTemplateModal()
					await onLoadTemplates()
				},
				undefined,
				{ message: 'Error while creating new template. Try again.' },
			)
		}
	}

	const onCreateNewTemplate = () => {
		setOpenTemplateModal(true)
	}

	const onEditTemplate = (templateId: string) => {
		setSelectedTemplateId(templateId)
		setOpenTemplateModal(true)
	}

	const onCloseTemplateModal = () => {
		setSelectedTemplateId(undefined)
		setOpenTemplateModal(false)
	}

	const templateModal = useMemo(
		() => (
			<Modal
				destroyOnClose
				footer={null}
				className={styles.templateFormModal}
				title={selectedTemplateId ? 'Edit template' : 'Create new template'}
				open={openTemplateModal}
				onCancel={onCloseTemplateModal}
			>
				<div className={styles.modalContent}>
					<TemplateForm
						onSubmit={createOrUpdateTemplate}
						onCancel={onCloseTemplateModal}
						templateId={selectedTemplateId}
					/>
				</div>
			</Modal>
		),
		[!!selectedTemplateId, openTemplateModal],
	)

	if (loading) {
		return <Loading />
	}

	return (
		<>
			{templateModal}

			<TemplateList
				onLoadTemplates={onLoadTemplates}
				templates={templates}
				deleteTemplates={deleteTemplates}
				onCreateNewTemplate={onCreateNewTemplate}
				onEditTemplate={onEditTemplate}
			/>
		</>
	)
}

export default Templates
