import { Statistic, Tooltip } from 'antd'
import React from 'react'
import { PublicAnalytics } from '@neuron/types/analytics'
import { getCostValue } from '@neuron/utils'
import { HUMAN_AVERAGE_COST_PER_HOUR, HUMAN_MINIMAL_COST_PER_HOUR } from '@neuron/types/usage'
import { InfoCircleOutlined } from '@ant-design/icons'
import styles from './UsageStatistics.module.scss'

type Props = {
	periodTab: string
	analytics: PublicAnalytics | undefined
	loading: boolean
}

const UsageStatistics = ({ periodTab, analytics, loading }: Props) => {
	const isLifetime = periodTab === 'Lifetime'
	const menHours = isLifetime ? analytics?.totalMenHours ?? 0 : analytics?.monthlyMenHours ?? 0

	const minimalHumanCost = menHours * HUMAN_MINIMAL_COST_PER_HOUR
	const averageHumanCost = menHours * HUMAN_AVERAGE_COST_PER_HOUR

	return (
		<>
			<div className={styles.statistics}>
				<Statistic
					title='Personas response time'
					value={
						isLifetime ? analytics?.totalExecutionHours : analytics?.monthlyTotalExecutionHours
					}
					suffix='hours'
					prefix='~'
					precision={2}
					loading={loading}
				/>
				<Statistic
					title={
						<Tooltip
							mouseEnterDelay={0.3}
							title='Total required human availability time for Personas setup and operation.'
						>
							<div className={styles.infoTitle}>
								Human working hours <InfoCircleOutlined />
							</div>
						</Tooltip>
					}
					value={menHours}
					suffix='hours'
					prefix='~'
					loading={loading}
				/>
				<Statistic
					title={
						<Tooltip
							mouseEnterDelay={0.3}
							title='The minimum cost of maintaining employees during the operation and availability of Person (assuming a minimum hourly rate of 28.10 PLN gross).'
						>
							<div className={styles.infoTitle}>
								Minimum human cost <InfoCircleOutlined />
							</div>
						</Tooltip>
					}
					value={getCostValue(minimalHumanCost, false, 'zł')}
					loading={loading}
				/>
				<Statistic
					title={
						<Tooltip
							mouseEnterDelay={0.3}
							title='The average cost of maintaining employees during the operation and availability of Personas (assuming an average hourly rate of 48.50 PLN gross).'
						>
							<div className={styles.infoTitle}>
								Average human cost <InfoCircleOutlined />
							</div>
						</Tooltip>
					}
					value={getCostValue(averageHumanCost, false, 'zł')}
					loading={loading}
				/>
			</div>
		</>
	)
}

export default UsageStatistics
