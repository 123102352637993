import { Alert, App, Button, Segmented } from 'antd'
import KnowledgeFileUploader from './KnowledgeFileUploader/KnowledgeFileUploader'
import { useEffect, useState } from 'react'
import KnowledgeLink from './KnowledgeLink/KnowledgeLink'
import KnowledgeFAQ from './KnowledgeFAQ/KnowledgeFAQ'
import { useAssistantRoleContext } from '../../../../../hooks/context/AssistantRoleContext'
import { urlSchema, uuidIdSchema } from '@neuron/schemas/global'
import Joi from 'joi'
import { contentTypeToExtension } from '@neuron/utils'
import ContextTable from './ContextTable/ContextTable'
import query from '../../../../../utils/query'
import useTryCatch from '../../../../../hooks/useTryCatch'
import { Context, FileResource, MAX_CONTEXT_NAME_LENGTH, Resource } from '@neuron/types/context'
import { socket } from '../../../../../global/SocketManager'
import useAsyncEffect from '../../../../../hooks/useAsyncEffect'
import { formValidate } from '../../../../../utils/validation'
import { useAccountContext } from '../../../../../hooks/context/AccountContext'
import ExistingContexts from './ExistingContexts/ExistingContexts'
import { AssistantRole } from '@neuron/types/assistant/assistantRole'
import styles from './KnowledgeBase.module.scss'

const TABS = ['Files', 'Links', 'FAQs', 'Existing'] as const
type Tab = (typeof TABS)[number]

const KnowledgeBase = () => {
	const { message } = App.useApp()
	const tryCatch = useTryCatch(message)

	const accountContext = useAccountContext()
	const usingUserId = accountContext.useSubscribe((account) => account.usingUserId)

	const assistantRoleContext = useAssistantRoleContext()

	const assistantRoleId = assistantRoleContext.useSubscribe((context) => context.data.id)
	const assistantRoleContextIds = assistantRoleContext.useSubscribe(
		(context) => context.data.contextIds,
	)
	const contextFile = assistantRoleContext.useSubscribe((context) => context.contextFile)
	const contextUrlData = assistantRoleContext.useSubscribe((context) => context.contextUrlData)
	const contextFAQs = assistantRoleContext.useSubscribe((context) => context.contextFAQs)
	const selectedExistingContextIds = assistantRoleContext.useSubscribe(
		(context) => context.selectedExistingContextIds,
	)

	const [tab, setTab] = useState<Tab>('Files')
	const [contexts, setContexts] = useState<Context[]>([])
	const [buildContextLoading, setBuildContextLoading] = useState<boolean>(false)

	useEffect(() => {
		socket.on('contextStatusUpdated', updateContextState)
		socket.on('contextDeleted', removeContextState)

		return () => {
			socket.off('contextStatusUpdated', updateContextState)
			socket.off('contextDeleted', removeContextState)
		}
	}, [contexts])

	useAsyncEffect(async () => {
		if (!assistantRoleContextIds?.length) {
			setContexts([])
			return
		}

		await tryCatch(async () => {
			const contexts = await query<Context[]>('/context/get', 'GET', {
				params: {
					ids: assistantRoleContextIds,
				},
				withCredentials: true,
			})
			setContexts(contexts)
		})
	}, [assistantRoleContextIds])

	useEffect(() => {
		assistantRoleContext.setValidStep(true)
	}, [])

	const updateContextState = async (updatedContext: Context) => {
		const findContextToUpdate = contexts.find((context) => context.id === updatedContext.id)
		if (findContextToUpdate) {
			if (updatedContext.status === 'error') {
				message.open({
					type: 'error',
					content: `Context "${updatedContext.resource.name}" error`,
				})
			}
			if (updatedContext.status === 'refreshing') {
				message.open({
					type: 'info',
					content: `Context "${updatedContext.resource.name}" is refreshing...`,
				})
			}
			if (updatedContext.status === 'indexed') {
				message.open({
					type: 'success',
					content: `Context "${updatedContext.resource.name}" is indexed`,
				})
			}

			setContexts((contexts) =>
				contexts.map((context) => (context.id === updatedContext.id ? updatedContext : context)),
			)
		}
	}

	const removeContextState = (contextId: number) => {
		setContexts((contexts) => contexts.filter((context) => context.id !== contextId))
	}

	const getNewContextResource = (): Resource | undefined => {
		if (tab === 'Files' && contextFile) {
			return {
				name: contextFile.name,
				type: 'file',
			} as FileResource
		}
		if (tab === 'Links' && contextUrlData) {
			return {
				name: contextUrlData.url,
				type: 'url',
				url: contextUrlData.url,
				databaseIntegrationId: contextUrlData.databaseIntegrationId,
			}
		}
		if (tab === 'FAQs' && contextFAQs) {
			return {
				name: `FAQs: ${contextFAQs[0].question}`,
				type: 'text',
				text: contextFAQs.map((faq) => `${faq.question} - ${faq.answer}\n`).join(''),
			}
		}
	}

	const addExistingContexts = async () => {
		if (!isValidCurrentContext) {
			return
		}

		await tryCatch(
			async () => {
				const updatedAssistantRole = await query<AssistantRole>(
					'/assistant/role/updateContexts',
					'POST',
					{
						data: {
							id: assistantRoleId,
							contextIds: [
								...contexts.map((context) => context.id),
								...selectedExistingContextIds!,
							],
						},
					},
				)

				assistantRoleContext.setData(updatedAssistantRole)
				assistantRoleContext.setSelectedExistingContextIds(undefined)
				message.open({
					type: 'success',
					content: 'Selected knowledge bases have been added to the role',
				})
			},
			undefined,
			{ message: `Error while adding existing knowledge bases` },
		)
	}

	const createNewContext = async () => {
		if (!isValidCurrentContext || buildContextLoading) {
			return
		}

		const contextResource = getNewContextResource()
		if (!contextResource) {
			return
		}

		setBuildContextLoading(true)
		await tryCatch(
			async () => {
				const newContextData = new FormData()
				newContextData.append('assistantRoleId', assistantRoleId!)
				newContextData.append('resource', JSON.stringify(contextResource))

				if (contextResource.type === 'file') {
					newContextData.append('file', contextFile!)
				}

				if (usingUserId) {
					newContextData.append('usingUserId', usingUserId)
				}

				const newContext = await query<Context>('/context/create', 'POST', {
					headers: {
						'Content-Type': 'multipart/form-data',
					},
					data: newContextData,
					withCredentials: true,
				})
				setContexts((contexts) => [...contexts, newContext])

				if (tab === 'Files') {
					assistantRoleContext.setContextFile(undefined)
				}
				if (tab === 'Links') {
					assistantRoleContext.setContextUrlData(undefined)
				}
				if (tab === 'FAQs') {
					assistantRoleContext.setContextFAQs(undefined)
				}

				message.open({
					type: 'info',
					content: `Context "${newContext.resource.name}" is processing...`,
				})
			},
			undefined,
			{ message: `Error while processing new context` },
		)
		setBuildContextLoading(false)
	}

	const checkValidFile = () => {
		return contextFile && Object.keys(contentTypeToExtension).includes(contextFile.type)
	}

	const checkValidUrl = () => {
		const validate = formValidate(contextUrlData ?? {}, contextUrlDataSchema)
		return validate ? !Object.keys(validate).length : true
	}

	const checkValidFAQs = () => {
		const validate = formValidate({ contextFAQs }, contextFAQsSchema)
		return validate ? !Object.keys(validate).length : true
	}

	const checkValidSelectedExistingContextIds = () => {
		return selectedExistingContextIds?.length
	}

	const checkValidCurrentContext = () => {
		if (tab === 'Files') {
			return checkValidFile()
		}
		if (tab === 'Links') {
			return checkValidUrl()
		}
		if (tab === 'FAQs') {
			return checkValidFAQs()
		}
		if (tab === 'Existing') {
			return checkValidSelectedExistingContextIds()
		}
	}

	const isValidCurrentContext = checkValidCurrentContext()

	return (
		<div className={styles.knowledgeBaseContainer}>
			<h2 className={styles.heading}>Knowledge Base</h2>
			<div>
				In this step you can add your own data to knowledge context of your Persona from text, files
				or web pages. Remember that the quality and quantity of the context data will determine the
				quality of the Persona performance.
			</div>

			<div className={styles.content}>
				{contexts.length < 15 ? (
					<>
						<Segmented
							value={tab}
							onChange={(tabValue) => setTab(tabValue as Tab)}
							className={styles.contentTabs}
							size='large'
							options={[...TABS]}
						/>

						<div className={styles.tabWrapper}>
							{tab === 'Files' && <KnowledgeFileUploader />}
							{tab === 'Links' && <KnowledgeLink />}
							{tab === 'FAQs' && <KnowledgeFAQ />}
							{tab === 'Existing' && <ExistingContexts alreadyAddedContexts={contexts} />}
						</div>

						<Button
							disabled={!isValidCurrentContext || buildContextLoading}
							loading={buildContextLoading}
							onClick={tab === 'Existing' ? addExistingContexts : createNewContext}
							type='primary'
							size='large'
						>
							{tab === 'Existing' ? 'Add existing contexts' : 'Build new context'}
						</Button>
					</>
				) : (
					<Alert
						message='Warning'
						description='The limit of contexts for a single Persona Role has been reached. A single Role can have a maximum of 15 contexts.'
						type='warning'
						showIcon
					/>
				)}
			</div>

			<div>
				{!!assistantRoleId && (
					<ContextTable assistantRoleId={assistantRoleId} contexts={contexts} />
				)}
			</div>
		</div>
	)
}

const contextUrlDataSchema = {
	url: urlSchema.required(),
	isValidUrl: Joi.boolean().truthy().required(),
	databaseIntegrationId: uuidIdSchema.optional(),
}

const contextFAQsSchema = {
	contextFAQs: Joi.array()
		.items(
			Joi.object({
				question: Joi.string().min(3).max(MAX_CONTEXT_NAME_LENGTH).required(),
				answer: Joi.string().min(3).max(500).required(),
			}),
		)
		.min(1)
		.max(30)
		.required(),
}

export default KnowledgeBase
