import { Button, Form, Input } from 'antd'
import { AssistantRole } from '@neuron/types/assistant/assistantRole'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { useEffect } from 'react'
import Joi from 'joi'
import { prepareAssistantData } from '../../Role'
import { useAssistantRoleContext } from '../../../../../../hooks/context/AssistantRoleContext'
import { formValidate } from '../../../../../../utils/validation'
import FormRow from '../../../../../../components/FormRow/FormRow'
import { createHelpdeskUpsellRoleSchema } from '@neuron/schemas/assistant/roles/helpdeskUpsell'
import RoleHeader from '../../components/RoleHeader/RoleHeader'
import { useParams } from 'react-router-dom'
import { prepareRoleFormValues } from '../../utils'
import FormHeading from '../../components/FormHeading/FormHeading'
import SystemName from '../../components/Forms/SystemName'
import CompanyOffer from '../../components/Forms/CompanyOffer'
import PromotionOffer from '../../components/Forms/PromotionOffer'
import AdditionalInformation from '../../components/Forms/AdditionalInformation'
import EmojiConfig from '../../components/Forms/EmojiConfig'
import ConversationConfig from '../../components/Forms/ConversationConfig'
import FollowupsConfig from '../../components/Forms/FollowupsConfig'
import NaturalizationBooster from '../../components/Forms/NaturalizationBooster'
import RedirectConditions from '../../components/Forms/RedirectConditions'
import { roles } from '../../../../../../utils/roles'
import styles from './HelpdeskUpsell.module.scss'

const DEFAULT_HELPDESK_UPSELL_ROLE_DATA: Partial<AssistantRole> = {
	type: 'helpdeskUpsell',
	disabledEmoji: false,
	conversationStyle: 'balanced',
	responseLength: 'balanced',
	followupInterval: 5,
	maxFollowups: 2,
	goal: 'offersUrls',
	naturalizationBooster: false,
}

const HelpdeskUpsellRoleForm = () => {
	const [form] = Form.useForm()

	const { assistantRoleId } = useParams()

	const assistantRoleContext = useAssistantRoleContext()
	const assistantRoleData = assistantRoleContext.useSubscribe((context) => context.data)

	useEffect(() => {
		if (!assistantRoleId) {
			assistantRoleContext.updateData(DEFAULT_HELPDESK_UPSELL_ROLE_DATA)
		}
	}, [assistantRoleId])

	useEffect(() => {
		checkFormValidate()
	}, [assistantRoleData])

	useEffect(() => {
		form.resetFields()
	}, [
		assistantRoleData.id,
		assistantRoleData.type,
		'followupInterval' in assistantRoleData && !!assistantRoleData.followupInterval,
	])

	const updateAssistantRoleData = (value: Record<string, any>, values: any) => {
		prepareRoleFormValues(value, values)
		assistantRoleContext.updateData(values)
	}

	const checkFormValidate = () => {
		const validate = formValidate(prepareAssistantData(assistantRoleData), assistantRoleSchema)
		assistantRoleContext.setValidStep(validate ? !Object.keys(validate).length : true)
	}

	const roleInfo = roles.flat().find((role) => role.key === 'helpdeskUpsell')!

	return (
		<Form
			form={form}
			autoComplete='true'
			initialValues={assistantRoleData}
			onValuesChange={updateAssistantRoleData}
			labelCol={{ span: 4 }}
			wrapperCol={{ span: 14 }}
			className={styles.form}
			layout='vertical'
		>
			<RoleHeader
				avatar={roleInfo.avatar}
				name={roleInfo.name}
				description={roleInfo.description}
			/>

			<FormHeading title='Base information' />

			<SystemName />

			<CompanyOffer />

			<PromotionOffer />

			<AdditionalInformation />

			<EmojiConfig disabledEmoji={assistantRoleData.disabledEmoji} />

			<Form.Item
				required
				tooltip="Enter a link to a cross-sell offer. Persona will use the link after solving the customer's problem as a cross-sell offer."
				className={styles.formItem}
				label='Cross-selling links'
			>
				<Form.List
					initialValue={
						'offersUrls' in assistantRoleData && assistantRoleData.offersUrls
							? undefined
							: [{ url: '' }]
					}
					name='offersUrls'
				>
					{(fields, { add, remove }) => (
						<>
							{fields.map(({ key, name, ...restField }) => (
								<div key={key} className={styles.fieldList}>
									<FormRow columns={[1, 1]}>
										<Form.Item
											{...restField}
											rules={[
												{ required: true, message: 'Please fill cross-sell link' },
												{
													type: 'url',
													message: 'Please fill correct link',
												},
												{
													message: 'Cross-sell link must have a minimum of 6 characters',
													min: 6,
												},
												{
													message: 'Cross-sell link can have a maximum of 1000 characters',
													max: 1000,
												},
											]}
											className={styles.formItem}
											name={[name, 'url']}
										>
											<Input
												maxLength={1000}
												size='large'
												className={styles.formInput}
												placeholder='https://quantumneuron.eu/cross-sell'
											/>
										</Form.Item>

										<Form.Item
											{...restField}
											rules={[
												{
													required: true,
													message: 'Please fill cross-sell link usage context',
												},
												{
													message:
														'Cross-sell link usage context must have a minimum of 6 characters',
													min: 6,
												},
												{
													message:
														'Cross-sell link usage context can have a maximum of 500 characters',
													max: 500,
												},
											]}
											className={styles.formItem}
											name={[name, 'context']}
										>
											<Input
												size='large'
												count={{
													show: true,
												}}
												maxLength={500}
												className={styles.formInput}
												placeholder='Use this link for customers inquiring about an XYZ offer'
											/>
										</Form.Item>
									</FormRow>
									{key !== 0 && (
										<MinusCircleOutlined
											className={styles.listRemoveIcon}
											onClick={() => remove(name)}
										/>
									)}
								</div>
							))}
							<Form.Item className={styles.formItem}>
								<Button
									size='large'
									type='dashed'
									disabled={fields.length === 5}
									onClick={() => {
										fields.length < 5 && add()
									}}
									block
									icon={<PlusOutlined />}
								>
									Add another cress-selling link
								</Button>
							</Form.Item>
						</>
					)}
				</Form.List>
			</Form.Item>

			<FormHeading title='Role settings' />

			<ConversationConfig />

			<FollowupsConfig
				followupInterval={
					'followupInterval' in assistantRoleData ? assistantRoleData.followupInterval : undefined
				}
			/>

			<FormHeading title='Advanced settings' />

			<NaturalizationBooster />
			<RedirectConditions />
		</Form>
	)
}

const assistantRoleSchema = Joi.object({
	...createHelpdeskUpsellRoleSchema,
	id: Joi.any().optional(),
}).required()

export default HelpdeskUpsellRoleForm
