export const MB_IN_BYTES = 1048576

// MESSAGE CONTEXT STATUSES
export const LOADING_CONTENT = '[LOADING]' as const
export const CONTEXT_LOADED_CONTENT = '[CONTEXT_LOADED]' as const
export const ERROR_CONTENT = '[ERROR]' as const
export const COST_LIMIT_CONTENT = '[COST_LIMIT]' as const
export const CANCEL_CONTENT = '[CANCEL]' as const
export const SKIP_MESSAGE_CONTENT = '[SKIP]' as const
export const DELETED_MESSAGE_CONTENT = '[DELETED]' as const
export const NOT_SUPPORTED_MESSAGE_CONTENT = '[NOT_SUPPORTED]' as const

export const STATUS_CONTENT_REGEX = /^\[.*]$/

export const messageRole = {
	SYSTEM: 'system',
	USER: 'user',
	ASSISTANT: 'assistant',
	FUNCTION: 'function',
	TOOL: 'tool',
} as const

export const STRICT_URL_REGEX = /^(?:(?:https?|www):\/\/)?(?:[\w-]+\.)+[a-z]{2,}(?:\/\S*)?$/i
export const GOOGLE_SHEETS_URL_REGEX =
	/https:\/\/docs\.google\.com\/spreadsheets\/d\/([a-zA-Z0-9-_]+)(?:\/.*gid=([0-9]+))?/
export const GOOGLE_DOCS_URL_REGEX = /https:\/\/docs\.google\.com\/document\/d\/([a-zA-Z0-9-_]+)?/

export const FIND_URL_REGEX =
	/(?:(?:https?|www):\/\/)?(?:[\w-]+\.)+[a-z]{2,}((\/[-a-zA-Z0-9()@:%_+.~#?&=]*)*)/gi
