import Joi from 'joi'
import { WARMUP_LEVEL } from '@neuron/types/assistant/assistantRole'
import { assistantRoleBaseSchema } from '../assistantRole'
import { usingUserIdSchema, uuidIdSchema } from '../../global'
import { LEAD_GENERATION_GOALS } from '@neuron/types/assistant/roles/leadGeneration'

const leadGenerationRoleSchema = {
	...assistantRoleBaseSchema,
	type: Joi.string().valid('leadGeneration'),
	companyOffer: Joi.string().max(3000),
	promotionsDescription: Joi.string().max(1500),
	warmupLevel: Joi.string().valid(...WARMUP_LEVEL),
	goal: Joi.string().valid(...LEAD_GENERATION_GOALS),
	lead: Joi.array().items(Joi.string().required()).min(1).max(10),
}

export const createLeadGenerationRoleSchema = {
	assistantId: leadGenerationRoleSchema.assistantId.required(),
	name: leadGenerationRoleSchema.name.required(),
	additionalInformation: leadGenerationRoleSchema.additionalInformation.optional(),
	naturalizationBooster: leadGenerationRoleSchema.naturalizationBooster.optional(),
	redirectConditions: leadGenerationRoleSchema.redirectConditions.optional(),

	type: leadGenerationRoleSchema.type.required(),
	companyOffer: leadGenerationRoleSchema.companyOffer.required(),
	promotionsDescription: leadGenerationRoleSchema.promotionsDescription.optional(),
	disabledEmoji: leadGenerationRoleSchema.disabledEmoji.required(),
	preferredEmoji: leadGenerationRoleSchema.preferredEmoji.optional(),
	followupInterval: leadGenerationRoleSchema.followupInterval.optional(),
	maxFollowups: leadGenerationRoleSchema.maxFollowups.optional(),
	conversationStyle: leadGenerationRoleSchema.conversationStyle.required(),
	responseLength: leadGenerationRoleSchema.responseLength.required(),
	warmupLevel: leadGenerationRoleSchema.warmupLevel.required(),
	goal: leadGenerationRoleSchema.goal.required(),
	lead: leadGenerationRoleSchema.lead.required(),
	...usingUserIdSchema,
}

export const updateLeadGenerationRoleSchema = {
	id: uuidIdSchema.required(),
	assistantId: leadGenerationRoleSchema.assistantId.required(),
	name: leadGenerationRoleSchema.name.optional(),
	additionalInformation: leadGenerationRoleSchema.additionalInformation.optional(),
	naturalizationBooster: leadGenerationRoleSchema.naturalizationBooster.optional(),
	redirectConditions: leadGenerationRoleSchema.redirectConditions.optional(),

	type: leadGenerationRoleSchema.type.optional(),
	companyOffer: leadGenerationRoleSchema.companyOffer.optional(),
	promotionsDescription: leadGenerationRoleSchema.promotionsDescription.optional(),
	disabledEmoji: leadGenerationRoleSchema.disabledEmoji.optional(),
	preferredEmoji: leadGenerationRoleSchema.preferredEmoji.optional(),
	followupInterval: leadGenerationRoleSchema.followupInterval.optional(),
	maxFollowups: leadGenerationRoleSchema.maxFollowups.optional(),
	conversationStyle: leadGenerationRoleSchema.conversationStyle.optional(),
	responseLength: leadGenerationRoleSchema.responseLength.optional(),
	warmupLevel: leadGenerationRoleSchema.warmupLevel.optional(),
	goal: leadGenerationRoleSchema.goal.optional(),
	lead: leadGenerationRoleSchema.lead.optional(),
}
