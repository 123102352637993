import Joi from 'joi'
import { emailSchema, usingUserIdSchema, uuidIdSchema } from '../global'
import { TEMPLATE_PROVIDERS } from '@neuron/types/leadsGroup/template'

export const templateBaseSchema = {
	name: Joi.string().max(120).min(3).required(),
	assistantRoleId: uuidIdSchema.required(),
	firstMessageContent: Joi.string().when('provider', {
		is: 'gmail',
		then: Joi.string().min(10).max(2000).required(),
		otherwise: Joi.string().min(10).max(1000).required(),
	}),
	followupMessagesContents: Joi.array()
		.items(Joi.string().min(10).max(2000).required())
		.allow(null)
		.optional(),
	provider: Joi.string()
		.valid(...TEMPLATE_PROVIDERS)
		.required(),
	subject: Joi.string().when('provider', {
		is: 'gmail',
		then: Joi.string().min(5).max(200).required(),
		otherwise: Joi.string().max(200).allow(null).optional(),
	}),
	signature: Joi.string().max(10000).allow(null).optional(),
	customMailAddress: emailSchema.optional(),
}

export const getTemplateRequestSchema = Joi.object({
	id: uuidIdSchema.required(),
})

export const getUserTemplatesRequestSchema = Joi.object(usingUserIdSchema)

export const createTemplateRequestSchema = Joi.object({
	...templateBaseSchema,
	...usingUserIdSchema,
})

export const updateTemplateRequestSchema = Joi.object({
	id: uuidIdSchema.required(),
	...templateBaseSchema,
})

export const deleteTemplateRequestSchema = Joi.object({
	id: uuidIdSchema.required(),
})
