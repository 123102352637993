import React from 'react'
import { Tag } from 'antd'
import { GlobalAccountRole } from '@neuron/types/account'

type Props = {
	role: GlobalAccountRole | string
}

const AccountRoleTag = ({ role }: Props) => {
	if (role === 'admin') {
		return <Tag color='#292b2c'>Admin</Tag>
	}
	if (role === 'god') {
		return (
			<Tag color='#cfa708'>
				<b>God</b>
			</Tag>
		)
	}
	if (role === 'onboarding') {
		return <Tag color='#d9534f'>Onboarding</Tag>
	}
	if (role === 'broker') {
		return <Tag color='#007bff'>Broker</Tag>
	}
	if (role === 'senior.broker') {
		return <Tag color='#5cb85c'>Senior broker</Tag>
	}
	if (role === 'master.broker') {
		return <Tag color='#f0ad4e'>Master broker</Tag>
	}
	return <Tag>{`${role.charAt(0).toUpperCase()}${role.slice(1)}`}</Tag>
}

export default AccountRoleTag
