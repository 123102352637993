import Joi from 'joi'
import { idSchema, urlSchema, usingUserIdSchema, uuidIdSchema } from '../global'
import {
	CONTEXT_STATUSES,
	MAX_CONTEXT_NAME_LENGTH,
	MAX_TEXT_CONTENT_LENGTH,
	MIN_REINDEX_INTERVAL_IN_MINUTES,
	REINDEX_INTERVAL_UNITS,
} from '../../types/context'

const textResourceSchema = Joi.object({
	type: 'text',
	name: Joi.string().min(3).max(MAX_CONTEXT_NAME_LENGTH).required(),
	text: Joi.string().min(3).max(MAX_TEXT_CONTENT_LENGTH).required(),
}).required()

const urlResourceSchema = Joi.object({
	type: 'url',
	name: Joi.string().min(3).max(MAX_CONTEXT_NAME_LENGTH).required(),
	url: urlSchema.required(),
	databaseIntegrationId: uuidIdSchema.optional(),
}).required()

const fileResourceSchema = Joi.object({
	type: 'file',
	name: Joi.string().min(3).max(MAX_CONTEXT_NAME_LENGTH).required(),
	fileId: uuidIdSchema.allow('').optional(),
}).required()

export const contextSettingsSchema = {
	reindexInterval: Joi.number().when('reindexIntervalUnit', {
		is: 'minutes',
		then: Joi.number().positive().integer().min(MIN_REINDEX_INTERVAL_IN_MINUTES).required(),
		otherwise: Joi.number().positive().integer().required(),
	}),
	reindexIntervalUnit: Joi.string()
		.valid(...REINDEX_INTERVAL_UNITS)
		.allow(null)
		.optional(),
}

export const getContextRequestSchema = {
	id: idSchema.optional(),
	ids: Joi.array().items(idSchema.required()).optional(),
}

export const refreshContextRequestSchema = {
	id: idSchema.required(),
}

export const deleteContextPublicRequestSchema = {
	id: idSchema.required(),
}

export const deleteContextPrivateRequestSchema = {
	...deleteContextPublicRequestSchema,
	userId: uuidIdSchema.required(),
}

export const createContextRequestSchema = {
	assistantRoleId: uuidIdSchema.optional(),
	resource: Joi.alternatives()
		.try(textResourceSchema, urlResourceSchema, fileResourceSchema)
		.required(),
	...usingUserIdSchema,
}

export const updateContextRequestSchema = {
	id: idSchema.required(),
	...createContextRequestSchema,
}

export const updateContextSettingsRequestSchema = {
	id: idSchema.required(),
	...contextSettingsSchema,
}

export const updateContextStatusRequestSchema = {
	id: idSchema.required(),
	status: Joi.string()
		.valid(...CONTEXT_STATUSES)
		.required(),
	errorMessage: Joi.string().optional(),
	indexId: Joi.string().optional(),
	userId: uuidIdSchema.required(),
}
