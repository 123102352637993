import { App, Button, Form, Select } from 'antd'
import React, { useState } from 'react'
import { Template, TemplateProvider } from '@neuron/types/leadsGroup/template'
import useAsyncEffect from '../../../../hooks/useAsyncEffect'
import query from '../../../../utils/query'
import useTryCatch from '../../../../hooks/useTryCatch'
import Loading from '../../../../components/Loading/Loading'
import { useAccountContext } from '../../../../hooks/context/AccountContext'
import styles from './StartLeadsConversationsForm.module.scss'

type Props = {
	provider: TemplateProvider
	groupId: string
	onSubmit: (templateId: string) => Promise<void>
	onCancel: () => void
}

const StartLeadsConversationsForm = ({ provider, groupId, onSubmit, onCancel }: Props) => {
	const { message } = App.useApp()
	const tryCatch = useTryCatch(message)

	const accountContext = useAccountContext()
	const usingUserId = accountContext.useSubscribe((account) => account.usingUserId)

	const [templates, setTemplates] = useState<Template[]>([])
	const [selectedTemplateId, setSelectedTemplateId] = useState<string>()
	const [loading, setLoading] = useState<boolean>(true)
	const [onSubmitLoading, setOnSubmitLoading] = useState<boolean>(false)

	useAsyncEffect(async () => {
		await tryCatch(
			async () => {
				const userTemplates = await query<Template[]>('/leadsGroup/templates/user', 'GET', {
					params: { usingUserId },
					withCredentials: true,
				})
				setTemplates(userTemplates.filter((template) => template.provider === provider))
			},
			undefined,
			{ message: 'Load data error' },
		)
		setLoading(false)
	}, [groupId, provider])

	const onStartNewConversations = async () => {
		if (!selectedTemplateId) {
			return
		}
		setOnSubmitLoading(true)
		await onSubmit(selectedTemplateId)
	}

	if (loading) {
		return <Loading />
	}

	return (
		<Form autoComplete='true' layout='vertical'>
			<div className={styles.formContent}>
				<Form.Item name='templateId' label='Select Template' className={styles.formItem}>
					<Select
						size='large'
						showSearch
						placeholder='Select a template'
						value={selectedTemplateId}
						onChange={setSelectedTemplateId}
						options={templates.map((template) => ({
							label: template.name,
							value: template.id,
							disabled: template.status !== 'active',
						}))}
					/>
				</Form.Item>
			</div>

			<Form.Item noStyle>
				<div className={styles.buttons}>
					<Button
						disabled={!selectedTemplateId}
						size='large'
						loading={onSubmitLoading}
						onClick={onStartNewConversations}
						type='primary'
					>
						Start conversations
					</Button>
					<Button loading={onSubmitLoading} size='large' onClick={onCancel}>
						Cancel
					</Button>
				</div>
			</Form.Item>
		</Form>
	)
}

export default StartLeadsConversationsForm
