import { Button } from 'antd'
import React, { ReactNode } from 'react'
import { useGoogleLogin } from '@react-oauth/google'
import env from '../../../../../boot/env'
import { GMAIL_SCOPE } from '@neuron/types/integration/messaging/api/gmail'
import styles from './GmailAuthorizationButton.module.scss'

type Props = {
	showAuthorizationErrorMessage: () => void
	createOrUpdateIntegration: (accessCode?: string) => Promise<void>
	processingIntegrationLoading: boolean
	setProcessingIntegrationLoading: (processing: boolean) => void
	providerIcon: ReactNode
}

const GmailAuthorizationButton = ({
	processingIntegrationLoading,
	setProcessingIntegrationLoading,
	providerIcon,
	showAuthorizationErrorMessage,
	createOrUpdateIntegration,
}: Props) => {
	const useGoogleAuthorization = useGoogleLogin({
		redirect_uri: `https://${env.DOMAIN_NAME}`,
		scope: GMAIL_SCOPE,
		onSuccess: async (codeResponse) => {
			await createOrUpdateIntegration(codeResponse.code)
		},
		onError: () => {
			showAuthorizationErrorMessage()
			setProcessingIntegrationLoading(false)
		},
		onNonOAuthError: () => {
			showAuthorizationErrorMessage()
			setProcessingIntegrationLoading(false)
		},
		flow: 'auth-code',
	})

	return (
		<Button
			type='primary'
			loading={processingIntegrationLoading}
			disabled={processingIntegrationLoading}
			size='large'
			icon={providerIcon}
			onClick={() => {
				setProcessingIntegrationLoading(true)
				useGoogleAuthorization()
			}}
			className={styles.googleButton}
		>
			Login via Google
		</Button>
	)
}

export default GmailAuthorizationButton
