import { Button, Layout, Tooltip } from 'antd'
import EmptyData from '../EmptyData/EmptyData'
import {
	MessagingIntegrationProvider,
	PublicMessagingIntegration,
} from '@neuron/types/integration/messaging'
import { useNavigate } from 'react-router-dom'
import { ApiOutlined, DeleteOutlined, SyncOutlined } from '@ant-design/icons'
import IntegrationCard from './IntegrationCard/IntegrationCard'
import React from 'react'
import styles from './IntegrationList.module.scss'

const { Header } = Layout

type Props = {
	integrations: PublicMessagingIntegration[]
	selectedIntegrationIds: string[]
	selectIntegration: (integrationId: string, provider: MessagingIntegrationProvider) => void
	onRefreshIntegrations: () => Promise<void>
	showSelectingButton?: boolean
	showEditButton?: boolean
	showCreateButton?: boolean
	deleteIntegrations?: (selectedIntegrationIds: string[]) => void
}

const IntegrationList = ({
	integrations,
	selectedIntegrationIds,
	selectIntegration,
	onRefreshIntegrations,
	showSelectingButton,
	showEditButton,
	deleteIntegrations,
	showCreateButton,
}: Props) => {
	const navigate = useNavigate()

	const createNewIntegration = () => {
		navigate('/personas/integration')
	}

	return (
		<div className={styles.container}>
			<Header className={styles.header}>
				<div className={styles.headerElements}>
					{!!showCreateButton && (
						<Button icon={<ApiOutlined />} onClick={createNewIntegration}>
							Create new integration
						</Button>
					)}
				</div>

				<div className={styles.headerElements}>
					{!!deleteIntegrations && !!selectedIntegrationIds.length && (
						<Button
							icon={<DeleteOutlined />}
							danger
							onClick={() => deleteIntegrations(selectedIntegrationIds)}
						>
							Delete selected {selectedIntegrationIds.length > 1 ? 'integrations' : 'integration'} (
							{selectedIntegrationIds.length})
						</Button>
					)}
					<Tooltip trigger={['hover', 'focus']} title='Refresh'>
						<Button
							className={styles.headerRefresh}
							onClick={onRefreshIntegrations}
							shape='circle'
							icon={<SyncOutlined />}
						/>
					</Tooltip>
				</div>
			</Header>

			<div className={styles.integrations}>
				{integrations.map((integration) => (
					<IntegrationCard
						key={integration.id}
						integration={integration}
						selectedIntegrationIds={selectedIntegrationIds}
						selectIntegration={selectIntegration}
						showSelectingButton={showSelectingButton}
						showEditButton={showEditButton}
						deleteIntegrations={deleteIntegrations}
					/>
				))}

				{!integrations.length && (
					<EmptyData onClick={createNewIntegration} description='Empty integration list' />
				)}
			</div>
		</div>
	)
}

export default IntegrationList
