import React, { useEffect, useState } from 'react'
import { PublicAnalytics } from '@neuron/types/analytics'
import useAsyncEffect from '../../hooks/useAsyncEffect'
import { App, Segmented } from 'antd'
import useTryCatch from '../../hooks/useTryCatch'
import query from '../../utils/query'
import { useAccountContext } from '../../hooks/context/AccountContext'
import Line from '../../components/Line/Line'
import Header from './components/Header/Header'
import PersonaStatistics from './components/PersonaStatistics/PersonaStatistics'
import ConversationsStatistics from './components/ConversationsStatistics/ConversationsStatistics'
import MessagesStatistics from './components/MessagesStatistics/MessagesStatistics'
import UsageStatistics from './components/UsageStatistics/UsageStatistics'
import AdminStatistics from './components/AdminStatistics/AdminStatistics'
import styles from './Dashboard.module.scss'

const BASE_TABS = ['Conversations', 'Messages', 'Persona', 'Usage']
const PERIOD_TABS = ['Lifetime', 'Month']

const Dashboard = () => {
	const { message } = App.useApp()
	const tryCatch = useTryCatch(message)

	const accountContext = useAccountContext()
	const accountId = accountContext.useSubscribe((account) => account.id)
	const usingUserId = accountContext.useSubscribe((account) => account.usingUserId)
	const isAdmin = accountContext.useSubscribe((context) => context.roles.includes('admin'))

	const [availableTabs, setAvailableTabs] = useState<string[]>(BASE_TABS)
	const [tab, setTab] = useState<string>('Conversations')

	const [periodTab, setPeriodTab] = useState<string>(PERIOD_TABS[0])

	const [analytics, setAnalytics] = useState<PublicAnalytics>()
	const [loading, setLoading] = useState<boolean>(true)

	useAsyncEffect(async () => {
		if (!accountId) {
			return
		}

		await tryCatch(async () => {
			const resAnalytics = await query<PublicAnalytics>('/analytics/get', 'GET', {
				withCredentials: true,
				params: {
					usingUserId,
				},
			})
			setAnalytics(resAnalytics)
		})

		setLoading(false)
	}, [accountId, usingUserId])

	useEffect(() => {
		if (!isAdmin) {
			return
		}

		setAvailableTabs((tabs) => [...tabs, 'Admin'])
	}, [isAdmin])

	return (
		<div className={styles.dashboard}>
			<Segmented
				value={periodTab}
				onChange={(tabValue) => setPeriodTab(tabValue as string)}
				className={styles.periodTabs}
				size='large'
				options={PERIOD_TABS}
			/>

			<Header periodTab={periodTab} analytics={analytics} loading={loading} />

			<div className={styles.tabsWrapper}>
				<Segmented
					value={tab}
					onChange={(tabValue) => setTab(tabValue as string)}
					className={styles.contentTabs}
					size='large'
					options={availableTabs}
				/>
			</div>

			<div className={styles.statisticsContainer}>
				<div className={styles.statisticsHeading}>Statistics</div>
				<Line />

				{tab === 'Conversations' && (
					<ConversationsStatistics periodTab={periodTab} analytics={analytics} loading={loading} />
				)}
				{tab === 'Messages' && (
					<MessagesStatistics periodTab={periodTab} analytics={analytics} loading={loading} />
				)}
				{tab === 'Persona' && <PersonaStatistics analytics={analytics} loading={loading} />}
				{tab === 'Usage' && (
					<UsageStatistics periodTab={periodTab} analytics={analytics} loading={loading} />
				)}
				{isAdmin && tab === 'Admin' && (
					<AdminStatistics periodTab={periodTab} analytics={analytics} loading={loading} />
				)}
			</div>
		</div>
	)
}

export default Dashboard
