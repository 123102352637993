import { App, Segmented } from 'antd'
import React, { useState } from 'react'
import { AssistantRoleType } from '@neuron/types/assistant/assistantRole'
import { Currency, PaymentInterval, ProductType } from '@neuron/types/order'
import OfferForm from './OfferForm/OfferForm'
import query from '../../../utils/query'
import useTryCatch from '../../../hooks/useTryCatch'
import { prepareOfferFormDataToOrderOffer } from './utils'
import Loading from '../../../components/Loading/Loading'
import useQuery from '../../../hooks/useQuery'
import { Offer } from '@neuron/types/account'
import styles from './UserOffer.module.scss'

type Props = {
	currentOffer: Offer
	closeModal: (refresh?: boolean) => void
}

const OFFER_TAB_TYPES = ['New Personas', 'Additional Roles']
type OfferTabType = (typeof OFFER_TAB_TYPES)[number]

const UserOffer = ({ currentOffer, closeModal }: Props) => {
	const { message } = App.useApp()
	const tryCatch = useTryCatch(message)

	const userId = useQuery().get('userId')

	const [offerType, setOfferType] = useState<OfferTabType>('New Personas')

	const onSaveUserOffer = async (offerFormData: OfferFormData) => {
		await tryCatch(
			async () => {
				await query('/order/create', 'POST', {
					data: {
						userId,
						...prepareOfferFormDataToOrderOffer(offerFormData),
					},
					withCredentials: true,
				})

				closeModal(true)
			},
			undefined,
			{ message: 'Error while creating user offer. Check data and try again.' },
		)
	}

	if (!offerType || !userId) {
		return <Loading />
	}

	return (
		<div>
			<div className={styles.tabs}>
				<Segmented
					value={offerType}
					onChange={(tabValue) => setOfferType(tabValue)}
					className={styles.contentTabs}
					size='large'
					options={[...OFFER_TAB_TYPES]}
				/>
			</div>

			<OfferForm
				type={offerTypeTabToType[offerType]}
				ownedRoles={currentOffer.availableRoles}
				onSavePersonaOffer={onSaveUserOffer}
			/>
		</div>
	)
}

const offerTypeTabToType: Record<OfferTabType, 'personas' | 'roles'> = {
	'New Personas': 'personas',
	'Additional Roles': 'roles',
}

export type OfferFormData = {
	type: ProductType
	roles: AssistantRoleType[]
	personasNumber?: number
	additionalCost: number
	paymentInterval: PaymentInterval
	currency: Currency
}

export default UserOffer
