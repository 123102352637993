import { App, Button, Form, InputNumber, Select, Switch } from 'antd'
import { useEffect, useMemo, useState } from 'react'
import { Context, ContextSettings, MIN_REINDEX_INTERVAL_IN_MINUTES } from '@neuron/types/context'
import useAsyncEffect from '../../../hooks/useAsyncEffect'
import query from '../../../utils/query'
import useTryCatch from '../../../hooks/useTryCatch'
import Loading from '../../../components/Loading/Loading'
import { formValidate } from '../../../utils/validation'
import FormRow from '../../../components/FormRow/FormRow'
import { contextSettingsSchema } from '@neuron/schemas/context'
import styles from './UpdateContextSettings.module.scss'

type Props = {
	contextId: number
	onSubmit: (data: ContextSettings) => Promise<void>
	onCancel: () => void
}

const ContextSettingsForm = ({ onSubmit, contextId, onCancel }: Props) => {
	const [form] = Form.useForm()
	const { message } = App.useApp()
	const tryCatch = useTryCatch(message)

	const [contextSettings, setContextSettings] = useState<ContextSettings>({
		reindexInterval: MIN_REINDEX_INTERVAL_IN_MINUTES,
		reindexIntervalUnit: 'minutes',
	})
	const [loading, setLoading] = useState<boolean>(true)

	useEffect(() => {
		if (
			contextSettings.reindexInterval === null ||
			contextSettings.reindexInterval === MIN_REINDEX_INTERVAL_IN_MINUTES
		) {
			form.resetFields()
		}
	}, [contextSettings.reindexInterval])

	useAsyncEffect(async () => {
		await tryCatch(
			async () => {
				const resContext = await query<Context>('/context/get', 'GET', {
					params: { id: contextId },
					withCredentials: true,
				})
				setContextSettings({
					reindexInterval: resContext.reindexInterval,
					reindexIntervalUnit: resContext.reindexIntervalUnit,
				})
			},
			undefined,
			{ message: 'Fetch context data error. Try again and refresh page.' },
		)
		setLoading(false)
	}, [contextId])

	const onUpdateContextSettings = (value: any, values: any) => {
		if ('reindexInterval' in value) {
			if (value.reindexInterval === true) {
				values.reindexInterval = MIN_REINDEX_INTERVAL_IN_MINUTES
				values.reindexIntervalUnit = 'minutes'
			}
			if (value.reindexInterval === false) {
				values.reindexInterval = null
				values.reindexIntervalUnit = null
			}
		}

		setContextSettings((prev) => ({ ...prev, ...values }))
	}

	const isValid = useMemo(() => {
		const validate = formValidate(contextSettings, contextSettingsSchema)
		return validate ? !Object.keys(validate).length : true
	}, [contextSettings])

	if (loading) {
		return <Loading size={32} />
	}

	return (
		<Form
			form={form}
			autoComplete='true'
			initialValues={contextSettings}
			onValuesChange={onUpdateContextSettings}
			labelCol={{ span: 4 }}
			wrapperCol={{ span: 14 }}
			layout='vertical'
		>
			<FormRow>
				<Form.Item name='reindexInterval' label='Autoamtic refresh' className={styles.formItem}>
					<Switch />
				</Form.Item>

				{!!contextSettings.reindexInterval && (
					<Form.Item
						label='Refresh interval'
						name='reindexInterval'
						rules={[
							{ required: true, message: 'Please set the reindex interval' },
							contextSettings.reindexIntervalUnit === 'minutes'
								? {
										type: 'number',
										min: MIN_REINDEX_INTERVAL_IN_MINUTES,
										message: `Minimum interval is ${MIN_REINDEX_INTERVAL_IN_MINUTES} minutes`,
									}
								: {
										type: 'number',
										min: 1,
										message: `Minimum interval is 1 ${contextSettings.reindexIntervalUnit}`,
									},
						]}
						className={styles.formItem}
					>
						<InputNumber
							min={
								contextSettings.reindexIntervalUnit === 'minutes'
									? MIN_REINDEX_INTERVAL_IN_MINUTES
									: 1
							}
							max={100}
							addonAfter={
								<Select
									value={contextSettings.reindexIntervalUnit}
									onChange={(unit) =>
										setContextSettings((settings) => ({ ...settings, reindexIntervalUnit: unit }))
									}
								>
									<Select.Option value='minutes'>Minutes</Select.Option>
									<Select.Option value='hours'>Hours</Select.Option>
									<Select.Option value='days'>Days</Select.Option>
								</Select>
							}
						/>
					</Form.Item>
				)}
			</FormRow>

			<Form.Item noStyle>
				<div className={styles.buttons}>
					<Button onClick={onCancel} size='large'>
						Cancel
					</Button>
					<Button
						type='primary'
						size='large'
						disabled={!isValid}
						onClick={async () => {
							await onSubmit(contextSettings)
						}}
					>
						Update
					</Button>
				</div>
			</Form.Item>
		</Form>
	)
}

export default ContextSettingsForm
