import React, { useEffect, useMemo, useRef, useState, WheelEvent } from 'react'
import { CloseOutlined, PlusOutlined, EditOutlined } from '@ant-design/icons'
import { useAiContext } from '../../hooks/context/AiContext'
import { Tab } from '../../types/AiContext'
import Context from './Context/Context'
import query from '../../utils/query'
import { MAX_TEXT_CONTENT_LENGTH, Resource, SourceType } from '@neuron/types/context'
import { useNavigate } from 'react-router-dom'
import { App, Button } from 'antd'
import InlineTextInput from '../../components/InlineTextInput/InlineTextInput'
import useTryCatch from '../../hooks/useTryCatch'
import { useAccountContext } from '../../hooks/context/AccountContext'
import styles from './AiContext.module.scss'

const AiContext = () => {
	const navigate = useNavigate()

	const { message } = App.useApp()
	const tryCatch = useTryCatch(message)

	const accountContext = useAccountContext()
	const usingUserId = accountContext.useSubscribe((account) => account.usingUserId)

	const aiContext = useAiContext()
	const tabs = aiContext.useSubscribe((context) => context.tabs)
	const currentTabId = aiContext.useSubscribe((context) => context.currentTabId)

	const [editTabNameId, setEditTabNameId] = useState<number | undefined>()
	const [editedTabName, setEditedTabName] = useState<string | undefined>()
	const [loading, setLoading] = useState<boolean>(false)

	const tabsRef = useRef<HTMLDivElement>(null)

	useEffect(() => {
		return () => {
			aiContext.resetTabs()
		}
	}, [])

	useEffect(() => {
		if (!tabs.some((tab) => tab.id === currentTabId)) {
			aiContext.setCurrentTabId(tabs.length ? tabs[tabs.length - 1].id : null)
		}
	}, [tabs.length])

	const handleScroll = (event: WheelEvent<HTMLDivElement>) => {
		if (!tabsRef.current) {
			return
		}

		const delta = event.deltaY
		const scrollLeft = tabsRef.current.scrollLeft
		tabsRef.current.scrollLeft = scrollLeft + delta
		event.preventDefault()
	}

	const createNewTab = (type: SourceType, tab: Partial<Tab> = {}) => {
		const nextId = tabs.reduce((sum, tab) => tab.id + sum, 1)
		if (type === 'text') {
			const nextIndexName = tabs.filter((tab) => tab.type === 'text').length + 1
			const newTab: Tab = {
				id: nextId,
				type,
				name: `Text ${nextIndexName === 1 ? '' : nextIndexName}`,
				content: {
					text: '',
				},
				editing: false,
				...tab,
			}

			aiContext.addNewTab(newTab)
			aiContext.setCurrentTabId(newTab.id)
		}

		if (type === 'url') {
			const nextIndexName = tabs.filter((tab) => tab.type === 'url').length + 1
			const newTab: Tab = {
				id: nextId,
				type,
				name: `Website ${nextIndexName === 1 ? '' : nextIndexName}`,
				content: {
					url: '',
					isValidUrl: false,
				},
				editing: false,
				...tab,
			}

			aiContext.addNewTab(newTab)
			aiContext.setCurrentTabId(newTab.id)
		}

		if (type === 'file') {
			const nextIndexName = tabs.filter((tab) => tab.type === 'file').length + 1
			const newTab: Tab = {
				id: nextId,
				type,
				name: `Source file ${nextIndexName === 1 ? '' : nextIndexName}`,
				content: {
					file: null,
				},
				editing: false,
				...tab,
			}

			aiContext.addNewTab(newTab)
			aiContext.setCurrentTabId(newTab.id)
		}
	}

	const getCurrentTab = () => {
		return tabs.find((tab) => tab.id === currentTabId)
	}

	const deleteTab = (id: number) => {
		aiContext.deleteTab(id)
	}

	const handleTabNameSubmit = (event?: React.FormEvent<HTMLFormElement>) => {
		event?.preventDefault()
		if (editTabNameId === undefined || !editedTabName) {
			setEditedTabName(undefined)
			setEditTabNameId(undefined)
			return
		}

		aiContext.updateTab(editTabNameId, { name: editedTabName })
		setEditedTabName(undefined)
		setEditTabNameId(undefined)
	}

	const getContextDataFromTab = (tab: Tab): FormData => {
		const resource: Partial<Resource> = {
			name: tab.name,
			type: tab.type,
		}

		const newContext = new FormData()

		if (resource.type === 'text') {
			if ('text' in tab.content) {
				resource.text = tab.content.text
			} else {
				throw new Error('Missing text')
			}
		}
		if (resource.type === 'url') {
			if ('url' in tab.content && 'isValidUrl' in tab.content && tab.content.isValidUrl) {
				resource.url = tab.content.url

				if (tab.content.databaseIntegrationId) {
					resource.databaseIntegrationId = tab.content.databaseIntegrationId
				}
			} else {
				throw new Error('Missing or invalid url')
			}
		}
		if (resource.type === 'file') {
			if ('fileId' in tab.content && tab.content.fileId?.length) {
				resource.fileId = tab.content.fileId
			} else if ('file' in tab.content && tab.content.file) {
				newContext.append('file', tab.content.file)
			} else {
				throw new Error('Missing file')
			}
		}

		newContext.append('resource', JSON.stringify(resource))

		if (tab.editing) {
			newContext.append('id', `${tab.id}`)
		}

		if (!tab.editing && usingUserId) {
			newContext.append('usingUserId', usingUserId)
		}

		return newContext
	}

	const createOrUpdateContexts = async () => {
		if (loading || anyTextIsTooLong || !anyTabsWithContent) {
			return
		}

		setLoading(true)
		let hasErrors = false

		for (const tab of tabs) {
			await tryCatch(
				async () => {
					const contextData = getContextDataFromTab(tab)

					await query(tab.editing ? '/context/update' : '/context/create', 'POST', {
						headers: {
							'Content-Type': 'multipart/form-data',
						},
						data: contextData,
						withCredentials: true,
					})

					aiContext.deleteTab(tab.id)
				},
				() => {
					hasErrors = true
				},
				{ message: `Error while processing context: ${tab.name}` },
			)
		}

		setLoading(false)
		if (!hasErrors) {
			navigate('/contexts')
		}
	}

	const anyTabsWithInvalidUrl = useMemo(() => {
		return tabs.some((tab) => 'isValidUrl' in tab.content && !tab.content.isValidUrl)
	}, [tabs])

	const anyTabsWithContent = useMemo(() => {
		return tabs.some((tab) => !!Object.values(tab.content).filter((v) => !!v).length)
	}, [tabs])

	const anyTextIsTooLong = useMemo(() => {
		return tabs.some(
			(tab) => 'text' in tab.content && tab.content.text.length > MAX_TEXT_CONTENT_LENGTH,
		)
	}, [tabs])

	return (
		<section>
			<div className={styles.container}>
				<nav>
					<div className={styles.tabsContainer}>
						<div ref={tabsRef} onWheel={(e) => handleScroll(e)} className={styles.tabs}>
							{tabs.length ? (
								<>
									{tabs.map((tab) => (
										<div
											onDoubleClick={() => setEditTabNameId(tab.id)}
											className={`${styles.tab} ${currentTabId === tab.id ? styles.active : ''}`}
											key={`tab-${tab.id}`}
											onClick={() => aiContext.setCurrentTabId(tab.id)}
										>
											{editTabNameId === tab.id ? (
												<form onSubmit={(event) => handleTabNameSubmit(event)}>
													<InlineTextInput
														onBlur={() => handleTabNameSubmit()}
														value={editedTabName ?? tab.name}
														onChange={(e) => setEditedTabName(e.target.value)}
														autoFocus
													/>
												</form>
											) : (
												<div className={styles.tabContent}>
													<div className={styles.tabName}>{tab.name}</div>
													<div className={styles.icons}>
														<EditOutlined
															disabled={loading}
															onClick={() => setEditTabNameId(tab.id)}
															className={styles.tabIcon}
														/>
														<CloseOutlined
															disabled={loading}
															onClick={() => deleteTab(tab.id)}
															className={styles.tabIcon}
														/>
													</div>
												</div>
											)}
										</div>
									))}

									<div
										className={`${styles.tab} ${styles.addNewTab}`}
										onClick={() => !loading && aiContext.setCurrentTabId(null)}
									>
										<PlusOutlined disabled={loading} />
									</div>
								</>
							) : (
								<div onClick={() => aiContext.setCurrentTabId(null)} className={styles.tab}>
									Choose context source
								</div>
							)}
						</div>
					</div>
				</nav>

				<div className={styles.context}>
					<Context createNewTab={createNewTab} tab={getCurrentTab()} />
				</div>
			</div>

			<div className={styles.gradientBar} />

			<div className={styles.saveButtonWrapper}>
				<Button size='large' onClick={() => navigate('/contexts')}>
					Cancel
				</Button>

				<Button
					size='large'
					type='primary'
					disabled={loading || anyTextIsTooLong || anyTabsWithInvalidUrl || !anyTabsWithContent}
					loading={loading}
					onClick={createOrUpdateContexts}
				>
					Save
				</Button>
			</div>
		</section>
	)
}

export default AiContext
