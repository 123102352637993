import { PublicProgressStep } from '@neuron/types/progressSteps'
import React from 'react'
import { CheckOutlined } from '@ant-design/icons'
import { InlinePulseLoading } from '../../../../../../Loading/Loading'
import styles from './MessageProgressSteps.module.scss'

type Props = {
	messageProgressSteps: PublicProgressStep[]
}

const MessageProgressSteps = ({ messageProgressSteps }: Props) => {
	return (
		<>
			{messageProgressSteps.map((step) => (
				<div key={step.id} className={styles.progressStepContainer}>
					{getStatusIcon(step)}
					{step.status === 'failed' ? (
						<div className={styles.progressStepText}>Failed: {step.currentStepName}</div>
					) : (
						<div
							className={`${styles.progressStepText} ${
								step.status !== 'succeeded' ? styles.loadingStepText : ''
							}`}
						>
							{step.currentStepPercent ? `${step.currentStepPercent}% ` : ''}
							{step.currentStepName}
						</div>
					)}
				</div>
			))}
		</>
	)
}

const getStatusIcon = (progressStep: PublicProgressStep) => {
	if (progressStep.status === 'succeeded') {
		return (
			<div className={styles.succeededIconWrapper}>
				<CheckOutlined className={styles.succeededIcon} />
			</div>
		)
	}

	return (
		<InlinePulseLoading
			iconClassName={styles.progressStepLoading}
			size={10}
			color={progressStep.status === 'failed' ? '#c75a56' : '#DCF1E2'}
		/>
	)
}

export default MessageProgressSteps
