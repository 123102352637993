import React, { PropsWithChildren } from 'react'
import { createStateContext } from '../createStateContext'
import { ChatDataContext, FileData } from '../../types/ChatContext'
import { UploadFileRef } from '../../components/UploadFileWrapper/UploadFileWrapper'
import { MIN_CHAT_FOOTER_HEIGHT } from '../../utils/consts'
import { PublicProgressStep } from '@neuron/types/progressSteps'
import { PublicChat, PublicSession } from '../../types/Chat'

const chatInitialState: ChatDataContext = {
	chatSessions: [],
	attachedSessionIds: [],
	progressSteps: [],
	chatFiles: [],
	attachedFileIds: [],
	disableNewMessage: false,
	blurMessages: false,
	sessionsCollapsed: false,
	userScrolling: false,
	showChatDropzone: false,
	isHardcodedFooterHeight: false,
	footerHeight: MIN_CHAT_FOOTER_HEIGHT,
}

const ChatContext = createStateContext(chatInitialState, (setStore) => ({
	setChat(chat: Partial<PublicChat>) {
		setStore((store) => {
			return {
				...store,
				chat: {
					...(store.chat ?? ({} as PublicChat)),
					...chat,
				},
			}
		})
	},
	setChatSessions(chatSessions: PublicSession[]) {
		setStore((store) => {
			return {
				...store,
				chatSessions,
			}
		})
	},
	setCurrentChatSession(chatSession: PublicSession | undefined) {
		setStore((store) => {
			return {
				...store,
				currentChatSession: chatSession,
			}
		})
	},
	addChatFile(file: FileData) {
		setStore((store) => {
			return {
				...store,
				chatFiles: [...store.chatFiles, file],
			}
		})
	},
	removeChatFile(fileId: string) {
		setStore((store) => {
			const findFileData = store.chatFiles.find(({ id }) => id === fileId)

			if (findFileData && findFileData.file) {
				window.URL.revokeObjectURL(findFileData.url)

				return {
					...store,
					chatFiles: store.chatFiles.filter(({ id }) => id !== fileId),
				}
			}

			return store
		})
	},
	clearChatFiles() {
		setStore((store) => {
			for (const fileData of store.chatFiles) {
				if (fileData.file) {
					window.URL.revokeObjectURL(fileData.url)
				}
			}

			return {
				...store,
				chatFiles: [],
			}
		})
	},
	setProgressSteps(progressSteps: PublicProgressStep[]) {
		setStore((store) => {
			return {
				...store,
				progressSteps,
			}
		})
	},
	setProgressStep(progressStep: PublicProgressStep) {
		setStore((store) => {
			let progressSteps: PublicProgressStep[]

			if (store.chat?.isAssistant) {
				progressSteps = store.progressSteps.filter(
					(step) => step.conversationMessageId === progressStep.conversationMessageId,
				)
			} else {
				progressSteps = store.progressSteps.filter(
					(step) => step.messageId === progressStep.messageId,
				)
			}

			const isExist = progressSteps.some((step) => step.id === progressStep.id)
			return {
				...store,
				progressSteps: isExist
					? progressSteps.map((step) => (step.id === progressStep.id ? progressStep : step))
					: [...progressSteps, progressStep],
			}
		})
	},
	clearProgressSteps() {
		setStore((store) => {
			return {
				...store,
				progressSteps: [],
			}
		})
	},
	addAttachedFileId(fileId: string) {
		setStore((store) => {
			return {
				...store,
				attachedFileIds: [...store.attachedFileIds, fileId],
			}
		})
	},
	removeAttachedFileId(fileId: string) {
		setStore((store) => {
			return {
				...store,
				attachedFileIds: store.attachedFileIds.filter(
					(attachedFileId) => attachedFileId !== fileId,
				),
			}
		})
	},
	setChatContext(data: Partial<ChatDataContext>) {
		setStore((store) => {
			return {
				...store,
				...data,
			}
		})
	},
	setDisableNewMessage(disableNewMessage: boolean) {
		setStore((store) => {
			return {
				...store,
				disableNewMessage,
			}
		})
	},
	setUserScrolling(scrolling: boolean) {
		setStore((store) => {
			return {
				...store,
				userScrolling: scrolling,
			}
		})
	},
	setShowChatDropzone(showChatDropzone: boolean) {
		setStore((store) => {
			return {
				...store,
				showChatDropzone,
			}
		})
	},
	setUploadChatFileRef(uploadChatFileRef: UploadFileRef) {
		setStore((store) => {
			return {
				...store,
				uploadChatFileRef,
			}
		})
	},
}))

export const ChatContextProvider = ({ children }: PropsWithChildren<{}>) => (
	<ChatContext.Provider>{children}</ChatContext.Provider>
)

export const useChatContext = ChatContext.useContext
