import { useAccountContext } from '../hooks/context/AccountContext'
import { io, Socket } from 'socket.io-client'
import { useEffect } from 'react'
import { getCookies } from '@neuron/utils'

export let socket: Socket = {
	// eslint-disable-next-line
	on: (_ev: any, _listener: any): any => null,
	// eslint-disable-next-line
	emit: <Ev extends string>(_ev: Ev, ..._args: any[]): any => null,
	off: (): any => null,
} as Socket

const SocketManager = () => {
	const cookies = getCookies()

	const accountContext = useAccountContext()
	const accountId = accountContext.useSubscribe((account) => account.id)
	const usingUserId = accountContext.useSubscribe((account) => account.usingUserId)

	useEffect(() => {
		if (!accountId || cookies['hasRefresh'] !== '1') {
			return
		}

		socket = io('/', {
			path: '/socket.io',
			withCredentials: true,
		})

		socket.on('connect', () => {
			socket.emit('accountId', accountId)
			if (usingUserId) {
				socket.emit('accountId', usingUserId)
			}
		})

		return () => {
			if (socket) {
				socket.disconnect()
			}
		}
	}, [accountId, usingUserId, cookies['hasRefresh']])

	return <></>
}

export default SocketManager
