import React, { useMemo, useState } from 'react'
import { Button, DatePicker, Form, InputNumber, Slider, Switch } from 'antd'
import Joi from 'joi'
import { BASE_TRAINING_COSTS } from '@neuron/types/order'
import { CreateAgreementRequest } from '@neuron/types/agreement'
import { createAgreementSchema } from '@neuron/schemas/agreement'
import { formValidate } from '../../../utils/validation'
import FormRow from '../../../components/FormRow/FormRow'
import { utcDate } from '@neuron/utils/dates'
import dayjs from 'dayjs'
import styles from './AgreementForm.module.scss'

type Props = {
	userId: string
	onSubmitData: (formData: CreateAgreementRequest) => Promise<void>
}

const DATE_FORMAT = 'DD-MM-YYYY'

const AgreementForm = ({ onSubmitData, userId }: Props) => {
	const [agreementFormData, setAgreementFormData] = useState<CreateAgreementRequest>({
		userId,
		businessTurnoverLevel: 1,
		enterprise: false,
		trainingDays: 1,
		trainingAdditionalCost: 0,
		startAt: utcDate(),
	})

	const updateAgreementFormData = (_value: Record<string, any>, values: CreateAgreementRequest) => {
		setAgreementFormData((agreementData) => formDataToData({ ...agreementData, ...values }))
	}

	const baseTrainingCost = useMemo(() => {
		if (
			agreementFormData.enterprise ||
			(agreementFormData.trainingDays && agreementFormData.trainingDays >= 5)
		) {
			return BASE_TRAINING_COSTS[3]
		}
		if (agreementFormData.businessTurnoverLevel) {
			return BASE_TRAINING_COSTS[agreementFormData.businessTurnoverLevel - 1]
		}
		return 0
	}, [agreementFormData])

	const isFormValid = useMemo(() => {
		const validate = formValidate(agreementFormData, agreementFormSchema)
		return validate ? !Object.keys(validate).length : true
	}, [agreementFormData])

	return (
		<Form
			className={styles.form}
			initialValues={dataToFormData(agreementFormData)}
			onValuesChange={updateAgreementFormData}
			labelCol={{ span: 4 }}
			wrapperCol={{ span: 14 }}
			layout='vertical'
		>
			<Form.Item
				name='businessTurnoverLevel'
				label='Business turnover'
				className={styles.formItem}
				rules={[{ required: true, message: 'Please select a business turnover level' }]}
			>
				<Slider
					className={styles.turnoverSlider}
					min={1}
					max={4}
					marks={{
						1: '0-1 mln',
						2: '1-4 mln',
						3: '4-10 mln',
						4: '10+ mln',
					}}
				/>
			</Form.Item>

			<FormRow columns={[2, 2, 1]}>
				<Form.Item
					label='Agreement start date'
					name='startAt'
					className={styles.formItem}
					rules={[{ required: true, message: 'Please enter an start date' }]}
				>
					<DatePicker size='large' format={DATE_FORMAT} maxDate={dayjs(utcDate())} />
				</Form.Item>

				<Form.Item
					name='trainingDays'
					label='Number of Training Days'
					className={styles.formItem}
					rules={[
						{ required: true, message: 'Please enter the number of training days' },
						{ type: 'number', min: 1, message: 'Training days must be at least 1' },
						{ type: 'number', max: 10, message: 'Training days cannot exceed 10' },
					]}
				>
					<InputNumber min={1} max={10} size='large' />
				</Form.Item>

				<Form.Item
					name='enterprise'
					label='Enterprise'
					valuePropName='checked'
					className={styles.formItem}
					rules={[{ required: true, message: 'Please indicate if this is an enterprise' }]}
				>
					<Switch />
				</Form.Item>
			</FormRow>

			<FormRow>
				<Form.Item
					className={styles.formItem}
					label='Additional training cost'
					name='trainingAdditionalCost'
					rules={[
						{ required: true, message: 'Please enter an additional cost' },
						{ type: 'number', min: 0, message: 'Additional cost cannot be negative' },
						{ type: 'number', max: 200000, message: 'Additional cost cannot exceed 200000' },
					]}
				>
					<InputNumber min='0' max='200000' size='large' addonAfter='PLN' precision={2} />
				</Form.Item>

				<div className={styles.dealCostWrapper}>
					<div>Base training cost: {baseTrainingCost} PLN</div>
					<div>Additional cost: {agreementFormData.trainingAdditionalCost} PLN</div>
					<hr />
					<div>
						Total training cost:{' '}
						<b>{baseTrainingCost + agreementFormData.trainingAdditionalCost} PLN</b>
					</div>
				</div>
			</FormRow>

			<div className={styles.buttons}>
				<Button
					type='primary'
					disabled={!isFormValid}
					size='large'
					onClick={() => isFormValid && onSubmitData(agreementFormData)}
				>
					Save
				</Button>
			</div>
		</Form>
	)
}

const dataToFormData = (agreementData: CreateAgreementRequest) => {
	return {
		...agreementData,
		startAt: dayjs(agreementData.startAt),
	}
}

const formDataToData = (agreementData: CreateAgreementRequest) => {
	return {
		...agreementData,
		startAt: dayjs(agreementData.startAt).toDate(),
	}
}

const agreementFormSchema = Joi.object(createAgreementSchema).required()

export default AgreementForm
