import React, { useEffect, useState } from 'react'
import { ChatMessage } from '@neuron/types/chat/chatMessage'
import { ERROR_CONTENT, messageRole } from '@neuron/utils/consts'
import { messageContentIsPending } from '@neuron/utils/assistant'
import { ChatSession } from '@neuron/types/chat/chatSession'
import { App } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'
import { PublicProgressStep } from '@neuron/types/progressSteps'
import { v4 as uuidv4 } from 'uuid'
import { ConversationMessage } from '@neuron/types/assistant/conversationMessage'
import { Conversation } from '@neuron/types/assistant/conversation'
import { AssistantRole, AssistantRoleType } from '@neuron/types/assistant/assistantRole'
import { PublicMessage, PublicSession } from '../../../../../types/Chat'
import useTryCatch from '../../../../../hooks/useTryCatch'
import useQuery from '../../../../../hooks/useQuery'
import { useEditorContext } from '../../../../../hooks/context/EditorContext'
import { useChatContext } from '../../../../../hooks/context/ChatContext'
import { socket } from '../../../../../global/SocketManager'
import useAsyncEffect from '../../../../../hooks/useAsyncEffect'
import query from '../../../../../utils/query'
import { toPublicChat, toPublicMessage, toPublicSession } from '../../../../../utils/chatMappers'
import {
	assistantRoleTypeToName,
	checkLastFetchedMessage,
	getUpdatedMessages,
} from '../../../../../utils/helpers'
import { MIN_CHAT_FOOTER_HEIGHT } from '../../../../../utils/consts'
import Loading from '../../../../../components/Loading/Loading'
import Chat from '../../../../../components/Chat/Chat'
import { useAssistantRoleContext } from '../../../../../hooks/context/AssistantRoleContext'
import { utcDate } from '@neuron/utils/dates'
import { Assistant } from '@neuron/types/assistant'
import { useAccountContext } from '../../../../../hooks/context/AccountContext'
import styles from './Playground.module.scss'

const RESPONSE_MESSAGE_TIMEOUT = 1000 * 60 * 5
const LOADING_MESSAGE_TIMEOUT = 1000 * 60 * 5

const getDefaultMessage = (roleFirstname?: string, roleType?: AssistantRoleType): PublicMessage => {
	const templateMessage: PublicMessage = {
		id: '0',
		sessionId: '',
		role: messageRole.SYSTEM,
		content: '',
		createdAt: utcDate(),
	}

	if (!roleFirstname || !roleType) {
		return templateMessage
	}

	templateMessage.content =
		`Hi, I am **${roleFirstname}**, your **${assistantRoleTypeToName(
			roleType,
		)} AI Persona**! 🚀🔥\n` +
		'You are in a place where you can **test me**! 💪 Messages written in this ' +
		'chat are **private** and are **only for testing** the configuration of your Persona. 🤖✨'

	return templateMessage
}

const Playground = () => {
	const navigate = useNavigate()
	const { message } = App.useApp()
	const tryCatch = useTryCatch(message)

	const { assistantId, assistantRoleId } = useParams()
	const conversationId = useQuery().get('conversationId')

	const assistantRoleContext = useAssistantRoleContext()
	const editorContext = useEditorContext()

	const chatContext = useChatContext()
	const chat = chatContext.useSubscribe((context) => context.chat)
	const endedSession = chatContext.useSubscribe((context) => context.currentChatSession?.ended)
	const disableNewMessage = chatContext.useSubscribe((context) => context.disableNewMessage)

	const accountContext = useAccountContext()
	const usingUserId = accountContext.useSubscribe((account) => account.usingUserId)

	const [messages, setMessages] = useState<PublicMessage[]>([])
	const [scrollDownVisible, setScrollDownVisible] = useState<boolean>(false)

	useEffect(() => {
		socket.on('updateConversationMessage', updateConversationMessage)
		socket.on('updateConversation', updateConversation)
		return () => {
			socket.off('updateConversationMessage', updateConversationMessage)
			socket.off('updateConversation', updateConversation)
		}
	}, [conversationId])

	useEffect(() => {
		socket.on('updatedProgressStep', updateProgressSteps)
		return () => {
			socket.off('updatedProgressStep', updateProgressSteps)
		}
	}, [messages])

	useEffect(() => {
		assistantRoleContext.setValidStep(true)
	}, [])

	useEffect(() => {
		if (!messages.length && chat?.roleFirstname && chat?.roleType) {
			const defaultMessage = getDefaultMessage(chat.roleFirstname, chat.roleType)

			setMessages([defaultMessage])
			setScrollDownVisible(false)
			chatContext.setDisableNewMessage(false)
		}
	}, [!!messages.length, chat])

	useAsyncEffect(async () => {
		if (!assistantId || !assistantRoleId) {
			return
		}

		await tryCatch(
			async () => {
				const assistant = await query<Assistant>('/assistant/get', 'GET', {
					params: { id: assistantId },
					withCredentials: true,
				})
				const assistantRole = await query<AssistantRole>('/assistant/role/get', 'GET', {
					params: { id: assistantRoleId },
					withCredentials: true,
				})
				chatContext.setChat(toPublicChat(assistantRole, assistant.firstname))
			},
			undefined,
			{ message: 'Conversation data error. Try again and refresh page.' },
		)
	}, [assistantId, assistantRoleId])

	useAsyncEffect(async () => {
		if (!assistantRoleId) {
			navigate(-1)
			return
		}

		await fetchConversations()
	}, [assistantRoleId, chat?.integrations])

	useAsyncEffect(async () => {
		if (conversationId && chat && endedSession !== undefined) {
			setScrollDownVisible(false)
			await fetchAssistantMessages()
		}
	}, [conversationId, endedSession, chat])

	const fetchAssistantMessages = async () => {
		await tryCatch(async () => {
			const resMessages = await query<ChatMessage[]>('/assistant/messages/forConversation', 'GET', {
				params: {
					conversationId,
				},
				withCredentials: true,
			})
			const publicMessages = resMessages.map(toPublicMessage)

			let shouldDisableNewMessage = true
			const fetchedMessages = [
				getDefaultMessage(chat?.roleFirstname, chat?.roleType),
				...publicMessages,
			]

			if (!endedSession) {
				shouldDisableNewMessage = checkLastFetchedMessage(
					fetchedMessages,
					loadMessageProgressSteps,
					RESPONSE_MESSAGE_TIMEOUT,
					LOADING_MESSAGE_TIMEOUT,
				)
			}

			chatContext.setDisableNewMessage(shouldDisableNewMessage)
			chatContext.setUserScrolling(false)
			setMessages(fetchedMessages)
		}, newConversation)
	}

	const loadMessageProgressSteps = async (conversationMessage: PublicMessage) => {
		await tryCatch(
			async () => {
				const publicProgressSteps = await query<PublicProgressStep[]>('/progressStep/get', 'GET', {
					withCredentials: true,
					params: {
						conversationMessageId: conversationMessage.id,
					},
				})
				chatContext.setProgressSteps(publicProgressSteps)
			},
			() => {},
			{ hideMessage: true },
		)
	}

	const fetchConversations = async () => {
		if (!conversationId) {
			chatContext.setCurrentChatSession(undefined)
		}

		if (!chat?.integrations?.length) {
			return []
		}

		const findPlaygroundIntegration = chat.integrations.find(
			(integration) => integration.provider === 'playground',
		)
		if (!findPlaygroundIntegration) {
			return []
		}

		await tryCatch<PublicSession[]>(
			async () => {
				const resConversations = await query<ChatSession[]>(
					'/assistant/conversation/forIntegration',
					'GET',
					{
						params: {
							integrationId: findPlaygroundIntegration.id,
						},
						withCredentials: true,
					},
				)

				if (resConversations.length) {
					const publicSessions = resConversations.map(toPublicSession)
					chatContext.setChatSessions(publicSessions)
					if (conversationId) {
						const findCurrentChatSession = publicSessions.find(
							(session) => session.id === conversationId,
						)
						chatContext.setCurrentChatSession(findCurrentChatSession)
					}
				}
				return []
			},
			() => {},
		)
	}

	const updateConversationMessage = (updatedMessage: ConversationMessage) => {
		if (!conversationId || conversationId !== updatedMessage.conversationId) {
			return
		}

		setMessages((messages) => getUpdatedMessages(messages, updatedMessage))

		if (
			updatedMessage.role === messageRole.ASSISTANT &&
			!messageContentIsPending(updatedMessage.content)
		) {
			chatContext.setDisableNewMessage(false)
		}
	}

	const updateConversation = (updatedConversation: Conversation) => {
		const publicUpdatedSession = toPublicSession(updatedConversation)

		if (!conversationId) {
			chatContext.setChatSessions([
				publicUpdatedSession,
				...chatContext.storeRef.current!.chatSessions,
			])
			moveToConversation(updatedConversation.id)
		} else {
			chatContext.setChatSessions(
				chatContext.storeRef.current!.chatSessions.map((chatSession) => {
					if (chatSession.id === updatedConversation.id) {
						return publicUpdatedSession
					} else {
						return chatSession
					}
				}),
			)
			if (updatedConversation.id === conversationId) {
				chatContext.setCurrentChatSession(publicUpdatedSession)
			}
		}
	}

	const updateProgressSteps = (progressStep: PublicProgressStep) => {
		if (messages.some((message) => message.id === progressStep.conversationMessageId)) {
			chatContext.setProgressStep(progressStep)
		}
	}

	const newConversation = () => {
		navigate({ search: '' })
		setMessages([])
	}

	const moveToConversation = (conversationId: string) => {
		navigate({ search: `?conversationId=${conversationId}` })
	}

	const deleteConversation = async (selectedConversationId: string) => {
		await tryCatch(async () => {
			await query('/assistant/conversation/delete', 'POST', {
				data: {
					id: selectedConversationId,
				},
				withCredentials: true,
			})
			chatContext.setChatSessions(
				chatContext.storeRef.current!.chatSessions.filter(
					(session) => session.id !== selectedConversationId,
				),
			)

			if (conversationId === selectedConversationId) {
				newConversation()
			}
		})
	}

	const sendNewMessage = async (messageContent: string) => {
		if (disableNewMessage || chat?.status !== 'active' || !messages.length) {
			return
		}

		chatContext.setChatContext({
			disableNewMessage: true,
			userScrolling: false,
			footerHeight: MIN_CHAT_FOOTER_HEIGHT,
		})
		chatContext.clearProgressSteps()
		editorContext.clearEditor()

		await startChatWithAssistant(messageContent)
	}

	const startChatWithAssistant = async (messageContent: string) => {
		await tryCatch(
			async () => {
				await query('/assistant/message/userCreate', 'POST', {
					data: {
						conversationId,
						assistantRoleId,
						content: messageContent,
						usingUserId,
					},
					timeout: RESPONSE_MESSAGE_TIMEOUT,
					withCredentials: true,
				})
			},
			() => {
				const userTemplateMessage: PublicMessage = {
					id: uuidv4(),
					sessionId: '',
					createdAt: utcDate(),
					content: messageContent,
					role: messageRole.USER,
				}
				const assistantTemplateMessage: PublicMessage = {
					id: uuidv4(),
					sessionId: '',
					createdAt: utcDate(),
					content: ERROR_CONTENT,
					role: messageRole.ASSISTANT,
				}

				setMessages((messages) => [...messages, userTemplateMessage, assistantTemplateMessage])
				chatContext.setDisableNewMessage(false)
			},
			{ hideMessage: true },
		)
	}

	if (!chat) {
		return <Loading size={48} />
	}

	return (
		<div className={styles.chatWrapper}>
			<Chat
				messages={messages}
				sessionId={conversationId}
				sendNewMessage={sendNewMessage}
				newSession={newConversation}
				moveToSession={moveToConversation}
				deleteSession={deleteConversation}
				scrollDownVisible={scrollDownVisible}
				setScrollDownVisible={setScrollDownVisible}
				contentCustomHeight={338}
			/>
		</div>
	)
}

export default Playground
